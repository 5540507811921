import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';

import { PaymentService } from '../shared/payment.service';
import { DbLookUpService } from '../shared/db-look-up.service';
import { LoginService } from './login.service';
import { HelperRtnsComponent } from '../shared/helper-rtns.component';
import { ICustomer } from '../shared/interfaces/customer'
import { INewRegistration } from '../shared/interfaces/new-registration';
import { ModalService } from '../shared/modal.service';

@Component({
    selector: 'mb-registernewclient',
    templateUrl: 'register-newclient.component.html',
    providers: [PaymentService,
        HelperRtnsComponent,
        DbLookUpService,
        LoginService,
        ModalService]
})
export class RegisterNewclientComponent implements OnInit {
    constructor(private _helperRtnsComponent: HelperRtnsComponent,
        private _loginService: LoginService,
        private _modalService: ModalService,
        private _dbLookUpService: DbLookUpService) { }

    @Input() engLang: boolean;
    @Input() cust: ICustomer;

    custCompanyNm: string;
    custLastNm: string;
    custFirstNm: string;
    custMbDesktopSN: string;
    newEmail: string;
    password2: string;

    custEmailErr: boolean = false;
    custUserNmErr: boolean = false;
    custPasswordInvalidErr: boolean = false;
    password2InvalidErr: boolean = false;
    custPasswordMismatchErr: boolean = false;
    custCompanyNmErr: boolean = false;
    custLastNmErr: boolean = false;
    custFirstNmErr: boolean = false;
    custCellPhoneErr: boolean = false;
    custOtherPhoneErr: boolean = false;
    custMbDesktopSNErr: boolean = false;

    @ViewChild('proceedToRegister', { static: true }) proceedToRegister: ElementRef;

    unFilled: string = '?????';
    customerName: string;
    customerNameColor: string = 'red';
    customerFullLegalName: string;
    customerFullLegalNameColor: string = 'red';
    customerTypedSignatureErr: boolean = false;
    signedDateErr: boolean = false;
    accepted: boolean = false;
    acceptedErr: boolean = false;
    connErr: boolean = false;
    msgBoxMessage: string = undefined;
    msgBoxImgSrc: string = undefined;
    msgBoxImgWidth: string = undefined;
    msgBoxImgHeight: string = undefined;
    msgBoxImgAlt: string = undefined;
    msgBoxTitle: string = undefined;
    msgBoxClose: boolean = true;
    modalForm: string = '';
    waiting4Response: boolean = false;
    signInErrMsg: string = undefined;
    signInErr: boolean = false;
    messageResult: boolean = false;
    allowPrint: boolean = false;

    newRegistration: INewRegistration = {
        customer: undefined,
    };

    ngOnInit(): void {
        this.custCompanyNm = this.unFilled;
        this.customerFullLegalName = this.unFilled + ' ' + this.unFilled;
        this.setUnfilledColor();
    }

    updateDisclaimer(): void {
        this.custCompanyNm = (this.custCompanyNm && this.custCompanyNm.trim().length > 0) ? this.custCompanyNm.trim() : this.unFilled;
        this.custFirstNm = (this.custFirstNm && this.custFirstNm.trim().length > 0 ? this.custFirstNm.trim() : this.unFilled)
        this.custLastNm = (this.custLastNm && this.custLastNm.trim().length > 0 ? this.custLastNm.trim() : this.unFilled);

        if (!this.custLastNm || this.custLastNm.trim().length == 0 || this.custLastNm == this.unFilled) {
            if (!this.custCompanyNm || this.custCompanyNm.trim().length == 0 || this.custCompanyNm == this.unFilled) {
                this.customerName = this.unFilled + ' ' + this.custFirstNm ? this.custFirstNm : this.unFilled;
            } else {
                this.customerName = this.custCompanyNm;
            }
        } else {
            if (!this.custCompanyNm || this.custCompanyNm.trim().length == 0 || this.custCompanyNm == this.unFilled) {
                this.customerName = this.custLastNm + ' ' + this.custFirstNm ? this.custFirstNm : this.unFilled;
            }
        }
        if (!this.custFirstNm || this.custFirstNm.trim().length == 0 || this.custFirstNm == this.unFilled) {
            if (!this.custCompanyNm || this.custCompanyNm.trim().length == 0 || this.custCompanyNm == this.unFilled) {
                this.customerName = this.custLastNm ? this.custLastNm : this.unFilled + ' ' + this.unFilled;
            } else {
                this.customerName = this.custCompanyNm;
            }
        } else {
            this.customerFullLegalName = this.custLastNm ? this.custLastNm : this.unFilled + ' ' + this.custFirstNm;
        }
        if (!this.custCompanyNm || this.custCompanyNm.trim().length == 0 || this.custCompanyNm == this.unFilled) {
            this.customerName = this.custFirstNm + ' ' + this.custLastNm;
        } else {
            this.customerName = this.custCompanyNm;
        }

        this.customerFullLegalName = this.custFirstNm + ' ' + this.custLastNm;
        if (this.customerName == this.unFilled + ' ' + this.unFilled) {
            this.customerName = this.unFilled;
        }

        this.setUnfilledColor();
    }

    setUnfilledColor(): void {
        if (this.custLastNm == this.unFilled || this.custFirstNm == this.unFilled) {
            this.customerNameColor = 'red'
        } else {
            this.customerNameColor = 'black';
        }

        if (this.customerFullLegalName.indexOf(this.unFilled) > -1) {
            this.customerFullLegalNameColor = 'red'
        } else {
            this.customerFullLegalNameColor = 'black';
        }
    }

    register(): boolean {
        var scrollUp = false;   // to scroll up in case of entry error of fields on top of page
        this.waiting4Response = false;  // clear visual feedback of tcp transmission
        var goRegister = true;    // continue button navigation when true
        if (!this.newEmail || !this.newEmail.match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$|^\s*$/g)) {
            this.custEmailErr = true;    // displays error message
            goRegister = false;
            scrollUp = true;
        } else {
            this.custEmailErr = false;   // clears error message
            this.cust.email = this.newEmail;
        }

        if (!this.cust.userNm || !this.cust.userNm.trim().match(/^\w{3,20}$/gi)) {
            this.custUserNmErr = true;    // displays error message
            goRegister = false;
            scrollUp = true;
        } else {
            this.custUserNmErr = false;   // clears error message
        }

        if (!this.cust.password || this.cust.password.trim().length < 8 || this.cust.password.trim().length > 50) {
            this.custPasswordInvalidErr = true;
            goRegister = false;
            scrollUp = true;
        } else {
            this.custPasswordInvalidErr = false;
        }
        if (!this.password2 || this.password2.trim().length < 8 || this.password2.trim().length > 50) {
            this.password2InvalidErr = true;
            goRegister = false;
            scrollUp = true;
        } else {
            this.password2InvalidErr = false;
        }
        if (this.cust.password != this.password2) {
            this.custPasswordMismatchErr = true;
            goRegister = false;
            scrollUp = true;
        } else {
            this.custPasswordMismatchErr = false;
        }

        if (!this.cust.companyNm || this.cust.companyNm.match(/^[-\w &@()#+'%$.,ñÑü]*$/gi) || this.cust.companyNm.trim().length == 0) {
            this.custCompanyNmErr = false;
        } else {
            this.custCompanyNmErr = true;
            goRegister = false;   // block button navigation until error is fixed
            scrollUp = true;
        }
        if (!this.custLastNm || !this.custLastNm.match(/^[-a-z ]*$/gi) || this.custLastNm.trim().length == 0) {    // letters + space + - only
            this.custLastNmErr = true;
            goRegister = false;   // block button navigation until error is fixed
            scrollUp = true;
        } else {
            this.custLastNmErr = false;
            this.cust.lastNm = this.custLastNm;
        }
        if (!this.custFirstNm || !this.custFirstNm.match(/^[a-z ]*$/gi) || this.custFirstNm.trim().length == 0) {   // letters + space only
            this.custFirstNmErr = true;
            goRegister = false;   // block button navigation until error is fixed
            scrollUp = true;
        } else {
            this.custFirstNmErr = false;
            this.cust.firstNm = this.custFirstNm;
        }

        if (goRegister) { // if no err with names
            if (!this.cust.typedSignature || !this.cust.typedSignature.match(/^[-a-z ]*$/gi) || this.cust.typedSignature.trim().length == 0
                || !this.cust.typedSignature.match(new RegExp((this.custLastNm ? this.custLastNm.trim() : ''), 'gi'))
                || !this.cust.typedSignature.match(new RegExp((this.custFirstNm ? this.custFirstNm.trim() : ''), 'gi'))) {
                this.customerTypedSignatureErr = true;
                goRegister = false;
            } else {
                this.customerTypedSignatureErr = false;
            }
        }

        this.cust.signedDate = this.ckDateInStr(this.cust.signedDate);
        if ((this.cust.signedDate.length > 0 ? false : true)
            || (this._helperRtnsComponent.compDates(this.cust.signedDate, this._helperRtnsComponent.todaysDt(2)) == 0 ? false : true)) {
            this.signedDateErr = true;
            goRegister = false;
        } else {
            this.signedDateErr = false;
        }

        if (!this.ckPhone(this.cust.cellPhone)) {
            this.custCellPhoneErr = true;
            goRegister = false;
            scrollUp = true;
        } else {
            this.custCellPhoneErr = false;
        }

        if (this.cust.otherPhone && this.cust.otherPhone.match(/\d+/g) && !this.ckPhone(this.cust.otherPhone)) {
            this.custOtherPhoneErr = true;
            goRegister = false;
            scrollUp = true;
        } else {
            this.custOtherPhoneErr = false;
        }

        if (!this.accepted) {
            this.acceptedErr = true;
            goRegister = false;
        } else {
            this.acceptedErr = false;
        }

        if ((!this.custCompanyNm || this.custCompanyNm.trim().length == 0 || this.custCompanyNm.trim() == this.unFilled) && this.cust.firstNm && this.cust.firstNm) {
            this.custCompanyNm = (this.cust.firstNm + ' ' + this.cust.lastNm).toUpperCase();
        }
        this.cust.companyNm = this.custCompanyNm;

        if (this.custMbDesktopSN && !this.custMbDesktopSN.match(/^[a-fA-F0-9]{4}$/g)) {
            this.custMbDesktopSNErr = true;
            goRegister = false;
        }

        if (goRegister) {
            this.waiting4Response = true;

            this.cust.userNm = this.cust.userNm.toUpperCase();
            this.cust.companyNm = this.cust.companyNm.toUpperCase();
            this.cust.lastNm = this.cust.lastNm.toUpperCase();
            this.cust.firstNm = this.cust.firstNm.toUpperCase();
            this.cust.title = this.cust.title.toUpperCase();
            this.cust.typedSignature = this.cust.typedSignature.toUpperCase();
            this.cust.sn = this.custMbDesktopSN ? this.custMbDesktopSN.toUpperCase() : 'nul';

            this._loginService.postNewRegistration(this.cust)
                .subscribe(
                    returnedData => this.backFromPostNewRegistration(returnedData),
                    error => this.backFromPostNewRegistrationError(error)    // for any http error 
                );
        }

        if (scrollUp) { // only top form controls set scrollUp = true when in error
            window.scrollTo(0, 0);
        }

        return goRegister;
    }

    backFromPostNewRegistrationError(error: any): void {
        this.waiting4Response = false;
        var err: any = JSON.parse(error._body);

        // "{"name":"RequestError","message":"Cannot insert duplicate key row in object 'dbo.WebCustomers' with unique index 'idxEmail'. The duplicate key value is (whc1995@gmail.com).","code":"EREQUEST","number":2601,"lineNumber":31,"state":1,"class":14,"serverName":"medicalbiller-1","procName":"spWebNewClientRegistration","precedingErrors":[]}"
        // "{"name":"RequestError","message":"Cannot insert duplicate key row in object 'dbo.WebCustomers' with unique index 'idxUserNm'. The duplicate key value is (rene).","code":"EREQUEST","number":2601,"lineNumber":31,"state":1,"class":14,"serverName":"medicalbiller-1","procName":"spWebNewClientRegistration","precedingErrors":[]}"

        var dupKeyErr: string;
        if (err && err.message) {
            if (err.message.match(/idxEmail/g)) {
                dupKeyErr = 'email ' + err.message.match(/\(\w*@\w*\.\w*\)/g)[0];
            } else if (err.message.match(/idxUserNm/g)) {
                dupKeyErr = (this.engLang ? 'User name ' : 'nombre Usuario ') + err.message.match(/\(\w*\)/g)[0];
            } else if (err.message.match(/duplicate key/g)) {
                dupKeyErr = (this.engLang ? 'User name and/or email ' : 'nombre Usuario y/o Email ') + (err.message.match(/\(+.*\)+/g) ? err.message.match(/\(+.*\)+/g)[0] : '');
            } else {
                this.msgBoxMessage = (err.message ? err.message : error) + (err.procName ? ' Proc: ' + err.procName : '');
            }
        }

        this.msgBoxTitle = 'ERROR';
        if (dupKeyErr) {
            this.msgBoxMessage = this.engLang ? 'Looks like your ' + dupKeyErr + ' is already registered. Use a different one or try to recover your password if previously registered. Thank you!' :
                'Parece que su ' + dupKeyErr + ' ya está registrado(a). Trate uno diferente o trate de recuperar su password si ya fue resistrado(a). ¡Gracias!';
        }
        this.msgBoxImgSrc = 'app/assets/images/stopPare113x113.png';
        this.msgBoxImgWidth = '40';
        this.msgBoxImgHeight = '40';
        this.msgBoxImgAlt = this.engLang ? 'Information' : 'Información';
        this.openMessage();
    }

    backFromPostNewRegistration(returnedData: any): void {
        console.dir('returnedData=' + returnedData._body);
        this.waiting4Response = false;
        if (JSON.parse(returnedData._body)[0]['pKey'] > 0) {
            this.updateDisclaimer();
            this.cust.pKey = JSON.parse(returnedData._body)[0]['pKey'];
            this.cust.sn = JSON.parse(returnedData._body)[0]['sn'];

            this.proceedToRegister.nativeElement.style['display'] = 'none';

            this.msgBoxTitle = this.engLang ? 'Registration Succesful!' : 'Registro Exitoso!';
            this.msgBoxMessage = this.engLang ? 'We sent you an email to ' + this.cust.email + '. Please confirm your registration. Thank you!' :
                'Le enviamos un email a ' + this.cust.email + '. Por favor confirme su registo. ¡Gracias!';
            this.msgBoxImgSrc = 'app/assets/images/info.gif';
            this.msgBoxImgWidth = '40';
            this.msgBoxImgHeight = '40';
            this.msgBoxImgAlt = this.engLang ? 'Information' : 'Información';
            this.openMessage();

            var email = {
                "email": this.cust.email,
                "subject": (this.engLang ? 'Welcome to MedicalBiller!' : '¡Bienvenido al MedicalBiller!'),
                "text": "",
                "fullName": this.cust.firstNm + ' ' + this.cust.lastNm,
                "sn": this.cust.sn,
                "pKey": this.cust.pKey,
                "engLang": this.engLang,
                "emailType": 'welcome',
                "responseKey": encodeURIComponent('iVBORw0KGgoAAAANSUhEUgAAA1IAAAEsCAYAAADJ+kYKfAAAgAElEQVR/42uy9a5hc1Xnn~988ziROJvHu1qUFxKaEg5AENiXJyeQ4BlVzk4wdVPLJ+jO0PsarPBGRsie7G5HN3f4%pbjCWwczpUp4z58xzfFEJjHGQUJeIwcnJeFTiImTwRCUcELp09%ZkJnHOhfd82Lu6q6r3fa99q%r%e/Aqkpqq6au13r7V~6%IugBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBB+CCCGEEEIIIYQQQgghhBBCCCH2aCyC7HL/t2FIBgoL11yIAHQAE~JD1d0dEOv7m9pyTXT~ut%7w3mODdV4FQgghhBBCKFKZ43fHl0oACtbjFgC6yIo02TmQ~HjfACLl%j7mf5rWwwBw2vpvA0Dz4mODTV5FQgghhBBCkSKx8OHxpQLMWaQigJ2yIk%egiPBRarzfcSncLnKlBt1iLQkqw6gefEbayhYhBBCCCGEIkUCi1OpJU0ASmjNMPmQEy~R8itTCYqU3Ru1Zq1OWv~tX%zGGoORQQghhBBCKFJkmY88ZBQtYdoJSNlLULzkpOP1~RQpO5oA6gI5CaB~6Rtrm4wcQgghpDf5zf%9f9EBzMNpX7ePbQj~Oz2Bei1~~jUGgOF%~tLhBq8koUjFI09lAHssgSq0381RBUXF8j7F~6RUiFSrdFp%aAKoATh56fG1NUYUIYQQ0icSlW2RMiBCiSIUKdVc95BRacmTdC3X676bo8xK9YlIdVZa5v6qowBqlx5fy2WAhBBCSB4l6v%wIVFBZMr35nAfT%Z~L3Mm6k~rlChCkVIkT62ZpzJ873WSWEXKT12QeZFyf%MagKMiqF3~JqWKEEIIyYVE%SdLosSHRGVPpChRhCKlgsLXjAKAigj2wSG7XpwitcpNUtwnlZJItb9tDZCjl7~5rsrIJIQQQrLJB9tnogLJT4Ii5fwUQ9MoUYQiFVWgygD2wZx98nSEKCLldbvnKuFEvCLVeqJhShVmL39zHSs6QgghJIsSFVh~EhQpB4kCMPzPX6JEEYpUGHnSLXGagM3sU1iR8uMXPSNSAWQqgki10wAwC6B2~ZvruPSPEEIIyYpE5UukKFGEIhWGjQ8bOoAxAKMiTokjoshQ5H1STZgpwwGgCcH5gPXKzjYRKcDvAcD5EKn2CrAGYOryN9c1eUsTQgghKUtUfkSKEkUoUlEECl+byCP/HdoY9FpOow/Z1')
            }

            this._dbLookUpService.sendEmail(email)
                .subscribe(
                    returnedData => this.backFromSendEmail(returnedData),
                    error => console.log('%c' + error, 'color: red; background: yellow; font-size: 20px')
                );
        }
    }

    backFromSendEmail(returnedData: any): void {
        console.log('%c' + returnedData, 'color: green; background: yellow; font-size: 20px');
        this.allowPrint = true;
        // Use placeholder so input values print
        var email: HTMLInputElement = <HTMLInputElement>document.getElementById('newEmail');
        email.placeholder = this.cust.email;
        var custUserNm: HTMLInputElement = <HTMLInputElement>document.getElementById('custUserNm');
        custUserNm.placeholder = this.cust.userNm;
        var custCompanyNm: HTMLInputElement = <HTMLInputElement>document.getElementById('custCompanyNm');
        custCompanyNm.placeholder = this.cust.companyNm;
        var custMbDesktopSN: HTMLInputElement = <HTMLInputElement>document.getElementById('custMbDesktopSN');
        custMbDesktopSN.placeholder = this.cust.sn;
        var custLastNm: HTMLInputElement = <HTMLInputElement>document.getElementById('custLastNm');
        custLastNm.placeholder = this.cust.lastNm;
        var custFirstNm: HTMLInputElement = <HTMLInputElement>document.getElementById('custFirstNm');
        custFirstNm.placeholder = this.cust.firstNm;
        var custCellPhone: HTMLInputElement = <HTMLInputElement>document.getElementById('custCellPhone');
        custCellPhone.placeholder = this.cust.cellPhone;
        var custOtherPhone: HTMLInputElement = <HTMLInputElement>document.getElementById('custOtherPhone');
        custOtherPhone.placeholder = this.cust.otherPhone;
        var custTitle: HTMLInputElement = <HTMLInputElement>document.getElementById('custTitle');
        custTitle.placeholder = this.cust.title;
        var custSignedDate: HTMLInputElement = <HTMLInputElement>document.getElementById('custSignedDate');
        custSignedDate.placeholder = this.cust.signedDate;  
        var custTypedSignature: HTMLInputElement = <HTMLInputElement>document.getElementById('custTypedSignature');
        custTypedSignature.placeholder = this.cust.typedSignature;  
 }

    ckDateInStr(dts: string): string {
        var rx = new RegExp(/^[ ]?\d{1,2}[ ]?\/[ ]?\d{1,2}[ ]?\/\d{2}|\d{4}$/, 'g'); // allows 01/04/17 01/04/2017 1 /4 /17 1 /4 /2017  1/ 4/2017 etc.
        var dtStrOut: string;   // resulting formated date string outputed; '' if date error
        if (dts.match(rx)) {    // if input date dts has expected date pattern
            var dtDate = new Date(dts);    // convert to date type
            var mDate: number = 1 + dtDate.getMonth();    // month 0 based from Date type
            var dDate: number = dtDate.getDate();   //day of the month 1 based from Date type
            var yDate: number = dtDate.getFullYear();   // year 1 based from Date type

            var yrLen: number;  // year length, 2 or 4 digits
            if (dts.match(/\/\d{2}$/g)) {
                yrLen = 2;
            }
            if (dts.match(/\/\d{4}$/g)) {
                yrLen = 4;
            }

            dtStrOut = ('00' + mDate.toString()).slice(-2) + '/' + ('00' + dDate.toString()).slice(-2) + '/' + ('0000' + yDate.toString()).slice(-yrLen);   // use Date numbers to format a date string

            var mStr = dts.match(/^[ ]?\d{1,2}[ ]?/g).toString().trim() // month from input string type
            var dStr = dts.match(/\/[ ]?\d{1,2}[ ]?\//g).toString().replace(/\//g, "").trim();   // day from input string type
            /*            
            console.log('dtDate=' + dtDate);
            console.log('dtStrOut=' + dtStrOut);
            console.log('dt2s=' + ('00' + mStr).slice(-2) + '/' + ('00' + dStr).slice(-2) + '/' + ('0000' + yDate.toString()).slice(-yrLen));
            */
            if (dtStrOut == ('00' + mStr).slice(-2) + '/' + ('00' + dStr).slice(-2) + '/' + ('0000' + yDate.toString()).slice(-yrLen)) {    // if 02/30/16 is entered it will be compared to 03/01/16 & fail
                return dtStrOut;
            } else {
                return '';  // entered date is invalid
            }
        } else {
            return '';   // doesn't even resembles a date
        }
    }

    ckPhone(ph: string): boolean {
        if (!ph || ph.replace(/\D/g, '').length != 10) {
            return false;   // bad phone
        }
        return true;
    }

    openMessage() {
        this._modalService.message(
            this.msgBoxMessage,
            this.msgBoxTitle,
            this.msgBoxImgSrc,
            this.msgBoxImgAlt,
            this.msgBoxImgWidth,
            this.msgBoxImgHeight
        ).pipe(
            take(1) // take() manages unsubscription for us
        ).subscribe(result => {
            console.log('%c' + { messageResult: result }, 'color: green; background: yellow; font-size: 20px')
            this.messageResult = result;
        });
    }

}
