import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output, ViewChild, TemplateRef, HostListener, ElementRef } from '@angular/core';
import { DataMessageService } from "../shared/data-message.service";
import { WebsocketService } from "../shared/websocket.service";
import { take } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { HelperRtnsComponent } from "../shared/helper-rtns.component";
import { IPatients } from '../shared/interfaces/patients';
import { RecordService } from "./record.service";
import { NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDropdownConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../shared/modal.service';
import { IReferring } from '../shared/interfaces/referring';
import { SafePipe } from '../shared/safe.pipe';
import { PsPipe } from '../shared/ps.pipe';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ICommonElementStyles } from '../shared/interfaces/common-element-styles';
import { ILocalInsurances } from '../shared/interfaces/localInsurances';
import { ILocalProviders } from '../shared/interfaces/localProviders';
import { ILocalProductors } from '../shared/interfaces/localProductors';
import { ILocalReferring } from '../shared/interfaces/localReferring';
import { ILocalFacilities } from '../shared/interfaces/localFacilities';
import { ILocalICD10 } from '../shared/interfaces/localICD10';
import { ILocalZipCodes } from '../shared/interfaces/localZipCodes';
import { ICms1500 } from '../shared/interfaces/cms1500';
import { IDedcode } from '../shared/interfaces/dedcodeObj';
import { IXcecode } from '../shared/interfaces/xcecodeObj';
import { CustomDateParserFormatter } from '../shared/datepicker/custom-date-parser-formatter';
import { CustomAdapter } from '../shared/datepicker/custom-adapter';
import { ICase } from '../shared/interfaces/case';
import { ISubmitted } from '../shared/interfaces/submitted';
import { IRecord } from '../shared/interfaces/record';
import { IErrsRec } from '../shared/interfaces/errsRec';
import { IErrsCas } from '../shared/interfaces/errsCas';
import { IErrsProc } from '../shared/interfaces/errsProc';
import { IErrsDx } from '../shared/interfaces/errsDx';
import { IErrsPay } from '../shared/interfaces/errsPay';
import { CustomDateParserFormatterYYYY } from '../shared/datepicker/custom-date-parser-formatter-yyyy';
import { IProc } from '../shared/interfaces/proc';
import { IRecordsLastUsedParams } from '../shared/interfaces/recordsLastUsedParams';
import { AppToastsService } from '../shared/app-toasts/app-toasts.service';
import { TwilioService } from '../shared/twilio.service';
import { ISite } from '../shared/interfaces/site';
import { EventService } from '../shared/event.service';
import { X12UtilsService } from '../shared/x12-utils.service';
import { ILocalFormConfig } from '../shared/interfaces/localFormConfig';
import { ReclaimComponent } from '../shared/reclaim/reclaim.component';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.css'],
  imports: [ReclaimComponent],
  providers: [NgbActiveModal, SafePipe, PsPipe, NgbDropdownConfig,  // provideNgxMask(),
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterYYYY }]
})
export class RecordComponent implements OnInit, AfterViewInit {
  @Input() activeTab: number;  // ngbNav get/set ngbNavItem selected
  @Input() xtPID?: string; // Global Patient ID coming from external component
  @Input() xtCID?: string; // Global Case ID coming from external component
  @Input() xtLastNm?: string;  // Global fields coming from external componens
  @Input() xtFirstNm?: string;
  @Input() xtMidInit?: string;
  @Input() xtSex?: string;
  @Input() xtDob?: string;
  @Input() xtInsId1?: string;
  @Input() xtContr1?: string;
  @Input() xtGroup1?: string;
  @Input() xtInsId2?: string;
  @Input() xtContr2?: string;
  @Input() xtGroup2?: string;
  @Input() xtEmail?: string;
  @Input() xtTel?: string;
  @Input() xtCell?: string;
  @Input() xtTelWk?: string;
  @Input() xtRecNo?: string;
  @Input() xtRecOffice?: string;
  @Input() xtRecYr?: string;
  @Output() setNewRecordNoEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('errors') errsTmplt: TemplateRef<any>;
  @ViewChild('reclaimsExitWoSaveModal', { read: TemplateRef, static: true }) reclaimsExitWoSaveModal: TemplateRef<any>;
  @ViewChild('reclaimsModalTmpl', { read: TemplateRef, static: true }) reclaimsModalTmpl: TemplateRef<any>;

  @HostListener('window:scroll', ['$event']) onWindowScroll(event) {
    const srchBtn: HTMLButtonElement = <HTMLButtonElement>document.getElementById('back2srchBtn');
    if (srchBtn) {
      let vs = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log('window.scrollY',window.scrollY);
      if ((vs > 235)) {
        srchBtn.style.display = 'block';
      } else {
        srchBtn.style.display = 'none';
      }
    }
  }

  @Input() modalBody: ElementRef;

  winVertCtrUp: string; // Browser window vertical center for up arrow
  winVertCtrDn: string; // Browser window vertical center for dn arrow
  winVertBack2srch: string  // Back 2 search btn position
  winHorzCtr: string = '20px'; // Separation from right border to buttons position

  showExtHmlTst: boolean = false; // For testing only 
  qsTst: string = '?s=0001%26d=VazquezZayas%26pi=0%26it=(787)752-1490%26ie=cgodrlevz%40gmail.com%26o=CENTRO%20GINECO-OBSTETRICO%26l=en%26st=HIPAA%26st=H%26dt=112723'

  stylesCfg: ICommonElementStyles;

  showMnu1: boolean = false;
  floatMnu1_1: any = {
    icon: 'far fa-save',
    ttEng: 'Save',
    ttEsp: 'Grabar'
  }

  floatMnu1_2: any = {
    icon: 'fas fa-print',
    ttEng: 'Print CMS 1500 primary',
    ttEsp: 'Imprimir CMS 1500 primario'
  }

  floatMnu1_3: any = {
    icon: 'material-icons',
    ttEng: 'Create 837 primary',
    ttEsp: 'Crear 837 primario',
    html: 'rss_feed'
  }

  floatMnu1_4: any = {
    icon: '',
    ttEng: 'Other',
    ttEsp: 'Otro',
  }

  showMnu2: boolean = false;
  floatMnu2_1: any = {
    icon: 'far fa-save',
    ttEng: 'Save',
    ttEsp: 'Grabar'
  }

  floatMnu2_2: any = {
    icon: 'fas fa-print',
    ttEng: 'Print CMS 1500 primary',
    ttEsp: 'Imprimir CMS 1500 primario'
  }

  floatMnu2_3: any = {
    icon: 'material-icons',
    ttEng: 'Create 837 primary',
    ttEsp: 'Crear 837 primario',
    html: 'rss_feed'
  }

  floatMnu2_4: any = {
    icon: '',
    ttEng: '',
    ttEsp: '',
    html: ''
  }

  showMnu3: boolean = false;
  floatMnu3_1: any = {
    icon: 'far fa-save',
    ttEng: 'Save',
    ttEsp: 'Grabar'
  }

  floatMnu3_2: any = {
    icon: 'fas fa-print',
    ttEng: 'Print patient\'s invoice',
    ttEsp: 'Imprimir factura privada'
  }

  floatMnu3_3: any = {
    icon: '',
    ttEng: '',
    ttEsp: '',
    html: ''
  }

  floatMnu3_4: any = {
    icon: '',
    ttEng: '',
    ttEsp: '',
    html: ''
  }

  sn: string;
  site: ISite = {
    pk: undefined,
    nm: undefined,
    ad1: undefined,
    ad2: undefined,
    ct: undefined,
    st: undefined,
    zp: undefined,
    tl1: undefined,
    xt1: undefined,
    tl2: undefined,
    xt2: undefined,
    em1: undefined,
    em2: undefined
  }
  userID: string;
  userLastNm: string;
  userFirstNm: string;
  engLang: boolean;
  userSecOpts: any;
  sioSubscrpt: Subscription;
  hideBillHx: boolean;
  ipadPortrait: boolean = false;
  isSigning: boolean = false;
  noSigning: boolean = false;
  errsMsgs: string[];

  localProviders: ILocalProviders[] = this._recordService.localProviders;
  localInsurances: ILocalInsurances[] = this._recordService.localInsurances;
  localProductors: ILocalProductors[] = this._recordService.localProductors;
  localReferring: ILocalReferring[] = this._recordService.localReferring;
  localFacilities: ILocalFacilities[] = this._recordService.localFacilities;
  insLstObj: ILocalInsurances;
  localICD10: ILocalICD10[] = this._recordService.localICD10;
  localZipCodes: ILocalZipCodes[] = this._recordService.localZipCodes;
  paymntModes: [{}] = this._recordService.paymntModes;

  // search parameters
  lastUsedParam: IRecordsLastUsedParams = {
    lastNm: '',
    telNo: '',
    recNo: '',
    casDt: '',
    contrNo: '',
    topRecRows: '10',
    topCasRows: '5',
  };

  lastSrchParm: string; // Last parameter used for search
  openedRecSrchPid: number = -1;

  pat: IPatients = {
    patID: '',
    recNo: '',
    recLocale: '',
    recYr: new Date().getFullYear().toString().substring(2, 4),
    otherRecNo: '',
    lastNm: '',
    firstNm: '',
    midInit: '',
    dob: '',
    age: '',
    sex: '',
    status: '',
    add1: '',
    add2: '',
    city: '',
    st: 'PR',
    zip: '',
    cell: '',
    tel: '',
    telWk: '',
    email: '',
    relation: '',
    provId: '',
    provAlias: '',
    insId1: '',
    insAlias1: '',
    contr1: '',
    group1: '',
    ded1: '',
    insId2: '',
    insAlias2: '',
    contr2: '',
    group2: '',
    ded2: '',
    facId: '',
    facAlias: '',
    created: '',
    updated: '',
    notes: '',
    lastNm1: '',
    firstNm1: '',
    midInit1: '',
    lastNm2: '',
    firstNm2: '',
    midInit2: '',
    occupat: '',
    employer: '',
    marital: '',
    hSign: '',
    hSignDt: '',
    sn: undefined,
    casesHx: undefined
  };

  patImg: any = {
    id1: undefined,
    id1Dt: undefined,
    id1Pkey: '0',

    id2: undefined,
    id2Dt: undefined,
    id2Pkey: '0',

    lic: undefined,
    licDt: undefined,
    licPkey: '0',

    photo: undefined,
    photoDt: undefined,
    photoPkey: '0',
  };

  records: IRecord[] = [];

  viewImgId: string = undefined;

  patOrg: any = {}; // Original pat data

  ref: IReferring = {
    pKey: undefined,
    npi: undefined,
    lastNm: undefined,
    firstNm: undefined
  };

  errsRec: IErrsRec = {
    recNo: '',
    recYr: '',
    recLocale: '',
    last: '',
    first: '',
    init: '',
    dob: '',
    sex: '',
    add1: '',
    add2: '',
    city: '',
    st: '',
    zip: '',
    cell: '',
    tel: '',
    email: '',
    insId1: '',
    contr1: '',
    group1: '',
    lastIns1: '',
    firstIns1: '',
    initIns1: '',
    insId2: '',
    contr2: '',
    group2: '',
    lastIns2: '',
    firstIns2: '',
    initIns2: ''
  }

  errsCas: IErrsCas = {
    dat: '',
    provId: '',
    i1ID: '',
    contr1: '',
    lastIns1: '',
    firstIns1: '',
    initIns1: '',
    i2ID: '',
    contr2: '',
    lastIns2: '',
    firstIns2: '',
    initIns2: '',
    facId: '',
    refID: '',
    refNpi: '',
    refLastNm: '',
    refFirstNm: '',
    admDt: '',
    discDt: ''
  }

  errsDx: IErrsDx[] = [];

  errsProc1: IErrsProc[] = [];
  errsProc2: IErrsProc[] = [];
  errsProc0: IErrsProc[] = [];
  errsProc: IErrsProc = {
    frDt: '',
    toDt: '',
    dx: '',
    qty: '',
    usual: '',
    xpect: '',
    pvdo: '',
    pos: '',
    desc: '',
    rxNDC: '',
    rxQty: '',
    rxUM: ''
  };

  errsPay: IErrsPay[];
  errsPy: IErrsPay = {
    dt: '',
    amnt: '',
    fp: ''
  }

  casObj: ICase = {
    casPatID: '',
    casID: '0',
    casNo: '',
    casOfNo: '',
    casDat: '',
    casDate: '',
    casDt: '',
    casDtCated: '',
    casProvID: '',
    casProvAlias: '',
    casI1ID: '',
    casIns1Alias: '',
    casCont1: '',
    casGrp1: '',
    casDed1: '',
    casChg1: 0,
    casAdjP1: 0,
    casAdjN1: 0,
    casPay1: 0,
    casBal1: 0,
    casI2ID: '',
    casIns2Alias: '',
    casCont2: '',
    casGrp2: '',
    casDed2: '',
    casChg2: 0,
    casAdjP2: 0,
    casAdjN2: 0,
    casPay2: 0,
    casBal2: 0,
    casChgP: 0,
    casAdjNP: 0,
    casPayP: 0,
    casBalP: 0,
    casSubDt1: '',
    casSubT1: '',
    casSubDt2: '',
    casSubT2: '',
    casSubDtP: '',
    casRefer1: '',
    casRefer2: '',
    casDx1: '',
    casDxDesc1: '',
    casDx2: '',
    casDxDesc2: '',
    casDx3: '',
    casDxDesc3: '',
    casDx4: '',
    casDxDesc4: '',
    casDx5: '',
    casDxDesc5: '',
    casDx6: '',
    casDxDesc6: '',
    casDx7: '',
    casDxDesc7: '',
    casDx8: '',
    casDxDesc8: '',
    casDx9: '',
    casDxDesc9: '',
    casDxa: '',
    casDxDesca: '',
    casDxb: '',
    casDxDescb: '',
    casDxc: '',
    casDxDescc: '',
    casRefID: '',
    casRefAlias: '',
    casRefQual: '',
    casRefNPI: '',
    casRefLastNm: '',
    casRefFirstNm: '',
    casFacID: '',
    casFacAlias: '',
    casAuthNo: '',
    casRefNo: '',
    cas19: '',
    casILastIns1: '',
    casIFirstIns1: '',
    casIMidIns1: '',
    casILastIns2: '',
    casIFirstIns2: '',
    casIMidIns2: '',
    casAcAss: '',
    casAccTyp: '',
    casAccDt: '',
    casAdmDt: '',
    casAdmDtYy: '',
    casDiscDt: '',
    casDiscDtYy: '',
    casPrivacy: '',
    dxs: [],
    procs1: [],
    procs2: [],
    procs0: [],
    pays: [],
    submitted: [],
    sofSign: '',
    sofSignDt: '',
    recl1: '0',
    recl2: '0',
    s837errCnt: '0'
  }

  selectedCase: ICase[] = [this.casObj];
  selectedCaseIndx: number = 0;
  expandedPatID: string;  // Holds last patID of expanded record in record search table
  selectedPatID: string;  // Holds patID of opened record
  selectedCasID: string;  // Holds casID of opened case
  casOrg: ICase;

  cms: ICms1500 = {
    top_insName: '',
    top_insAddr1: '',
    top_insAddr2: '',
    top_insCtZpSt: '',
    top_pgCnt: '',
    s1_medicare: '',
    s1_medicaid: '',
    s1_tricare: '',
    s1_champva: '',
    s1_grouphealth: '',
    s1_feca: '',
    s1_other: '',
    s1a_insuredIDno: '',
    s2_patientName: '',
    s3_mm: '',
    s3_dd: '',
    s3_yy: '',
    s3_m: '',
    s3_f: '',
    s4_insuredName: '',
    s5_patientAddr1: '',
    s5_patientAddr2: '',
    s5_city: '',
    s5_acode: '',
    s5_tel: '',
    s5_state: '',
    s5_zip: '',
    s6_self: '',
    s6_spouse: '',
    s6_child: '',
    s6_other: '',
    s7_insuredAddr1: '',
    s7_insuredAddr2: '',
    s7_city: '',
    s7_state: '',
    s7_zip: '',
    s7_acode: '',
    s7_tel: '',
    s9_otherInsuredName: '',
    s9a_OtherInsuredPolicyOrGroup: '',
    s9d_insurancePayerID: '',
    s9d_insurancePlanName: '',
    s10a_yes: '',
    s10a_no: '',
    s10b_yes: '',
    s10b_no: '',
    s10_place: '',
    s10c_yes: '',
    s10c_no: '',
    s11_InsuredPolicyGroup: 'INSUREDs POLICY GROUP',
    s11a_mm: '12',
    s11a_dd: '31',
    s11a_yy: '2020',
    s11a_m: 'X',
    s11a_f: 'X',
    s11b_OtherClaimID: 'OTHER CLAIM ID',
    s11c_insurancePayerID: 'INSURANCE PLAN NAME - PayerID',
    s11c_insurancePlanName: 'INSURANCE PLAN NAME',
    s11d_yes: 'X',
    s11d_no: 'X',
    s12_sof: 'Signature on File',
    s12_date: '',
    s13_sof: 'Signature on File',
    s14_mm: '',
    s14_dd: '',
    s14_yy: '',
    s17_refQual: '',
    s17_refName: '',
    s17b_npi: '',
    s18from_mm: '',
    s18from_dd: '',
    s18from_yy: '',
    s18to_mm: '',
    s18to_dd: '',
    s18to_yy: '',
    s19: '',
    s20_no: 'X',
    s21_icdInd: '0',
    s21a: '',
    s21b: '',
    s21c: '',
    s21d: '',
    s21e: '',
    s21f: '',
    s21g: '',
    s21h: '',
    s21i: '',
    s21j: '',
    s21k: '',
    s21l: '',
    s22_resubmissionCode: '',
    s22_originalRefNo: '',
    s23_priorAuth: '',
    s23_refNo: '',
    s23_cliaNo: '',
    s24: [{
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    },
    {
      s24from_mm: '',
      s24from_dd: '',
      s24from_yy: '',
      s24to_mm: '',
      s24to_dd: '',
      s24to_yy: '',
      s24pos: '',
      s24cpt: '',
      s24m1: '',
      s24m2: '',
      s24m3: '',
      s24m4: '',
      s24ptr: '',
      s24chg: '',
      s24units: '',
      s24npi: ''
    }],
    s25: '',
    s25_ssn: '',
    s25_ein: '',
    s26: '',
    s27_yes: '',
    s27_no: '',
    s28: '',
    s29: '',
    s30: '',
    s31: '',
    s32_1: '',
    s32_2: '',
    s32_3: '',
    s32_4: '',
    s32_5: '',
    s32a: '',
    s33_acode: '',
    s33_tel: '',
    s33_1: '',
    s33_2: '',
    s33_3: '',
    s33_4: '',
    s33a: '',
    s33b: '',
    bot_RecNo: 'REC: 12345-1-20 User 1'
  }

  dxCodes: any = [{
    pKey: undefined,
    categPKey: undefined,
    code: undefined,
    desc: undefined,
    selected: undefined,
    order: undefined
  }];

  localConfig: ILocalFormConfig[] = [];

  images: any = [];
  scanLic: boolean = false;
  scanID1: boolean = false;
  scanID2: boolean = false;
  scanPhoto: boolean = false;
  scanRec: boolean = false;
  scanCas: boolean = false;
  docSelectedRwBkg: string = '#F7F7B0'
  hSignB64: SafeResourceUrl;
  getSignSubscrpt: Subscription;
  sofSignB64: SafeResourceUrl;

  blockTabKeyAfterLookUp: boolean = false;   // simulates event.stopPropagation() after tabbing out with a lookup function
  block2ndEnterKeyResponse2OkInModal: boolean = false;

  showCasesHx: boolean = false;
  casesHxRefresh: boolean = false;
  showRecIns: boolean = false;

  fetchingSrchResLastNm: boolean = false;
  fetchingSrchResFirstNm: boolean = false;
  fetchingSrchResTel: boolean = false;
  fetchingSrchResRecNo: boolean = false;
  fetchingSrchResCasDt: boolean = false;
  fetchingSrchResContNo: boolean = false;
  fetchingSrchCases: boolean = false;
  fetchingCodesDesc: boolean = false;
  fetchingDxCodesDesc: boolean = false;
  deError: string = undefined;  // Data entry error
  saveStatus: string = undefined;  // Save-Update-Delete success
  warning: string = undefined;
  waiting4Success: boolean = false;
  waiting4Docs: boolean = false;
  waiting4PatImg2Load: boolean = false;
  waiting4Print1: boolean = false;
  waiting4Print2: boolean = false;
  waiting4Print0: boolean = false;
  waiting4Img: boolean = false;
  ps: string;
  selectedCode: string;
  selectedCodePkey: string;
  digits2select: string;
  codeSrchStr: any = { 'txt': undefined, 'id': undefined };  // ICD10 & CPT4 code/description search string, input element id
  ajusteStr: string = 'AJUSTE';
  pos: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '31', '32', '33', '34', '41', '42', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '60', '61', '62', '65', '71', '72', '81', '99'];
  recEdited: boolean = false;
  casEdited: boolean = false;
  proc1Edited: boolean = false;
  proc2Edited: boolean = false;
  proc0Edited: boolean = false;
  payEdited: boolean = false;
  isOpenSubDt1: boolean = false;
  isOpenSubDt2: boolean = false;
  isOpenSubDt0: boolean = false;
  modalStack: string[] = [];
  modalRef: NgbModalRef;  // Keeps reference to last modal openned
  tmpStr: string;
  tmpSign: string;
  firstFocus: HTMLInputElement = undefined;
  s837errCnt: number = 0;

  base64Scheme = 'data:image/png;base64,';
  reclCasIdSeled: string;
  reclPsSeled: string;
  reclID: string;
  reclCkBxCked: boolean; // True = checked as state of Reclaim checkbox with reclCkBxId when closing reclaim-component
  spinner: boolean = true; // Spinner feedback for Reclaims Component
  enabSaveRecl: boolean = false;
  changeIcnObj: { nxtIcnIdx: number, nxtReclID: string, prvIcnIdx: number };
  reclIdxSeld: number = -1; // Index in reclaims[] being edited
  reclaimsModalRef: any; // To be able to close main reclaims modal
  reclaimsExitWoSaveModalRef: any;  // Exit w/o Saving modal
  reclInsID: string;
  saveToggle: boolean;
  deleteToggle: boolean;
  printToggle: boolean;
  icnsPrevIdx: number = -1;

  constructor(private _dataMessageService: DataMessageService,
    private _websocketService: WebsocketService,
    private _help: HelperRtnsComponent,
    private _recordService: RecordService,
    private _modalService: ModalService,
    private _activatedRoute: ActivatedRoute,
    private _cookieService: CookieService,
    private _toastService: AppToastsService,
    private _sanitizer: DomSanitizer,
    private _twilioService: TwilioService,
    private _eventService: EventService,
    private _x12Service: X12UtilsService) { }

  ngOnInit(): void {
    this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang
    this._dataMessageService.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
    this._dataMessageService.currentSiteStr.subscribe(siteStr => this.siteChange(siteStr)); // Subscription looking for changes in siteName
    this._dataMessageService.currentSiteAd1Str.subscribe(siteAd1Str => this.siteAd1Change(siteAd1Str)); // Subscription looking for changes in siteAd1
    this._dataMessageService.currentSiteAd2Str.subscribe(siteAd2Str => this.siteAd2Change(siteAd2Str)); // Subscription looking for changes in siteAd2
    this._dataMessageService.currentSiteCtStr.subscribe(siteCtStr => this.siteCtChange(siteCtStr)); // Subscription looking for changes in siteCt
    this._dataMessageService.currentSiteStStr.subscribe(siteStStr => this.siteStChange(siteStStr)); // Subscription looking for changes in siteSt
    this._dataMessageService.currentSiteZpStr.subscribe(siteZpStr => this.siteZpChange(siteZpStr)); // Subscription looking for changes in siteZp
    this._dataMessageService.currentSiteTl1Str.subscribe(siteTl1Str => this.siteTl1Change(siteTl1Str)); // Subscription looking for changes in siteTl1
    this._dataMessageService.currentSiteXt1Str.subscribe(siteXt1Str => this.siteXt1Change(siteXt1Str)); // Subscription looking for changes in siteXt1
    this._dataMessageService.currentSiteTl2Str.subscribe(siteTl2Str => this.siteTl2Change(siteTl2Str)); // Subscription looking for changes in siteTl2
    this._dataMessageService.currentSiteXt2Str.subscribe(siteXt2Str => this.siteXt2Change(siteXt2Str)); // Subscription looking for changes in siteXt2
    this._dataMessageService.currentSiteEm1Str.subscribe(siteEm1Str => this.siteEm1Change(siteEm1Str)); // Subscription looking for changes in siteEm1
    this._dataMessageService.currentSiteEm2Str.subscribe(siteEm2Str => this.siteEm2Change(siteEm2Str)); // Subscription looking for changes in siteEm2
    this._dataMessageService.currentUserIdStr.subscribe(userIdStr => this.userIdChange(userIdStr)); // Subscription looking for changes in userIdStr
    this._dataMessageService.currentUserLastNmStr.subscribe(userLastNmStr => this.userLastNmChange(userLastNmStr)); // Subscription looking for changes in userLastNmStr
    this._dataMessageService.currentUserFirstNmStr.subscribe(userFirstNmStr => this.userFirstNmChange(userFirstNmStr)); // Subscription looking for changes in userLastNmStr
    this._dataMessageService.currentUserSecOptsStr.subscribe(userSecOptsStr => this.userSecOptsChange(userSecOptsStr));

    this.stylesCfg = JSON.parse(this._activatedRoute.snapshot.params.stylesCfgJson);

    this.sioSubscrpt = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning events

      console.log('%c' + 'dataSet RecordComponent', 'color: green; background: yellow; font-size: 14px');
      console.log(dataSet[0]?.sqlProcNm, dataSet);

      if (dataSet?.error) {
        this.showToast((this.engLang ? 'Error receiving data' : 'Error recibiendo data'), dataSet.error, true, true, false, false, false);
      } else {
        for (let r = 0; r < dataSet.length; r++) {
          Object.keys(dataSet[r]).forEach(key => {
            dataSet[r][key] = dataSet[r][key].replace('%2f', '#');
            dataSet[r][key] = dataSet[r][key].replace('%22', '\\');
          });
        }

        if (dataSet?.length > 0) {

          if (dataSet[0]?.sqlProcNm === 'spMB_Sio_GetHSignature') {
            // console.log(dataSet[0].hSign);
            if (dataSet[0].type == 'HIPAA') {
              this.pat.hSign = dataSet[0].hSign;
              this.patOrg.hSign = this.pat.hSign;
              this.pat.hSignDt = dataSet[0].hSignDt;
              this.patOrg.hSignDt = this.pat.hSignDt;
              this.hSignB64 = this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Scheme + this.pat.hSign);

              if (!+this.selectedCase[this.selectedCaseIndx].casID) { // For a new case copy hippa sign to sof
                this.selectedCase[this.selectedCaseIndx].sofSign = dataSet[0].hSign;
                this.selectedCase[this.selectedCaseIndx].sofSignDt = dataSet[0].hSignDt;
                this.sofSignB64 = this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Scheme + this.selectedCase[this.selectedCaseIndx].sofSign);
              }
            } else {
              this.selectedCase[this.selectedCaseIndx].sofSign = dataSet[0].hSign;
              this.selectedCase[this.selectedCaseIndx].sofSignDt = dataSet[0].hSignDt;
              if (+this.selectedCase[this.selectedCaseIndx].casID > 0) {  // Case previously saved
                this.casOrg.sofSign = dataSet[0].hSign;
                this.casOrg.sofSignDt = dataSet[0].hSignDt;
              }
              this.sofSignB64 = this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Scheme + this.selectedCase[this.selectedCaseIndx].sofSign);
            }
            this.getSignSubscrpt?.unsubscribe();
          }

          if (dataSet[0]?.result) {  // Callback from Save, Update, Delete

            if (dataSet[0]?.sqlProcNm === 'spMB_Web_Save_Record') {
              this.saveStatus = this.engLang ? 'Record saved' : 'Record grabado';
              this.waiting4Success = false;
              this.pat.patID = dataSet[0].pKey;
              if (!this.pat.recNo) {
                this.pat.recNo = dataSet[0].recNo;
              }

              if (dataSet[0].result == 'rUpdated') {
                this.pat.updated = dataSet[0].updated;
              } else {
                this.pat.created = dataSet[0].created;
              }

              this._toastService.updateDeadCenter(true);
              this._toastService.show('',
                {
                  header: (this.engLang ? 'Record Saved!' : '¡Record Grabado!')
                  , autohide: true
                  , success: true
                }
              );

              this.hl7Send();

              this.saveCase();
              this.patOrg = {};
              this.patOrg = JSON.parse(JSON.stringify(this.pat)); // Deep copy of saved record to check further editing
              this.recEdited = false;

              if (this.xtPID && this.xtCID && (this.xtRecNo != this.pat.recNo || this.xtRecOffice != this.pat.recLocale || this.pat.recYr != this.xtRecYr)) { // Record component is in an external component modal window
                this.setNewRecordNoEvent.emit({
                  'patID': this.pat.patID,
                  'patRecNo': this.pat.recNo + '-' + this.pat.recLocale + '-' + this.pat.recYr
                });
                this.xtRecNo = this.pat.recNo;
                this.xtRecOffice = this.pat.recLocale;
                this.xtRecYr = this.pat.recYr;
                this.xtPID = this.pat.patID;
              }
            }

            if (dataSet[0]?.sqlProcNm === 'spMB_Web_Save_Case') {
              this.saveStatus = this.engLang ? 'Case saved' : 'Caso grabado';
              this.waiting4Success = false;
              if (dataSet[0].result == 'cInserted') {
                let cas = this.selectedCase[this.selectedCaseIndx];
                cas.casID = dataSet[0].pKey;
                cas.casNo = dataSet[0].casNo.toString().replace(/^0*/g, '');  // Remove excess lead zeros
                cas.casOfNo = dataSet[0].casOfNo;
                let cid = cas.casID;
                this.selectedCase.unshift(this.casObj);
                this.prepareNewCase();
                this.selectedCaseIndx = this.selectedCase.findIndex(e => { return e.casID == cid });
              }

              this._toastService.updateDeadCenter(true);
              this._toastService.show('',
                {
                  header: (this.engLang ? "Claim Saved!" : '¡Caso Grabado!')
                  , autohide: true
                  , success: true
                }
              );

              this.calcCaseTotals();
              this.clrCasOrg_setSofSign();
              this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[this.selectedCaseIndx])); // Deep copy of saved record to check further editing
              this.casEdited = false;
              this.saveProcs();
              this.savePayments();
            }

            if (dataSet[0]?.sqlProcNm === 'spMB_Web_Save_Procs') {
              if (dataSet[0].result == 'procInserted' || dataSet[0].result == 'procUpdated' || dataSet[0].result == 'procDeleted') {
                this.saveStatus = this.engLang ? 'Procedures saved' : 'Procedimientos grabados';
                this.waiting4Success = false;
                let cas = this.selectedCase[this.selectedCaseIndx];

                cas.procs1.forEach((e, i) => {
                  if (+e.detID == 0) {
                    let ixPkey = dataSet.findIndex(elDs => { return elDs.indx == i });
                    if (ixPkey < 0) { // Incoming proc indx in dataSet not found locally so sync deleteing local
                      cas.procs1.splice(i, 1);
                    } else {
                      e.detID = dataSet[ixPkey].pKey; // Update new pKey into e.detID 
                    }
                  } else if (dataSet[0].result == 'procDeleted' && +e.detID === +dataSet[0].pKey) {
                    cas.procs1.splice(i, 1);
                  }
                });

                cas.procs2.forEach((e, i) => {
                  if (+e.detID == 0) {
                    let ixPkey = dataSet.findIndex(elDs => { return elDs.indx == i });
                    if (ixPkey < 0) {
                      cas.procs2.splice(i, 1);
                    } else {
                      e.detID = dataSet[ixPkey].pKey;
                    }
                  } else if (dataSet[0].result == 'procDeleted' && +e.detID === +dataSet[0].pKey) {
                    cas.procs2.splice(i, 1);
                  }
                });

                cas.procs0.forEach((e, i) => {
                  if (+e.detID == 0) {
                    let ixPkey = dataSet.findIndex(elDs => { return elDs.indx == i });
                    if (ixPkey < 0) {
                      cas.procs0.splice(i, 1);
                    } else {
                      e.detID = dataSet[ixPkey].pKey;
                    }
                  } else if (dataSet[0].result == 'procDeleted' && +e.detID === +dataSet[0].pKey) {
                    cas.procs0.splice(i, 1);
                  }
                });

                this.calcCaseTotals();
                this.clrCasOrg_setSofSign();
                this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[this.selectedCaseIndx])); // Deep copy of saved record to check further editing
                this.proc1Edited = false;
                this.proc2Edited = false;
                this.proc0Edited = false;
              }
            }

            if (dataSet[0].result == 'payInserted') {
              this.saveStatus = this.engLang ? 'Payments saved' : 'Pagos grabados';
              this.waiting4Success = false;
              let cas = this.selectedCase[this.selectedCaseIndx];
              cas.pays.forEach((e, i) => {
                if (+e.payID == 0) {
                  let ixPkey = dataSet.findIndex(elDs => { return elDs.indx == i });
                  e.payID = dataSet[ixPkey].pKey;
                }
              });

              this.calcCaseTotals();
              this.clrCasOrg_setSofSign();
              this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[this.selectedCaseIndx])); // Deep copy of saved record to check further editing
              this.payEdited = false;
            }

            if (dataSet[0].result == 'docInserted' || dataSet[0].result == 'docUpdated' || dataSet[0].result == 'docDeleted') {
              // console.log(dataSet[0]);
              this.saveStatus = this.engLang ? 'Record images saved' : 'Imágenes record grabadas';
              this.waiting4Success = false;

              if (dataSet[0].result == 'docDeleted') {
                this.removeDeletedDoc(dataSet[0].docType, dataSet[0].indx);
              } else {  // docInserted or docUpdated
                switch (dataSet[0].docType) {
                  case 'id1':
                    this.patImg.id1Pkey = dataSet[0].docID;
                    break;
                  case 'id2':
                    this.patImg.id2Pkey = dataSet[0].docID;
                    break;
                  case 'lic':
                    this.patImg.licPkey = dataSet[0].docID;
                    break;
                  case 'photo':
                    this.patImg.photoPkey = dataSet[0].docID;
                    break;
                  case 'img':
                    let i = dataSet[0].indx;
                    this.images[i].docID = dataSet[0].docID;
                    this.images[i].docBase64 = '';
                    break;
                }
              }
            }

            if (dataSet[0].result == 'rDeleted' || dataSet[0].result == 'cDeleted') { // [spMB_Web_Delete_RecordCase]
              window.scroll(0, 0);
              if (dataSet[0]?.spanWarn) {

                this._toastService.updateDeadCenter(true);
                this._toastService.show(this.engLang ? 'Warning' : 'Aviso'
                  , {
                    header: (this.engLang ? dataSet[0].engWarn : dataSet[0].spanWarn)
                    , autohide: false, warning: true
                  });

              } else {
                if (dataSet[0].result == 'rDeleted') {
                  if (this.xtPID && this.xtCID && this.xtPID == dataSet[0]?.pKey) { // Record component is in an external component modal window
                    this.setNewRecordNoEvent.emit({
                      'patID': '0',
                      'patRecNo': ''
                    });
                    this.xtPID = undefined; // So as not to repeat this block with another record from search
                    this.xtCID = undefined;
                  }

                  this._toastService.updateDeadCenter(true);
                  this._toastService.show(''
                    , {
                      header: (this.engLang ? "Record deleted." : 'Record eliminado.')
                      , autohide: true, success: true
                    });

                  this.newRecord();
                } else {
                  this.selectedCase[this.selectedCaseIndx].dxs = [];
                  this.selectedCase[this.selectedCaseIndx].procs1 = [];
                  this.selectedCase[this.selectedCaseIndx].procs2 = [];
                  this.selectedCase[this.selectedCaseIndx].procs0 = [];
                  this.selectedCase[this.selectedCaseIndx].pays = [];
                  this.selectedCase.splice(this.selectedCaseIndx, 1);
                  this.selectedCaseIndx = 0;
                  this.clrCasOrg_setSofSign();
                  this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[this.selectedCaseIndx])); // Deep copy of saved record to check further editing
                  this.recEdited = false;
                  this.casEdited = false;
                  this.proc1Edited = false;
                  this.proc2Edited = false;
                  this.proc0Edited = false;
                  this.payEdited = false;

                  this._toastService.updateDeadCenter(true);
                  this._toastService.show(''
                    , {
                      header: (this.engLang ? "Claim deleted." : 'Caso eliminado.')
                      , autohide: true, success: true
                    });

                }
              }
            }
          }

          if (dataSet[0].result == 'imgDescrUpdated' && dataSet[0].cnt == 1) {
            this.waiting4Success = false;
          }

          if (dataSet[0]?.sqlProcNm == 'spMBSearch4Record') { // A dataSet with 1 or more records is coming as a result of a patient SEARCH
            // console.log('%c' + dataSet[0].sqlProcNm + ' results 1', 'color: black; background: white; font-size: 20px');
            this.records = [];
            dataSet.forEach(rec => {
              this.records.push({ pat: undefined });
              this.records[this.records.length - 1].pat = rec;
            })
            this.images = [];

            if (this.lastSrchParm) {  // dataSet is the result of a record search
              document.getElementById(this.lastSrchParm).focus();
            }

          } else if (dataSet[0].hasOwnProperty('id1') && dataSet[0].hasOwnProperty('id2') && dataSet[0].hasOwnProperty('lic') && dataSet[0].hasOwnProperty('photo')) {  // Record images
            this.patImg.id1 = ''; // this.base64Scheme + dataSet[0].id1;
            this.patImg.id1Dt = (dataSet[0].id1Dt ? dataSet[0].id1Dt : undefined);
            this.patImg.id1Pkey = dataSet[0].id1Pkey;

            this.patImg.id2 = '';  // this.base64Scheme + dataSet[0].id2;
            this.patImg.id2Dt = (dataSet[0].id2Dt ? dataSet[0].id2Dt : undefined);
            this.patImg.id2Pkey = dataSet[0].id2Pkey;

            this.patImg.lic = '';  // this.base64Scheme + dataSet[0].lic;
            this.patImg.licDt = (dataSet[0].licDt ? dataSet[0].licDt : undefined);
            this.patImg.licPkey = dataSet[0].licPkey;


            this.patImg.photo = ''; // this.base64Scheme + dataSet[0].photo;
            this.patImg.photoDt = (dataSet[0].photoDt ? dataSet[0].photoDt : undefined);
            this.patImg.photoPkey = dataSet[0].photoPkey;

            this.waiting4PatImg2Load = false;

          } else if (dataSet[0].hasOwnProperty('docDt') && !dataSet[0].hasOwnProperty('docImg')) {  // All images table
            if (dataSet.length == 1 && !dataSet[0].docID) {
              this.images = [];
              this.images.pop();
            } else {
              this.images = dataSet;
            }
            this.waiting4Docs = false;

          } else if (dataSet[0].hasOwnProperty('docImg') && dataSet[0].docImg) {  // Getting a particular image to display
            this.waiting4Img = false;

            let msgBoxTitle;
            let msgBoxMessage;

            if (this.patImg.licPkey == dataSet[0].docID || this.patImg.id1Pkey == dataSet[0].docID || this.patImg.id2Pkey == dataSet[0].docID || this.patImg.photoPkey == dataSet[0].docID) {
              msgBoxTitle = this.engLang ? 'Image' : 'Imagen';
            } else {
              for (let i = 0; i < this.images.length; i++) {  // this.images was loaded initially
                if (this.images[i].docID.replace('-', '') == dataSet[0].docID) {
                  msgBoxTitle = this.engLang ? 'Image' : 'Imagen';
                  break;
                }
              }
            }

            msgBoxMessage = dataSet[0].docDesc + '      ' + dataSet[0].docDt;

            this._modalService.message(msgBoxMessage, msgBoxTitle,
              undefined, undefined, undefined, undefined, this.engLang, undefined, 'Pc', '#e6eff3', this.base64Scheme + dataSet[0].docImg, true, true)
              .pipe(
                take(1) // take() manages unsubscription for us
              ).subscribe(response => {
                if (response) { // response = undefined if Esc was pressed, False if closed with upper rt hand x
                  let resp: any = response; // response is types as boolean so resp is used to read string
                  if (resp == 'print') {
                    this.printImg(undefined, this.base64Scheme + dataSet[0].docImg, dataSet[0].docWidth, dataSet[0].docHeight);
                  } else if (resp == 'delete') {
                    this.onClick_deleteImg(undefined);
                  }
                }
              });

          } else if (dataSet[0]?.sqlProcNm == 'spMBSearch4Case' || dataSet[0]?.sqlProcNm == 'spMB_Sio_GetCase_ByID') {  // (caseHx) . A dataSet of 1 or more cases is coming for 1 record in this.records selected
            // console.log('%c' + dataSet[0].sqlProcNm + ' results', 'color: black; background: white; font-size: 18px');
            // console.log('dataSet', dataSet);

            if (this.selectedPatID) { // Get rest of record
              console.log('%c' + 'query @ ngOnInit:' + "Exec spMB_Sio_GetRecord_ByID @sn = '" + this.sn + "', @id = '" + this.selectedPatID + "';", 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecord_ByID @sn = '" + this.sn + "', @id = '" + this.selectedPatID + "';");

              this.waiting4Docs = true; // Get Images
              console.log('%c' + 'query @ ngOnInit:' + "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @id = '" + this.selectedPatID + "', @top = " + this.lastUsedParam.topCasRows + ";", 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @id = '" + this.selectedPatID + "', @top = " + this.lastUsedParam.topCasRows + ";");
            }

            for (let r = 0; r < this.records.length; r++) {
              // console.log('%c' + 'caseHx loop-top', 'color: black; background: #90EE90; font-size: 12px');
              if (dataSet[0].casPatID == this.records[r].pat.patID) {
                // console.log('%c' + 'caseHx loop-inside if() {}', 'color: black; background: #90EE90; font-size: 12px');

                this.selectedCase = dataSet; // Displays case Hx inside openned record

                for (let c = 0; c < dataSet.length; c++) {
                  // Prepare dates in MM/DD/YY format for ngbDatepicker
                  if (this.selectedCase[c]?.casAdmDt) {
                    const admDt = moment(this.selectedCase[c].casAdmDt, 'MM/DD/YYYY');
                    if (admDt.isValid) {
                      this.selectedCase[c].casAdmDtYy = admDt.format('MM/DD/YY');
                    }
                  }
                  if (this.selectedCase[c]?.casDiscDt) {
                    const discDt = moment(this.selectedCase[c].casDiscDt, 'MM/DD/YYYY');
                    if (discDt.isValid) {
                      this.selectedCase[c].casDiscDtYy = discDt.format('MM/DD/YY');
                    }
                  }

                  if (this.records[r].pat.patID == dataSet[c].casPatID) {
                    this.records[r].pat.casesHx = dataSet;  // Displays in record search output table


                    if (this.records[r].pat.casesHx) {

                      if (this.records[r].pat.casesHx[c].casID == dataSet[c].casID) { // Set up Dxs for the selected case
                        this.records[r].pat.casesHx[c].dxs = [];  // Initializa all child arrays before loading
                        this.records[r].pat.casesHx[c].procs1 = [];
                        this.records[r].pat.casesHx[c].procs2 = [];
                        this.records[r].pat.casesHx[c].procs0 = [];
                        this.records[r].pat.casesHx[c].pays = [];

                        if (this.records[r].pat.casesHx[c].casDx1.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '1', code: dataSet[c].casDx1, desc: dataSet[c].casDxDesc1 });
                        }
                        if (this.records[r].pat.casesHx[c].casDx2.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '2', code: dataSet[c].casDx2, desc: dataSet[c].casDxDesc2 });
                        }
                        if (this.records[r].pat.casesHx[c].casDx3.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '3', code: dataSet[c].casDx3, desc: dataSet[c].casDxDesc3 });
                        }
                        if (this.records[r].pat.casesHx[c].casDx4.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '4', code: dataSet[c].casDx4, desc: dataSet[c].casDxDesc4 });
                        }
                        if (this.records[r].pat.casesHx[c].casDx5.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '5', code: dataSet[c].casDx5, desc: dataSet[c].casDxDesc5 });
                        }
                        if (this.records[r].pat.casesHx[c].casDx6.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '6', code: dataSet[c].casDx6, desc: dataSet[c].casDxDesc6 });
                        }
                        if (this.records[r].pat.casesHx[c].casDx7.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '7', code: dataSet[c].casDx7, desc: dataSet[c].casDxDesc7 });
                        }
                        if (this.records[r].pat.casesHx[c].casDx8.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '8', code: dataSet[c].casDx8, desc: dataSet[c].casDxDesc8 });
                        }
                        if (this.records[r].pat.casesHx[c].casDx9.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: '9', code: dataSet[c].casDx9, desc: dataSet[c].casDxDesc9 });
                        }
                        if (this.records[r].pat.casesHx[c].casDxa.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: 'a', code: dataSet[c].casDxa, desc: dataSet[c].casDxDesca });
                        }
                        if (this.records[r].pat.casesHx[c].casDxb.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: 'b', code: dataSet[c].casDxb, desc: dataSet[c].casDxDescb });
                        }
                        if (this.records[r].pat.casesHx[c].casDxc.length > 0) {
                          this.records[r].pat.casesHx[c].dxs.push({ indx: 'c', code: dataSet[c].casDxc, desc: dataSet[c].casDxDescc });
                        }
                      }
                    }
                  }
                }
                break;  // Selected record caseHx was loaded so get out of this loop.
              }
              // console.log('%c' + 'caseHx loop-bottom', 'color: black; background: #90EE90; font-size: 12px');
            }

          } else if (dataSet[0]?.detID && dataSet[0]?.sqlProcNm == 'spMBSearch4Procs_Pays') {  // Procs detail
            // console.log('%c' + dataSet[0].sqlProcNm + ' results', 'color: black; background: white; font-size: 10px');

            let loaded = false;
            for (let r = 0; r < this.records.length; r++) {
              // console.log('%c' + 'procs loop-top', 'color: black; background: #90EE90; font-size: 12px');

              if (this.records[r].pat.casesHx) {
                this.selectedCase[this.selectedCaseIndx].procs1 = [];
                // this.selectedCase[this.selectedCaseIndx].procs1.pop(); // To clear all procs1 before loading
                this.selectedCase[this.selectedCaseIndx].procs2 = [];
                // this.selectedCase[this.selectedCaseIndx].procs2.pop(); // To clear all procs2  before loading
                this.selectedCase[this.selectedCaseIndx].procs0 = [];
                // this.selectedCase[this.selectedCaseIndx].procs0.pop(); // To clear all procs0  before loading
                this.selectedCase[this.selectedCaseIndx].pays = [];
                // this.selectedCase[this.selectedCaseIndx].pays.pop();  // To clear all payments initially for new case

                for (let c = 0; c < this.records[r].pat.casesHx.length; c++) {

                  for (let p = 0; p < dataSet.length; p++) {
                    if (this.records[r].pat.casesHx[c].casID == dataSet[p].detCasID) {
                      if (parseInt(this.records[r].pat.casesHx[c].casID) > 0) {
                        switch (dataSet[p].ps) {
                          case '1': {
                            this.records[r].pat.casesHx[c].procs1.push(dataSet[p]);
                            loaded = true;
                            break;
                          }
                          case '2': {
                            this.records[r].pat.casesHx[c].procs2.push(dataSet[p]);
                            loaded = true;
                            break;
                          }
                          default: {
                            this.records[r].pat.casesHx[c].procs0.push(dataSet[p]);
                            loaded = true;
                            break;
                          }
                        }
                      }
                    }
                  }
                  if (loaded) {
                    this.selectedCaseIndx = c;  // Index of selected case within selectedRecordCaseHx
                    this.s837errCnt = +this.selectedCase[c].s837errCnt;
                    this.calcCaseTotals();
                    this.clrCasOrg_setSofSign();
                    this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[c])); // Deep copy of original case to check editing
                    let cas = this.selectedCase[this.selectedCaseIndx];
                    this.loadLocalPcodes(cas.casProvID, cas.casI1ID, cas.casI2ID);
                    break;  // Selected case procs loaded so get out of this loop.
                  }
                }
              }
              if (loaded) {
                this.activeTab = 2;  // Show Record tab
                window.scroll(0, 0);
                break;
              }
            }
            // console.log('%c' + 'Case ' + loadedCasNo + ' ID ' + loadedCasID, 'color: blue; background: yellow; font-size: 10px');

          } else if (dataSet[0]?.payID && dataSet[0]?.sqlProcNm == 'spMBSearch4Procs_Pays') {
            // console.log('%c' + dataSet[0].sqlProcNm + ' results', 'color: black; background: white; font-size: 10px');

            let loaded = false;
            for (let r = 0; r < this.records.length; r++) {
              if (this.records[r].pat.casesHx) {
                for (let c = 0; c < this.records[r].pat.casesHx.length; c++) {

                  for (let p = 0; p < dataSet.length; p++) {
                    if (this.records[r].pat.casesHx[c].casID == dataSet[p].payCasID) {
                      this.selectedCaseIndx = c;  // Index of selected case within selectedRecordCaseHx
                      this.records[r].pat.casesHx[c].pays.push(dataSet[p]);
                      loaded = true;
                    }
                  }

                  if (loaded) {
                    this.calcCaseTotals();
                    this.clrCasOrg_setSofSign();
                    this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[this.selectedCaseIndx])); // Deep copy of original case to check editing
                    // console.log('%c' + 'casOrg: PAYMENTS - Loaded', 'color: green; background: yellow; font-size: 18px');
                    break;  // Selected case pays loaded so get out of this loop.
                  }
                }
              }
              if (loaded) {
                this.activeTab = 2;  // Show Record tab
                window.scroll(0, 0);
                break;
              }
            }

          } else if (dataSet[0]?.subDtID && dataSet[0]?.sqlProcNm == 'spMBSearch4Procs_Pays') {
            // console.log('%c' + dataSet[0].sqlProcNm + ' results', 'color: black; background: white; font-size: 10px');

            let loaded = false;
            for (let r = 0; r < this.records.length; r++) {
              // console.log('%c' + 'subDt loop-top', 'color: black; background: #90EE90; font-size: 12px');

              if (this.records[r].pat.casesHx) {
                this.selectedCase[this.selectedCaseIndx].submitted = [];

                for (let c = 0; c < this.records[r].pat.casesHx.length; c++) {

                  for (let s = 0; s < dataSet.length; s++) {
                    if (this.records[r].pat.casesHx[c].casID == dataSet[s].subDtCasId) {
                      this.records[r].pat.casesHx[c].submitted.push(dataSet[s]);
                      loaded = true;
                    }
                  }

                  if (loaded) {
                    this.clrCasOrg_setSofSign();
                    this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[this.selectedCaseIndx])); // Deep copy of original case to check editing
                    // console.log('%c' + 'casOrg: SUBDT - Loaded', 'color: green; background: yellow; font-size: 18px'); break;
                  }
                }
                if (loaded) {
                  this.activeTab = 2;  // Show Record tab
                  window.scroll(0, 0);
                  break;
                }
              }
            }

          } else if (dataSet[0]?.subDtID_afterSubmit) {
            // console.log('%c' + 'hasOwnProperty("subDtID_afterSubmit")' + ' results', 'color: black; background: white; font-size: 10px');

            let sub: ISubmitted = {
              'subDtID': dataSet[0].subDtID,
              'subDtCasId': dataSet[0].subDtCasId,
              'ps': dataSet[0].ps,
              'subDt': dataSet[0].subDt,
              'subDtUser': dataSet[0].subDtUser,
              'subDtType': dataSet[0].subDtType
            }
            if (!this.selectedCase[this.selectedCaseIndx].submitted || this.selectedCase[this.selectedCaseIndx].submitted.length == 0) {
              this.selectedCase[this.selectedCaseIndx].submitted = [];
              this.selectedCase[this.selectedCaseIndx].submitted.push(sub);
            } else {
              this.selectedCase[this.selectedCaseIndx].submitted.unshift(sub);
            }

            let dt = this._help.fmtDate(new Date(sub.subDt), 'mm/dd/yyyy');
            let tp = sub.subDtType;
            if (sub.ps == '1') {
              this.selectedCase[this.selectedCaseIndx].casSubDt1 = dt;
              this.selectedCase[this.selectedCaseIndx].casSubT1 = tp;
              this.casOrg.casSubDt1 = dt;
              this.casOrg.casSubT1 = tp;
            } else if (sub.ps == '2') {
              this.selectedCase[this.selectedCaseIndx].casSubDt2 = dt;
              this.selectedCase[this.selectedCaseIndx].casSubT2 = tp;
              this.casOrg.casSubDt2 = dt;
              this.casOrg.casSubT2 = tp;
            } else if (sub.ps == '0') {
              this.selectedCase[this.selectedCaseIndx].casSubDtP = dt;
              this.casOrg.casSubDtP = dt;
            }
            this.casOrg.submitted = this.selectedCase[this.selectedCaseIndx].submitted;

          } else if (dataSet[0]?.proID) {
            dataSet.forEach(e => {
              let procExists = this._recordService.localPcodes.find(existingElement =>
                existingElement.proID == e.proID);
              if (!procExists) {
                this._recordService.localPcodes.push(e);
              }
            });

          } else if (dataSet[0]?.dedId) {
            dataSet.forEach(e => {
              let dedExists = this._recordService.localDedcodes.find(existingElement =>
                existingElement.dedId == e.dedId);
              if (!dedExists) {
                this._recordService.localDedcodes.push(e);
              }
            });

          } else if (dataSet[0]?.xceId) {
            dataSet.forEach(e => {
              let xceExists = this._recordService.localXcecodes.find(existingElement =>
                existingElement.xceId == e.xceId);
              if (!xceExists) {
                this._recordService.localXcecodes.push(e);
              }
            });

          } else if (dataSet[0]?.forID) { // [spMB_Web_Get_PcodeList] 
            dataSet.forEach(e => {
              let confExists = this.localConfig.find(existingElement =>
                existingElement.forID == e.forID);
              if (!confExists) {
                this.localConfig.push(e);
              }
            });

          } else if (dataSet[0]?.sqlProcNm == 'spMB_Sio_GetRecord_ByID') { // Keep for last after all support datasets were loaded.
            // console.log('%c' + dataSet[0].sqlProcNm + ' results 2', 'color: black; background: white; font-size: 20px');
            this.pat = dataSet[0];
            let dontEquate: boolean = false;

            if (this.pat.patID == '0') {
              this.newRecord();

              this.pat.lastNm = this.xtLastNm;
              this.pat.firstNm = this.xtFirstNm;
              this.pat.midInit = this.xtMidInit;
              this.pat.sex = this.xtSex;
              this.pat.dob = this.xtDob;
              this.pat.insId1 = this.xtInsId1;
              if (+this.pat.insId1 > 0) {
                let alias = this.localInsurances.find(ins => ins.pKey == this.pat.insId1)
                this.pat.insAlias1 = alias ? alias.alias : '';
              }
              this.pat.contr1 = this.xtContr1;
              this.pat.group1 = this.xtGroup1;
              this.pat.insId2 = this.xtInsId2;
              if (+this.pat.insId2 > 0) {
                let alias = this.localInsurances.find(ins => ins.pKey == this.pat.insId2)
                this.pat.insAlias2 = alias ? alias.alias : '';
              }
              this.pat.contr2 = this.xtContr2;
              this.pat.group2 = this.xtGroup2;
              this.pat.email = this.xtEmail;
              this.pat.tel = this.xtTel;
              this.pat.cell = this.xtCell;
              this.pat.telWk = this.xtTelWk;
              dontEquate = true;  // Do not eqate pat & patOrg below to be able to save the new record
            }

            if (this.pat.dob.match(/^\d{2}\/\d{2}\/\d{4}$/g)) {
              this.pat.age = this._help.calcAge(this.pat.dob);
            }

            this.hSignB64 = undefined;
            this.noSigning = false;
            this.isSigning = false;
            if (this.pat.hSign) {
              this.hSignB64 = this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Scheme + this.pat.hSign);
            }

            if (!dontEquate) {
              this.patOrg = {};
              this.patOrg = JSON.parse(JSON.stringify(this.pat)); // Deep copy of record to check further editing
            }
            this.images = [];
            this.images.pop();
            this.showRecIns = false;

            if (!this.xtPID && !this.xtCID) { // Xternal Modal record & case change on 2/27/23
              this.prepareNewCase();
              this.selectedCaseIndx = this.selectedCase.findIndex(itm => itm.casID == this.selectedCasID);

              this._websocketService.sendChat('query', this.sn, "EXEC spMBSearch4Procs_Pays @sn = '" + this.sn + "',  @casID = '" + this.selectedCasID + "';");
              console.log('%c' + 'query @ onClick_OpenRecord(pid,cid):' + 'EXEC spMBSearch4Procs_Pays @sn = ' + this.sn + ',  @casID = ' + this.selectedCasID + ';', 'color: black; background: #90EE90; font-size: 12px');
            } else {
              this.prepareNewCase();
            }

            this.clrCasOrg_setSofSign();
            this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[this.selectedCaseIndx])); // Deep copy of new case to check editing but undefined props are not copied!
            this.activeTab = 2;  // Show Record tab
            window.scroll(0, 0);
          }
        }
      }

      this.fetchingSrchResLastNm = false;
      this.fetchingSrchResFirstNm = false;
      this.fetchingSrchResTel = false;
      this.fetchingSrchResRecNo = false;
      this.fetchingSrchResCasDt = false;
      this.fetchingSrchResContNo = false;
      this.fetchingSrchCases = false;
      setTimeout(() => {
        this.scrollIntoViewBillHxSelectedCase();
      }, 250);
    });

    if (this.xtPID && this.xtCID) { // Coming in from another component
      this.openRecordAndCaseFromXternalComponentModal();
    }

    // Get record search last used params
    this._recordService.proc = new Error().stack.split('\n')[1];    // For error logging in _recordService
    this._recordService.getLastUsedParams(this.sn, this.userID)
      .subscribe({
        next: (data: IRecordsLastUsedParams) => {
          data[0].recordsetRecordsLastUsedParams[0] ? this.lastUsedParam = data[0].recordsetRecordsLastUsedParams[0] : '';
          if (+this.lastUsedParam.topRecRows) {
            const e = <HTMLInputElement>document.getElementById('topRecordsRows');
            if (e) {  // RTErr(2/14/24): Cannot set properties of null (setting 'value')
              e.value = this.lastUsedParam.topRecRows;
            }
          }
          if (+this.lastUsedParam.topCasRows) {
            const e = <HTMLInputElement>document.getElementById('topCasesRows');
            if (e) {
              e.value = this.lastUsedParam.topCasRows;
            }
          }
        },
        error: (err: any) => {
          this._toastService.updateDeadCenter(false);
          this._toastService.show(
            (this.engLang ? "Failed to load Record's last used search params."
              : 'No se cargaron los últimos párametros de búsqueda de records.')
            + this._recordService.errMsgSuggestion(this.engLang),
            {
              header: (
                err.displayMsg
              ), autohide: false, error: true
            }
          );
        }
      });
  }

  ngAfterContentChecked() {
    // Set first focus to save time if new case or new record
    if (!this.firstFocus && <HTMLInputElement>document.getElementById('casProvId')) {
      this.firstFocus = <HTMLInputElement>document.getElementById('casProvId');

      if (+this.pat.patID && !+this.selectedCase[this.selectedCaseIndx].casID) {
        this.firstFocus.focus(); // To select provider for new case
      } else if (!+this.pat.patID) {
        this.firstFocus = <HTMLInputElement>document.getElementById('recNo');
        this.firstFocus.focus();  // New record
      } else {
        // Maybe do something here for an established record & case - no first focus set ???
      }
    }

    if (this.modalBody) { // modalBody compiles modalBody.nativeElement but not modalBody.nativeElement.clientHeight nor modalBody.clientHeight
      const testElement = this.modalBody as unknown as HTMLElement; // Cast to unknown and then to HTMLElement
      if ('clientHeight' in testElement) {  // testElement.clientHeight works at run time by isolating modalBody with testElement
        this.winVertCtrUp = ((testElement.clientHeight) / 2 + 30).toString() + 'px';
        this.winVertCtrDn = ((testElement.clientHeight) / 2 - 30).toString() + 'px';
        this.winHorzCtr = ((window.innerWidth - testElement.clientWidth) / 2 + 40).toString() + 'px';
      }
    } else {
      this.winVertCtrUp = ((window.innerHeight || document.documentElement.clientHeight) / 2 + 30).toString() + 'px';
      this.winVertCtrDn = ((window.innerHeight || document.documentElement.clientHeight) / 2 - 30).toString() + 'px';
      this.winVertBack2srch = ((((window.innerHeight || document.documentElement.clientHeight) / 2) - 150) + 'px').toString();
    }
  }

  ngOnDestroy() {
    this.lastUsedParam.lastNm = this.lastUsedParam.lastNm ? this.lastUsedParam.lastNm : '';
    this.lastUsedParam.telNo = this.lastUsedParam.telNo ? this.lastUsedParam.telNo : '';
    this.lastUsedParam.recNo = this.lastUsedParam.recNo ? this.lastUsedParam.recNo : '';
    this.lastUsedParam.casDt = this.lastUsedParam.casDt ? this.lastUsedParam.casDt : '';
    this.lastUsedParam.contrNo = this.lastUsedParam.contrNo ? this.lastUsedParam.contrNo : '';
    this.lastUsedParam.topRecRows = +this.lastUsedParam.topRecRows ? this.lastUsedParam.topRecRows : '10';
    this.lastUsedParam.topCasRows = +this.lastUsedParam.topCasRows ? this.lastUsedParam.topCasRows : '5';

    const params: any = this.lastUsedParam;
    params.userID = this.userID;  // Add 2 more properties to parameter object
    params.sn = this.sn;
    this._recordService.postRecordsLastUsedParams(params)
      .subscribe({
        next: data => console.log('postRecordsLastUsedParams() saved!'),
        error: error => console.error(error)
      });

    this.sioSubscrpt.unsubscribe();
    console.log('%c' + 'UNSUSCRIBE-RecordComponent', 'color: white; background: black; font-size: 10px');
    this.getSignSubscrpt?.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (this.activeTab == 2 && this.pat.patID == '0') {  // New record
      document.getElementById('recDob').focus();
    }
  }

  ngAfterViewInit(): void {
    if (this.activeTab == 1) {  // Search for records
      if (this.lastSrchParm) {
        document.getElementById(this.lastSrchParm).focus();
      }

      // Create a media query for iPad portrait
      const mediaQuery = window.matchMedia('only screen and (max-width: 1024px)');
      const handleMediaQuery = (event) => { // Function to handle media query changes
        if (event.matches) {  // Callback re executes on change
          const table = document.getElementById('sTable');
          if (table) {
            if (event.matches && table?.classList) { // Media query matched, add the responsive class
              table.classList.add('portrate');
              this.ipadPortrait = true;
            } else {  // Media query not matched, remove the responsive class
              if (table?.classList.contains('portrate')) {
                table.classList.remove('portrate');
              }
              this.ipadPortrait = false;
            }
          }
        }
      };
      mediaQuery.addEventListener('change', handleMediaQuery); // Attach the event listener for media query changes
      handleMediaQuery(mediaQuery); // Initial check of the media query
    }
  }

  clrCasOrg_setSofSign(): void {
    this.casOrg = undefined;
    this.sofSignB64 = undefined;
    if (this.selectedCase?.length) {
      let cas = this.selectedCase[this.selectedCaseIndx];
      if (cas?.sofSign) {
        this.noSigning = false;
        this.isSigning = false;
        this.sofSignB64 = this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Scheme + cas.sofSign);
      }
    }
  }

  beforeTabChange(event: any): void {
    console.log('%c' + 'event.activeId = ' + event.activeId, 'color: green; background: white; font-size: 10px');
    console.log('%c' + 'event.nextId = ' + event.nextId, 'color: green; background: white; font-size: 10px');

    this._toastService.clear(); // Clear the toaster
    this.onClose_mainMnu('')  // Close all menus
    if (+event.activeId == 2) { // activeId = 2 is the record tab (current) and it is about to change to nextId
      this.firstFocus = undefined;
    }

    this.hideScanners();
    if (event.nextId == '2') {  // 2 = Record
      if (!this.pat.patID && !this.pat.lastNm && !this.pat.firstNm) {
        this.newRecord();
      }
    } else if (event.nextId == '1') { // 1 = Search
      this.abortEdits(event, false, false, undefined, undefined);
    }
    console.log('%c' + 'event.nextId = ' + event.nextId, 'color: green; background: white; font-size: 10px');
  }

  saveRecord(patOnly: boolean): boolean {
    this._toastService.clear(); // Clear the toaster

    if (this.scanLic || this.scanID1 || this.scanID2 || this.scanPhoto || this.scanRec || this.scanCas) {
      return; // Don't allow saving while scanning
    }

    this.recEdited = Object.keys(this.patOrg).length > 0 && !this._help.deepEqual(this.pat, this.patOrg);
    this.casEdited = Object.keys(this.casOrg).length > 0 && !this._help.deepEqual(this.selectedCase[this.selectedCaseIndx], this.casOrg);
    this.proc1Edited = Object.keys(this.casOrg.procs1).length > 0 && !this._help.deepEqual(this.selectedCase[this.selectedCaseIndx].procs1, this.casOrg.procs1);
    this.proc2Edited = Object.keys(this.casOrg.procs2).length > 0 && !this._help.deepEqual(this.selectedCase[this.selectedCaseIndx].procs2, this.casOrg.procs2);
    this.proc0Edited = Object.keys(this.casOrg.procs0).length > 0 && !this._help.deepEqual(this.selectedCase[this.selectedCaseIndx].procs0, this.casOrg.procs0);
    this.payEdited = !this._help.deepEqual(this.selectedCase[this.selectedCaseIndx].pays, this.casOrg.pays);

    this.waiting4Success = false;

    if (this.ckOk2Save(patOnly)) {
      if (this.recEdited) {

        let q = "Exec spMB_Web_Save_Record @sn = '" + this.sn
          + "'" + (this.pat.patID ? ", @pKey = '" + this.pat.patID + "'" : '')
          + " " + (this.pat.recNo ? ", @recNo = '" + this._help.escApos(this.pat.recNo) + "'" : '')
          + ", @recLocale = '" + this._help.escApos(this.pat.recLocale)
          + "', @recYr = '" + this._help.escApos(this.pat.recYr)
          + "' " + (this.pat.otherRecNo ? ", @otherRecNo = '" + this._help.escApos(this.pat.otherRecNo) + "'" : '')
          + ", @lastNm = '" + this._help.escApos(this.pat.lastNm).toUpperCase()
          + "', @firstNm = '" + this._help.escApos(this.pat.firstNm).toUpperCase()
          + "' " + (this.pat.midInit ? ", @midInit = '" + this._help.escApos(this.pat.midInit).toUpperCase() + "'" : '')
          + " " + (this.pat.lastNm1 ? ", @lastNm1 = '" + this._help.escApos(this.pat.lastNm1).toUpperCase() + "'" : '')
          + " " + (this.pat.firstNm1 ? ", @firstNm1 = '" + this._help.escApos(this.pat.firstNm1).toUpperCase() + "'" : '')
          + " " + (this.pat.midInit1 ? ", @midInit1 = '" + this._help.escApos(this.pat.midInit1).toUpperCase() + "'" : '')
          + " " + (this.pat.lastNm2 ? ", @lastNm2 = '" + this._help.escApos(this.pat.lastNm2).toUpperCase() + "'" : '')
          + " " + (this.pat.firstNm2 ? ", @firstNm2 = '" + this._help.escApos(this.pat.firstNm2).toUpperCase() + "'" : '')
          + " " + (this.pat.midInit2 ? ", @midInit2 = '" + this._help.escApos(this.pat.midInit2).toUpperCase() + "'" : '')
          + ", @dob = '" + this._help.fmtDate(new Date(this.pat.dob), 'ymd')
          + "', @sex = '" + this._help.escApos(this.pat.sex).toUpperCase().toUpperCase()
          + "' " + (this.pat.add1 ? ", @add1 = '" + this._help.escApos(this.pat.add1).toUpperCase() + "'" : '')
          + " " + (this.pat.add2 ? ", @add2 = '" + this._help.escApos(this.pat.add2).toUpperCase() + "'" : '')
          + " " + (this.pat.city ? ", @city = '" + this._help.escApos(this.pat.city).toUpperCase() + "'" : '')
          + " " + (this.pat.st ? ", @st = '" + this._help.escApos(this.pat.st).toUpperCase() + "'" : '')
          + " " + (this.pat.zip ? ", @zip = '" + this.pat.zip + "'" : '')
          + " " + (this.pat.cell ? ", @cell = '" + this.pat.cell.replace(/[\D]/g, '') + "'" : '')
          + " " + (this.pat.tel ? ", @tel = '" + this.pat.tel.replace(/\D/g, '') + "'" : '')
          + " " + (this.pat.email ? ", @email = '" + this._help.escApos(this.pat.email) + "'" : '')
          + " " + (this.pat.insId1 ? ", @insId1 = '" + this.pat.insId1 + "'" : '')
          + " " + (this.pat.contr1 ? ", @contr1 = '" + this._help.escApos(this.pat.contr1).toUpperCase() + "'" : '')
          + " " + (this.pat.group1 ? ", @group1 = '" + this._help.escApos(this.pat.group1).toUpperCase() + "'" : '')
          + " " + (this.pat.ded1 ? ", @ded1 = '" + this._help.escApos(this.pat.ded1).toUpperCase() + "'" : '')
          + " " + (this.pat.insId2 ? ", @insId2 = '" + this.pat.insId2 + "'" : '')
          + " " + (this.pat.contr2 ? ", @contr2 = '" + this._help.escApos(this.pat.contr2).toUpperCase() + "'" : '')
          + " " + (this.pat.group2 ? ", @group2 = '" + this._help.escApos(this.pat.group2).toUpperCase() + "'" : '')
          + " " + (this.pat.ded2 ? ", @ded2 = '" + this._help.escApos(this.pat.ded2).toUpperCase() + "'" : '')
          + " " + (this.pat.notes ? ", @notes = '" + this._help.escApos(this.pat.notes) + "'" : '')

          + " " + (this.pat.hSign != this.patOrg.hSign ? ", @hSign = '" + this.pat.hSign + "', @hSignDt = '" + moment(this.pat.hSignDt, 'MM/DD/YY hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss') + "'" : '')  // FIX(11/24/23)

          + ", @userId = '" + this.userID
          + "';"

        this.saveStatus = this.engLang ? 'Saving record...' : 'Grabando record...';
        this.waiting4Success = true;
        window.scroll(0, 0);
        console.log('%c' + 'query ' + q, 'color: black; background: #90EE90; font-size: 12px');
        this._websocketService.sendChat('query', this.sn, q);
      }

      // let qi = '';
      // if (this.patImg.id1Pkey == '0' && this.patImg.id1) {  // Insert new id1
      //   qi += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
      //     + "', @docID = '" + this.patImg.id1Pkey
      //     + "', @userID = '" + this.userID
      //     + "', @docDesc = '" + 'ID Seg 1 ' + this.pat.lastNm + ',' + this.pat.firstNm
      //     + "', @patID = '" + this.pat.patID
      //     + "', @id1 = '" + this.patImg.id1.replace(this.base64Scheme, '') + "';";
      // } else if (parseInt(this.patImg.id1Pkey) < 0 || (this.patImg.id1 && Md5.hashStr(this.patImg.id1) != this.patImg.id1Md5)) { // Delete or update id1
      //   qi += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
      //     + "', @docID = '" + this.patImg.id1Pkey
      //     + "', @userID = '" + this.userID
      //     + "', @id1 = '" + (parseInt(this.patImg.id1Pkey) < 0 ? "" : this.patImg.id1.replace(this.base64Scheme, '')) + "';";
      // }

      // if (this.patImg.id2Pkey == '0' && this.patImg.id2) {  // Insert new id2
      //   qi += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
      //     + "', @docID = '" + this.patImg.id2Pkey
      //     + "', @userID = '" + this.userID
      //     + "', @docDesc = '" + 'ID Seg 1 ' + this.pat.lastNm + ',' + this.pat.firstNm
      //     + "', @patID = '" + this.pat.patID
      //     + "', @id2 = '" + this.patImg.id2.replace(this.base64Scheme, '') + "';";
      // } else if (parseInt(this.patImg.id2Pkey) < 0 || (this.patImg.id2 && Md5.hashStr(this.patImg.id2) != this.patImg.id2Md5)) { // Delete or update id2
      //   qi += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
      //     + "', @docID = '" + this.patImg.id2Pkey
      //     + "', @userID = '" + this.userID
      //     + "', @id2 = '" + (parseInt(this.patImg.id2Pkey) < 0 ? "" : this.patImg.id2.replace(this.base64Scheme, '')) + "';";
      // }

      // if (this.patImg.licPkey == '0' && this.patImg.lic) {  // Insert new lic
      //   qi += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
      //     + "', @docID = '" + this.patImg.licPkey
      //     + "', @userID = '" + this.userID
      //     + "', @docDesc = '" + 'ID Seg 1 ' + this.pat.lastNm + ',' + this.pat.firstNm
      //     + "', @patID = '" + this.pat.patID
      //     + "', @lic = '" + this.patImg.lic.replace(this.base64Scheme, '') + "';";
      // } else if (parseInt(this.patImg.licPkey) < 0 || (this.patImg.lic && Md5.hashStr(this.patImg.lic) != this.patImg.licMd5)) { // Delete or update lic
      //   qi += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
      //     + "', @docID = '" + this.patImg.licPkey
      //     + "', @userID = '" + this.userID
      //     + "', @lic = '" + (parseInt(this.patImg.licPkey) < 0 ? "" : this.patImg.lic.replace(this.base64Scheme, '')) + "';";
      // }

      // if (this.patImg.photoPkey == '0' && this.patImg.photo) {  // Insert new photo
      //   qi += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
      //     + "', @docID = '" + this.patImg.photoPkey
      //     + "', @userID = '" + this.userID
      //     + "', @docDesc = '" + 'ID Seg 1 ' + this.pat.lastNm + ',' + this.pat.firstNm
      //     + "', @patID = '" + this.pat.patID
      //     + "', @photo = '" + this.patImg.photo.replace(this.base64Scheme, '') + "';";
      // } else if (parseInt(this.patImg.photoPkey) < 0 || (this.patImg.photo && Md5.hashStr(this.patImg.photo) != this.patImg.photoMd5)) { // Delete or update photo
      //   qi += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
      //     + "', @docID = '" + this.patImg.photoPkey
      //     + "', @userID = '" + this.userID
      //     + "', @photo = '" + (parseInt(this.patImg.photoPkey) < 0 ? "" : this.patImg.photo.replace(this.base64Scheme, '')) + "';";
      // }

      // if (qi) {
      //   this.saveStatus = this.engLang ? 'Saving record images...' : 'Grabando imágenes record...';
      //   this.waiting4Success = true;
      //   window.scroll(0, 0);
      //   this._websocketService.sendChat('query', this.sn, qi);
      // }

      // this.saveImages('r'); // Save docs attached to record

    } else {

      this.errsMsgs = [];
      for (let key in this.errsRec) {
        if (this.errsRec[key]) {
          this.errsMsgs.push(this.errsRec[key]);
        }
      }

      for (let key in this.errsCas) {
        if (this.errsCas[key]) {
          this.errsMsgs.push(this.errsCas[key]);
        }
      }

      for (let i = 0; i < this.errsDx.length; i++) {
        for (let key in this.errsDx[i]) {
          if (this.errsDx[i][key]) {
            this.errsMsgs.push(this.errsDx[i][key]);
          }
        }
      }

      for (let i = 0; i < this.errsProc1.length; i++) {
        for (let key in this.errsProc1[i]) {
          if (this.errsProc1[i][key]) {
            this.errsMsgs.push(this.errsProc1[i][key] + (this.engLang ? ' Ins 1' : ' Seg 1'));
          }
        }
      }

      for (let i = 0; i < this.errsProc2.length; i++) {
        for (let key in this.errsProc2[i]) {
          if (this.errsProc2[i][key]) {
            this.errsMsgs.push(this.errsProc2[i][key] + (this.engLang ? ' Ins 2' : ' Seg 2'));
          }
        }
      }

      for (let i = 0; i < this.errsProc0.length; i++) {
        for (let key in this.errsProc0[i]) {
          if (this.errsProc0[i][key]) {
            this.errsMsgs.push(this.errsProc0[i][key] + (this.engLang ? ' Private' : ' Pvdo'));
          }
        }
      }

      for (let i = 0; i < this.errsPay.length; i++) {
        for (let key in this.errsPay[i]) {
          if (this.errsPay[i][key]) {
            this.errsMsgs.push(this.errsPay[i][key]);
          }
        }
      }

      this._toastService.updateDeadCenter(true);
      this._toastService.show(this.errsTmplt,
        {
          header: (this.engLang ? "ERROR Saving!" : '¡ERROR Grabando!')
          , autohide: false
          , error: true
        }
      );

      window.scroll(0, 0);

      return false; // this.ckOk2Save(patOnly) returned false
    }

    if (!this.recEdited) {  // No edits to record
      this.saveCase();
      this.saveProcs();
      // this.savePayments();

      this.hl7Send();
    }
    return true;
  }

  saveCase(): void {
    this.saveStatus = undefined;
    this.waiting4Success = false;

    this.x837encodeClaim(undefined);

    if (this.casEdited) {
      let cas = this.selectedCase[this.selectedCaseIndx];
      cas.casDate = cas.casDat.substring(0, 6) + cas.casDat.substring(8, 10); // casDate is the short mm/dd/yy version of casDat & it must be manually synced here if changed.

      let q = "Exec spMB_Web_Save_Case @sn = '" + this.sn
        + "'" + ((cas.casID && +cas.casID > 0) ? ", @casID = " + cas.casID : '')
        + ", @casOfNo = '" + ((!cas.casOfNo || +cas.casOfNo <= 0) ? this.pat.recLocale.match(/^\d{4}/g)[0] + "'" : cas.casOfNo + "'")
        + ", @casPatID = " + this.pat.patID
        + ", @casDt = '" + this._help.fmtDate(new Date(cas.casDat), 'ymd')
        + "', @casProvID = " + cas.casProvID
        + ", @casI1ID = " + cas.casI1ID
        + (cas.casCont1 ? ", @casCont1 = '" + this._help.escApos(cas.casCont1).toUpperCase() + "'" : '')
        + (cas.casGrp1 ? ", @casGrp1 = '" + this._help.escApos(cas.casGrp1).toUpperCase() + "'" : '')
        + (cas.casDed1 ? ", @casDed1 = '" + this._help.escApos(cas.casDed1).toUpperCase() + "'" : '')
        + (cas.casBal1 ? ", @casBal1 = " + cas.casBal1.toString().replace(/,/g, '') : '')
        + " " + (cas.casILastIns1 ? ", @casILastIns1 = '" + this._help.escApos(cas.casILastIns1).toUpperCase() + "'" : '')
        + " " + (cas.casIFirstIns1 ? ", @casIFirstIns1 = '" + this._help.escApos(cas.casIFirstIns1).toUpperCase() + "'" : '')
        + " " + (cas.casIMidIns1 ? ", @casIMidIns1 = '" + this._help.escApos(cas.casIMidIns1).toUpperCase() + "'" : '')
        + ", @casI2ID = " + cas.casI2ID
        + (cas.casCont2 ? ", @casCont2 = '" + this._help.escApos(cas.casCont2).toUpperCase() + "'" : '')
        + (cas.casGrp2 ? ", @casGrp2 = '" + this._help.escApos(cas.casGrp2).toUpperCase() + "'" : '')
        + (cas.casDed2 ? ", @casDed2 = '" + this._help.escApos(cas.casDed2).toUpperCase() + "'" : '')
        + (cas.casBal2 ? ", @casBal2 = " + cas.casBal2.toString().replace(/,/g, '') : '')
        + " " + (cas.casILastIns2 ? ", @casILastIns2 = '" + this._help.escApos(cas.casILastIns2).toUpperCase() + "'" : '')
        + " " + (cas.casIFirstIns2 ? ", @casIFirstIns2 = '" + this._help.escApos(cas.casIFirstIns2).toUpperCase() + "'" : '')
        + " " + (cas.casIMidIns2 ? ", @casIMidIns2 = '" + this._help.escApos(cas.casIMidIns2).toUpperCase() + "'" : '')
        + " " + (cas.casBalP ? ", @casBalP = " + cas.casBalP.toString().replace(/,/g, '') : '')
        + (cas.dxs && cas.dxs.length > 0 && cas.dxs[0].code ? ", @casDx1 = '" + cas.dxs[0].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 1 && cas.dxs[1].code ? ", @casDx2 = '" + cas.dxs[1].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 2 && cas.dxs[2].code ? ", @casDx3 = '" + cas.dxs[2].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 3 && cas.dxs[3].code ? ", @casDx4 = '" + cas.dxs[3].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 4 && cas.dxs[4].code ? ", @casDx5 = '" + cas.dxs[4].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 5 && cas.dxs[5].code ? ", @casDx6 = '" + cas.dxs[5].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 6 && cas.dxs[6].code ? ", @casDx7 = '" + cas.dxs[6].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 7 && cas.dxs[7].code ? ", @casDx8 = '" + cas.dxs[7].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 8 && cas.dxs[8].code ? ", @casDx9 = '" + cas.dxs[8].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 9 && cas.dxs[9].code ? ", @casDxa = '" + cas.dxs[9].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 10 && cas.dxs[10].code ? ", @casDxb = '" + cas.dxs[10].code.toUpperCase() + "'" : '')
        + (cas.dxs && cas.dxs.length > 11 && cas.dxs[11].code ? ", @casDxc = '" + cas.dxs[11].code.toUpperCase() + "'" : '')
        + (cas.casRefID ? ", @casRefID = " + cas.casRefID : '')
        + (cas.casRefNPI ? ", @casRefNPI = '" + cas.casRefNPI + "'" : '')
        + (cas.casRefLastNm ? ", @casRefLastNm = '" + this._help.escApos(cas.casRefLastNm).toUpperCase() + "'" : '')
        + (cas.casRefFirstNm ? ", @casRefFirstNm = '" + this._help.escApos(cas.casRefFirstNm).toUpperCase() + "'" : '')
        + (cas.casFacID ? ", @casFacID = " + cas.casFacID : '')
        + (cas.casFacAlias ? ", @casFacAlias = '" + this._help.escApos(cas.casFacAlias).toUpperCase() + "'" : '')
        + (cas.casAuthNo ? ", @casAuthNo = '" + this._help.escApos(cas.casAuthNo).toUpperCase() + "'" : '')
        + (cas.casRefNo ? ", @casRefNo = '" + this._help.escApos(cas.casRefNo).toUpperCase() + "'" : '')
        + (cas.cas19 ? ", @cas19 = '" + this._help.escApos(cas.cas19).toUpperCase() + "'" : '')
        + (cas.casAdmDt ? ", @casAdmDt = '" + this._help.fmtDate(new Date(cas.casAdmDt), 'ymd') + "'" : '')
        + (cas.casDiscDt ? ", @casDiscDt = '" + this._help.fmtDate(new Date(cas.casDiscDt), 'ymd') + "'" : '')
        + ", @userId = '" + this.userID
        + "';"

      this.saveStatus = this.engLang ? 'Saving case...' : 'Grabando caso...';
      this.waiting4Success = true;
      window.scroll(0, 0);
      console.log('%c' + 'saveCase(): ' + q, 'color: black; background: #90EE90 ; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, q);
    }

    this.saveImages('c'); // Save docs attached to case
  }

  saveProcs(): void {
    let cas = this.selectedCase[this.selectedCaseIndx];
    let q = '';
    if (this.proc1Edited) {
      cas.procs1.forEach((e, i) => {
        if ((e.code && e.code.length > 0) || (e.desc && e.desc.length > 0)) {
          q += this.procQ(e, i, '1', cas);
        }
      });
    }
    if (this.proc2Edited) {
      cas.procs2.forEach((e, i) => {
        if ((e.code && e.code.length > 0) || (e.desc && e.desc.length > 0)) {
          q += this.procQ(e, i, '2', cas);
          console.log(q);
        }
      });
    }
    if (this.proc0Edited) {
      cas.procs0.forEach((e, i) => {
        if ((e.code && e.code.length > 0) || (e.desc && e.desc.length > 0)) {
          q += this.procQ(e, i, '', cas);
        }
      });
    }

    if (q.length > 0) {
      this.saveStatus = this.engLang ? 'Saving procedures...' : 'Grabando procedimientos...';
      this.waiting4Success = true;
      window.scroll(0, 0);
      console.log('%c' + 'saveProcs(): ' + q, 'color: black; background: #90EE90; font-size: 14px');
      this._websocketService.sendChat('query', this.sn, q);
    }
  }

  procQ(e, i, ps, cas): string {
    let dtFrom: string = '';
    let momFrom = moment(e.fromDt, 'MM/DD/YYYY');
    if (momFrom.isValid) {
      dtFrom = momFrom.format('YYYYMMDD');
    }
    let dtTo: string = '';
    let momTo = moment(e.fromDt, 'MM/DD/YYYY');
    if (momTo.isValid) {
      dtTo = momTo.format('MM/DD/YYYY');  // This format for legacy reasons
    }

    return "Exec spMB_Web_Save_Procs @sn = '" + this.sn + "', @detID = " + e.detID
      + ", @detCasID = " + cas.casID
      + ", @fromDt = '" + dtFrom
      + "', @provID = " + cas.casProvID
      + ", @toDt = '" + dtTo
      + "'" + (e.code ? ", @code = '" + this._help.escApos(e.code).toUpperCase() + "'" : '')
      + (e.mod1 ? ", @mod1 = '" + this._help.escApos(e.mod1).toUpperCase() + "'" : '')
      + (e.mod2 ? ", @mod2 = '" + this._help.escApos(e.mod2).toUpperCase() + "'" : '')
      + (e.mod3 ? ", @mod3 = '" + this._help.escApos(e.mod3).toUpperCase() + "'" : '')
      + (e.mod4 ? ", @mod4 = '" + this._help.escApos(e.mod4).toUpperCase() + "'" : '')
      + (e.qty ? ", @qty = '" + e.qty + "'" : '')
      + (e.usual ? ", @usual = '" + e.usual + "'" : '')
      + (e.xpect ? ", @xpect = '" + e.xpect + "'" : '')
      + (e.pvdo ? ", @pvdo = '" + e.pvdo + "'" : '')
      + ", @ps = '" + ps.toUpperCase() + "'"
      + (e.dx ? ", @dx = '" + this._help.escApos(e.dx).toUpperCase() + "'" : '')
      + (e.pos ? ", @pos = '" + this._help.escApos(e.pos).toUpperCase() + "'" : '')
      + (e.prod ? ", @prod = " + e.prod.toString().match(/\d+/g) : '')
      + (e.desc ? ", @desc = '" + this._help.escApos(e.desc).toUpperCase() + "'" : '')
      + ", @userID = " + this.userID
      + ", @indx = " + i
      + (e.rxNDC ? ", @rxNDC = '" + e.rxNDC + "'" : "")
      + (e.rxQty ? ", @rxQty = '" + e.rxQty + "'" : "")
      + (e.rxUM ? ", @rxUM = '" + e.rxUM.toUpperCase() + "'" : "")
      + (e.rxNo ? ", @rxNo = '" + this._help.escApos(e.rxNo.toUpperCase()) + "'" : "")
      + ";"
  }

  savePayments(): void {
    let cas = this.selectedCase[this.selectedCaseIndx];
    if (cas.pays) {
      let q = '';
      cas.pays.forEach((e, i) => {
        if (this.payEdited) {
          if (+e.payID == 0 && this._help.isDate(new Date(e.payDt))) {
            q += "Exec spMB_Web_Save_Pays @sn = '" + this.sn
              + "', @payCasID = " + cas.casID
              + ", @payDt = '" + this._help.fmtDate(new Date(e.payDt), 'ymd') + "'"
              + ", @payAmnt = " + e.payAmnt
              + ", @payPS = '" + this._help.escApos(e.payPS).toUpperCase() + "'"
              + ", @payMode = '" + this._help.escApos(e.payMode).toUpperCase() + "'"
              + ", @PayCkNo = '" + this._help.escApos(e.payCkNo).toUpperCase() + "'"
              + (e.payApCode ? ", @payApCode = '" + this._help.escApos(e.payApCode).toUpperCase() + "'" : '')
              + (e.payMemo ? ", @payMemo = '" + this._help.escApos(e.payMemo) + "'" : '')
              + ", @userID = " + this.userID
              + ", @indx = " + i
              + ";"
          }
        }
      });

      if (q.length > 0) {
        this.saveStatus = this.engLang ? 'Saving payments...' : 'Grabando pagos...';
        this.waiting4Success = true;
        window.scroll(0, 0);
        console.log('%c' + 'query ' + q, 'color: black; background: #90EE90; font-size: 12px');
        this._websocketService.sendChat('query', this.sn, q);
      }
    }
  }

  assignNewMB_RecordNo(): void {
    console.log('%c' + 'query ' + 'Exec spMBAssigneRecordNo @sn = "' + this.sn + '"', 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, 'Exec spMBAssigneRecordNo @sn = "' + this.sn + '"');
  }

  ckOk2Save(patOnly: boolean): boolean {
    let ok2Save = true;
    this.resetDeErrs();

    if (!this.pat.recYr.match(/^\d{2}$/g)) {
      this.errsRec.recYr = this.engLang ? 'Record No. year' : 'Año No. Record';
      ok2Save = false;
    }
    if (!this.pat.recLocale || !this.pat.recLocale.match(/^\d{4}$/g)) {
      this.errsRec.recLocale = this.engLang ? 'Locale/Office Record No.' : 'Localidad/Oficina No. Record';
      ok2Save = false;
    }

    if (this.pat.patID && (!this.pat.recNo || this.pat.recNo.match(/\D+/g))) {
      this.errsRec.recNo = this.engLang ? 'Record No.' : 'No. Record';
      ok2Save = false;
    }

    if (!this.pat.lastNm || this.pat.lastNm.match(/[^A-Z a-z'áéíóú]+/g) || this.pat.lastNm.match(/^.*'.*'.*$/gi)) {
      this.errsRec.last = this.engLang ? 'Patient\'s Last Name' : 'Apellidos Paciente';
      ok2Save = false;
    }

    if (!this.pat.firstNm || this.pat.firstNm.match(/[^A-Z a-záéíóú]+/g)) {
      this.errsRec.first = this.engLang ? 'Patient\'s First Name' : 'Nombre Paciente';
      ok2Save = false;
    }

    if (this.pat.midInit && this.pat.midInit.match(/[^A-Z a-z]+/g)) {
      this.errsRec.init = this.engLang ? 'Patient\'s Mid Initial' : 'Inicial Nombre Paciente';
      ok2Save = false;
    }

    if (!this.pat.dob || !this._help.ckDateInStr(this.pat.dob) || !this.pat.dob.match(/\d{4}$/g)) {
      this.errsRec.dob = this.engLang ? 'Date of Birth' : 'Fecha Nacimiento';
      ok2Save = false;
    } else if (this._help.compDates(this.pat.dob, this._help.todaysDt(4)) > 0) {
      this.errsRec.dob = this.engLang ? 'Date of Birth' : 'Fecha Nacimiento';  // DOB in the future
      ok2Save = false;
    }

    if (!this.pat.sex || (this.pat.sex != 'M' && this.pat.sex != 'F')) {
      this.errsRec.sex = this.engLang ? 'Patient\'s Gender' : 'Género Paciente';
      ok2Save = false;
    }

    if (this.pat.add1 && this.pat.add1.match(/[^A-Z0-9# a-záéíóú\.]+/g)) {
      this.errsRec.add1 = this.engLang ? 'Address 1' : 'Dirección 1';
      ok2Save = false;
    }

    if (this.pat.add2 && this.pat.add2.match(/[^A-Z0-9# a-záéíóú\.]+/g)) {
      this.errsRec.add2 = this.engLang ? 'Address 2' : 'Dirección 2';
      ok2Save = false;
    }

    if (this.pat.city && this.pat.city.match(/[^A-Z a-záéíóú]+/g)) {
      this.errsRec.city = this.engLang ? 'City' : 'Ciudad';
      ok2Save = false;
    }

    if (this.pat.zip && !this.pat.zip.match(/^\d{5}$|^\d{9}$|^\d{5}\-\d{4}$/g)) {
      this.errsRec.zip = this.engLang ? 'Zip Code' : 'Código Postal';
      ok2Save = false;
    }

    if (this.pat.add1) {
      if (!this.pat.city) {
        this.errsRec.city = this.engLang ? 'City' : 'Ciudad';
        ok2Save = false;
      }
      if (!this.pat.zip) {
        this.errsRec.zip = this.engLang ? 'Zip Code' : 'Código Postal';
        ok2Save = false;
      }
    }

    if ((this.pat.city || this.pat.zip) && !this.pat.add1) {
      this.errsRec.add1 = this.engLang ? 'Address 1' : 'Dirección 1';
      ok2Save = false;
    }

    if (this.pat.cell) {
      if (!this.pat.cell.replace('(', '').replace(')', '').replace('-', '').replace(' ', '').match(/^\d{10}$|^\d{7}$/g)) {
        this.errsRec.cell = this.engLang ? 'Cell Phone' : 'Tel Celular';
        ok2Save = false;
      }
    }

    if (this.pat.tel) {
      if (!this.pat.tel.replace('(', '').replace(')', '').replace('-', '').replace(' ', '').match(/^\d{10}$|^\d{7}$/g)) {
        this.errsRec.tel = this.engLang ? 'Telephone' : 'Teléfono';
        ok2Save = false;
      }
    }

    if (this.pat.email) {
      if (!this.pat.email.match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$|^\s*$/g)) {
        this.errsRec.email = 'Email';
        ok2Save = false;
      }
    }

    this.errsRec.insId1 = this.localInsurances.some(e => { return e.pKey == this.pat.insId1 }) ? '' : (this.engLang ? 'Insurance 1' : 'Seguro 1');
    if (this._help.isNumeric(this.pat.insId1) && parseInt(this.pat.insId1) > 0 && (!this.pat.contr1 || this.pat.contr1.trim().length == 0)) {
      this.errsRec.contr1 = this.engLang ? 'Contract No. 1' : 'No. Contrato 1';
    }

    this.errsRec.insId2 = this.localInsurances.some(e => { return e.pKey == this.pat.insId2 }) ? '' : (this.engLang ? 'Insurance 1' : 'Seguro 1');
    if (this._help.isNumeric(this.pat.insId2) && parseInt(this.pat.insId2) > 0 && (!this.pat.contr2 || this.pat.contr2.trim().length == 0)) {
      this.errsRec.contr2 = this.engLang ? 'Contract No. 1' : 'No. Contrato 1';
    }

    if (this.ref.npi || this.ref.lastNm || this.ref.firstNm) {
      if (!this.ref.npi || !this._help.isNPIvalid(this.ref.npi)) {
        this.errsCas.refNpi = this.engLang ? 'Referring NPI' : 'NPI Refiere';
        ok2Save = false;
      }
      if (!this.ref.lastNm || this.ref.lastNm.match(/[^A-Z a-záéíóú]+/g)) {
        this.errsCas.refLastNm = this.engLang ? 'Referring\'s Last Name' : 'Apellidos del que Refiere';
        ok2Save = false;
      }
      if (!this.ref.firstNm || this.ref.firstNm.match(/[^A-Z a-záéíóú]+/g)) {
        this.errsCas.refFirstNm = this.engLang ? 'Referring\'s First Name' : 'Nombre del que Refiere';
        ok2Save = false;
      }
    }

    if (patOnly || (!this.casEdited && !this.proc1Edited && !this.proc2Edited && !this.proc0Edited && !this.payEdited)) {
      return ok2Save;
    }

    let cas = this.selectedCase[this.selectedCaseIndx];

    if (!this._help.isDate(new Date(cas.casDat)) || ((!cas.casID || +cas.casID == 0) && this._help.dateDiffDays(cas.casDat, Date()) > 99)) {
      this.errsCas.dat = this.engLang ? 'Claim Posted Date' : 'Fecha Posteo del Caso';
      ok2Save = false;
    }

    if (parseInt(cas.casProvID) <= 0) {
      this.errsCas.provId = this.engLang ? 'Provider' : 'Proveedor';
    } else {
      this.errsCas.provId = this.localProviders.some(e => { return e.pKey == cas.casProvID }) ? '' : 'Proveedor';
    }
    if (this.errsCas.provId) {
      ok2Save = false;
    }

    this.ckDigitNPI(cas.casRefNPI);  // Sets this.errsCas.refNpi = true if bad npi.
    this.errsCas.refLastNm = /[^A-Za-z ']/gi.test(cas.casRefLastNm) ? (this.engLang ? 'Referring\'s Last Name' : 'Apellidos del que Refiere') : '';
    this.errsCas.refFirstNm = /[^A-Za-z ]/gi.test(cas.casRefFirstNm) ? (this.engLang ? 'Referring\'s First Name' : 'Nombre del que Refiere') : '';
    if (this.errsCas.refLastNm || this.errsCas.refFirstNm || this.errsCas.refNpi) {
      ok2Save = false;
    }

    this.errsCas.facId = +cas.casFacID && !this.localFacilities.some(e => { return e.pKey == cas.casFacID }) ? (this.engLang ? 'Facility/Hospital' : 'Facilidad/Hospital') : '';
    if (this.errsCas.facId) {
      ok2Save = false;
    }

    if (cas.casDiscDt && cas.casDiscDt && moment(cas.casAdmDt).isAfter(cas.casDiscDt)) {
      this.errsCas.admDt = this.engLang ? 'Admission Date' : 'Fecha Admisión';
      this.errsCas.discDt = this.engLang ? 'Discharge Date' : 'Fecha Alta';
      ok2Save = false;
    }

    cas.dxs.forEach((e, i) => {
      if (e.code && e.code.trim().length > 0) {
        if (!this._help.isICD10(e.code)) {
          this.errsDx[i].code = 'ICD10 item ' + (i + 1).toString();
          ok2Save = false;
        }
      }
    });

    cas.procs1.forEach((e, i) => {
      if ((e.code && e.code.length > 0) || (e.desc && e.desc.length > 0)) { // Valid row for saving.

        if (!+cas.casI1ID) {
          this.errsCas.i1ID = this.engLang ? 'Claim Insurance 1' : 'Seguro 1 Caso';
        } else {
          this.errsCas.i1ID = !this.localInsurances.some(e => { return e.pKey == cas.casI1ID }) ? (this.engLang ? 'Claim Insurance 1' : 'Seguro 1 Caso') : '';
          this.errsCas.contr1 = !cas.casCont1 ? (this.engLang ? 'Contract No. 1 Claim' : 'No. Contrato 1 Caso') : '';
          this.errsCas.lastIns1 = /[^A-Za-z ']/gi.test(cas.casILastIns1) ? (this.engLang ? 'ID Card Last Name' : 'Apellidos Tarjeta 1') : '';
          this.errsCas.firstIns1 = /[^A-Za-z ]/gi.test(cas.casIFirstIns1) ? (this.engLang ? 'ID Card First Name 1' : 'Nombre Tarjeta 1') : '';
          this.errsCas.initIns1 = /[^A-Za-z ]/gi.test(cas.casIMidIns1) ? (this.engLang ? 'ID Card Mid Initial 1' : 'Inicial Tarjeta 1') : '';
        }
        if (this.errsCas.i1ID || this.errsCas.contr1) {
          ok2Save = false;
        }

        if (e.code.toUpperCase() != this.ajusteStr) {
          if (!this._help.isDate(new Date(e.fromDt)) || !this._help.isDate(new Date(e.toDt)) || this._help.dateDiffDays(e.fromDt, e.toDt) < 0) {
            this.errsProc1[i].frDt = (this.engLang ? 'From Date' : 'Fecha Desde') + ' item ' + (i + 1).toString();
            this.errsProc1[i].toDt = (this.engLang ? 'To Date' : 'Fecha Hasta') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if ((!e.dx || e.dx.trim().length == 0)) {
            this.errsProc1[i].dx = (this.engLang ? 'Dx Pointer' : 'Puntero Dx') + ' item ' + (i + 1).toString();
          } else {
            Array.from(e.dx).forEach(c => {
              this.errsProc1[i].dx = (!cas.dxs.some(dx => { return dx.indx == c && dx.code.trim().length > 0 }) ? (this.engLang ? 'Dx Pointer' : 'Puntero Dx') + ' item ' + (i + 1).toString() : '');
            });
          }
          if (this.errsProc1[i]?.dx) {
            ok2Save = false;
          }

          if (!e.qty || parseFloat(e.qty) <= 0) {
            this.errsProc1[i].qty = (this.engLang ? 'Units/Quantity' : 'Unidades/Cantidad') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if (!/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.usual) || (/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.xpect) && parseFloat(e.xpect) > parseFloat(e.usual))) {
            this.errsProc1[i].usual = (this.engLang ? 'Usual Charge' : 'Cargo Usual') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }
          if (!/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.xpect) || parseFloat(e.xpect) < 0) {
            this.errsProc1[i].xpect = (this.engLang ? 'Expected Amount' : 'Cantidad Esperada') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }
          if (!/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.pvdo) || parseFloat(e.pvdo) < 0) {
            this.errsProc1[i].pvdo = (this.engLang ? 'Patient\'s Charge' : 'Cargo Privado') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          this.errsProc1[i].pos = (!this.pos.some(pos => { return pos == e.pos }) ? (this.engLang ? 'Place of Service' : 'Lugar de Servicio') + ' item ' + (i + 1).toString() : '');
          if (this.errsProc1[i].pos) {
            ok2Save = false;
          }

          if (+e.rx) {
            if (!e.rxNDC.match(/^\d{4,5}\-\d{3,4}\-\d{2}$/g)) {
              this.errsProc1[i].rxNDC = 'National Drug Code' + ' item ' + (i + 1).toString();
              ok2Save = false;
            }
            if (!e.rxQty.match(/^\d+$/g)) {
              this.errsProc1[i].rxQty = 'Quantity' + ' item ' + (i + 1).toString();
              ok2Save = false;
            }
            if (!e.rxUM.match(/^(UN|F2|GR|ML)$/gi)) {
              this.errsProc1[i].rxUM = 'NDC - Unit of Measure (UN,F2,GR,ML)' + ' item ' + (i + 1).toString();
              ok2Save = false;
            }
          }

        } else {  // Only for adjustments.

          if (!e.desc || !e.desc.toUpperCase().startsWith(this.ajusteStr)) {
            this.errsProc1[i].desc = (this.engLang ? 'Description must start with ' : 'Descripción debe comenzar con ') + ' ' + this.ajusteStr + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if (!/^\-?(\d{1,3},?)?(\d{1,3})(\.\d{0,4})?$/g.test(e.xpect) || parseFloat(e.xpect) == 0) {
            this.errsProc1[i].xpect = (this.engLang ? 'Expected amount must have adjustment amount' : 'Cargo esperado debe contener cantidad ajustada') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if (!/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.pvdo) || parseFloat(e.pvdo) > 0) {
            this.errsProc1[i].pvdo = (this.engLang ? 'Coins amount must have patient\'s discount amount' : 'Coins debe contener descuento privado') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

        }
      }
    });

    cas.procs2.forEach((e, i) => {
      if ((e.code && e.code.length > 0) || (e.desc && e.desc.length > 0)) { // Valid row for saving.

        if (!cas.casI2ID) {
          this.errsCas.i2ID = this.engLang ? 'Claim Insurance 2' : 'Seguro 2 Caso';
        } else {
          this.errsCas.i2ID = !this.localInsurances.some(e => { return e.pKey == cas.casI2ID }) ? (this.engLang ? 'Claim Insurance 2' : 'Seguro 2 Caso') : '';
          this.errsCas.contr2 = !cas.casCont2 ? (this.engLang ? 'Contract No. 2 Claim' : 'No. Contrato 2 Caso') : '';
          this.errsCas.lastIns2 = /[^A-Za-z ']/gi.test(cas.casILastIns2) ? (this.engLang ? 'ID Card Last Name 2' : 'Apellidos Tarjeta 2') : '';
          this.errsCas.firstIns2 = /[^A-Za-z ]/gi.test(cas.casIFirstIns2) ? (this.engLang ? 'ID Card First Name 2' : 'Nombre Tarjeta 2') : '';
          this.errsCas.initIns2 = /[^A-Za-z ]/gi.test(cas.casIMidIns2) ? (this.engLang ? 'ID Card Mid Initial 2' : 'Inicial Tarjeta 2') : '';
        }
        if (this.errsCas.i2ID || this.errsCas.contr2) {
          ok2Save = false;
        }

        if (e.code.toUpperCase() != this.ajusteStr) {
          if (!this._help.isDate(new Date(e.fromDt)) || !this._help.isDate(new Date(e.toDt)) || this._help.dateDiffDays(e.fromDt, e.toDt) < 0) {
            this.errsProc2[i].frDt = (this.engLang ? 'From Date' : 'Fecha Desde') + ' item ' + (i + 1).toString();
            this.errsProc2[i].toDt = (this.engLang ? 'To Date' : 'Fecha Hasta') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if ((!e.dx || e.dx.trim().length == 0)) {
            this.errsProc2[i].dx = (this.engLang ? 'Dx Pointer' : 'Puntero Dx') + ' item ' + (i + 1).toString();
          } else {
            Array.from(e.dx).forEach(c => {
              this.errsProc2[i].dx = (!cas.dxs.some(dx => { return dx.indx == c && dx.code.trim().length > 0 }) ? (this.engLang ? 'Dx Pointer' : 'Puntero Dx') + ' item ' + (i + 1).toString() : '');
            });
          }
          if (this.errsProc2[i].dx[i]) {
            ok2Save = false;
          }

          if (!e.qty || parseFloat(e.qty) <= 0) {
            this.errsProc2[i].qty = (this.engLang ? 'Units/Quantity' : 'Unidades/Cantidad') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if (!/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.usual) || (/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.xpect) && parseFloat(e.xpect) > parseFloat(e.usual))) {
            this.errsProc2[i].usual = (this.engLang ? 'Usual Charge' : 'Cargo Usual') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }
          if (!/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.xpect) || parseFloat(e.xpect) < 0) {
            this.errsProc2[i].xpect = (this.engLang ? 'Expected Amount' : 'Cantidad Esperada') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }
          if (!/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.pvdo) || parseFloat(e.pvdo) < 0) {
            this.errsProc2[i].pvdo = (this.engLang ? 'Patient\'s Charge' : 'Cargo Privado') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          this.errsProc2[i].pos = (!this.pos.some(pos => { return pos == e.pos }) ? (this.engLang ? 'Place of Service' : 'Lugar de Servicio') + ' item ' + (i + 1).toString() : '');
          if (this.errsProc2[i].pos[i]) {
            ok2Save = false;
          }

        } else {  // Only for adjustments.

          if (!e.desc || !e.desc.toUpperCase().startsWith(this.ajusteStr)) {
            this.errsProc2[i].desc = (this.engLang ? 'Description must start with ' : 'Descripción debe comenzar con ') + ' ' + this.ajusteStr + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if (!/^\-?(\d{1,3},?)?(\d{1,3})(\.\d{0,4})?$/g.test(e.xpect) || parseFloat(e.xpect) == 0) {
            this.errsProc2[i].xpect = (this.engLang ? 'Expected amount must have adjustment amount' : 'Cargo esperado debe contener cantidad ajustada') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if (!/^\-?(\d{1,3},?)?(\d{1,3})(\.\d{0,4})?$/g.test(e.pvdo) || parseFloat(e.pvdo) > 0) {
            this.errsProc2[i].pvdo = (this.engLang ? 'Coins amount must have patient\'s discount amount' : 'Coins debe contener descuento privado') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

        }
      }
    });

    cas.procs0.forEach((e, i) => {
      if ((e.code && e.code.length > 0) || (e.desc && e.desc.length > 0)) { // Valid row for saving.

        if (e.code.toUpperCase() != this.ajusteStr) {
          if (!this._help.isDate(new Date(e.fromDt)) || !this._help.isDate(new Date(e.toDt)) || this._help.dateDiffDays(e.fromDt, e.toDt) < 0) {
            this.errsProc0[i].frDt = (this.engLang ? 'From Date' : 'Fecha Desde') + ' item ' + (i + 1).toString();
            this.errsProc0[i].toDt = (this.engLang ? 'To Date' : 'Fecha Hasta') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if ((!e.dx || e.dx.trim().length == 0)) {
            this.errsProc0[i].dx = (this.engLang ? 'Dx Pointer' : 'Puntero Dx') + ' item ' + (i + 1).toString();
          } else {
            Array.from(e.dx).forEach(c => {
              this.errsProc0[i].dx = (!cas.dxs.some(dx => { return dx.indx == c && dx.code.trim().length > 0 }) ? (this.engLang ? 'Dx Pointer' : 'Puntero Dx') + ' item ' + (i + 1).toString() : '');
            });
          }
          if (this.errsProc0[i].dx[i]) {
            ok2Save = false;
          }

          if (!e.qty || parseFloat(e.qty) <= 0) {
            this.errsProc0[i].qty = (this.engLang ? 'Units/Quantity' : 'Unidades/Cantidad') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if (!/^(\d{1,3},)?(\d{1,3})(\.\d{0,4})?$/g.test(e.pvdo) || parseFloat(e.pvdo) < 0) {
            this.errsProc0[i].usual = (this.engLang ? 'Usual Charge' : 'Cargo Usual') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

        } else {  // Only for adjustments.

          if (!e.desc || !e.desc.toUpperCase().startsWith(this.ajusteStr)) {
            this.errsProc0[i].desc = (this.engLang ? 'Description must start with ' : 'Descripción debe comenzar con ') + ' ' + this.ajusteStr + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

          if (!/^\-?(\d{1,3},?)?(\d{1,3})(\.\d{0,4})?$/g.test(e.pvdo) || parseFloat(e.pvdo) >= 0) {
            this.errsProc0[i].pvdo = (this.engLang ? 'Coins amount must have patient\'s discount amount' : 'Coins debe contener descuento privado') + ' item ' + (i + 1).toString();
            ok2Save = false;
          }

        }
      }
    });

    cas.pays.forEach((e, i) => {
      if (!e.payID || +e.payID == 0) {
        if (!e.payDt || !this._help.isDate(new Date(e.payDt))) {
          this.errsPay[i].dt = (this.engLang ? 'Payment date item' : 'Fecha de pago') + ' item ' + (i + 1).toString();
          ok2Save = false;
        }

        if (e.payPS && e.payPS.toUpperCase() != 'S1' && e.payPS.toUpperCase() != 'S2' && e.payPS != '' && e.payPS.toUpperCase() != 'D1' && e.payPS.toUpperCase() != 'D2') {
          this.errsPay[i].fp = (this.engLang ? 'Payment source' : 'Fuente de pago') + ' item ' + (i + 1).toString();
        } else {
          if (e.payPS && e.payPS.endsWith('1') && parseInt(cas.casI1ID) == 0) {
            this.errsPay[i].fp = (this.engLang ? 'Payment source' : 'Fuente de pago') + ' item ' + (i + 1).toString();
          }
          if (e.payPS && e.payPS.endsWith('2') && parseInt(cas.casI2ID) == 0) {
            this.errsPay[i].fp = (this.engLang ? 'Payment source' : 'Fuente de pago') + ' item ' + (i + 1).toString();
          }
        }
        if (this.errsPay && this.errsPay[i].fp) {
          ok2Save = false;
        }

        if (!e.payAmnt || !/^\-?(\d{1,3},?)?(\d{1,3})(\.\d{0,4})?$/g.test(e.payAmnt)) {
          this.errsPay[i].amnt = (this.engLang ? 'Payment amount' : 'Cantidad de pago') + ' item ' + (i + 1).toString();
          ok2Save = false;
        }

      }
    });

    if (!ok2Save) {  // If error in record data abort save
      window.scroll(0, 0);
    }
    return ok2Save;
  }

  resetDeErrs(): void {
    this.saveStatus = undefined;
    this.warning = undefined;
    this.errsRec.recYr = '';
    this.errsRec.recLocale = '';
    this.errsRec.recNo = '';
    this.errsRec.last = '';
    this.errsRec.first = '';
    this.errsRec.init = '';
    this.errsRec.dob = '';
    this.errsRec.sex = '';
    this.errsRec.add1 = '';
    this.errsRec.add2 = '';
    this.errsRec.city = '';
    this.errsRec.st = '';
    this.errsRec.zip = '';
    this.errsRec.cell = '';
    this.errsRec.tel = '';
    this.errsRec.email = '';
    this.errsRec.insId1 = '';
    this.errsRec.contr1 = '';
    this.errsRec.insId2 = '';
    this.errsRec.contr2 = '';
    this.errsRec.group2 = '';
    this.errsCas.dat = '';
    this.errsCas.provId = '';
    this.errsCas.i1ID = '';
    this.errsCas.contr1 = '';
    this.errsCas.i2ID = '';
    this.errsCas.contr2 = '';
    this.errsCas.facId = '';
    this.errsCas.refID = '';
    this.errsCas.refNpi = '';
    this.errsCas.refLastNm = '';
    this.errsCas.refFirstNm = '';
    this.errsCas.admDt = '';
    this.errsCas.discDt = '';

    this.errsDx = [];
    for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].dxs.length; i++) {
      this.errsDx.push({ code: '' });
    }

    for (let key in this.errsProc) {
      this.errsProc[key] = '';
    }

    this.errsProc1 = [];
    for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].procs1.length; i++) {
      this.errsProc1.push({ ...this.errsProc });
    }
    this.errsProc2 = [];
    for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].procs2.length; i++) {
      this.errsProc2.push({ ...this.errsProc });
    }
    this.errsProc0 = [];
    for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].procs0.length; i++) {
      this.errsProc0.push({ ...this.errsProc });
    }

    for (let key in this.errsPy) {
      this.errsPy[key] = '';
    }

    this.errsPay = [];
    for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].pays.length; i++) {
      this.errsPay.push({ ...this.errsPy });
    }
  }

  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
  }

  siteChange(siteStr: string) { // for when site changes
    this.site.nm = siteStr;
  }

  siteAd1Change(siteAd1Str: string) { // for when site changes
    this.site.ad1 = siteAd1Str;
  }

  siteAd2Change(siteAd2Str: string) { // for when site changes
    this.site.ad2 = siteAd2Str;
  }

  siteCtChange(siteCtStr: string) { // for when site changes
    this.site.ct = siteCtStr;
  }

  siteStChange(siteStStr: string) { // for when site changes
    this.site.st = siteStStr;
  }

  siteZpChange(siteZpStr: string) { // for when site changes
    this.site.zp = siteZpStr;
  }

  siteTl1Change(siteTl1Str: string) { // for when site changes
    this.site.tl1 = siteTl1Str;
  }

  siteXt1Change(siteXt1Str: string) { // for when site changes
    this.site.xt1 = siteXt1Str;
  }

  siteTl2Change(siteTl2Str: string) { // for when site changes
    this.site.tl2 = siteTl2Str;
  }

  siteXt2Change(siteXt2Str: string) { // for when site changes
    this.site.xt2 = siteXt2Str;
  }

  siteEm1Change(siteEm1Str: string) { // for when site changes
    this.site.em1 = siteEm1Str;
  }

  siteEm2Change(siteEm2Str: string) { // for when site changes
    this.site.em2 = siteEm2Str;
  }

  userIdChange(userIdStr) {
    this.userID = userIdStr;
  }

  userLastNmChange(userLastNmStr) {
    this.userLastNm = userLastNmStr;
  }

  userFirstNmChange(userFirstNmStr) {
    this.userFirstNm = userFirstNmStr;
  }

  userSecOptsChange(userSecOptsStr: string) {
    this.userSecOpts = JSON.parse(userSecOptsStr);
  }

  onClick_findRecord(srchParm: any): void {
    this.saveStatus = undefined;
    this.showCasesHx = false;

    this.clrPat();
    this.patOrg = JSON.parse(JSON.stringify(this.pat)); // Deep copy of new record to check editing

    this.selectedCase = [];
    this.selectedCase.pop();
    this.selectedCase.push(this.casObj);
    this.clrCasOrg_setSofSign();
    this.selectedCaseIndx = 0;
    this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[this.selectedCaseIndx])); // Deep copy of new case to check editing but undefined props are not copied!

    let qry: string
    if (srchParm?.id && srchParm?.value) {
      qry = "Exec spMBSearch4Record @top = " + this.lastUsedParam.topRecRows + ", @order = "
      switch (srchParm.id) {
        case 'srchLast':
          const nams: string[] = srchParm.value.split(',');
          qry += "'1', @lastNm = '" + nams[0].trim().replace("'", "''") + "%'";
          if (nams.length == 2) {
            qry += ", @firstNm = '" + nams[1].trim().replace("'", "''") + "%'";
          }
          this.fetchingSrchResLastNm = true;
          break;

        case 'srchTel':
          qry += "'2', @tel = '%" + srchParm.value.trim().replace("'", "''").replace('-', '') + "'"
          this.fetchingSrchResTel = true;
          break;

        case 'srchRecord':
          qry += "'3', @recNo = '" + srchParm.value.trim().replace("'", "''") + "'";
          this.fetchingSrchResRecNo = true;
          break;

        case 'srchCasDt':
          const dt = moment(srchParm.value, 'MM/DD/YYYY');
          if (dt.isValid()) {
            qry += "'4' , @casDt = '" + dt.format('YYYYMMDD') + "'";
            this.fetchingSrchResCasDt = true;
            break;
          }
          return;

        case 'srchContNo':
          qry += "'5', @contNo = '%" + srchParm.value.trim().replace("'", "''") + "'";
          this.fetchingSrchResContNo = true;
          break;

        default:
      }
    } else {
      return;
    }

    this.lastSrchParm = srchParm?.id;
    this.records = [];
    console.log('%c' + 'query ' + qry, 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, qry);
  }

  onClick_openRecord(pid: string, cid: string) {
    this.openedRecSrchPid = +pid;
    this.firstFocus = undefined;
    this.waiting4Success = false;
    this.waiting4PatImg2Load = true;
    this.resetDeErrs();

    this.xtPID = undefined; // In case a different (than the original) record is selected through search from another component's modal record window
    this.xtCID = undefined;

    if (+pid) {
      this.selectedPatID = pid;
      this.selectedCasID = cid;
      this.selectedCaseIndx = 0;

      if (this.sn.length > 4) { // Read from MedicalBillerPR.com
        this.fetchingCodesDesc = true;
        this._recordService.getRecordCasesProcsPay(this.sn, pid).subscribe(
          returnedData => this.backFromLookUpIcd10CodeOrDescr(returnedData),
          error => this.backFromServerError(error)
        );

      } else {

        this.fetchingDxCodesDesc = false;
        this.casesHxRefresh = true
        for (let r = 0; r < this.records.length; r++) {
          if (this.records[r].pat.patID == pid) {
            if (this.casesHxRefresh || !this.records[r].pat.casesHx) {
              this.casesHxRefresh = false;
              this.fetchingSrchCases = true;
              console.log('%c' + 'onClick_OpenRecord(pid,cid):' + 'EXEC spMBSearch4Case @top = ' + this.lastUsedParam.topCasRows + ', @casPatID = ' + pid + ', @uID = ' + this.userID + ';', 'color: black; background: #90EE90 ; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, 'EXEC spMBSearch4Case @top = ' + this.lastUsedParam.topCasRows + ', @casPatID = ' + pid + ', @uID = ' + this.userID + ';');
              break;
            }
          }
        }
      }
    } else {
      this.selectedPatID = undefined;
    }
  }

  onClick_OpenCase(cid: string): void {
    if (this._help.isNumeric(cid) && parseInt(cid) > 0) {
      this.selectedCaseIndx = this.selectedCase.findIndex(e => { return e.casID.trim() == cid.trim() });  // In case no procs[] nor pays[] it must be set here

      if (this.selectedCaseIndx > -1) {  // If the case is not in this.selectedCase[] then it must be loaded
        let cas = this.selectedCase[this.selectedCaseIndx];
        this.clrCasOrg_setSofSign();
        this.casOrg = JSON.parse(JSON.stringify(cas)); // Deep copy of new case to check editing but undefined props are not copied!

        if (cas.procs1.length == 0 && cas.procs2.length == 0 && cas.procs0.length == 0) {
          this._websocketService.sendChat('query', this.sn, "EXEC spMBSearch4Procs_Pays @sn = '" + this.sn + "',  @casID = '" + cid + "';");
          console.log('%c' + 'query @ onClick_OpenCase(cid):' + 'EXEC spMBSearch4Procs_Pays @sn = ' + this.sn + ',  @casID = ' + cid + ';', 'color: black; background: #90EE90; font-size: 12px');
        }
      }
    } else {
      this.selectedCaseIndx = 0;  // pre select new empty case
      this.patOrg = JSON.parse(JSON.stringify(this.pat)); // Deep copy of new record to check editing
      this.clrCasOrg_setSofSign();
      this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[0])); // Deep copy of new case to check editing but undefined props are not copied!
    }
    this.resetDeErrs();
  }

  onKeyUp_srch(event: any, srchParm: any): void {
    if (event) {
      if (event.key === 'Enter') {
        if (this.records && this.records.length == 1 && this.records[0].pat.patID) {
          this.onClick_openRecord(this.records[0].pat.patID, '0');
          return;
        }
        this.onClick_findRecord(srchParm);
      } else {
        if (event.target.type == 'date' && !event.target.value) {
          return;
        }
        event.target.value = event.target.value.toUpperCase();

        this.records = [];
      }
    }
  }

  openRecordAndCaseFromXternalComponentModal(): void {

    this.hideBillHx = true;

    console.log('%c' + 'query @ openRecordAndCaseXternalComponent():' + "Exec spMBSearch4Record @patID = '" + this.xtPID + "';", 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, "Exec spMBSearch4Record @patID = '" + this.xtPID + "';");

    console.log('%c' + 'query @ openRecordAndCaseXternalComponent():' + "Exec spMB_Sio_GetRecord_ByID @sn = '" + this.sn + "', @id = '" + this.xtPID + "';", 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecord_ByID @sn = '" + this.sn + "', @id = '" + this.xtPID + "';");

    this.waiting4Docs = true;
    console.log('%c' + 'query @ openRecordAndCaseXternalComponent():' + "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @id = '" + this.xtPID + "', @top = 1;", 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @id = '" + this.xtPID + "', @top = 1;");

    if (+this.xtCID > 0) {
      this.fetchingSrchCases = true;
      console.log('%c' + 'query @ openRecordAndCaseXternalComponent():' + 'EXEC spMB_Sio_GetCase_ByID @casID = ' + this.xtCID + ';', 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, 'EXEC spMB_Sio_GetCase_ByID @casID = ' + this.xtCID + ';');

      console.log('%c' + 'query @ openRecordAndCaseXternalComponent():' + 'EXEC spMBSearch4Procs_Pays @sn = ' + this.sn + ',  @casID = ' + this.xtCID + ';', 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, "EXEC spMBSearch4Procs_Pays @sn = '" + this.sn + "',  @casID = '" + this.xtCID + "';");
    }
  }

  enterDate(event: any, prop?: string): void {
    this.errsRec.dob = '';
    this.deError = undefined;
    if (event && event.target.value) {
      let s: string = this.insertDateSeparators(event.target.value);
      if (s) {
        s = this._help.ckDateInStr(s);
        if (s) {  // s will be '' if bad date
          event.target.value = s; // Feedback to UI

          switch (prop) {
            case 'pat.dob':
              if (/^\d{2}\/\d{2}\/\d{2}$/g.test(s)) { // Entered mm/dd/yy
                if (parseInt(s.substr(-2)) > parseInt(new Date().getFullYear().toString().substr(-2))) {
                  s = s.substring(0, 6) + '19' + s.substr(-2);
                } else {
                  s = s.substring(0, 6) + '20' + s.substr(-2);
                }
              } else if (/^\d{2}\/\d{2}\/\d{4}$/g.test(s)) {
                if (parseInt(s.match(/\d{4}$/g)[0]) > new Date().getFullYear()) {
                  s = s.substring(0, 6) + '19' + s.substr(-2);
                }
              }

              this.pat.dob = s;
              this.pat.age = this._help.calcAge(s);
              break;
            case '':
              break;
            default:
          }
          return;
        }
      }
      this.deError = this.engLang ? 'Check date (month / day / year)' : 'Coteje fecha (mes / día / año)';
      this.errsRec.dob = this.engLang ? 'Check date (month / day / year)' : 'Coteje fecha (mes / día / año)';
    }
  }

  insertDateSeparators(dts: string): string {
    var s: string;
    if (dts.match(/^\d{6}$|^\d{8}$/g)) { // Complete separators if missing
      s = dts.substring(0, 2) + '/' + dts.substring(2, 4) + '/' + (dts.length == 6 ? '20' : dts.substring(4, 6)) + dts.substring(dts.length - 2);
    } else if (dts.match(/^\d{1,2}[ -/]{1}\d{1,2}[ -/]{1}\d{2}$|^\d{1,2}[ -/]{1}\d{1,2}[ -/]{1}\d{4}$/g)) {
      return dts; // Separators were ok
    }
    return s;
  }

  onClick_Expand2ShowCases(pid: string): void {
    if (pid) {
      const curr = this.records.find(rec => rec.pat.patID == pid);
      if (curr.pat.casesHx?.length > 0) { // Cases expamded
        curr.pat.casesHx = undefined;
        this.expandedPatID = undefined;
        return;
      } else {
        this.expandedPatID = pid;
        this.showCasesHx = true;
        this.selectedPatID = undefined;
      }

      for (let r = 0; r < this.records.length; r++) {
        if (this.records[r].pat.patID == pid) {
          if (this.casesHxRefresh || !this.records[r].pat.casesHx) {
            this.casesHxRefresh = false;
            this.fetchingSrchCases = true;
            this._websocketService.sendChat('query', this.sn, 'EXEC spMBSearch4Case @top = ' + this.lastUsedParam.topCasRows + ', @casPatID = ' + pid + ', @uID = ' + this.userID + ';');
            console.log('%c' + 'onClick_Expand2ShowCases(pid):' + 'EXEC spMBSearch4Case @top = ' + this.lastUsedParam.topCasRows + ', @casPatID = ' + pid + ', @uID = ' + this.userID + ';', 'color: black; background: #90EE90 ; font-size: 12px');
          }
          break;
        }
      };
    } else {
      this.showCasesHx = false;
      this.expandedPatID = undefined;
    }
  }

  onChange_topRows2Read(event): void {
    if (event?.target) {
      let val = event.target.value;
      if (val > 999) {
        val = 999;
        event.target.value = val;
      } else if (val < 1) {
        val = 1;
        event.target.value = val;
      }
      if (event.target.id == 'topRecordsRows') {
        this.lastUsedParam.topRecRows = val;
        if (this.lastSrchParm) {
          let e = <HTMLInputElement>document.getElementById(this.lastSrchParm);
          if (e) {
            this.onClick_findRecord({ id: e.id, value: e.value });
          }
        }
      } else {
        if (this.lastUsedParam.topCasRows == val) {
          this.casesHxRefresh = false;
        } else {
          this.casesHxRefresh = true;  // To re-read cases if 'this.lastUsedParam.topCasRows' changed
        }
        this.lastUsedParam.topCasRows = val;

        if (+this.expandedPatID && this.casesHxRefresh) {
          this.fetchingSrchCases = true;
          this._websocketService.sendChat('query', this.sn, 'EXEC spMBSearch4Case @top = ' + this.lastUsedParam.topCasRows + ', @casPatID = ' + this.expandedPatID + ', @uID = ' + this.userID + ';');
          console.log('%c' + 'onChange_topRows2Read(event):' + 'EXEC spMBSearch4Case @top = ' + this.lastUsedParam.topCasRows + ', @casPatID = ' + this.expandedPatID + ', @uID = ' + this.userID + ';', 'color: black; background: #90EE90 ; font-size: 12px');
        }
      }
    }
  }

  fmtTel(t: string): string {
    const v = t;
    if (t.match(/^\d{7}$|^\d{3}[ \-]?\d{4}$/g)) {
      t = '787' + t;
    }
    t = t.replace(/\D*/g, '');
    if (t.match(/^\d{10}$/g)) {
      return t.replace(/\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g, '($1)$2-$3');
    } else {
      return v;  // Return previous value entered
    }
  }

  preFixPrAreaCode(event): void {
    if (event?.key == '7' || event?.key == '9') {
      if (event.target.value.length === 1) {  // Only upon initial digit
        if (event.key == '7') {
          event.target.value = '787';
        } else {
          event.target.value = '939';
        }
      }
    }
  }

  isTelOk(tel: string): boolean {
    return (tel.replace(/\D*/g, '').length == 10);
  }

  srchListKeyDownEventHndlr2FilterTab(event: any, idSrch: boolean, key: string) {
    console.log('1'); // Fires first
    console.log('idSrch@1=' + idSrch);
    if (event.keyCode == 9) {   // Tab key
      this.srchListKeyUpEventHndlr(event, idSrch, key);
    }
  }

  srchListKeyUpEventHndlr(event: any, idSrch: boolean, elID: string): void {   // idSrch = true looking up insID, ps=1(prim), 2(sec), lstObj = 'F' for Facilities
    console.log('2'); // Fires second
    console.log('idSrch@2=' + idSrch);
    if (this.blockTabKeyAfterLookUp) { // if Tab was pressed this.srchListKeyDownEventHndlr2FilterTab(...) was called first, then this.srchListKeyUpEventHndlr(...) is called, and then again from next element. This blocks the last call.
      this.blockTabKeyAfterLookUp = false;   //  reset for next time Tab is pressed
      event.stopPropagation();
      return;
    }

    var txtBox: HTMLInputElement = <HTMLInputElement>event.target;
    if (idSrch) {
      if (txtBox.value.trim().length == 0) {  // in case id was blanked
        txtBox.value = '';
      } else if (txtBox.value.match(/\d+/g)) {
        txtBox.value = parseInt(txtBox.value).toString(); // Remove lead zeroes
      }
    }

    if (txtBox.value && event.key && !event.key.match(/[\b]/g)) { // make sure something is entered & is not a Backspace
      let cas = this.selectedCase[this.selectedCaseIndx];
      if (event.keyCode == 13 || event.keyCode == 9) {  // Enter or Tab
        event.keyCode == 9 ? this.blockTabKeyAfterLookUp = true : this.blockTabKeyAfterLookUp = false;    // this.blockTabAfterLookUp = true will prevent Tab from re-executing the rest after having done it once

        if (elID == 'patIns1Alias' || elID == 'patIns2Alias' || elID == 'casIns1Alias' || elID == 'casIns2Alias') { // Insurance searching by ID
          for (var i = 0; i < this.localInsurances.length; i++) {
            if ((this.localInsurances[i].pKey == txtBox.value && idSrch) || (this.localInsurances[i].alias.toUpperCase() == txtBox.value.toUpperCase() && !idSrch)) {   // found it by ID ?
              if (elID == 'patIns1Alias') {
                this.pat.insId1 = this.localInsurances[i].pKey;
                this.pat.insAlias1 = this.localInsurances[i].alias;
                this.errsRec.insId1 = '';
                if (+cas.casI1ID == 0) {  // Copy only in new case
                  cas.casI1ID = this.pat.insId1;
                  cas.casIns1Alias = this.pat.insAlias1;
                  this.errsCas.i1ID = '';
                  if (cas.procs1.length == 0) {
                    this.onClick_addProc('1');
                  }
                }
                return;
              } else if (elID == 'patIns2Alias') {
                this.pat.insId2 = this.localInsurances[i].pKey;
                this.pat.insAlias2 = this.localInsurances[i].alias;
                this.errsRec.insId2 = '';
                if (+cas.casI2ID == 0) {  // Copy only in new records
                  cas.casI2ID = this.pat.insId2;
                  cas.casIns2Alias = this.pat.insAlias2;
                  this.errsCas.i2ID = '';
                  if (cas.procs2.length == 0) {
                    this.onClick_addProc('2');
                  }
                }
                return;
              } else if (elID == 'casIns1Alias') {
                cas.casI1ID = this.localInsurances[i].pKey;
                cas.casIns1Alias = this.localInsurances[i].alias;
                this.errsCas.i1ID = '';
                if (cas.procs1.length == 0) {
                  this.onClick_addProc('1');
                }

                this.loadLocalPcodes(cas.casProvID, cas.casI1ID, cas.casI2ID);

                return;
              } else if (elID == 'casIns2Alias') {
                cas.casI2ID = this.localInsurances[i].pKey;
                cas.casIns2Alias = this.localInsurances[i].alias;
                this.errsCas.i2ID = '';
                if (cas.procs2.length == 0) {
                  this.onClick_addProc('2');
                }

                this.loadLocalPcodes(cas.casProvID, cas.casI1ID, cas.casI2ID);

                return;
              }
            }
          }
          // not found by id or alias in Insurance

          if (elID == 'patIns1Alias') {
            this.pat.insId1 = '0';
            this.pat.insAlias1 = '';
          } else if (elID == 'patIns2Alias') {
            this.pat.insId2 = '0';
            this.pat.insAlias2 = '';
          } else if (elID == 'casIns1Alias') {
            cas.casI1ID = '0';
            cas.casIns1Alias = '';
          } else if (elID == 'casIns2Alias') {
            cas.casI2ID = '0';
            cas.casIns2Alias = '';
          }
          return;

        } else {  // Provider, Facility, Referring...

          if (elID && (elID == 'casFacAlias' || elID == 'casFacID')) {  // Facility
            for (var i = 0; i < this.localFacilities.length; i++) { // Facility
              if ((this.localFacilities[i].pKey == txtBox.value && idSrch) || (this.localFacilities[i].alias.toUpperCase() == txtBox.value.toUpperCase() && !idSrch)) {   // found it ?
                cas.casFacID = this.localFacilities[i].pKey;
                cas.casFacAlias = this.localFacilities[i].alias;
                this.errsCas.facId = '';
                return;
              }
            }
            // not found by alias in Facility
            if (!idSrch && event.keyCode == 13) {
              cas.casFacID = '';
              return;
            }

            cas.casFacAlias = '';
            cas.casFacID = '0';

          } else if (elID == 'casProvAlias') {  // Provier
            for (var i = 0; i < this.localProviders.length; i++) {  // Provider
              if ((this.localProviders[i].pKey == txtBox.value && idSrch) || (this.localProviders[i].alias.toUpperCase() == txtBox.value.toUpperCase() && !idSrch)) {   // found it ?
                cas.casProvID = this.localProviders[i].pKey;
                cas.casProvAlias = this.localProviders[i].alias;
                this.errsCas.provId = '';

                this.loadLocalPcodes(cas.casProvID, cas.casI1ID, cas.casI2ID);

                return;
              }
            }
            // not found by alias in Provider
            if (!idSrch && event.keyCode == 13) {
              cas.casProvID = ''  // document.getElementById(elID + 'Clk').click(); Functionality eliminated with ngbDropDn component implementation.  Before => // display list of providers upon Enter in alias & not found
              return;
            }

            cas.casProvAlias = '';
            cas.casProvID = '0'

          } else if (elID == 'casRefAlias') {  // Referring
            for (var i = 0; i < this.localReferring.length; i++) {  // Referring
              if (this.localReferring[i].name.toUpperCase() == txtBox.value.toUpperCase() && !idSrch) {   // found it ?
                cas.casRefID = this.localReferring[i].pKey;
                cas.casRefAlias = this.localReferring[i].name;

                cas.casRefLastNm = txtBox.value.match(/^[A-Z a-z']*,/gi)[0].replace(',', '').trim();
                cas.casRefFirstNm = txtBox.value.match(/,[A-Z a-z']*/gi)[0].replace(',', '').trim();
                cas.casRefNPI = this.localReferring[i].npi;
                let aliasEle = <HTMLInputElement>document.getElementById('casRefAlias');
                aliasEle.value = '';  // This clears the referring alias txtbox.
                return;
              }
            }
            // not found by alias in Referring
            if (event.keyCode == 13) {
              cas.casRefID = '0';
              cas.casRefLastNm = '';
              cas.casRefFirstNm = '';
              cas.casRefNPI = '';
              return;
            }
          }
        }

      } // End-If Enter or Tab

      else if ((event.key.match(/[^A-Za-z0-9 ]/gi) && !idSrch) || event.key.match(/[^0-9]/g) && idSrch) { // if not alphanumerics & space or all numeric for idSrch
        if (event.key != ',' || elID != 'casRefAlias') { // Don't block comma when looking for Refferring MD as it's used to separate last & first name
          txtBox.value = txtBox.value.replace(event.key, '');   // not an acceptable keys so discard it
        }
      }
    }
  }

  onBlur_copyRecIns2CasIns(ps): void {
    if (+this.selectedCase[this.selectedCaseIndx].casID == 0) { // Copy only in new case
      if (ps == '1') {
        if (this.pat.contr1 && !this.selectedCase[this.selectedCaseIndx].casCont1) {
          this.selectedCase[this.selectedCaseIndx].casCont1 = this.pat.contr1;
        }
        if (this.pat.group1 && !this.selectedCase[this.selectedCaseIndx].casGrp1) {
          this.selectedCase[this.selectedCaseIndx].casGrp1 = this.pat.group1;
        }
        if (this.pat.ded1 && !this.selectedCase[this.selectedCaseIndx].casDed1) {
          this.selectedCase[this.selectedCaseIndx].casDed1 = this.pat.ded1;
        }
        if (this.pat.lastNm1 && !this.selectedCase[this.selectedCaseIndx].casILastIns1) {
          this.selectedCase[this.selectedCaseIndx].casILastIns1 = this.pat.lastNm1;
        }
        if (this.pat.firstNm1 && !this.selectedCase[this.selectedCaseIndx].casIFirstIns1) {
          this.selectedCase[this.selectedCaseIndx].casIFirstIns1 = this.pat.firstNm1;
        }
        if (this.pat.midInit1 && !this.selectedCase[this.selectedCaseIndx].casIMidIns1) {
          this.selectedCase[this.selectedCaseIndx].casIMidIns1 = this.pat.midInit1;
        }
      } else {
        if (this.pat.contr2 && !this.selectedCase[this.selectedCaseIndx].casCont2) {
          this.selectedCase[this.selectedCaseIndx].casCont2 = this.pat.contr2;
        }
        if (this.pat.group2 && !this.selectedCase[this.selectedCaseIndx].casGrp2) {
          this.selectedCase[this.selectedCaseIndx].casGrp2 = this.pat.group2;
        }
        if (this.pat.ded2 && !this.selectedCase[this.selectedCaseIndx].casDed2) {
          this.selectedCase[this.selectedCaseIndx].casDed2 = this.pat.ded2;
        }
        if (this.pat.lastNm2 && !this.selectedCase[this.selectedCaseIndx].casILastIns2) {
          this.selectedCase[this.selectedCaseIndx].casILastIns2 = this.pat.lastNm2;
        }
        if (this.pat.firstNm2 && !this.selectedCase[this.selectedCaseIndx].casIFirstIns2) {
          this.selectedCase[this.selectedCaseIndx].casIFirstIns2 = this.pat.firstNm2;
        }
        if (this.pat.midInit2 && !this.selectedCase[this.selectedCaseIndx].casIMidIns2) {
          this.selectedCase[this.selectedCaseIndx].casIMidIns2 = this.pat.midInit2;
        }
      }
    }
  }

  onClick_clearInsurance(key: string): void {
    switch (key) {
      case 'patIns1Alias': {
        this.pat.insId1 = '0';
        this.pat.insAlias1 = undefined;
        this.errsRec.insId1 = '';
        break;
      }
      case 'patIns2Alias': {
        this.pat.insId2 = '0';
        this.pat.insAlias2 = undefined;
        this.errsRec.insId2 = '';
        break;
      }
      case 'casIns1Alias': {
        this.selectedCase[this.selectedCaseIndx].casI1ID = '0';
        this.selectedCase[this.selectedCaseIndx].casIns1Alias = undefined;
        this.errsCas.i1ID = '';
        break;
      }
      case 'casIns2Alias': {
        this.selectedCase[this.selectedCaseIndx].casI2ID = '0';
        this.selectedCase[this.selectedCaseIndx].casIns2Alias = undefined;
        this.errsCas.i2ID = '';
        break;
      }
      default:
    }
  }

  onClick_clearProvider(): void {
    this.selectedCase[this.selectedCaseIndx].casProvID = '';
    this.selectedCase[this.selectedCaseIndx].casProvAlias = '';
    this.errsCas.provId = '';
  }

  onClick_clearFacility(ps: string): void {
    this.selectedCase[this.selectedCaseIndx].casFacID = '';
    this.selectedCase[this.selectedCaseIndx].casFacAlias = '';
    this.errsCas.facId = '';
  }

  onClick_clearReferring(): void {
    this.selectedCase[this.selectedCaseIndx].casRefID = '';
    this.selectedCase[this.selectedCaseIndx].casRefLastNm = '';
    this.selectedCase[this.selectedCaseIndx].casRefFirstNm = '';
    this.selectedCase[this.selectedCaseIndx].casRefNPI = '';
    this.errsCas.refID = '';
    (<HTMLSelectElement>document.getElementById('casRefAlias')).value = '';
  }

  backFromServerError(error): void {
    this.fetchingCodesDesc = false;
    this.fetchingDxCodesDesc = false;
    this.waiting4PatImg2Load = false;
    this.showToast((this.engLang ? 'Error receiving data' : 'Error recibiendo data'), error.message, true, true, false, false, false);
  }

  onKeyUp_SearchPcode(event: any): void {
    if (event) {
      this.codeSrchStr.id = event.target.id;  // Typical ids; 'proc' + ps(1 or 2 or 0) + _ + index ; proc1_1 , proc1_2 , proc1_3 , etc.,  proc2_5 , proc2_6 , proc2_7 , etc., proc0_3 , proc0_4 , proc0_5
      let indx = this.codeSrchStr.id.match(/\d+$/g)[0];  // Table row index
      let ps = this.codeSrchStr.id.match(/\d/g)[0]; // Looking for 1(primary),2(secondary) or 0(pvdo)

      if (event.key == 'ArrowDown') {
        indx = 1 + parseInt(indx);
        let len = 0;
        switch (ps) {
          case '1':
            len = this.selectedCase[this.selectedCaseIndx].procs1.length;
            break;
          case '2':
            len = this.selectedCase[this.selectedCaseIndx].procs2.length;
            break;
          case '0':
            len = this.selectedCase[this.selectedCaseIndx].procs0.length;
            break;
        }

        if (indx > len - 1) {
          this.onClick_addProc(ps);
          return; // document.getElementById(nextId).focus() yields a RTErr when (indx > len - 1) because the new line added doesn't exist yet
        }
        let nextId = (this.codeSrchStr.id).replace(/\d+$/gi, indx);
        document.getElementById(nextId).focus();
        return;
      }

      if (event.key == 'ArrowUp') {
        indx = parseInt(indx) - 1;
        if (indx >= 0) {
          let nextId = (this.codeSrchStr.id).replace(/\d+$/gi, indx);
          document.getElementById(nextId).focus();
        }
        return;
      }

      if (event.key == 'Enter' && event.target.value) {

        let cd = (this.codeSrchStr.id.match(/[proc|mod|desc]/g).toString() == 'p,r,o,c' || this.codeSrchStr.id.match(/[proc|mod|desc]/g).toString() == 'm,o,d' ? 'C' : 'D'); // Looking to set = to upper case 'C' for code when begins 'proc' or 'D' when begins 'desc' for description

        let mod;
        let mod2;
        let mod3;
        let mod4;

        if (cd == 'C') {
          this.codeSrchStr.txt = (<HTMLInputElement>document.getElementById('proc' + ps + '_' + indx)).value;
          mod = (<HTMLInputElement>document.getElementById('modI' + ps + '_' + indx)).value;
          mod2 = (<HTMLInputElement>document.getElementById('modII' + ps + '_' + indx)).value;
          mod3 = (<HTMLInputElement>document.getElementById('modIII' + ps + '_' + indx)).value;
          mod4 = (<HTMLInputElement>document.getElementById('modIV' + ps + '_' + indx)).value;
        } else {
          this.codeSrchStr.txt = (<HTMLInputElement>document.getElementById('desc' + ps + '_' + indx)).value;
          mod = '';
          mod2 = '';
          mod3 = ''
          mod4 = '';
        }

        event.srcElement.blur(); // Avoids -> ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ng-untouched: true'. Current value: 'ng-untouched: false'.
        event.target.value = event.target.value.toUpperCase();
        document.getElementById(this.codeSrchStr.id).focus();

        let results: any = [];
        results.pop();

        for (let i = 0; i < this._recordService.localPcodes.length; i++) {
          if (this._recordService.localPcodes[i].provId == this.selectedCase[this.selectedCaseIndx].casProvID
            && (ps == '1' && this._recordService.localPcodes[i].insId == this.selectedCase[this.selectedCaseIndx].casI1ID
              || ps == '2' && this._recordService.localPcodes[i].insId == this.selectedCase[this.selectedCaseIndx].casI2ID
              || ps == '0' && this._recordService.localPcodes[i].insId == '0')
            && (cd == 'C' && this._recordService.localPcodes[i].code.toUpperCase().includes(this.codeSrchStr.txt)
              && (this._recordService.localPcodes[i].mod.toUpperCase() == mod || mod == '')
              && (this._recordService.localPcodes[i].mod2.toUpperCase() == mod2 || mod2 == '')
              && (this._recordService.localPcodes[i].mod3.toUpperCase() == mod3 || mod3 == '')
              && (this._recordService.localPcodes[i].mod4.toUpperCase() == mod4 || mod4 == '')
              || (cd == 'D' && this._recordService.localPcodes[i].desc.toUpperCase().includes(this.codeSrchStr.txt)))) {

            let result = this._recordService.localPcodes[i];
            result['pKey'] = result['proID']; // Add property 'pKey' which is the same as property proID
            results.push(result);
            results[results.length - 1].visible = true; // Needed to display in message-dialog.html

            // console.log('%c' + this.codeSrchStr.txt, 'color: green; background: yellow; font-size: 15px');

            if (result.code == this.codeSrchStr.txt) {
              break;
            }
          }
        }

        if (results.length > 0) {
          if (results.length == 1) {
            this.calculateProcPrice(ps, indx, results, results[0].pKey);
          } else {

            let msgBoxMessage: string = (this.engLang ? 'Looking for: ' : 'Buscando: ') + this.codeSrchStr.txt; // + ' <input type="text" id="inStrKeys" size="40" (keyup)="inStrSearch($event)">';
            this._modalService.message(msgBoxMessage,
              results.length.toString() + ' ' + (this.engLang ? 'Result(s)' : 'Resultado(s)'),
              undefined, undefined, undefined, undefined, this.engLang, results, 'Pc', '#e6eff3', undefined)
              .pipe(
                take(1) // take() manages unsubscription for us
              ).subscribe(response => {
                if (response || (response && response['response'])) {
                  this.block2ndEnterKeyResponse2OkInModal = true;

                  // console.log('%c' + 'response["selectedCodes"].length=' + response['selectedCodes'].length, 'color: green; background: yellow; font-size: 15px');

                  response['selectedCodes'].forEach(element => {
                    // console.log('%c' + 'element=' + element.pKey, 'color: blue; background: yellow; font-size: 15px');

                    switch (ps) {
                      case '1':
                        if (this.selectedCase[this.selectedCaseIndx].procs1.length <= indx) {
                          this.onClick_addProc(ps)  // Add to procs1
                        }
                        break;
                      case '2':
                        if (this.selectedCase[this.selectedCaseIndx].procs2.length <= indx) {
                          this.onClick_addProc(ps)  // Add to procs2
                        }
                        break;
                      case '0':
                        if (this.selectedCase[this.selectedCaseIndx].procs0.length <= indx) {
                          this.onClick_addProc(ps)  // Add to procs0
                        }
                        break;
                    }

                    this.calculateProcPrice(ps, indx, results, element.pKey);
                    indx = (1 + parseInt(indx)).toString();
                  });
                }
              });
          }
        } else {
          event.preventDefault();
        }
      }
      this.block2ndEnterKeyResponse2OkInModal = false;
    }
  }

  calculateProcPrice(ps, indx, results: any, resPKey): void {
    let result = results.filter(e => (resPKey == e.pKey))[0];
    let provId = result.provId;
    let insId = result.insId;
    let code = result.code;
    let mod = result.mod;
    let mod2 = result.mod2;
    let mod3 = result.mod3;
    let mod4 = result.mod4;
    let pos = result.pos;
    let dedPct = result.dedPct;
    let dedSubstract = result.dedSubstract;
    let dedFixed = result.dedFixed;
    let pvdo = result.pvdo;
    let xpect = result.xpect;
    let usual = result.usual;
    let desc = result.desc;

    let cas = this.selectedCase[this.selectedCaseIndx];

    switch (ps) {
      case '1':
        let deductible: IDedcode = this._recordService.localDedcodes.filter(e => (e.provId == provId && e.insId == insId && e.code.toUpperCase() == cas.casDed1.toUpperCase()))[0];

        let xceDeductible: IXcecode = this._recordService.localXcecodes.filter(e => (e.provId == provId && e.insId == insId && e.pcode == code && e.mod == mod && e.code == cas.casDed1))[0];

        if (xceDeductible) {
          if (dedPct == 'True' && xceDeductible.pct) {
            pvdo = (parseFloat(xpect) * parseFloat(xceDeductible.pct) / 100).toFixed(2);
            xpect = (parseFloat(xpect) - parseFloat(pvdo)).toFixed(2);
          } else if (dedSubstract == 'True' && xceDeductible.pvdo) {
            pvdo = parseFloat(xceDeductible.pvdo).toFixed(2);
            xpect = (parseFloat(xpect) - parseFloat(pvdo)).toFixed(2);
          } else if (dedFixed == 'True' && xceDeductible.pvdo && xceDeductible.xpect) {
            pvdo = xceDeductible.pvdo;
            xpect = xceDeductible.xpect;
          }
        } else if (deductible) {
          if (dedPct == 'True' && deductible.pct) {
            pvdo = (parseFloat(xpect) * parseFloat(deductible.pct) / 100).toFixed(2);
            xpect = (parseFloat(xpect) - parseFloat(pvdo)).toFixed(2);
          } else if (dedSubstract == 'True' && deductible.pvdo) {
            pvdo = parseFloat(deductible.pvdo).toFixed(2);
            xpect = (parseFloat(xpect) - parseFloat(pvdo)).toFixed(2);
          } else if (dedFixed == 'True' && deductible.pvdo && deductible.xpect) {
            pvdo = deductible.pvdo;
            xpect = deductible.xpect;
          }
        }

        if (cas.casI2ID && +cas.casI2ID > 0) { // Correlate secondary insurance
          let foundit = false;
          while (!foundit) {
            let i = 0;
            while (cas.procs2 && i < cas.procs2.length) {
              if (!cas.procs2[i].code) {
                cas.procs2[i].code = code;
                cas.procs2[i].mod1 = mod;
                cas.procs2[i].mod2 = mod2;
                cas.procs2[i].mod3 = mod3;
                cas.procs2[i].mod4 = mod4;
                cas.procs2[i].pos = pos;
                cas.procs2[i].qty = cas.procs1[indx].qty;
                cas.procs2[i].usual = pvdo ? pvdo : '0.00';
                cas.procs2[i].xpect = pvdo ? pvdo : '0.00';
                cas.procs2[i].pvdo = '0.00';
                pvdo = '0.00';  // Zero for primary Pvdo
                cas.procs2[i].desc = desc;
                cas.procs2[i].dx = cas.procs1[indx].dx;
                cas.procs2[i].fromDt = cas.procs1[indx].fromDt;
                cas.procs2[i].fromDtYy = cas.procs1[indx].fromDtYy;
                cas.procs2[i].toDt = cas.procs1[indx].toDt;
                cas.procs2[i].toDtYy = cas.procs1[indx].toDtYy;
                if (cas.dxs && cas.dxs.length == 1 && !cas.procs2[i].dx) {
                  cas.procs2[indx].dx = '1';
                }
                foundit = true;
                break;
              }
              i += 1;
            }
            if (!foundit) {
              this.onClick_addProc('2');  // Make space
            }
          }
        }

        cas.procs1[indx].code = code;
        cas.procs1[indx].mod1 = mod;
        cas.procs1[indx].mod2 = mod2;
        cas.procs1[indx].mod3 = mod3;
        cas.procs1[indx].mod4 = mod4;
        cas.procs1[indx].pos = pos;
        cas.procs1[indx].usual = usual;
        cas.procs1[indx].xpect = xpect;
        cas.procs1[indx].pvdo = pvdo ? pvdo : '0.00';
        cas.procs1[indx].desc = desc;
        if (cas.dxs && cas.dxs.length == 1 && !cas.procs1[indx].dx) {
          cas.procs1[indx].dx = '1';
        }
        break;
      case '2':
        cas.procs2[indx].code = results.filter(e => (resPKey == e.pKey))[0].code;
        cas.procs2[indx].mod1 = results.filter(e => (resPKey == e.pKey))[0].mod;
        cas.procs2[indx].mod2 = results.filter(e => (resPKey == e.pKey))[0].mod2;
        cas.procs2[indx].mod3 = results.filter(e => (resPKey == e.pKey))[0].mod3;
        cas.procs2[indx].mod4 = results.filter(e => (resPKey == e.pKey))[0].mod4;
        cas.procs2[indx].pos = results.filter(e => (resPKey == e.pKey))[0].pos;
        cas.procs2[indx].usual = results.filter(e => (resPKey == e.pKey))[0].usual;
        cas.procs2[indx].xpect = results.filter(e => (resPKey == e.pKey))[0].xpect;
        cas.procs2[indx].pvdo = results.filter(e => (resPKey == e.pKey))[0].pvdo;
        cas.procs2[indx].desc = results.filter(e => (resPKey == e.pKey))[0].desc;
        break;
      case '0':
        cas.procs0[indx].code = results.filter(e => (resPKey == e.pKey))[0].code;
        cas.procs0[indx].mod1 = results.filter(e => (resPKey == e.pKey))[0].mod;
        cas.procs0[indx].mod2 = results.filter(e => (resPKey == e.pKey))[0].mod2;
        cas.procs0[indx].mod3 = results.filter(e => (resPKey == e.pKey))[0].mod3;
        cas.procs0[indx].mod4 = results.filter(e => (resPKey == e.pKey))[0].mod4;
        cas.procs0[indx].pos = results.filter(e => (resPKey == e.pKey))[0].pos;
        cas.procs0[indx].usual = results.filter(e => (resPKey == e.pKey))[0].usual;
        cas.procs0[indx].xpect = results.filter(e => (resPKey == e.pKey))[0].xpect;
        cas.procs0[indx].pvdo = results.filter(e => (resPKey == e.pKey))[0].pvdo;
        cas.procs0[indx].desc = results.filter(e => (resPKey == e.pKey))[0].desc;
        break;
    }

    this.calcCaseTotals();
  }

  onKeyUp_SearchICD10(event: any, try_localICD10: boolean): void {
    if (event) {
      this.codeSrchStr.id = event.target.id;
      this.codeSrchStr.txt = event.target.value.toUpperCase();
      let indx = this.codeSrchStr.id.match(/\w$/g)[0];
      indx = (indx == 'a' ? '10' : indx);
      indx = (indx == 'b' ? '11' : indx);
      indx = (indx == 'c' ? '12' : indx);
      // console.log(indx);

      if (event.key == 'ArrowDown') {
        indx = 1 + parseInt(indx);
        let len = this.selectedCase[this.selectedCaseIndx].dxs.length;

        if (indx > len) {
          this.onClick_AddDx();
          return; // document.getElementById(nextId).focus() yields a RTErr when (indx > len - 1) because the new line added doesn't exist yet
        }
        let nextId = (this.codeSrchStr.id).replace(/\w$/gi, indx);
        nextId = nextId.replace(/10$/gi, 'a');
        nextId = nextId.replace(/11$/gi, 'b');
        nextId = nextId.replace(/12$/gi, 'c');

        document.getElementById(nextId).focus();
        return;
      }

      if (event.key == 'ArrowUp') {
        indx = parseInt(indx) - 1;
        if (indx > 0) {
          indx = (indx == '10' ? 'a' : indx);
          indx = (indx == '11' ? 'b' : indx);
          let nextId = (this.codeSrchStr.id).replace(/\w$/gi, indx);
          document.getElementById(nextId).focus();
        }
        return;
      }

      if (event.keyCode == 13 && event.target.value) {

        if (try_localICD10) { // Try looking for dx code in the most used codes first in this.localICD10[]
          for (let i = 0; i < this.localICD10.length; i++) {
            if (this.codeSrchStr.txt == this.localICD10[i].dxCode) {
              this.selectedCase[this.selectedCaseIndx].dxs[parseInt(indx) - 1].code = this.localICD10[i].dxCode;
              this.selectedCase[this.selectedCaseIndx].dxs[parseInt(indx) - 1].desc = this.localICD10[i].dxDescr;
              return;
            }
          }
        }

        if (this.block2ndEnterKeyResponse2OkInModal) {
          this.block2ndEnterKeyResponse2OkInModal = false;
          return;
        }

        event.srcElement.blur(); // Avoids -> ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ng-untouched: true'. Current value: 'ng-untouched: false'.
        event.target.value = event.target.value.toUpperCase();
        document.getElementById(this.codeSrchStr.id).focus();
        let isIcd10Code: boolean = this._help.isICD10(event.target.value) || this._help.isICD10(event.target.value.replace(/\*/g, '0'));

        // console.log('%c' + 'isIcd10Code = ' + isIcd10Code, 'color: green; background: yellow; font-size: 15px');

        let code: string = 'nul';
        let desc: string = 'nul';
        if (isIcd10Code) {
          this.codeSrchStr.txt = event.target.value.replace(/\*/g, '_');  // Preserve to show in modal window
          code = this.codeSrchStr.txt;
        } else if (event.target.id.startsWith('dxC')) {
          code = this.codeSrchStr.txt;
        } else {
          this.codeSrchStr.txt = event.target.value.replace(/[^A-za-z0-9 ]*/g, ''); // Punctuation breaks Sql Full Text search
          desc = this.codeSrchStr.txt; // Preserve to show in modal window
        }

        if (this.activeTab == 1) {
          this.fetchingDxCodesDesc = true;
        } else {
          this.fetchingCodesDesc = true;
        }

        this._recordService.lookUpIcd10CodeOrDescr(code, desc).subscribe(
          returnedData => this.backFromLookUpIcd10CodeOrDescr(returnedData),
          error => this.backFromServerError(error)
        );
      }
    }
  }

  backFromLookUpIcd10CodeOrDescr(returnedData: any): void {
    this.fetchingCodesDesc = false;
    this.fetchingDxCodesDesc = false;
    let results: any = returnedData;
    results.forEach(e => {
      e.visible = true; //  Add visible property
    });

    let indx: string = this.codeSrchStr.id; // initial index where it started
    indx = indx.match(/\w$/g)[0];
    indx = (indx == 'a' ? '10' : indx);
    indx = (indx == 'b' ? '11' : indx);
    indx = (indx == 'c' ? '12' : indx);

    if (results.length == 1) {
      this.selectedCase[this.selectedCaseIndx].dxs[parseInt(indx) - 1].code = results[0].code;
      this.selectedCase[this.selectedCaseIndx].dxs[parseInt(indx) - 1].desc = results[0].desc;
      this.block2ndEnterKeyResponse2OkInModal = false;
      return;
    }

    let msgBoxMessage: string = (this.engLang ? 'Looking for: ' : 'Buscando: ') + this.codeSrchStr.txt; // + ' <input type="text" id="inStrKeys" size="40" (keyup)="inStrSearch($event)">';
    this._modalService.message(msgBoxMessage,
      results.length.toString() + ' ' + (this.engLang ? 'Result(s)' : 'Resultado(s)'),
      undefined, undefined, undefined, undefined, this.engLang, results, 'Dx', '#e6eff3', undefined)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response || (response && response['response'])) {
          this.block2ndEnterKeyResponse2OkInModal = true;

          console.log('%c' + 'response["selectedCodes"].length=' + response['selectedCodes'].length, 'color: green; background: yellow; font-size: 15px');

          response['selectedCodes'].forEach(e => {
            if (+indx < 13) {

              console.log('%c' + 'element=' + e.code, 'color: blue; background: yellow; font-size: 15px');

              console.log(this.selectedCase[this.selectedCaseIndx].dxs.length);

              if (+indx > this.selectedCase[this.selectedCaseIndx].dxs.length) {
                this.onClick_AddDx();
              }

              this.selectedCase[this.selectedCaseIndx].dxs[parseInt(indx) - 1].code = e.code;
              this.selectedCase[this.selectedCaseIndx].dxs[parseInt(indx) - 1].desc = e.desc;

              this.localICD10.push({ 'dxCode': e.code, 'dxDescr': e.desc, 'new': true })  // Buffer new code & flag to save to [DxCode] table in local server for reuse.

              indx = (1 + parseInt(indx)).toString();
            }
          });
          this.block2ndEnterKeyResponse2OkInModal = false;
        }
      });
  }

  ckDigitNPI(npi): void {
    let cas = this.selectedCase[this.selectedCaseIndx];
    if (npi && npi.length > 0) {
      this.errsCas.refNpi = !this._help.isNPIvalid(npi) ? (this.engLang ? 'Referring NPI' : 'NPI del que Refiere') : ''; // this._helperRtns.isNPIvalid() = true when npi is Ok.
      if (!this.errsCas.refNpi) { // Get last & first refferring MD names.
        const result = this.localReferring.find(e => { return e.npi === npi });
        if (result) {
          cas.casRefID = result.pKey;
          cas.casRefLastNm = result.name.split(',')[0].trim();
          cas.casRefFirstNm = result.name.split(',')[1].trim();
        } else {  // Valid npi entered but not in this.localReferring[]
          if (!cas.casRefID) {
            cas.casRefID = '0';  // Last + First + npi will be added to this.localReferring[]
          }
        }
      } else {  // Bad npi.
        cas.casRefID = '0';
        cas.casRefLastNm = '';
        cas.casRefFirstNm = '';
      }
    } else {
      this.errsCas.refNpi = '';  // Blank npi clears error
      cas.casRefID = '0';
      cas.casRefLastNm = '';
      cas.casRefFirstNm = '';
    }
  }

  onClick_AddDx(): void {
    let minCnt = (this.selectedCase[this.selectedCaseIndx].dxs.length > 0 ? 1 : 4)
    for (let n = 0; n < minCnt; n++) {
      if (this.selectedCase[this.selectedCaseIndx].dxs.length < 12) {
        this.selectedCase[this.selectedCaseIndx].dxs.push({ indx: '', code: '', desc: '' });
        if (this.casOrg?.dxs) {
          this.casOrg.dxs.push({ indx: '', code: '', desc: '' });
        }
        this.reIndexDxPointer();
      }
    }
  }

  onClick_DeleteDx(d): void {
    this.selectedCase[this.selectedCaseIndx].dxs.splice(d, 1);
    this.casOrg.dxs.splice(d, 1);
    this.reIndexDxPointer();
  }

  onClick_MoveDxDown(d): void {
    let movingObj: any = this.selectedCase[this.selectedCaseIndx].dxs.splice(d, 1);
    let i = d + 1;
    if (i <= this.selectedCase[this.selectedCaseIndx].dxs.length) {
      this.selectedCase[this.selectedCaseIndx].dxs.splice(i, 0, movingObj[0]);
    } else {
      this.selectedCase[this.selectedCaseIndx].dxs.splice(0, 0, movingObj[0]);
    }
    this.reIndexDxPointer();
  }

  onClick_MoveDxUp(d): void {
    let movingObj: any = this.selectedCase[this.selectedCaseIndx].dxs.splice(d, 1);
    let i = d - 1;
    if (i < 0) {
      this.selectedCase[this.selectedCaseIndx].dxs.splice(this.selectedCase[this.selectedCaseIndx].dxs.length, 0, movingObj[0]);
    } else {
      this.selectedCase[this.selectedCaseIndx].dxs.splice(i, 0, movingObj[0]);
    }
    this.reIndexDxPointer();
  }

  onClick_addProc(ps: string): void {
    let minCnt: number = 0;
    let procs: IProc[];
    let fromDt: string;
    let fromDtYy: string;
    let toDt: string;
    let toDtYy: string;
    let len = 0;
    let qt: number;

    switch (ps) {
      case '1': {
        if (this.selectedCase[this.selectedCaseIndx].casI1ID && +this.selectedCase[this.selectedCaseIndx].casI1ID > 0) {
          len = this.selectedCase[this.selectedCaseIndx].procs1.length;
          minCnt = (len > 0 ? 1 : 4); // Add 1 Pcode row if there are other rows else add 4 rows if no rows
          procs = this.selectedCase[this.selectedCaseIndx].procs1;

          if (len > 0 && this.selectedCase[this.selectedCaseIndx].procs1[len - 1].fromDt) {
            fromDt = this.selectedCase[this.selectedCaseIndx].procs1[len - 1].fromDt;
            fromDtYy = this.selectedCase[this.selectedCaseIndx].procs1[len - 1].fromDtYy;
            toDt = this.selectedCase[this.selectedCaseIndx].procs1[len - 1].toDt;
            toDtYy = this.selectedCase[this.selectedCaseIndx].procs1[len - 1].toDtYy;
            qt = +this.setQty(len, ps, fromDt, toDt);
          }
        }
        break;
      }
      case '2': {
        if (this.selectedCase[this.selectedCaseIndx].casI2ID && +this.selectedCase[this.selectedCaseIndx].casI2ID > 0) {
          len = this.selectedCase[this.selectedCaseIndx].procs2.length;
          minCnt = (len > 0 ? 1 : 4);
          procs = this.selectedCase[this.selectedCaseIndx].procs2;

          if (len > 0 && this.selectedCase[this.selectedCaseIndx].procs2[len - 1].fromDt) {
            fromDt = this.selectedCase[this.selectedCaseIndx].procs2[len - 1].fromDt;
            fromDtYy = this.selectedCase[this.selectedCaseIndx].procs2[len - 1].fromDtYy;
            toDt = this.selectedCase[this.selectedCaseIndx].procs2[len - 1].toDt;
            toDtYy = this.selectedCase[this.selectedCaseIndx].procs2[len - 1].toDtYy;
            qt = +this.setQty(len, ps, fromDt, toDt);
          }
        }
        break;
      }
      case '0': {
        len = this.selectedCase[this.selectedCaseIndx].procs0.length;
        minCnt = (len > 0 ? 1 : 4);
        procs = this.selectedCase[this.selectedCaseIndx].procs0;

        if (len > 0 && this.selectedCase[this.selectedCaseIndx].procs0[len - 1].fromDt) {
          fromDt = this.selectedCase[this.selectedCaseIndx].procs0[len - 1].fromDt;
          fromDtYy = this.selectedCase[this.selectedCaseIndx].procs0[len - 1].fromDtYy;
          toDt = this.selectedCase[this.selectedCaseIndx].procs0[len - 1].toDt;
          toDtYy = this.selectedCase[this.selectedCaseIndx].procs0[len - 1].toDtYy;
          qt = +this.setQty(len, ps, fromDt, toDt);
        }
        break;
      }
      default:
    }

    if (procs) {
      if (!fromDt) {
        fromDt = this.selectedCase[this.selectedCaseIndx].casDat;
        const dtMom: any = moment(fromDt, 'MM/DD/YYYY', true);
        fromDtYy = dtMom.format('MM/DD/YY');
        toDt = this.selectedCase[this.selectedCaseIndx].casDat;
        toDtYy = fromDtYy;
        qt = 1;
      }

      for (let i = 1; i <= minCnt; i++) {
        procs.push({
          detID: '0',
          detCasID: this.selectedCase[this.selectedCaseIndx].casID,
          fromDt: fromDt,
          fromDtYy: fromDtYy,
          toDt: toDt,
          toDtYy: toDtYy,
          code: '',
          mod1: '',
          mod2: '',
          mod3: '',
          mod4: '',
          pos: '',
          qty: qt.toString(),
          usual: '0.00',
          xpect: '0.00',
          pvdo: '0.00',
          ps: ps,
          dx: '1',
          desc: '',
          prod: '',
          print: true,
          ref: '',
          fac: '',
          noDesc: '',
          noNTE: '',
          pwk: '',
          rx: '',
          revCode: '',
          rxNDC: '',
          rxQty: '',
          rxUM: '',
          rxNo: ''
        });

        if (ps == '1') {
          if (this.casOrg?.procs1) {
            this.casOrg.procs1.push({
              detID: '0',
              detCasID: this.selectedCase[this.selectedCaseIndx].casID,
              fromDt: fromDt,
              fromDtYy: fromDtYy,
              toDt: toDt,
              toDtYy: toDtYy,
              code: '',
              mod1: '',
              mod2: '',
              mod3: '',
              mod4: '',
              pos: '',
              qty: qt.toString(),
              usual: '0.00',
              xpect: '0.00',
              pvdo: '0.00',
              ps: '1',
              dx: '1',
              desc: '',
              prod: '',
              print: true,
              ref: '',
              fac: '',
              noDesc: '',
              noNTE: '',
              pwk: '',
              rx: '',
              revCode: '',
              rxNDC: '',
              rxQty: '',
              rxUM: '',
              rxNo: ''
            });
          }
        }
        if (ps == '2') {
          if (this.casOrg?.procs2) {
            this.casOrg.procs2.push({
              detID: '0',
              detCasID: this.selectedCase[this.selectedCaseIndx].casID,
              fromDt: fromDt,
              fromDtYy: fromDtYy,
              toDt: toDt,
              toDtYy: toDtYy,
              code: '',
              mod1: '',
              mod2: '',
              mod3: '',
              mod4: '',
              qty: qt.toString(),
              usual: '0.00',
              xpect: '0.00',
              pvdo: '0.00',
              ps: '2',
              dx: '1',
              desc: '',
              pos: '',
              prod: '',
              print: true,
              ref: '',
              fac: '',
              noDesc: '',
              noNTE: '',
              pwk: '',
              rx: '',
              revCode: '',
              rxNDC: '',
              rxQty: '',
              rxUM: '',
              rxNo: ''
            });
          }
        }
        if (ps == '0') {
          if (this.casOrg?.procs0) {
            this.casOrg.procs0.push({
              detID: '0',
              detCasID: this.selectedCase[this.selectedCaseIndx].casID,
              fromDt: fromDt,
              fromDtYy: fromDtYy,
              toDt: toDt,
              toDtYy: toDtYy,
              code: '',
              mod1: '',
              mod2: '',
              mod3: '',
              mod4: '',
              qty: qt.toString(),
              usual: '0.00',
              xpect: '0.00',
              pvdo: '0.00',
              ps: '',
              dx: '1',
              desc: '',
              pos: '',
              prod: '',
              print: true,
              ref: '',
              fac: '',
              noDesc: '',
              noNTE: '',
              pwk: '',
              rx: '',
              revCode: '',
              rxNDC: '',
              rxQty: '',
              rxUM: '',
              rxNo: ''
            });
          }
        }

        if (ps == '1' && this.selectedCase[this.selectedCaseIndx].casI2ID && +this.selectedCase[this.selectedCaseIndx].casI2ID > 0) {  // Repeat for ins 2
          this.selectedCase[this.selectedCaseIndx].procs2.push({
            detID: '0',
            detCasID: this.selectedCase[this.selectedCaseIndx].casID,
            fromDt: fromDt,
            fromDtYy: fromDtYy,
            toDt: toDt,
            toDtYy: toDtYy,
            code: '',
            mod1: '',
            mod2: '',
            mod3: '',
            mod4: '',
            qty: qt.toString(),
            usual: '0.00',
            xpect: '0.00',
            pvdo: '0.00',
            ps: '2',
            dx: '1',
            desc: '',
            pos: '',
            prod: '',
            print: true,
            ref: '',
            fac: '',
            noDesc: '',
            noNTE: '',
            pwk: '',
            rx: '',
            revCode: '',
            rxNDC: '',
            rxQty: '',
            rxUM: '',
            rxNo: ''
          });
          if (this.casOrg?.procs2) {
            this.casOrg.procs2.push({
              detID: '0',
              detCasID: this.selectedCase[this.selectedCaseIndx].casID,
              fromDt: fromDt,
              fromDtYy: fromDtYy,
              toDt: toDt,
              toDtYy: toDtYy,
              code: '',
              mod1: '',
              mod2: '',
              mod3: '',
              mod4: '',
              qty: qt.toString(),
              usual: '0.00',
              xpect: '0.00',
              pvdo: '0.00',
              ps: '2',
              dx: '1',
              desc: '',
              pos: '',
              prod: '',
              print: true,
              ref: '',
              fac: '',
              noDesc: '',
              noNTE: '',
              pwk: '',
              rx: '',
              revCode: '',
              rxNDC: '',
              rxQty: '',
              rxUM: '',
              rxNo: ''
            });
          }
        }
        if (ps == '1' && this.selectedCase[this.selectedCaseIndx].procs0.length == 0
          && !(+this.selectedCase[this.selectedCaseIndx].casI1ID > 0
            && +this.selectedCase[this.selectedCaseIndx].casI2ID > 0)) {  // Do Pvdo only when adding primary rows
          this.selectedCase[this.selectedCaseIndx].procs0.push({
            detID: '0',
            detCasID: this.selectedCase[this.selectedCaseIndx].casID,
            fromDt: fromDt,
            fromDtYy: fromDtYy,
            toDt: toDt,
            toDtYy: toDtYy,
            code: '',
            mod1: '',
            mod2: '',
            mod3: '',
            mod4: '',
            qty: this.setQty(len, ps, fromDt, toDt),
            usual: '0.00',
            xpect: '0.00',
            pvdo: '0.00',
            ps: '',
            dx: '1',
            desc: '',
            pos: '',
            prod: '',
            print: true,
            ref: '',
            fac: '',
            noDesc: '',
            noNTE: '',
            pwk: '',
            rx: '',
            revCode: '',
            rxNDC: '',
            rxQty: '',
            rxUM: '',
            rxNo: ''
          });
          if (this.casOrg?.procs0) {
            this.casOrg.procs0.push({
              detID: '0',
              detCasID: this.selectedCase[this.selectedCaseIndx].casID,
              fromDt: fromDt,
              fromDtYy: fromDtYy,
              toDt: toDt,
              toDtYy: toDtYy,
              code: '',
              mod1: '',
              mod2: '',
              mod3: '',
              mod4: '',
              qty: qt.toString(),
              usual: '0.00',
              xpect: '0.00',
              pvdo: '0.00',
              ps: '',
              dx: '1',
              desc: '',
              pos: '',
              prod: '',
              print: true,
              ref: '',
              fac: '',
              noDesc: '',
              noNTE: '',
              pwk: '',
              rx: '',
              revCode: '',
              rxNDC: '',
              rxQty: '',
              rxUM: '',
              rxNo: ''
            });
          }
        }
      }
    }
  }

  onClick_CopyProc(ps, p): void {
    let procs: any = undefined;
    let org: any = undefined;
    switch (ps) {
      case '1': {
        procs = this.selectedCase[this.selectedCaseIndx].procs1;
        org = this.casOrg.procs1;
        break;
      }
      case '2': {
        procs = this.selectedCase[this.selectedCaseIndx].procs2;
        org = this.casOrg.procs2;
        break;
      }
      case '0': {
        procs = this.selectedCase[this.selectedCaseIndx].procs0;
        org = this.casOrg.procs0;
        break;
      }
    }

    if (procs) {
      const obj = Object.create({});  // These 2 lines to create a new object.
      const newObj = Object.assign(obj, procs[p]);  // Copies properties & values.
      procs.splice(p + 1, 0, newObj);
      procs[p + 1].fromDt = undefined;  // To avoid same cpt & date duplication by accident.
      procs[p + 1].fromDtYy = undefined;
      procs[p + 1].toDt = undefined;
      procs[p + 1].toDtYy = undefined;
      org.splice(p + 1, 0, {
        detID: '0',
        detCasID: this.selectedCase[this.selectedCaseIndx].casID,
        fromDt: undefined,
        fromDtYy: undefined,
        toDt: undefined,
        toDtYy: undefined,
        code: '',
        mod1: '',
        mod2: '',
        mod3: '',
        mod4: '',
        qty: '',
        usual: '',
        xpect: '',
        pvdo: '',
        ps: '',
        dx: '',
        desc: '',
        prod: '',
        print: false
      });
      // for (const property in procs) {
      // console.log(`${property}: ${object[property]}`);
      // }
    }
  }

  onClick_DeleteProc(ps, p): void { // p = index within procs1[]
    let procs: IProc[];
    switch (ps) {
      case '1': {
        procs = this.selectedCase[this.selectedCaseIndx].procs1;
        break;
      }
      case '2': {
        procs = this.selectedCase[this.selectedCaseIndx].procs2;
        break;
      }
      case '0': {
        procs = this.selectedCase[this.selectedCaseIndx].procs0;
        break;
      }
      default:
    }

    if (procs) {
      procs[p].detID = (parseInt(procs[p].detID) * -1).toString();
      if (+procs[p].detID === 0) {  // Only for 
        procs.splice(p, 1);
      }
    }
  }

  onClick_addPaymnt(): void {
    this.calcCaseTotals();

    let cas = this.selectedCase[this.selectedCaseIndx];
    let ded1 = cas.procs1.some((e) => {
      return (e.code && e.desc && +e.pvdo > 0 && cas.casBalP > 0);
    });
    let ded2 = cas.procs2.some((e) => {
      return (e.code && e.desc && +e.pvdo > 0 && cas.casBalP > 0);
    });
    let pp = cas.procs0.some((e) => {
      return (e.code && e.desc && +e.pvdo > 0 && cas.casBalP > 0);
    });

    let pAmnt = cas.casBalP;
    let pPS = '';
    if (ded1) {
      pPS = 'D1';
    } else if (ded2) {
      pPS = 'D2';
    } else if (pp) {
      pPS = 'P';
    }
    let td: string = moment(new Date()).format('MM/DD/YY')

    cas.pays.push({
      payID: '0',
      payCasID: this.selectedCase[this.selectedCaseIndx].casID,
      payAmnt: pAmnt.toString(),
      payPS: pPS,
      payDt: td,
      payMode: '',
      payCkNo: '',
      payApCode: '',
      payDetID: '',
      payMemo: '',
      payEdit: 0,
      payUserID: this.userID,
      payCkSeqNo: ''
    });
  }

  onClick_DeletePaymnt(p): void {
    if (+ this.selectedCase[this.selectedCaseIndx].pays[p].payID == 0) {
      this.selectedCase[this.selectedCaseIndx].pays.splice(p, 1);
      this.calcCaseTotals();
    }
  }

  reIndexDxPointer(): void {
    for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].dxs.length; i++) {
      if (i < 12) {
        this.selectedCase[this.selectedCaseIndx].dxs[i].indx = (i + 1).toString(16);
        if (this.casOrg?.dxs[i]) {
          this.casOrg.dxs[i].indx = this.selectedCase[this.selectedCaseIndx].dxs[i].indx;
        }
      }
    }
  }

  date4Pipe(dt): string {
    if (dt && dt.length > 0) {
      return dt;
    } else {
      return null;
    }
  }

  onBlurCasDat(): void {
    if (parseInt(this.selectedCase[this.selectedCaseIndx].casID) == 0) { // Only for new cases
      for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].procs1.length; i++) {
        this.selectedCase[this.selectedCaseIndx].procs1[i].fromDt = this.selectedCase[this.selectedCaseIndx].casDat;
        this.selectedCase[this.selectedCaseIndx].procs1[i].fromDtYy = this.selectedCase[this.selectedCaseIndx].casDate;
        this.selectedCase[this.selectedCaseIndx].procs1[i].toDt = this.selectedCase[this.selectedCaseIndx].casDat;
        this.selectedCase[this.selectedCaseIndx].procs1[i].toDtYy = this.selectedCase[this.selectedCaseIndx].casDate;
      }
      for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].procs2.length; i++) {
        this.selectedCase[this.selectedCaseIndx].procs2[i].fromDt = this.selectedCase[this.selectedCaseIndx].casDat;
        this.selectedCase[this.selectedCaseIndx].procs1[i].fromDtYy = this.selectedCase[this.selectedCaseIndx].casDate;
        this.selectedCase[this.selectedCaseIndx].procs2[i].toDt = this.selectedCase[this.selectedCaseIndx].casDat;
        this.selectedCase[this.selectedCaseIndx].procs2[i].toDtYy = this.selectedCase[this.selectedCaseIndx].casDate;
      }
      for (let i = 0; i < this.selectedCase[this.selectedCaseIndx].procs0.length; i++) {
        this.selectedCase[this.selectedCaseIndx].procs0[i].fromDt = this.selectedCase[this.selectedCaseIndx].casDat;
        this.selectedCase[this.selectedCaseIndx].procs0[i].fromDtYy = this.selectedCase[this.selectedCaseIndx].casDate;
        this.selectedCase[this.selectedCaseIndx].procs0[i].toDt = this.selectedCase[this.selectedCaseIndx].casDat;
        this.selectedCase[this.selectedCaseIndx].procs0[i].toDtYy = this.selectedCase[this.selectedCaseIndx].casDate;
      }
    }
  }

  onBlurDates(event): void {  // Convert dates undefined value from date input to '' so as not to loose the property & later fail deep copy comparison
    if (event.target.value) {
      this.selectedCase[this.selectedCaseIndx][event.target.id] = this.date2mdy(event.target.value);
    } else {
      this.selectedCase[this.selectedCaseIndx][event.target.id] = '';
    }
  }

  date2mdy(dt): string {
    let ymdDateRegex = /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<day>[0-9]{2})/;
    if (ymdDateRegex.test(dt)) {
      let groups = dt.match(ymdDateRegex).groups;
      return groups.month + '/' + groups.day + '/' + groups.year;
    }
    return undefined;
  }

  dateValidate(dt, idx?, ps?, proc?: any): string {
    if (dt) {
      const dtMom = moment(dt, 'MM/DD/YY', true);
      if (dtMom.isValid()) {
        if (dt.match(/^\d{2}\/\d{2}\/\d{2}$/g)) {  // MM/DD/YY to MM/DD/YYYY
          dt = dtMom.format('MM/DD/YYYY');
        }
        if (dt && idx >= 0 && ps && proc) {
          this.setQty(idx, ps, proc.fromDt, '');
        }
        return dt;
      }
    }
    return undefined; // For example february 30 will do this
  }

  onDtpDateSelect(id: string, dt: string, idx?: number, ps?: string, obj?: any): string { // ngbDatepicker event
    // Input date dt can be in 2 or 4 digit year format, output will be 2 yr format
    let vdtYy: string = '';
    if (dt) {
      if (dt.match(/^\d{6}$/g)) {
        dt = dt.substring(0, 2) + '/' + dt.substring(2, 4) + '/' + dt.substring(4);
      } else if (dt.match(/^\d{8}$/g)) {
        dt = dt.substring(0, 2) + '/' + dt.substring(2, 4) + '/' + dt.substring(6);
      }

      let dtMom = moment(dt, 'MM/DD/YY', true);
      if (!dtMom.isValid()) { // In case dt came in formated as MM/DD/YYYY
        dtMom = moment(dt, 'MM/DD/YYYY', true);
      }

      if (dtMom.isValid()) {
        const vdt: string = dtMom.format('MM/DD/YYYY'); // Validated date with YYYY
        vdtYy = dtMom.format('MM/DD/YY'); // To be returned
        if (id === 'frDt') {
          obj.fromDt = vdt;
          obj.qty = this.setQty(idx, ps, obj.fromDt, obj.toDt);
          this.calcCaseTotals();
          this.setDxPntr(idx, ps);
        } else if (id === 'toDt') {
          obj.toDt = vdt;
          obj.qty = this.setQty(idx, ps, obj.fromDt, obj.toDt);
          this.calcCaseTotals();
          this.setDxPntr(idx, ps);
        } else if (id === 'payDt') {
          obj.payDt = vdt;
        } else if (id === 'casDat') {
          obj.casDat = vdt;
          this.onBlurCasDat();
        } else if (id === 'casAdmDt') {
          obj.casAdmDt = vdt;
        } else if (id === 'casDiscDt') {
          obj.casDiscDt = vdt;
        } else {
          alert('Invalid date input to onDtpDateSelect(...) or invalid id, values dt=' + dt + ', id=' + id);
        }
      }
    }
    return vdtYy;
  }

  loadLocalPcodes(provID, insID1, insID2) {
    if (+provID <= 0) {
      return;
    }
    if (this._recordService.localPcodes && this._recordService.localPcodes.some(e => (e.provId == provID && e.insId == insID1))) {
      insID1 = -1;
    }
    if (this._recordService.localPcodes && this._recordService.localPcodes.some(e => (e.provId == provID && e.insId == insID2))) {
      insID2 = -1;
    }
    if (insID1 < 0 && insID2 < 0) {
      return; // Don't reload
    }

    console.log('%c' + 'query ' + "Exec spMB_Web_Get_PcodeList @sn = '" + this.sn + "', @provID = '" + provID + "', @insID1 = '" + insID1 + "', @insID2 = '" + insID2 + "';", 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, "Exec spMB_Web_Get_PcodeList @sn = '" + this.sn + "', @provID = '" + provID + "', @insID1 = '" + insID1 + "', @insID2 = '" + insID2 + "';");
  }

  setQty(idx, ps, fromDt, toDt): string {
    if (fromDt) {
      let fromDtMom: any = moment(fromDt, 'MM/DD/YYYY', true);
      let toDtMom: any = moment(toDt, 'MM/DD/YYYY', true);
      if (fromDtMom.isValid() && toDtMom.isValid()) {
        return toDtMom.isSame(fromDtMom) ? '1' : (1 + toDtMom.diff(fromDtMom, 'days')).toString();
      } else if (fromDtMom.isValid()) {
        return '1';
      }
    } else {
      return;
    }
  }

  setDxPntr(p, ps): void {
    if (this.selectedCase[this.selectedCaseIndx].dxs.filter(e => { return e.code.length > 0 }).length == 1) {
      if (ps == '1') {
        this.selectedCase[this.selectedCaseIndx].procs1[p].dx = '1';
      }
      if (ps == '2') {
        this.selectedCase[this.selectedCaseIndx].procs2[p].dx = '1';
      }
      if (ps == '0') {
        this.selectedCase[this.selectedCaseIndx].procs0[p].dx = '1';
      }
    }
  }

  onKeyPress_decimal(event: any): void {
    if (event) {
      if ((event.key >= '0' && event.key <= '9') || event.key == '.' || event.key == '-') {
        return;
      } else {
        event.preventDefault(); // Cancel key pressed to avoid breaking the decimal pipe in html.
      }
    }
  }

  onBlur_setDecimalValue(event: any): string {
    if (event && /^\-?(\d{1,3},?)?(\d{1,3})(\.\d{0,4})?$/g.test(event.target.value)) {
      return event.target.value.match(/^\-?(\d{1,3},?)?(\d{1,3})(\.\d{0,2})?$/g).join('').trim();
    } else {
      return '';
    }
  }

  calcCaseTotals(): void {
    let tXpect1: number = 0;
    let tXpect2: number = 0;
    let tPvdo: number = 0;
    let tAdjP1: number = 0;
    let tAdjP2: number = 0;
    let tAdjN1: number = 0;
    let tAdjN2: number = 0;
    let tAdjN0: number = 0;
    let tPay1: number = 0;
    let tPay2: number = 0;
    let tPay0: number = 0;
    let cas = this.selectedCase[this.selectedCaseIndx];

    cas.procs1.forEach(e => {
      if (e.xpect && e.qty && this._help.isNumeric(e.xpect.replace(/,/g, '')) && this._help.isNumeric(e.qty) && e.code.toUpperCase() != this.ajusteStr) {
        tXpect1 += parseFloat(e.xpect.replace(/,/g, '')) * parseFloat(e.qty);
      }
      if (e.pvdo && e.qty && this._help.isNumeric(e.pvdo.replace(/,/g, '')) && this._help.isNumeric(e.qty)) {
        tPvdo += parseFloat(e.pvdo.replace(/,/g, '')) * parseFloat(e.qty);
      }
    });
    cas.procs2.forEach(e => {
      if (e.xpect && e.qty && this._help.isNumeric(e.xpect.replace(/,/g, '')) && this._help.isNumeric(e.qty) && e.code.toUpperCase() != this.ajusteStr) {
        tXpect2 += parseFloat(e.xpect.replace(/,/g, '')) * parseFloat(e.qty);
      }
      if (e.pvdo && e.qty && this._help.isNumeric(e.pvdo.replace(/,/g, '')) && this._help.isNumeric(e.qty)) {
        tPvdo += parseFloat(e.pvdo.replace(/,/g, '')) * parseFloat(e.qty);
      }
    });
    cas.procs0.forEach(e => {
      if (e.pvdo && e.qty && this._help.isNumeric(e.pvdo.replace(/,/g, '')) && this._help.isNumeric(e.qty) && e.code && e.code.toUpperCase() != this.ajusteStr) {
        tPvdo += parseFloat(e.pvdo.replace(/,/g, '')) * parseFloat(e.qty);
      }
    });

    cas.procs1.forEach(e => {
      if (e.xpect && this._help.isNumeric(e.xpect.replace(/,/g, '')) && e.code && e.code.toUpperCase() == this.ajusteStr && e.desc && e.desc.toUpperCase().startsWith(this.ajusteStr)) {
        if (parseFloat(e.xpect.replace(/,/g, '')) > 0) {
          tAdjP1 += parseFloat(e.xpect.replace(/,/g, ''));
        }
        if (parseFloat(e.xpect.replace(/,/g, '')) < 0) {
          tAdjN1 += parseFloat(e.xpect.replace(/,/g, ''));
        }
      }
    });
    cas.procs2.forEach(e => {
      if (e.xpect && this._help.isNumeric(e.xpect.replace(/,/g, '')) && e.code && e.code.toUpperCase() == this.ajusteStr && e.desc && e.desc.toUpperCase().startsWith(this.ajusteStr)) {
        if (parseFloat(e.xpect.replace(/,/g, '')) > 0) {
          tAdjP2 += parseFloat(e.xpect.replace(/,/g, ''));
        }
        if (parseFloat(e.xpect.replace(/,/g, '')) < 0) {
          tAdjN2 += parseFloat(e.xpect.replace(/,/g, ''));
        }
      }
    });

    cas.procs0.forEach(e => {
      if (e.pvdo && this._help.isNumeric(e.pvdo.replace(/,/g, '')) && e.code && e.code.toUpperCase() == this.ajusteStr && e.desc && e.desc.toUpperCase().startsWith(this.ajusteStr)) {
        if (parseFloat(e.pvdo.replace(/,/g, '')) < 0) {
          tAdjN0 += parseFloat(e.pvdo.replace(/,/g, ''));
        }
      }
    });

    cas.pays.forEach(e => {
      if (e.payAmnt && this._help.isNumeric(e.payAmnt.toString().replace(/,/g, ''))) {
        if (e.payPS == 'S1') {
          tPay1 += parseFloat(e.payAmnt.toString().replace(/,/g, ')'));
        }
        if (e.payPS == 'S2') {
          tPay2 += parseFloat(e.payAmnt.toString().replace(/,/g, ')'));
        }
        if (e.payPS == 'D1' || e.payPS == 'D2' || e.payPS == '') {
          tPay0 += parseFloat(e.payAmnt.toString().replace(/,/g, ')'));
        }
      }
    });

    cas.casChg1 = tXpect1;
    cas.casChg2 = tXpect2;
    cas.casChgP = tPvdo;
    cas.casAdjP1 = tAdjP1;
    cas.casAdjN1 = tAdjN1;
    cas.casAdjP2 = tAdjP2;
    cas.casAdjN2 = tAdjN2;
    cas.casAdjNP = tAdjN0;
    cas.casPay1 = tPay1;
    cas.casPay2 = tPay2;
    cas.casPayP = tPay0;

    cas.casBal1 = tXpect1 + tAdjP1 + tAdjN1 - tPay1;
    cas.casBal2 = tXpect2 + tAdjP2 + tAdjN2 - tPay2;
    cas.casBalP = tPvdo + tAdjN0 - tPay0;
  }

  getCityStfromZip(zip: string): void {
    for (let i = 0; i < this.localZipCodes.length; i++) {
      if (this.localZipCodes[i].zipCode == zip) {
        this.pat.city = this.localZipCodes[i].zipCity;
        this.pat.st = this.localZipCodes[i].zipState;
        return;
      }
    }
  }

  saveDeleteCookie(cookie, value): void {
    if (value && value != this._cookieService.get(cookie)) {
      this._cookieService.set(cookie, value);
    } else {
      this._cookieService.delete(cookie);
    }
  }

  abortEdits(event: any, newRec: boolean, openCase: boolean, pid: string, cid: string): boolean {
    let stitle = undefined;
    if ((Object.keys(this.patOrg).length > 0 && !this._help.deepEqual(this.pat, this.patOrg))
    ) {
      stitle = ' Record ';
    }
    if (Object.keys(this.casOrg).length > 0 && !this._help.deepEqual(this.selectedCase[this.selectedCaseIndx], this.casOrg)) {
      stitle ? stitle += '& ' + (this.engLang ? 'Case' : 'Caso') : stitle = (this.engLang ? ' Case' : ' Caso');
    }

    if (stitle) {
      if (event) {
        event.preventDefault(); // Preserves  event.activeId & prevents tabs to change
      }

      let title: string = (this.engLang ? 'CONFIRM -' : 'CONFIRME -') + stitle;
      let prompt: string = this.engLang ? 'Exit w/o Saving ' + stitle + ' ?' : '¿ Salir sin Grabar ' + stitle + ' ?';
      let btn_primary = true;
      let btn_default = true;
      let ok: string = this.engLang ? 'Yes' : 'Sí';
      let cancel = 'No';

      this._modalService.confirm(prompt, title, btn_primary, btn_default, ok, cancel)
        .pipe(
          take(1) // take() manages unsubscription for us
        ).subscribe(response => {
          if (response) {
            if (event && !newRec && !openCase) {
              this.activeTab = event.nextId;
            } else if (newRec) {
              this.newRecord();
              this.pat.lastNm = '';
              this.pat.firstNm = '';
              this.pat.midInit = '';
            } else if (openCase) {
              this.onClick_OpenCase(cid);
            }
            return true;
          } else {
            if (event && !newRec && !openCase) {
              event.nextId = event.activeId;
            }
            return false;
          }
        });
      return true;
    }
    if (newRec) {
      this.newRecord();
      this.pat.lastNm = '';
      this.pat.firstNm = '';
      this.pat.midInit = '';
    }
    if (openCase) {
      this.onClick_OpenCase(cid);
    }
  }

  newRecord(): void {
    this.showRecIns = true;
    this.clrPat();
    this.resetDeErrs();

    this.pat.recLocale = this._cookieService.get('pat.recLocale');
    if (!this.pat.recLocale) {
      this.pat.recLocale = this._recordService.locales[0].locNo;
    }
    this.pat.recYr = this._cookieService.get('pat.recYr');
    if (!this.pat.recYr) {
      this.pat.recYr = new Date().getFullYear().toString().substring(2, 4);
    }
    this.pat.sex = this._cookieService.get('pat.sex');
    this.pat.zip = this._cookieService.get('pat.zip');
    this.pat.status = '';
    if (this.pat.zip) {
      this.pat.city = this.localZipCodes.find(e => { return e.zipCode == this.pat.zip }).zipCity;
      this.pat.st = this.localZipCodes.find(e => { return e.zipCode == this.pat.zip }).zipState;
    }
    this.pat.insId1 = this._cookieService.get('pat.insId1');
    if (!this.pat.insId1 || this.pat.insId1 == '0') {
      this.pat.insId1 = '0';
      this.pat.insAlias1 = '';
    } else {
      this.pat.insAlias1 = this.localInsurances.find(e => { return e.pKey == this.pat.insId1 }).alias;
    }
    this.pat.insId2 = this._cookieService.get('pat.insId2');
    if (!this.pat.insId2 || this.pat.insId2 == '0') {
      this.pat.insId2 = '0';
      this.pat.insAlias2 = '';
    } else {
      this.pat.insAlias2 = this.localInsurances.find(e => { return e.pKey == this.pat.insId2 }).alias;
    }

    this.patOrg = JSON.parse(JSON.stringify(this.pat)); // Deep copy of new record to check editing

    if (this.lastSrchParm === 'schLast') { // Imbed searched for last, first name if new record
      const nams: string[] = this.lastUsedParam.lastNm.split(',');
      this.pat.lastNm = nams[0].toUpperCase().replace(/[^A_Za-zÑñ ]*/g, '');
      if (nams.length > 1) {
        this.pat.firstNm = nams[1].toUpperCase().replace(/[^A_Za-zÑñ ]*/g, '');
      }
    }

    this.selectedCaseIndx = 0;
    this.selectedCase = [];
    this.selectedCase.push(JSON.parse(JSON.stringify(this.casObj)));  // To get rid of arrays before calling this.onClick_AddDx() & this.onClick_addProc('1') etc.
    this.selectedCaseIndx = 0;
    this.prepareNewCase();
    this.clrCasOrg_setSofSign();
    this.casOrg = JSON.parse(JSON.stringify(this.selectedCase[0])); // Deep copy of new case to check editing but undefined props are not copied!
    this.recEdited = false;
    this.casEdited = false;
    this.proc1Edited = false;
    this.proc2Edited = false;
    this.proc0Edited = false;
    this.payEdited = false;
    this.waiting4Print1 = false;
    this.waiting4Print2 = false;
    this.waiting4Print0 = false;
    this.waiting4Img = false;
    this.activeTab = 2; // Show Record tab selected
    window.scroll(0, 0);

    if (<HTMLElement>document.getElementById('recNo')) {
      this.firstFocus = <HTMLInputElement>document.getElementById('recNo');
      this.firstFocus.focus();
    } else {
      this.firstFocus = undefined;
    }
  }

  clrPat(): void {
    for (let prop in this.pat) {
      this.pat[prop] = '';  // undefined properties will not deep copy to orgPat
    }
    for (let prop in this.patImg) {
      this.patImg[prop] = undefined;
    }
    this.images = [];
    this.images.pop();
    this.waiting4PatImg2Load = false;
  }

  clrSelectedCase(): void {
    while (this.selectedCase.length > 0) {
      this.selectedCase.pop();
    }
    this.selectedCaseIndx = 0;
    this.selectedCase.push(this.casObj);
    this.prepareNewCase();
  }

  prepareNewCase(): void {
    let cas = this.selectedCase[0];
    cas.casPatID = this.pat.patID;
    // Copy record's ins to case's ins 1
    cas.casI1ID = this.pat.insId1;
    cas.casIns1Alias = this.pat.insAlias1;
    cas.casCont1 = this.pat.contr1;
    cas.casGrp1 = this.pat.group1;
    cas.casDed1 = this.pat.ded1;
    cas.casILastIns1 = this.pat.lastNm1;
    cas.casIFirstIns1 = this.pat.firstNm1;
    cas.casIMidIns1 = this.pat.midInit1;
    // Copy record's ins to case's ins 2
    cas.casI2ID = this.pat.insId2;
    cas.casIns2Alias = this.pat.insAlias2;
    cas.casCont2 = this.pat.contr2;
    cas.casGrp2 = this.pat.group2;
    cas.casDed2 = this.pat.ded2;
    cas.casILastIns2 = this.pat.lastNm2;
    cas.casIFirstIns2 = this.pat.firstNm2;
    cas.casIMidIns2 = this.pat.midInit2;
    cas.casBal1 = 0;
    cas.casBal2 = 0;
    cas.casBalP = 0;

    if (this.localProviders.length == 1) {
      cas.casProvID = this.localProviders[0].pKey;
      cas.casProvAlias = this.localProviders[0].alias;
    } else {
      cas.casProvID = this._cookieService.get('casProvID');
    }
    if (cas.casProvID) {
      cas.casProvAlias = this.localProviders.find(e => { return e.pKey == cas.casProvID }).alias;
      this.loadLocalPcodes(cas.casProvID, cas.casI1ID, cas.casI2ID);
    }

    cas.casRefID = this._cookieService.get('casRefID');
    if (cas.casRefID && this.localReferring.some(e => { return e.pKey == cas.casRefID })) {
      cas.casRefLastNm = this.localReferring.find(e => { return e.pKey == cas.casRefID }).name.match(/^[A-Z' ]+(?!,)/gi).toString().trim();
      cas.casRefFirstNm = this.localReferring.find(e => { return e.pKey == cas.casRefID }).name.match(/[A-Z' ]+(?!,)$/gi).toString().trim();
      cas.casRefNPI = this.localReferring.find(e => { return e.pKey == cas.casRefID }).npi;
    }
    if (!cas.casRefID) {
      cas.casRefID = '0';
      cas.casRefLastNm = '';
      cas.casRefFirstNm = '';
      cas.casRefNPI = '';
    }

    cas.casFacID = this._cookieService.get('casFacID');
    if (cas.casFacID && this.localFacilities.some(e => { return e.pKey == cas.casFacID })) {
      cas.casFacAlias = this.localFacilities.find(e => { return e.pKey == cas.casFacID }).alias.toString().trim();
    }

    cas.casDat = this._cookieService.get('casDat');
    if (!cas.casDat) {
      let td: Date = new Date();
      cas.casDat = (1 + td.getMonth()).toString().padStart(2, '0') + '/' + td.getDate().toString().padStart(2, '0') + '/' + td.getFullYear().toString();
    }
    cas.casDate = cas.casDat.substring(0, 6) + cas.casDat.substring(8, 10);

    this.onClick_AddDx();
    if (+cas.casI1ID > 0) {
      this.onClick_addProc('1');
    }
    if (+cas.casI2ID > 0) {
      this.onClick_addProc('2');
    }
    if (+cas.casI2ID == 0) {
      this.onClick_addProc('0');
    }
  }

  getOverDueColor(bal: string, dt: string): string {
    if (+bal != 0) {
      const mdt: any = moment(dt, 'MM/DD/YY');  // bal dt
      const mtdt: any = moment(new Date()); // today
      if (mdt.isValid() && mtdt.isValid()) {
        if (mtdt.diff(mdt, 'days') > 30) {
          if (+bal > 0) { // debit
            return 'red';
          } else {  // credit
            return 'green';
          }
        }
      }
    }
    return '';
  }

  showHideRecIns(): void {
    this.showRecIns = !this.showRecIns;
  }

  open(content, opts: any, modalNm: string) {
    this._modalService.open(content, opts)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          console.log(response);
          return true;
        } else {
          return false;
        }
      });
  }

  openReclaim(ps: string) {
    this.reclCasIdSeled = this.selectedCase[this.selectedCaseIndx].casID;
    this.reclPsSeled = ps;
    this.reclInsID = ps === '1' ? this.selectedCase[this.selectedCaseIndx].casI1ID : this.selectedCase[this.selectedCaseIndx].casI2ID;

    this._modalService.open(this.reclaimsModalTmpl, { backdrop: 'static', size: 'xl', scrollable: true, keyboard: false }).subscribe(result => {
      if (result) {
        console.log('OK');
        // Handle result (e.g., user clicked OK and returned data)
      } else {
        console.log('dismiss');
        // Handle dismissal (e.g., user closed modal without taking action)
      }
    });
    this.reclaimsModalRef = this._modalService.modalRef;  // Main reclaims modal reference to dismiss later
  }

  closeReclaimsModal() {  // Always exit reclaimsModal #reclaimsModalTmpl through here
    if (this.enabSaveRecl) {
      this.reclaimsExitWoSaveModalRef = this._modalService.open(this.reclaimsExitWoSaveModal, { backdrop: 'static', size: 'sm', scrollable: false, keyboard: true }).subscribe(result => {
        if (result) {
          // Ok exit w/o saving
          console.log('OK');
          this.reclIdxSeld = this.changeIcnObj.nxtIcnIdx; // This will change idxSeld all the way down to the reclaim form component
          this.reclID = this.changeIcnObj.nxtReclID;
          this.icnsPrevIdx = -1;
          this.changeIcnObj = undefined;
          this.reclaimsModalRef.dismiss();  // Dimiss main reclaim modal
        } else {
          // Dismiss exit w/o saving modal
          console.log('dismiss');
          if (this.changeIcnObj) {
            this.icnsPrevIdx = this.changeIcnObj ? (this.changeIcnObj.prvIcnIdx > -1 ? this.changeIcnObj.prvIcnIdx : -1) : -1;

          } else {
            // this.reclaimsModalRef.dismiss();
          }
        }
      });
    } else {
      if (!this.changeIcnObj) {
        this.reclaimsModalRef.dismiss();//this._modalService.modalRef.dismiss('');
      } else {
        this.reclIdxSeld = this.changeIcnObj.nxtIcnIdx;
        this.reclID = this.changeIcnObj.nxtReclID;  // Causes change in reclaim component
        this.changeIcnObj = undefined;
      }
    }
  }

  closeReclaimComponent(event): void {
    if (event) {
      this.changeIcnObj = event;
    } else {
      this.changeIcnObj = undefined;
    }
    this.closeReclaimsModal();
  }

  printDemographics() {
    if (!this.waiting4PatImg2Load && this.saveRecord(false)) {
      let data: any = {
        "pat": this.pat,
        "siteNm": this.site.nm,
        "siteAd1": this.site.ad1,
        "siteAd2": this.site.ad2,
        "siteCt": this.site.ct,
        "siteSt": this.site.st,
        "siteZp": this.site.zp,
        "siteTl1": this.site.tl1,
        "siteXt1": this.site.xt1,
        "siteTl2": this.site.tl2,
        "siteXt2": this.site.xt2,
        "today": this._help.todaysDt(4)
      };
      data.pat.id1 = this.patImg.id1;
      data.pat.id2 = this.patImg.id2;

      let bdy = {
        "template":
        {
          "name": "/demographics/demographics-html"
        }, "data": data,
        "options": { "reports": { "save": true } }
      };

      this.waiting4PatImg2Load = true;
      this._recordService.postRenderJsRpt(bdy).subscribe(
        blob => this.backFromPrintRpt(blob),
        error => this.backFromServerError(error)
      );
    }
  }

  backFromPrintRpt(blob: Blob): void {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
      let win = window.open();
      win.document.open();
      win.document.write(iframe);
      win.document.close();
    };

    let cas = this.selectedCase[this.selectedCaseIndx];
    this.waiting4PatImg2Load = false;
    let dt = this._help.fmtDate(new Date(), 'sqlymd');
    let ps;
    if (this.waiting4Print1) {
      this.waiting4Print1 = false;
      ps = '1';
    }
    if (this.waiting4Print2) {
      this.waiting4Print2 = false;
      ps = '2';
    }
    if (ps) {
      console.log('%c' + 'query ' + "Exec spMB_Web_Save_SubmittedDt @sn = '" + this.sn + "', @subDtCasId = '" + cas.casID + "', @subDtPSP = '" + ps + "', @subDtDateTime = '" + dt + "', @subDtUserId = '" + this.userID + "', @subDtType = 'CMS-1500';", 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, "Exec spMB_Web_Save_SubmittedDt @sn = '" + this.sn + "', @subDtCasId = '" + cas.casID + "', @subDtPSP = '" + ps + "', @subDtDateTime = '" + dt + "', @subDtUserId = '" + this.userID + "', @subDtType = 'CMS-1500';");
    }
  }

  onClick_showImg(event): void {
    this.viewImgId = event.target.id;

    if (event.target.id == 'licView') {
      console.log('%c' + 'query ' + "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.patImg.licPkey + "', @id = '" + this.pat.patID + "';", 'color: black; background: #90EE90 ; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.patImg.licPkey + "', @id = '" + this.pat.patID + "';");
    } else if (event.target.id == 'id1View') {
      console.log('%c' + 'query ' + "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.patImg.id1Pkey + "', @id = '" + this.pat.patID + "';", 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.patImg.id1Pkey + "', @id = '" + this.pat.patID + "';");
    } else if (event.target.id == 'id2View') {
      console.log('%c' + 'query ' + "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.patImg.id2Pkey + "', @id = '" + this.pat.patID + "';", 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.patImg.id2Pkey + "', @id = '" + this.pat.patID + "';");
    } else if (event.target.id == 'photoView') {
      console.log('%c' + 'query ' + "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.patImg.photoPkey + "', @id = '" + this.pat.patID + "';", 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.patImg.photoPkey + "', @id = '" + this.pat.patID + "';");
    } else {
      event.target.parentElement.parentElement.style.backgroundColor = this.docSelectedRwBkg; // Highlights the row until (mouseover) clears it on images table
      this.viewImgId = event.target.parentElement.parentElement.firstElementChild.nextElementSibling.innerText; // Doc ID, if '0' it was recently scanned & image is in RAM, < 0 is set to be deleted after saveRecord()
      this.waiting4Img = true;
      console.log('%c' + 'query ' + "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.viewImgId + "', @id = '" + this.pat.patID + "';", 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetRecordImages_ByID @sn = '" + this.sn + "', @docId = '" + this.viewImgId + "', @id = '" + this.pat.patID + "';");
    }
  }

  printImg(imgId, s, w, h): void {
    if (imgId) {
      let e = <HTMLImageElement>document.getElementById(imgId);
      if (e) {
        s = e.src;
        w = e.width < 9 ? '100%' : e.width;
        h = (w == '100%' ? 'auto' : e.height);
      } else {
        return;
      }
    } else {
      w = (w < 9 ? '100%' : w);
      h = (w == '100%' ? 'auto' : h);
    }

    let data: any = {
      "src": s,
      "width": w,
      "height": h,
    };
    let bdy = {
      "template":
      {
        "name": "/image/image-html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    this._recordService.postRenderJsRpt(bdy).subscribe(
      blob => this.backFromPrintRpt(blob),
      error => this.backFromServerError(error)
    );
  }

  printCms1500(ps: string): void {
    if (+this.pat.patID && +this.selectedCase[this.selectedCaseIndx].casID && this.saveRecord(false)) {
      let cas = this.selectedCase[this.selectedCaseIndx];
      let prvNdx = this.localProviders.findIndex(e => e.pKey === cas.casProvID);
      let pc2Print = [];
      let insNdx1 = 0;
      insNdx1 = this.localInsurances.findIndex(e => e.pKey == cas.casI1ID);
      let insNdx2 = 0;
      insNdx2 = this.localInsurances.findIndex(e => e.pKey == cas.casI2ID);
      let cnfNdx = -1;

      let dobMM = (new Date(this.pat.dob).getMonth() + 1).toString().padStart(2, '0');
      let dobDD = new Date(this.pat.dob).getDate().toString().padStart(2, '0');
      let dobYYYY = new Date(this.pat.dob).getFullYear().toString();
      let patSxM = this.pat.sex == 'M' ? 'X' : '';
      let patSxF = this.pat.sex == 'F' ? 'X' : '';

      if (ps == '1') {

        pc2Print = cas.procs1.filter(e => { return e.code != this.ajusteStr && e.print });
        if (pc2Print.length == 0) {
          this.waiting4Print1 = false;
          let msgBoxMessage = this.engLang ? 'There are no procedures to print for primary insurance' : 'No hay procedimientos para imprimir del seguro primario';
          let msgBoxTitle = this.engLang ? 'Warning' : 'Aviso'
          this.showMsg(msgBoxMessage, msgBoxTitle);
          return;
        }

        this.waiting4Print1 = true;
        this.cms.top_insName = this.localInsurances[insNdx1].name;
        this.cms.top_insAddr1 = this.localInsurances[insNdx1].add1;
        this.cms.top_insAddr2 = this.localInsurances[insNdx1].add2;
        this.cms.top_insCtZpSt = this.localInsurances[insNdx1].city + ', ' + this.localInsurances[insNdx1].st + ' ' + this.fmtZip(this.localInsurances[insNdx1].zip);
        this.set1500formType(this.localInsurances[insNdx1].s1type);

        this.cms.s1a_insuredIDno = cas.casCont1;

        if (cas.casILastIns1.length > 0) {
          this.cms.s4_insuredName = cas.casILastIns1 + ', ' + cas.casIFirstIns1 + ' ' + cas.casIMidIns1;
          this.cms.s2_patientName = this.cms.s4_insuredName
        } else {
          this.cms.s2_patientName = this.pat.lastNm + ', ' + this.pat.firstNm + ' ' + this.pat.midInit;
          this.cms.s4_insuredName = this.cms.s2_patientName;
        }

        if (+cas.casI2ID > 0) {
          if (this.cms.s1_medicare == 'X') {  // Medicare primary with complimentary secondary insurance
            this.cms.s4_insuredName = '';

            this.cms.s7_insuredAddr1 = '';
            this.cms.s7_insuredAddr2 = '';
            this.cms.s7_city = '';
            this.cms.s7_state = '';
            this.cms.s7_zip = '';
            this.cms.s7_acode = '';
            this.cms.s7_tel = '';

            this.cms.s9_otherInsuredName = 'SAME';
            this.cms.s9a_OtherInsuredPolicyOrGroup = 'MEDIGAP ' + cas.casCont2 + ' ' + cas.casGrp2;
            this.cms.s9d_insurancePayerID = this.localInsurances[insNdx2].payerId;
            this.cms.s9d_insurancePlanName = this.localInsurances[insNdx2].name;

            this.cms.s11_InsuredPolicyGroup = "NONE";
            this.cms.s11a_mm = '';
            this.cms.s11a_dd = '';
            this.cms.s11a_yy = '';
            this.cms.s11a_m = '';
            this.cms.s11a_f = '';
            this.cms.s11b_OtherClaimID = '';
            this.cms.s11c_insurancePayerID = '';
            this.cms.s11c_insurancePlanName = 'NONE';
            this.cms.s11d_yes = '';
            this.cms.s11d_no = '';
          }
        } else {
          this.cms.s11_InsuredPolicyGroup = cas.casGrp1;
          this.cms.s11a_mm = dobMM;
          this.cms.s11a_dd = dobDD;
          this.cms.s11a_yy = dobYYYY;
          this.cms.s11a_m = patSxM;
          this.cms.s11a_f = patSxF;
          this.cms.s11b_OtherClaimID = '';
          this.cms.s11c_insurancePayerID = '';
          this.cms.s11c_insurancePlanName = '';
          this.cms.s11d_yes = '';
          this.cms.s11d_no = 'X';
        }

        cnfNdx = this.localConfig.findIndex(e => e.provID == cas.casProvID && e.insID == cas.casI1ID);

        this.cms.s33b = '';
        if (this.localInsurances[insNdx1].s33bTaxonomy == '1') {
          this.cms.s33b = 'ZZ' + this.localProviders[prvNdx].provTaxonomy;
        }
      } else {  //  ps = '2'

        pc2Print = cas.procs2.filter(e => { return e.code != this.ajusteStr && e.print });
        if (pc2Print.length == 0) {
          this.waiting4Print1 = false;
          let msgBoxMessage = this.engLang ? 'There are no procedures to print for secondary insurance' : 'No hay procedimientos para imprimir del seguro secundario';
          let msgBoxTitle = this.engLang ? 'Warninng' : 'Aviso'
          this.showMsg(msgBoxMessage, msgBoxTitle);
          return;
        }

        this.waiting4Print2 = true;
        this.cms.top_insName = this.localInsurances[insNdx2].name;
        this.cms.top_insAddr1 = this.localInsurances[insNdx2].add1;
        this.cms.top_insAddr2 = this.localInsurances[insNdx2].add2;
        this.cms.top_insCtZpSt = this.localInsurances[insNdx2].city + ', ' + this.localInsurances[insNdx2].st + ' ' + this.localInsurances[insNdx2].zip;
        this.set1500formType(this.localInsurances[insNdx2].s1type);

        this.cms.s1a_insuredIDno = cas.casCont2;

        if (cas.casILastIns2.length > 0) {
          this.cms.s4_insuredName = cas.casILastIns2 + ', ' + cas.casIFirstIns2 + ' ' + cas.casIMidIns2;
          this.cms.s2_patientName = this.cms.s4_insuredName
        } else {
          this.cms.s2_patientName = this.pat.lastNm + ', ' + this.pat.firstNm + ' ' + this.pat.midInit;
          this.cms.s4_insuredName = this.cms.s2_patientName;
        }

        if (+cas.casI1ID > 0) {
          if (cas.casILastIns1.length > 0) {
            this.cms.s9_otherInsuredName = cas.casILastIns1 + ', ' + cas.casIFirstIns1 + ' ' + cas.casIMidIns1;
          } else {
            this.cms.s9_otherInsuredName = this.pat.lastNm + ', ' + this.pat.firstNm + ' ' + this.pat.midInit;
          }
          this.cms.s9a_OtherInsuredPolicyOrGroup = cas.casCont1 + '   ' + cas.casGrp1;
          this.cms.s9d_insurancePlanName = this.localInsurances[insNdx1].name;
          this.cms.s9d_insurancePayerID = this.localInsurances[insNdx1].payerId;

          // this.cms.s11_InsuredPolicyGroup = cas.casGrp1;
          // this.cms.s11b_OtherClaimID = this.pat.employer;
          // this.cms.s11c_insurancePayerID = this.localInsurances[insNdx1].payerId;
          // this.cms.s11c_insurancePlanName = this.localInsurances[insNdx1].name;
          // this.cms.s11d_yes = 'X';
          // this.cms.s11d_no = '';
        }

        if (cas.casILastIns2.length > 0) {
          this.cms.s4_insuredName = cas.casILastIns2 + ', ' + cas.casIFirstIns2 + ' ' + cas.casIMidIns2;
        } else {
          this.cms.s4_insuredName = this.pat.lastNm + ', ' + this.pat.firstNm + ' ' + this.pat.midInit;
        }

        this.cms.s11_InsuredPolicyGroup = cas.casGrp2;
        this.cms.s11a_mm = dobMM;
        this.cms.s11a_dd = dobDD;
        this.cms.s11a_yy = dobYYYY;
        this.cms.s11a_m = patSxM;
        this.cms.s11a_f = patSxF;
        this.cms.s11b_OtherClaimID = '';
        this.cms.s11c_insurancePayerID = this.localInsurances[insNdx2].payerId;
        this.cms.s11c_insurancePlanName = this.localInsurances[insNdx2].name;
        this.cms.s11d_yes = 'X';
        this.cms.s11d_no = '';

        cnfNdx = this.localConfig.findIndex(e => e.provID == cas.casProvID && e.insID == cas.casI2ID);

        if (this.localInsurances[insNdx2].s33bTaxonomy == '1') {
          this.cms.s33b = 'ZZ' + this.localProviders[prvNdx].provTaxonomy;
        }

        this.cms.s33b = '';
        if (this.localInsurances[insNdx2].s33bTaxonomy == '1') {
          this.cms.s33b = 'ZZ' + this.localProviders[prvNdx].provTaxonomy;
        }
      }

      this.cms.s29 = cas.pays.reduce((t: number, e) => {
        if (e.payPS == 'D1' && ps == '1' || e.payPS == 'D2' && ps == '2') {
          t += parseFloat(e.payAmnt);
        }
        return t;
      }, 0).toFixed(2);

      let pgs = 0;  // Total pages to print
      if (pc2Print.length % 6 > 0) {  //  Get total pages count depending on number of procedure codes entered/selected
        pgs = 1 + Math.floor(pc2Print.length / 6);
      } else {
        pgs = Math.floor(pc2Print.length / 6);
      }

      this.cms.s3_mm = dobMM;
      this.cms.s3_dd = dobDD;
      this.cms.s3_yy = dobYYYY;
      this.cms.s3_m = patSxM;
      this.cms.s3_f = patSxF;

      if (this.cms.s1_medicare != 'X') {
        this.cms.s7_insuredAddr1 = this.pat.add1;
        this.cms.s7_insuredAddr2 = this.pat.add2;
        this.cms.s7_city = this.pat.city;
        this.cms.s7_state = this.pat.st;
        this.cms.s7_zip = this.pat.zip;
        this.cms.s7_acode = /^\(\d{3}\)/g.test(this.pat.tel) ? this.pat.tel.match(/^\(\d{3}\)/g).toString().replace('(', '').replace(')', '') : '';
        this.cms.s7_tel = this.pat.tel.substr(-8);

        if (this.pat.relation = '1') {
          this.cms.s2_patientName = this.cms.s4_insuredName;
        } else {
          this.cms.s2_patientName = this.pat.lastNm + ', ' + this.pat.firstNm + ' ' + this.pat.midInit;
        }
      }

      switch (this.pat.relation) {
        case '1':
          this.cms.s6_self = 'X';
          break;
        case '2':
          this.cms.s6_spouse = 'X';
          break;
        case '3':
          this.cms.s6_child = 'X';
          break;
        case '4':
          this.cms.s6_other = 'X';
          break;
      }

      this.cms.s5_patientAddr1 = this.pat.add1;
      this.cms.s5_patientAddr2 = this.pat.add2;
      this.cms.s5_city = this.pat.city;
      this.cms.s5_state = this.pat.st;
      this.cms.s5_zip = this.fmtZip(this.pat.zip);
      this.cms.s5_acode = /\(\d{3}\)/g.test(this.pat.tel) ? this.pat.tel.match(/\(\d{3}\)/g).toString().replace('(', '').replace(')', '') : '';
      this.cms.s5_tel = this.pat.tel.substr(-8);

      this.cms.s10a_no = 'X';
      this.cms.s10a_yes = '';
      this.cms.s10b_no = 'X';
      this.cms.s10b_yes = '';
      this.cms.s10c_no = 'X';
      this.cms.s10c_yes = '';
      this.cms.s10_place = '';
      switch (cas.casAccTyp) {
        case '1': // Auto accident
          this.cms.s10b_no = '';
          this.cms.s10b_yes = 'X';
          this.cms.s10_place = 'PR';
          break;
        case '2': // Work accident
          this.cms.s10a_no = '';
          this.cms.s10a_yes = 'X';
          this.cms.s10_place = 'PR';
          break;
        case '3': // Other accident
          this.cms.s10c_no = '';
          this.cms.s10c_yes = 'X';
          this.cms.s10_place = 'PR';
          break;
        default:
          break;
      }

      this.cms.s12_date = cas.casDat;

      if (/^\d{2}\/\d{2}\/\d{2}$/g.test(cas.casAccDt)) {
        this.cms.s14_mm = cas.casAccDt.match(/^\d{2}/g)[0];
        this.cms.s14_dd = cas.casAccDt.match(/\/\d{2}/g)[0].replace(/\//g, '');
        this.cms.s14_yy = cas.casAccDt.match(/\d{2}$/g)[0];
      }

      if (/^\d{2}\/\d{2}\/\d{2}$/g.test(cas.casAdmDt)) {
        this.cms.s18from_mm = cas.casAdmDt.match(/^\d{2}/g)[0];
        this.cms.s18from_dd = cas.casAdmDt.match(/\/\d{2}/g)[0].replace(/\//g, '');
        this.cms.s18from_yy = cas.casAdmDt.match(/\d{2}$/g)[0];
      }

      if (cas.casRefNPI) {
        this.cms.s17_refQual = cas.casRefQual;
        this.cms.s17_refName = cas.casRefLastNm + ', ' + cas.casRefFirstNm;
        this.cms.s17b_npi = cas.casRefNPI;
      } else {
        this.cms.s17_refQual = '';
        this.cms.s17_refName = '';
        this.cms.s17b_npi = '';
      }

      if (/^\d{2}\/\d{2}\/\d{2}$/g.test(cas.casDiscDt)) {
        this.cms.s18to_mm = cas.casDiscDt.match(/^\d{2}/g)[0];
        this.cms.s18to_dd = cas.casDiscDt.match(/\/\d{2}/g)[0].replace(/\//g, '');
        this.cms.s18to_yy = cas.casDiscDt.match(/\d{2}$/g)[0];
      }

      this.cms.s19 = cas.cas19;

      let icn = cas.pays.find(e => e.payPS == 'S' + ps && e.payApCode > '');
      if (icn) {
        this.cms.s22_originalRefNo = icn.payApCode; // ICN
        this.cms.s22_resubmissionCode = '7' // Correction
      }

      this.cms.s21a = '';
      this.cms.s21b = '';
      this.cms.s21c = '';
      this.cms.s21d = '';
      this.cms.s21e = '';
      this.cms.s21f = '';
      this.cms.s21g = '';
      this.cms.s21h = '';
      this.cms.s21i = '';
      this.cms.s21j = '';
      this.cms.s21k = '';
      this.cms.s21l = '';
      cas.dxs.forEach(e => {
        switch (e.indx) {
          case '1':
            this.cms.s21a = e.code.replace('.', '');
            break;
          case '2':
            this.cms.s21b = e.code.replace('.', '');
            break;
          case '3':
            this.cms.s21c = e.code.replace('.', '');
            break;
          case '4':
            this.cms.s21d = e.code.replace('.', '');
            break;
          case '5':
            this.cms.s21e = e.code.replace('.', '');
            break;
          case '6':
            this.cms.s21f = e.code.replace('.', '');
            break;
          case '7':
            this.cms.s21g = e.code.replace('.', '');
            break;
          case '8':
            this.cms.s21h = e.code.replace('.', '');
            break;
          case '9':
            this.cms.s21i = e.code.replace('.', '');
            break;
          case 'a':
            this.cms.s21j = e.code.replace('.', '');
            break;
          case 'b':
            this.cms.s21k = e.code.replace('.', '');
            break;
          case 'c':
            this.cms.s21l = e.code.replace('.', '');
            break;
          default:
            break;
        }

      });

      if (cnfNdx >= 0) {
        if (this.cms.s1_medicare == 'X') {
          this.cms.s23_cliaNo = this.localConfig[cnfNdx].clia;  // Clinical labs & Urologists with urinalysis
        }
        this.cms.s23_priorAuth = cas.casAuthNo;
        this.cms.s23_refNo = cas.casRefNo;

        if (this.localConfig[cnfNdx].ssnId.length > 0) {
          this.cms.s25 = this.localConfig[cnfNdx].ssnId;
          this.cms.s25_ssn = 'X';
        } else if (this.localConfig[cnfNdx].einId.length > 0) {
          this.cms.s25 = this.localConfig[cnfNdx].einId;
          this.cms.s25_ein = 'X';
        }
        this.cms.s32_5 = this.localConfig[cnfNdx].mamoCert;
      }

      if (this.cms.s25.length == 0) {
        this.cms.s25 = this.localProviders[prvNdx].taxId;
        if (this.localProviders[prvNdx].ssn == '1') {
          this.cms.s25_ssn = 'X';
        } else if (this.localProviders[prvNdx].ein == '1') {
          this.cms.s25_ein = 'X';
        }
      }

      this.cms.s26 = cas.casNo + '-' + cas.casOfNo.replace(/^0*/g, '');

      this.cms.s27_yes = cas.casAcAss == '1' ? 'X' : '';
      this.cms.s27_no = cas.casAcAss == '0' ? 'X' : '';

      this.cms.s31 = cas.casDat;

      this.cms.s33_acode = this.localProviders[prvNdx].provAcode;
      this.cms.s33_tel = /^\d{7}$/g.test(this.localProviders[prvNdx].provTel) ? this.localProviders[prvNdx].provTel.substring(0, 3) + '-' + this.localProviders[prvNdx].provTel.substring(4, 8) : this.localProviders[prvNdx].provTel;
      this.cms.s33_1 = this.localProviders[prvNdx].provName;
      if (cnfNdx >= 0 && this.localConfig[cnfNdx].add1.length > 0) { // Slot 33 prov address 1st priority is from [FormConfig]
        this.cms.s33_1 = this.localConfig[cnfNdx].name;
        this.cms.s33_2 = this.localConfig[cnfNdx].add1;
        this.cms.s33_3 = this.localConfig[cnfNdx].add2;
        this.cms.s33_4 = this.localConfig[cnfNdx].city + ', ' + this.localConfig[cnfNdx].st + ' ' + this.fmtZip(this.localConfig[cnfNdx].zip);
        this.cms.s33_acode = this.localConfig[cnfNdx].acode;
        this.cms.s33_tel = /^\d{7}$/g.test(this.localConfig[cnfNdx].tel) ? this.localConfig[cnfNdx].tel.substr(0, 3) + '-' + this.localConfig[cnfNdx].tel.substr(3, 4) : this.localConfig[cnfNdx].tel;
      } else if (this.localProviders[prvNdx].poAd1) { // Else use postal address from [ProvidersMD]
        this.cms.s33_1 = this.localProviders[prvNdx].provName;
        this.cms.s33_2 = this.localProviders[prvNdx].poAd1;
        this.cms.s33_3 = this.localProviders[prvNdx].poAd2;
        this.cms.s33_4 = this.localProviders[prvNdx].poCity + ', ' + this.localProviders[prvNdx].poSt + ' ' + this.fmtZip(this.localProviders[prvNdx].poZip);
      } else {  // Else use physical address from [ProvidersMD]
        this.cms.s33_2 = this.localProviders[prvNdx].provAd1;
        this.cms.s33_3 = this.localProviders[prvNdx].provAd2;
        this.cms.s33_4 = this.localProviders[prvNdx].provCity + ', ' + this.localProviders[prvNdx].provSt + ' ' + this.fmtZip(this.localProviders[prvNdx].provZip);
      }
      if (cnfNdx >= 0 && this.localConfig[cnfNdx].npi.length == 10) {
        this.cms.s33a = this.localConfig[cnfNdx].npi;
      } else {
        this.cms.s33a = this.localProviders[prvNdx].npi;
      }

      if (pc2Print.findIndex(e => { return e.pos == '12' }) > -1) {  // Services at pat's home (pos=12) do not require s32
        this.cms.s32_1 = '';
        this.cms.s32_2 = '';
        this.cms.s32_3 = '';
        this.cms.s32_4 = '';
        this.cms.s32a = '';
      }
      let posNot_11_nor_12 = pc2Print.findIndex(e => { return e.pos != '11' && e.pos != '12' });
      if (!posNot_11_nor_12 && +cas.casFacID > 0) {
        let facNdx = this.localFacilities.findIndex(e => { return e.pKey == cas.casFacID });
        this.cms.s32_1 = this.localFacilities[facNdx].facName;
        this.cms.s32_2 = this.localFacilities[facNdx].facAd1;
        this.cms.s32_3 = this.localFacilities[facNdx].facAd2;
        this.cms.s32_4 = this.localFacilities[facNdx].facCity + ', ' + this.localFacilities[facNdx].facSt + ' ' + this.fmtZip(this.localFacilities[facNdx].facZip);
        this.cms.s32a = this.localFacilities[facNdx].facNpi;
      }
      if (pc2Print.findIndex(e => { return e.pos == '11' }) > -1 && posNot_11_nor_12 == -1) {  // Services at phys's office & no home (pos=12) service
        this.cms.s32_1 = '';
        this.cms.s32_2 = this.localProviders[prvNdx].provAd1;
        this.cms.s32_3 = this.localProviders[prvNdx].provAd2;
        this.cms.s32_4 = this.localProviders[prvNdx].provCity + ', ' + this.localProviders[prvNdx].provSt + ' ' + this.fmtZip(this.localProviders[prvNdx].provZip);
      }

      this.cms.bot_RecNo = 'Rec#:' + this.pat.recNo.replace(/^[0]*/g, '') + '-' + this.pat.recLocale.replace(/^[0]*/g, '') + '-' + this.pat.recYr + ' User ' + this.userID;

      let pgTot: number = 0;
      for (let p = 1; p <= pgs; p++) {
        this.cms.top_pgCnt = p.toString() + ' of ' + pgs.toString();

        let j = 0;
        for (let i = 0; i < 6; i++) {
          this.cms.s24[i].s24from_mm = pc2Print[j].fromDt.match(/^\d{2}/g).toString();
          this.cms.s24[i].s24from_dd = pc2Print[j].fromDt.match(/\/\d{2}\//g).toString().substring(1, 3);
          this.cms.s24[i].s24from_yy = pc2Print[j].fromDt.match(/\d{2}$/g).toString();

          if (this._help.isDate(new Date(pc2Print[j].toDt))) {
            let dt = this._help.fmtDate(new Date(pc2Print[j].toDt), 'mm/dd/yyyy');
            this.cms.s24[i].s24to_mm = dt.match(/^\d{2}/g)[0].toString();
            this.cms.s24[i].s24to_dd = dt.match(/\/\d{2}\//g)[0].toString().substring(1, 3);
            this.cms.s24[i].s24to_yy = dt.match(/\d{2}$/g)[0].toString();
          } else {
            this.cms.s24[i].s24to_mm = '';
            this.cms.s24[i].s24to_dd = '';
            this.cms.s24[i].s24to_yy = '';
          }

          this.cms.s24[i].s24pos = pc2Print[j].pos;

          this.cms.s24[i].s24cpt = pc2Print[j].code;

          this.cms.s24[i].s24m1 = pc2Print[j].mod1;
          this.cms.s24[i].s24m2 = pc2Print[j].mod2;
          this.cms.s24[i].s24m3 = pc2Print[j].mod3;
          this.cms.s24[i].s24m4 = pc2Print[j].mod4;

          this.cms.s24[i].s24ptr = this.decodeDxPtr(pc2Print[j].dx);

          this.cms.s24[i].s24chg = pc2Print[j].usual;
          pgTot += Math.floor(100 * parseFloat(pc2Print[j].usual) * parseFloat(pc2Print[j].qty));

          this.cms.s24[i].s24units = pc2Print[j].qty;

          let prd = pc2Print[j].prod.toString().match(/^\(\d+/g);
          if (prd) {
            let prdPkey = prd.toString().replace('(', '');
            if (prdPkey) {
              let prdNdx = this.localProductors.findIndex(e => e.pKey == prdPkey);
              this.cms.s24[i].s24npi = this.localProductors[prdNdx].npi;
            }
          } else {
            this.cms.s24[i].s24npi = this.localProviders[prvNdx].npi; // If no rendering specified use provider
          }

          j += 1;
          if (j >= pc2Print.length) {
            break;
          }
        }

        this.cms.s28 = (pgTot / 100).toFixed(2);

        for (let i = j; i < 6; i++) {
          this.cms.s24[i].s24from_mm = '';
          this.cms.s24[i].s24from_dd = '';
          this.cms.s24[i].s24from_yy = '';
          this.cms.s24[i].s24to_mm = '';
          this.cms.s24[i].s24to_dd = '';
          this.cms.s24[i].s24to_yy = '';
          this.cms.s24[i].s24pos = '';
          this.cms.s24[i].s24cpt = '';
          this.cms.s24[i].s24m1 = '';
          this.cms.s24[i].s24m2 = '';
          this.cms.s24[i].s24m3 = '';
          this.cms.s24[i].s24m4 = '';
          this.cms.s24[i].s24ptr = '';
          this.cms.s24[i].s24chg = '';
          this.cms.s24[i].s24units = '';
          this.cms.s24[i].s24npi = '';
        }

        this.cms.s30 = '';
        if (ps == '1') {
          if (this.localInsurances[insNdx1].s30BalDue == '1') {
            this.cms.s30 = (parseFloat(this.cms.s28) - parseFloat(this.cms.s29)).toFixed(2);
          }
        } else {
          if (this.localInsurances[insNdx2].s30BalDue == '1') {
            this.cms.s30 = (parseFloat(this.cms.s28) - parseFloat(this.cms.s29)).toFixed(2);
          }
        }

        let data: any = {
          "cms": this.cms,
        };
        let bdy = {
          "template":
          {
            "name": "/cms1500/cms1500-html"
          }, "data": data,
          "options": { "reports": { "save": true } }
        };

        this._recordService.postRenderJsRpt(bdy).subscribe(
          blob => this.backFromPrintRpt(blob),
          error => this.backFromServerError(error)
        );

      }
    } else {
      let msgBoxMessage = this.engLang ? 'Record or case incomplete.' : 'Record o caso incompleto.';
      let msgBoxTitle = this.engLang ? 'Warning' : 'Aviso'
      this.showMsg(msgBoxMessage, msgBoxTitle);
    }
  }

  set1500formType(type): void {
    this.cms.s1_medicare = '';
    this.cms.s1_medicaid = '';
    this.cms.s1_tricare = '';
    this.cms.s1_champva = '';
    this.cms.s1_grouphealth = '';
    this.cms.s1_feca = '';
    this.cms.s1_other = '';

    switch (type) {
      case '1':
        this.cms.s1_medicare = 'X';
        break;
      case '2':
        this.cms.s1_medicaid = 'X';
        break;
      case '3':
        this.cms.s1_tricare = 'X';
        break;
      case '4':
        this.cms.s1_champva = 'X';
        break;
      case '5':
        this.cms.s1_grouphealth = 'X';
        break;
      case '6':
        this.cms.s1_feca = 'X';
        break;
      case '7':
        this.cms.s1_other = 'X';
        break;
      default:
        this.cms.s1_other = 'X';
        break;
    }
  }

  fmtZip(z): string {
    return z.length > 5 ? z.substr(0, 5) + '-' + z.substr(5).replace('-', '') : z;
  }

  decodeDxPtr(ptr): string {
    if (ptr >= '1' && ptr <= '9') {
      return String.fromCharCode(64 + parseInt(ptr));
    }
    switch (ptr) {
      case 'a':
        return 'J';
      case 'b':
        return 'K';
      case 'c':
        return 'L';
      default:
        return ptr + '?';
    }
  }

  scanDoc(event): void {
    if (!this.pat.patID) {
      let msgBoxMessage = this.engLang ? 'First save the new record before scanning documents.' : 'Primero grabe el record nuevo antes de escanear documentos.'
      let msgBoxTitle = this.engLang ? 'Info' : 'Info'
      this._modalService.message(msgBoxMessage
        , msgBoxTitle
        , undefined
        , undefined
        , undefined
        , undefined
        , this.engLang
        , undefined
        , undefined
        , undefined
        , undefined).pipe(
          take(1) // take() manages unsubscription for us
        ).subscribe(response => {
          if (response) { // response = undefined if Esc was pressed, False if closed with upper rt hand x
          }
        });
      return;
    }

    this.hideScanners();
    switch (event.target.id) {
      case 'scanLic':
        this.scanLic = true;
        break;
      case 'scanID1':
        this.scanID1 = true;
        break;
      case 'scanID2':
        this.scanID2 = true;
        break;
      case 'scanPhoto':
        this.scanPhoto = true;
      case 'scanRec':
        if (this.pat.patID && parseInt(this.pat.patID) > 0) {
          this.scanRec = true;
        } else {
        }
        break;
      case 'scanCas':
        if (+this.selectedCase[this.selectedCaseIndx].casID > 0) {
          this.scanCas = true;
        } else {
          let msgBoxMessage = this.engLang ? 'First create the case by saving before scanning associated documents.' : 'Primero grabe el caso nuevo antes de escanear documentos asociados.'
          let msgBoxTitle = this.engLang ? 'Info' : 'Info'
          this._modalService.message(msgBoxMessage
            , msgBoxTitle
            , undefined
            , undefined
            , undefined
            , undefined
            , this.engLang
            , undefined
            , undefined
            , undefined
            , undefined).pipe(
              take(1) // take() manages unsubscription for us
            ).subscribe(response => {
              if (response) { // response = undefined if Esc was pressed, False if closed with upper rt hand x
                return;
              }
            });
        }
        break;
      default:
        alert('Unknown event.target.id in scanDoc(event); event.target.id = ' + event.target.id);
        break;
    }
  }

  deleteRecordCase(rc: string): void {  // rc = 'R' delete record, 'C' delete case
    this.warning = undefined;
    let cas = this.selectedCase[this.selectedCaseIndx];
    if (rc == 'C') {
      if (this.userSecOpts.mbUserDeleteCas != '1') {
        window.scroll(0, 0);
        this.warning = 'Acceso denegado - eliminar caso';
        return;
      }
      if (!this._help.isNumeric(cas.casNo)) {
        return;
      }
    } else {
      if (this.pat.patID == '0') {
        this.newRecord();
        return;
      }
      if (this.userSecOpts.mbUserDeleteRec != '1') {
        window.scroll(0, 0);
        this.warning = 'Acceso denegado - eliminar record';
        return;
      }
    }

    let stitle = (rc == 'R' ? 'Record ' + this.pat.recNo + '-' + this.pat.recLocale + '-' + this.pat.recYr :
      (this.engLang ? 'Case' : 'Caso') + ' ' + cas.casNo + '-' + cas.casOfNo)
    if (this.pat.patID || this._help.deepEqual(this.pat, this.patOrg)) {
      let title: string = (this.engLang ? 'CONFIRM - DELETE' : 'CONFIRME - ELIMINAR');
      let prompt: string = (this.engLang ? 'Delete ' + stitle : '¿ Eliminar ' + stitle)
        + ' : ' + this.pat.lastNm + ', ' + this.pat.firstNm + ' ' + this.pat.midInit.trim() + ' ?';
      let btn_primary = true;
      let btn_default = true;
      let ok: string = this.engLang ? 'Yes' : 'Sí';
      let cancel = 'No';

      this._modalService.confirm(prompt, title, btn_primary, btn_default, ok, cancel)
        .pipe(
          take(1) // Unsubscribe after 1st response input
        ).subscribe(response => {
          if (response) {
            if (rc == 'C') {
              console.log('%c' + 'query ' + "Exec spMB_Web_Delete_RecordCase @sn = '" + this.sn + "', @rc = '" + rc + "', @pKey = '" + cas.casID + "';", 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, "Exec spMB_Web_Delete_RecordCase @sn = '" + this.sn + "', @rc = '" + rc + "', @pKey = '" + cas.casID + "';");
            } else {
              this.saveStatus = this.engLang ? 'Deleteing Record...' : 'Eliminando Record...';
              console.log('%c' + 'query ' + "Exec spMB_Web_Delete_RecordCase @sn = '" + this.sn + "', @rc = '" + rc + "', @pKey = '" + this.pat.patID + "';", 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, "Exec spMB_Web_Delete_RecordCase @sn = '" + this.sn + "', @rc = '" + rc + "', @pKey = '" + this.pat.patID + "';");
            }
          }
        });
    }
  }

  onKeyDown_focus(event, focusId): void {
    if (event?.key == 'Tab') {
      event.preventDefault();

      if (this.showRecIns) {
        switch (event.target.id) {
          case 'recNotes':
            document.getElementById('patInsId1').focus();
            return;
          case 'patIns1Alias':
            document.getElementById('patIns1Alias').focus();
            return;
          case 'recContr1':
            document.getElementById('recGroup1').focus();
            return;
          case 'recDed1':
            document.getElementById('patInsId2').focus();
            return;
          case 'recMidInit1':
            document.getElementById('patInsId2').focus();
            return;
          case 'recContr2':
            document.getElementById('recGroup2').focus();
            return;
          case 'recDed2':
            document.getElementById('casDat').focus();
            return;
          default:
            break;
        }
      }

      if (focusId === 'casDed2') {
        const dxTxt = document.getElementById('dxC1');
        if (dxTxt) {  // Dx table could be empty
          dxTxt?.focus();
        }
        return;
      }

      if (focusId === 'casDed2') {
        const dxTxt = document.getElementById('recNo');
        dxTxt?.focus();
        return;
      }

      document.getElementById(focusId).focus();
    }
  }

  ddnListSelectionClkd(value: any, p?: number) {
    let cas = this.selectedCase[this.selectedCaseIndx];
    switch (value.typ) {
      case 'provID':
        cas.casProvID = value.pKey;
        cas.casProvAlias = value.value;
        if (cas.casProvID) {
          this.loadLocalPcodes(cas.casProvID, cas.casI1ID, cas.casI2ID);
        }
        break;
      case 'prodID1':
        if (value.pKey.match(/\d+/g)) {
          this.selectedCase[this.selectedCaseIndx].procs1[p].prod = '(' + value.pKey + ')' + value.value.substring(0, 25);
          break;
        }
        this.selectedCase[this.selectedCaseIndx].procs1[p].prod = '';
        break;
      case 'prodID2':
        if (value.pKey.match(/\d+/g)) {
          this.selectedCase[this.selectedCaseIndx].procs2[p].prod = '(' + value.pKey + ')' + value.value.substring(0, 25);
          break;
        }
        this.selectedCase[this.selectedCaseIndx].procs2[p].prod = '';
      case 'prodID0':
        if (value.pKey.match(/\d+/g)) {
          this.selectedCase[this.selectedCaseIndx].procs0[p].prod = '(' + value.pKey + ')' + value.value.substring(0, 25);
          break;
        }
        this.selectedCase[this.selectedCaseIndx].procs0[p].prod = '';
      case 'refNpi':
        this.selectedCase[this.selectedCaseIndx].casRefID = value.pKey;
        this.selectedCase[this.selectedCaseIndx].casRefNPI = value.name;
        (<HTMLInputElement>document.getElementById('casRefAlias')).value = value.value;
        if ((value.value).includes(',')) {
          (<HTMLInputElement>document.getElementById('casRefLastNm')).value = (value.value).split(',')[0];
          (<HTMLInputElement>document.getElementById('casRefFirstNm')).value = (value.value).split(',')[1];
        } else {
          (<HTMLInputElement>document.getElementById('casRefLastNm')).value = '';
          (<HTMLInputElement>document.getElementById('casRefFirstNm')).value = '';
        }
        break;
      case 'facID':
        cas.casFacID = value.pKey;
        cas.casFacAlias = value.value;
        break;
      case 'cInsID1':
        cas.casI1ID = value.pKey;
        cas.casIns1Alias = value.value;
        this.loadLocalPcodes(+cas.casProvID, cas.casI1ID, cas.casI2ID);
        break;
      case 'cInsID2':
        cas.casI2ID = value.pKey;
        cas.casIns2Alias = value.value;
        this.loadLocalPcodes(+cas.casProvID, cas.casI1ID, cas.casI2ID);
        break;
      case 'pInsID1':
        this.pat.insId1 = value.pKey;
        this.pat.insAlias1 = value.value;
        break;
      case 'pInsID2':
        this.pat.insId2 = value.pKey;
        this.pat.insAlias2 = value.value;
        break;
      case 'payMode':
        cas.pays[p].payMode = value.value;
        break;
      default:
        alert('Unspecified `value.typ` @ record.component:ddnListSelectionClkd');
    }
  }

  onClick_showMnu1($event): void {
    this.showMnu1 = !this.showMnu1;
    if (this.showMnu1) {
      const fltCk: HTMLInputElement = <HTMLInputElement>document.getElementById('menu-open');
      if (fltCk) {
        fltCk.click();
      }
    }
  }

  onClick_showMnu2($event): void {
    this.showMnu2 = !this.showMnu2;
    if (this.showMnu2) {
      const fltCk: HTMLInputElement = <HTMLInputElement>document.getElementById('menu-open');
      if (fltCk) {
        fltCk.click();
      }
    }
  }

  onClick_showMnu3($event): void {
    this.showMnu3 = !this.showMnu3;
    if (this.showMnu3) {
      const fltCk: HTMLInputElement = <HTMLInputElement>document.getElementById('menu-open');
      if (fltCk) {
        fltCk.click();
      }
    }
  }

  onClose_mainMnu($event): void {
    this.showMnu1 = false;
    this.showMnu2 = false;
    this.showMnu3 = false;
  }

  onScannedDoc(event): void { // Called from dwt.component.ts with @Output() scannedDoc: EventEmitter<string> to set images into pat & images[]
    if (event == 'CloseScanners') {
      this.hideScanners();
    } else {
      let q: string;
      let tdt = new Date();
      let tdts = (1 + tdt.getMonth()).toString().padStart(2, '0') + '/' + tdt.getDate().toString().padStart(2, '0') + '/' + tdt.getFullYear();
      if (this.scanLic) {
        this.patImg.lic = event;
        this.patImg.licDt = tdts;

        q = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
          + "', @docID = '" + this.patImg.licPkey
          + "', @userID = '" + this.userID
          + "', @docDesc = '" + "Lic-ID " + this.pat.lastNm.toUpperCase() + ',' + this.pat.firstNm.toUpperCase()
          + "', @patID = '" + this.pat.patID
          + "', @lic = '" + event.replace(this.base64Scheme, '') + "';";
      }
      if (this.scanID1) {
        this.patImg.id1 = event;
        this.patImg.id1Dt = tdts;

        q = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
          + "', @docID = '0"
          + "', @userID = '" + this.userID
          + "', @docDesc = '" + "ID Seg 1 " + this.pat.lastNm.toUpperCase() + ',' + this.pat.firstNm.toUpperCase()
          + "', @patID = '" + this.pat.patID
          + "', @id1 = '" + event.replace(this.base64Scheme, '') + "';";
      }
      if (this.scanID2) {
        this.patImg.id2 = event;
        this.patImg.id2Dt = tdts;

        q = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
          + "', @docID = '0"
          + "', @userID = '" + this.userID
          + "', @docDesc = '" + "ID Seg 2 " + this.pat.lastNm.toUpperCase() + ',' + this.pat.firstNm.toUpperCase()
          + "', @patID = '" + this.pat.patID
          + "', @id2 = '" + event.replace(this.base64Scheme, '') + "';";
      }
      if (this.scanPhoto) {
        this.patImg.photo = event;
        this.patImg.photoDt = tdts;

        q = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
          + "', @docID = '0"
          + "', @userID = '" + this.userID
          + "', @docDesc = '" + "Photo " + this.pat.lastNm.toUpperCase() + ',' + this.pat.firstNm.toUpperCase()
          + "', @patID = '" + this.pat.patID
          + "', @photo = '" + event.replace(this.base64Scheme, '') + "';";
      }
      if (this.scanRec) {
        this.images.unshift({ 'docID': '0', 'docDt': tdts, 'docDesc': 'Doc ' + this.pat.lastNm.toUpperCase() + ', ' + this.pat.firstNm.toUpperCase(), 'docCasID': '0', 'docCasNo': '', 'ordr': 'r', 'docBase64': event }); // 'ordr': 'r' signals it's a record doc

        q = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
          + "', @indx = '0"
          + "', @docID = '0"
          + "', @userID = '" + this.userID
          + "', @docDesc = '" + "Doc " + this.pat.lastNm.toUpperCase() + ', ' + this.pat.firstNm.toUpperCase()
          + "', @patID = '" + this.pat.patID
          + "', @casID = '0"
          + "', @img = '" + event.replace(this.base64Scheme, '') + "';";
      }
      if (this.scanCas) {
        this.images.unshift({ 'docID': '0', 'docDt': tdts, 'docDesc': 'Doc ' + this.pat.lastNm.toUpperCase() + ', ' + this.pat.firstNm.toUpperCase(), 'docCasID': this.selectedCase[this.selectedCaseIndx].casID, 'docCasNo': this.selectedCase[this.selectedCaseIndx].casNo, 'ordr': 'c', 'docBase64': event });  // 'ordr': 'c' signals it's a case doc

        q = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
          + "', @indx = '0"
          + "', @docID = '0"
          + "', @userID = '" + this.userID
          + "', @docDesc = '" + "Doc " + this.pat.lastNm.toUpperCase() + ', ' + this.pat.firstNm.toUpperCase()
          + "', @patID = '" + this.pat.patID
          + "', @casID = '" + this.selectedCase[this.selectedCaseIndx].casID
          + "', @img = '" + event.replace(this.base64Scheme, '') + "';";
      }

      if (q) {
        this.saveStatus = this.engLang ? 'Saving images...' : 'Grabando imágenes...';
        this.waiting4Success = true;
        window.scroll(0, 0);
        console.log('%c' + 'query ' + q, 'color: black; background: #90EE90; font-size: 12px');
        this._websocketService.sendChat('query', this.sn, q);
      }

      this.hideScanners();
    }
  }

  hideScanners(): void {
    this.scanLic = false;
    this.scanID1 = false;
    this.scanID2 = false;
    this.scanPhoto = false;
    this.scanRec = false;
    this.scanCas = false;
  }

  onClick_deleteImg(event): void {
    let title: string = (this.engLang ? 'CONFIRM' : 'CONFIRME');
    let prompt: string = this.engLang ? 'DELETE document ?' : '¿ ELIMINAR el documento ?';
    let btn_primary = true;
    let btn_default = true;
    let ok: string = this.engLang ? 'Yes' : 'Sí';
    let cancel = 'No';

    this._modalService.confirm(prompt, title, btn_primary, btn_default, ok, cancel)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          let id;
          if (!event) {
            switch (this.viewImgId) {
              case 'licView':
                id = 'delImgLic';
                break;
              case 'id1View':
                id = 'delImgID1';
                break;
              case 'id2View':
                id = 'delImgID2';
                break;
              case 'photoView':
                id = 'delImgPhoto';
                break;
            }
          } else {
            id = event.target.id;
          }

          let qi;
          switch (id) {
            case "delImgLic":
              if (this.patImg.licPkey == '0') {
                this.removeDeletedDoc('lic', undefined);
                this.waiting4Success = false;
                return;
              }
              qi = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
                + "', @docID = '" + -1 * parseInt(this.patImg.licPkey)  //Delete
                + "', @userID = '" + this.userID
                + "', @lic = '';";
              this.saveStatus = this.engLang ? 'Saving record images...' : 'Grabando imágenes record...';
              this.waiting4Success = true;
              window.scroll(0, 0);
              console.log('%c' + 'query ' + qi, 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, qi);
              break;
            case "delImgID1":
              if (this.patImg.id1Pkey == '0') {
                this.removeDeletedDoc('id1', undefined);
                this.waiting4Success = false;
                return;
              }
              qi = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
                + "', @docID = '" + -1 * parseInt(this.patImg.id1Pkey)  //Delete
                + "', @userID = '" + this.userID
                + "', @id1 = '';";
              this.saveStatus = this.engLang ? 'Saving record images...' : 'Grabando imágenes record...';
              this.waiting4Success = true;
              window.scroll(0, 0);
              console.log('%c' + 'query ' + qi, 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, qi);
              break;
            case "delImgID2":
              if (this.patImg.id2Pkey == '0') {
                this.removeDeletedDoc('id2', undefined);
                this.waiting4Success = false;
                return;
              }
              qi = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
                + "', @docID = '" + -1 * parseInt(this.patImg.id2Pkey)  //Delete
                + "', @userID = '" + this.userID
                + "', @id2 = '';";
              this.saveStatus = this.engLang ? 'Saving record images...' : 'Grabando imágenes record...';
              this.waiting4Success = true;
              window.scroll(0, 0);
              console.log('%c' + 'query ' + qi, 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, qi);
              break;
            case "delImgPhoto":
              if (this.patImg.id2Photo == '0') {
                this.removeDeletedDoc('photo', undefined);
                this.waiting4Success = false;
                return;
              }
              qi = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
                + "', @docID = '" + -1 * parseInt(this.patImg.id2Photo)  //Delete
                + "', @userID = '" + this.userID
                + "', @photo = '';";
              this.saveStatus = this.engLang ? 'Saving record images...' : 'Grabando imágenes record...';
              this.waiting4Success = true;
              window.scroll(0, 0);
              console.log('%c' + 'query ' + qi, 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, qi);
              break;
            default:
              // Deleteing from images[]
              let i = this.images.findIndex(e => { return e.docID == this.viewImgId });
              qi = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
                + "', @indx = '" + i
                + "', @docID = '" + -1 * parseInt(this.images[i].docID)
                + "', @userID = '" + this.userID
                + "', @img = 'notNull';";

              this.saveStatus = this.engLang ? 'Saving record images...' : 'Grabando imágenes record...';
              this.waiting4Success = true;
              window.scroll(0, 0);
              console.log('%c' + 'query ' + qi, 'color: black; background: #90EE90; font-size: 12px');
              this._websocketService.sendChat('query', this.sn, qi);
              break;
          }
        } else {
          // Nothing
        }
      });
  }

  onMouseEnter_images(event): void {
    event.target.style.backgroundColor = '';
  }

  saveImages(rc: string): void {
    let q = '';
    let casId = (rc == 'r' ? '0' : this.selectedCase[this.selectedCaseIndx].casID);
    this.images.forEach((e, index) => {
      if (e.ordr == rc) {  // Saving record 'r' or case 'c' documents
        if (e.docBase64 || parseInt(e.docID) < 0) {
          if (e.docID == '0') { // Inserting
            q += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
              + "', @indx = '" + index
              + "', @docID = '" + e.docID
              + "', @userID = '" + this.userID
              + "', @docDesc = '" + e.docDesc
              + "', @patID = '" + this.pat.patID
              + "', @casID = '" + casId
              + "', @img = '" + e.docBase64.replace(this.base64Scheme, '') + "';";
          } else {  // Delete or Update
            q += "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
              + "', @indx = '" + index
              + "', @docID = '" + e.docID
              + "', @userID = '" + this.userID
              + "', @docDesc = '" + e.docDesc
              + "', @img = '" + e.docBase64.replace(this.base64Scheme, '') + "';";
          }
        }
      }
    });

    if (q) {
      this.saveStatus = this.engLang ? 'Saving record images...' : 'Grabando imágenes record...';
      this.waiting4Success = true;
      window.scroll(0, 0);
      console.log('%c' + 'query ' + q, 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, q);
    }
  }

  removeDeletedDoc(docType, indx): void {
    switch (docType) {
      case 'id1':
        this.patImg.id1 = undefined;
        this.patImg.id1Dt = undefined;
        this.patImg.id1Pkey = '0';
        break;
      case 'id2':
        this.patImg.id2 = undefined;
        this.patImg.id2Dt = undefined;
        this.patImg.id2Pkey = '0';
        break;
      case 'lic':
        this.patImg.lic = undefined;
        this.patImg.licDt = undefined;
        this.patImg.licPkey = '0';
        break;
      case 'photo':
        this.patImg.photo = undefined;
        this.patImg.photoDt = undefined;
        this.patImg.photoPkey = '0';
        break;
      case 'img':
        this.images.splice(indx, 1);
        break;
    }
  }

  showMsg(msgBoxMessage, msgBoxTitle): void {
    this._modalService.message(msgBoxMessage
      , msgBoxTitle
      , undefined
      , undefined
      , undefined
      , undefined
      , this.engLang
      , undefined
      , undefined
      , undefined
      , undefined).pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) { // response = undefined if Esc was pressed, False if closed with upper rt hand x
        }
      });
  }

  onKeyUp_imgDesc(event: any): void {
    if (event && event.keyCode == 13 && event.target.value) {
      let q = "Exec spMB_Web_Save_Imgs @sn = '" + this.sn
        + "', @indx = '0"
        + "', @docID = '" + event.target.parentElement.parentElement.firstElementChild.nextElementSibling.textContent
        + "', @userID = '" + this.userID
        + "', @docDesc = '" + this._help.escApos(event.target.value)
        + "', @patID = '" + this.pat.patID
        + "', @casID = '" + this.selectedCase[this.selectedCaseIndx].casID + "';";

      this.saveStatus = this.engLang ? 'Saving image description...' : 'Grabando descripción imagen...';
      this.waiting4Success = true;
      window.scroll(0, 0);
      console.log('%c' + 'query ' + q, 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, q);
    }
  }

  onClick_fltMnuSave(event) {
    this.saveRecord(false);
  }

  onClick_fltMnuPrint(event) {
    if (+event == 1 || +event == 2) {
      this.printCms1500(event);
    } else {
      alert('Impresion Factura PVDA - Coming Soon!  ' + event);
    }
  }

  onClick_fltMnuTxmit(event) {
    alert(event);
  }

  classExist(elemId: string, classNm: string): boolean {
    const e = document.getElementById(elemId);
    return e?.classList.contains(classNm);
  }

  dob2YrDigits(dt: string): string {
    const dtMom: any = moment(dt, 'MM/DD/YYYY', true);
    if (dtMom.isValid) {
      return dtMom.format('MM/DD/YY');
    }
    return '';
  }

  telWoAcode(tel: string): string {
    return tel ? tel.replace(/^\(\d{3}\)/g, '') : '';
  }

  recNoWoYr(recNo: string): string {
    return recNo ? recNo.replace(/\-\d{2}$/g, '') : '';
  }

  showToast(head: string, body: string, ctr: boolean, err: boolean, warn: boolean, succ: boolean, autohide: boolean): void {
    this._toastService.updateDeadCenter(ctr);
    this._toastService.show(
      body,
      {
        header: (head),
        autohide: autohide,
        warning: warn,
        success: succ,
        error: err
      }
    );
  }

  goTop() {
    if (this.modalBody) {
      const testElement = this.modalBody as unknown as HTMLElement;
      if ('scrollTop' in testElement) { // testElement.scrollTop works at run time by isolating modalBody with testElement
        testElement.scrollTop = 0;
      }
    } else {
      window.scroll(0, 0);
    }
  }

  goBottom() {
    if (this.modalBody) {
      const testElement = this.modalBody as unknown as HTMLElement; // Cast to unknown and then to HTMLElement
      if ('scrollTop' in testElement) {
        testElement.scrollTop = testElement.scrollHeight;
      }
    } else {
      window.scroll(0, document.body.scrollHeight);
    }
  }

  signCanvas(event): void {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('signCanvas');
    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = 'blue';
    this.isSigning = false;

    // const modalOffsetLeft = 300;  // Guessed but should be read from properties

    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseleave', stopDrawing);

    // For touch events
    canvas.addEventListener('touchstart', startDrawing);
    canvas.addEventListener('touchend', stopDrawing);
    canvas.addEventListener('touchmove', draw);

    function getTransformedPoint(x, y) {
      const transform = ctx.getTransform();
      const transformedX = x - transform.e;
      const transformedY = y - transform.f;
      return { x: transformedX, y: transformedY };
    }

    function startDrawing(event) {
      this.isSigning = true;
      ctx.beginPath();

      // Sum of distances for each element from left border of element to parent all the way to left border of viewport from top of canvas
      const modalOffsetLeft = event.target.offsetLeft + event.target.parentElement.parentElement.parentElement.offsetLeft;
      // Sum of distances for each element from top border of element to parent all the way to top border of viewport from top of canvas
      const modalOffsetTop = event.target.offsetTop + event.target.parentElement.offsetTop + event.target.parentElement.parentElement.parentElement.offsetTop;

      // Note, .clientX & .offsetX are absolute distances from the viewport's origin to where the click or point happened within the canvas
      const adjustedX = event.type.startsWith('touch')
        ? event.touches[0].clientX - modalOffsetLeft
        : getTransformedPoint(event.offsetX, event.offsetY).x;

      const adjustedY = event.type.startsWith('touch')
        ? event.touches[0].clientY - modalOffsetTop
        : getTransformedPoint(event.offsetX, event.offsetY).y;

      ctx.moveTo(adjustedX, adjustedY);
    }

    function stopDrawing() {
      this.isSigning = false;
      ctx.closePath();
    }

    function draw(event) {
      if (this.isSigning) {

        // Sum of distances for each element from left border of element to parent all the way to left border of viewport from top of canvas
        const modalOffsetLeft = event.target.offsetLeft + event.target.parentElement.parentElement.parentElement.offsetLeft;
        // Sum of distances for each element from top border of element to parent all the way to top border of viewport from top of canvas
        const modalOffsetTop = event.target.offsetTop + event.target.parentElement.offsetTop + event.target.parentElement.parentElement.parentElement.offsetTop;

        // Note, .clientX & .offsetX are absolute distances from the viewport's origin to where the click or point happened within the canvas
        const adjustedX = event.type.startsWith('touch')
          ? event.touches[0].clientX - modalOffsetLeft
          : getTransformedPoint(event.offsetX, event.offsetY).x;

        const adjustedY = event.type.startsWith('touch')
          ? event.touches[0].clientY - modalOffsetTop
          : getTransformedPoint(event.offsetX, event.offsetY).y;

        ctx.lineTo(adjustedX, adjustedY);
        ctx.stroke();
      }
    }
  }

  clearCanvas(): void {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('signCanvas');
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  canvasHasImg(): boolean {
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('signCanvas');
    const ctx = canvas.getContext('2d');
    ctx.getContextAttributes().willReadFrequently = true;

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < imageData.data.length; i += 1) {
      if (imageData.data[i] !== 0) {  // There's an image in there
        return true;  // Canvas has img
      }
    }
    return false; // No img in canvas
  }

  acceptSignature(): boolean {
    if (this.canvasHasImg()) {  // There's an image in there
      const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('signCanvas');
      const cas = this.selectedCase[this.selectedCaseIndx];

      if (this.tmpStr.includes('HIPAA')) {
        this.hSignB64 = this._sanitizer.bypassSecurityTrustResourceUrl(canvas.toDataURL()); // Base64 signature image
        this.pat.hSign = canvas.toDataURL().replace(this.base64Scheme, '');
        this.pat.hSignDt = moment(new Date()).format('MM/DD/YY hh:mm:ss A');

        if (!+cas.casID) {  // If a new case copy the HIPAA sign to SOF
          this.sofSignB64 = this._sanitizer.bypassSecurityTrustResourceUrl(canvas.toDataURL()); // Base64 signature image
          cas.sofSign = this.pat.hSign;
          cas.sofSignDt = moment(new Date()).format('MM/DD/YY hh:mm:ss A');
        }
      } else {
        this.sofSignB64 = this._sanitizer.bypassSecurityTrustResourceUrl(canvas.toDataURL()); // Base64 signature image
        cas.sofSign = canvas.toDataURL().replace(this.base64Scheme, '');
        cas.sofSignDt = this.pat.hSignDt;
      }
      return true;  // To close the modal
    }
    return false; // Modal stays open if no img in it
  }

  reqSignViaSms(): void {
    const tel: string = this.pat.cell.replace(/\D*/g, '');
    if (tel.match(/\d{10}/g) && this.pat.lastNm && this.pat.firstNm && this.pat.age && this.pat.sex && this.pat.zip) {
      const prov: ILocalProviders = this.localProviders.find(itm => itm.pKey == this.selectedCase[this.selectedCaseIndx].casProvID);
      const type: string = this.tmpStr.includes('HIPAA') ? 'H' : 'E';
      const msg: string = (this.engLang ? 'Dear patient:  Activate link, fill, sign and submit for your medical appointment at, ' : 'Estimado paciente:   Presione enlace, complete, firme y someta para su cita médica en, ')
        + (prov ? prov.provName : this.site.nm) + '. Info:' + this.site.tl1
        + '\n\nhttps://MedicalBiller.us/prereg/MedicalBillerSignatureCapture.html'
        + '?s=' + this.sn
        + '%26pi=' + this.pat.patID
        + '%26ci=' + this.selectedCase[this.selectedCaseIndx].casID
        + '%26it=' + this.site.tl1.replace(/\(/g, '%28').replace(/\)/g, '%29')
        + '%26ie=' + this.site.em1
        + '%26o=' + this.site.nm.replace(/ /g, '%20')
        + '%26l=' + (this.engLang ? 'en' : 'sp')
        + '%26st=' + type
        + '%26dt=' + moment(new Date()).format('MMDDYYHHmm')
        + '%26ln=' + this.pat.lastNm.replace(/ /g, '%20')
        + '%26fn=' + this.pat.firstNm.replace(/ /g, '%20')
        + '%26mi=' + this.pat.midInit.trim().replace(/ /g, '%20')
        + '%26ag=' + this.pat.age
        + '%26sx=' + this.pat.sex
        + '%26zp=' + this.pat.zip
        + '%26ui=' + this.userID

      this._twilioService.sendSMS(tel, msg);
      this.clearCanvas();
      this.noSigning = true;

      this._eventService.sn = this.sn;
      let sqlQry = "EXEC spMB_Sio_GetHSignature @sn='" + this.sn
        + "', @patID='" + this.pat.patID
        + "', @casID='" + this.selectedCase[this.selectedCaseIndx].casID
        + "', @type='" + (type == 'H' ? 'HIPAA' : 'SOF') + "';";

      this.getSignSubscrpt = this._eventService.checkForRemotelyCapturedSignatureSio(sqlQry)
        .subscribe(
          () => {
            // Handle completion or any other logic if needed
          }
        );
    } else {
      this._toastService.updateDeadCenter(false);
      this._toastService.show(
        (this.engLang ? 'Enter last/first names, birthdate, gender & zip code so the patient can verify identity when signing in his/her cell phone.'
          : 'Entre apellidos/nombre, nacimiento, sexo & zip code para que el paciente verifique su identidad al firmar en su celular.'),
        {
          header: (
            this.engLang ? `Missing information` : `Falta información.`),
          autohide: true,
          warning: true
        }
      );
    }
  }

  hl7Msh(timeStamp: string, msgType: string, msgTrigger: string): string {
    return 'MSH' + '|' + '^~\\&' + '|' + 'MedicalBiller' + '|' + this.site.nm.substring(0, 181).trim() + '||ISM4001|'
      + timeStamp + '||' + msgType + '^' + msgTrigger + '|' + timeStamp + '|' + 'P' + '|' + '2.3' + '||||NE';
  }

  hl7Pid(): string {
    let rNo = this.pat.recNo + '-' + this.pat.recLocale + '-' + this.pat.recYr;
    return "PID" + "|" + "1" + "|" + rNo.replace(/\D/g, '') + "|" + this.pat.patID + "||" + this.pat.lastNm + "^"
      + this.pat.firstNm + "^" + this.pat.midInit + "||" + this._help.fmtDate(new Date(this.pat.dob), 'ymd') + "|" + this.pat.sex + "|||"
      + this.pat.add1 + "^" + this.pat.add2 + "^" + this.pat.city + "^" + this.pat.st + "^"
      + this.pat.zip + "||" + this.pat.tel + "|" + this.pat.telWk + "||" + (this.pat.status ? this.pat.status : '') + "||" + rNo
      + "|".repeat(12);
  }

  hl7_ADT_Msg(timeStamp): string {
    let r = '\r\n';
    let cas = this.selectedCase[this.selectedCaseIndx];
    let prvNdx = this.localProviders.findIndex(e => e.pKey === cas.casProvID);
    let insNdx1 = 0;
    insNdx1 = this.localInsurances.findIndex(e => e.pKey == this.pat.insId1);
    let insNdx2 = 0;
    insNdx2 = this.localInsurances.findIndex(e => e.pKey == this.pat.insId2);

    let result = this.hl7Msh(timeStamp, 'ADT', 'A04') + r
      + "EVN" + "|" + "A01" + "|" + timeStamp + "||" + "01" + r
      + this.hl7Pid() + r
      + "PV1|1|O||||||"
    if (prvNdx >= 0) {
      result += this.localProviders[prvNdx].npi + "^" + this.localProviders[prvNdx].provName;
    }
    if (cas.casRefNPI) {
      result += "|" + cas.casRefNPI + "^" + cas.casRefLastNm + ', ' + cas.casRefFirstNm;
    }
    result += r
    result += (insNdx1 > 0 ? "IN1|1|" + this.localInsurances[insNdx1].payerId + "|" + this.pat.insId1 + "|" + this.localInsurances[insNdx1].name + "|" + '' + "|||" + this.pat.group1 + "||||||||||||||||||||||||||||" + this.pat.contr1 + "|" + r : '')
    result += (insNdx2 > 0 ? "IN1|2|" + this.localInsurances[insNdx2].payerId + "|" + this.pat.insId2 + "|" + this.localInsurances[insNdx2].name + "|" + '' + "|||" + this.pat.group2 + "||||||||||||||||||||||||||||" + this.pat.contr2 + "|" + r : '');

    return result;
  }

  hl7Send(): void {
    let host: string;
    let user: string;
    let pw: string;

    let cas = this.selectedCase[this.selectedCaseIndx];
    if (+ cas.casProvID > 0) {
      let e = this.localProviders.find(itm => { itm.pKey == cas.casProvID });
      if (e) {
        host = e.provFtpHost;
        user = e.provFtpUser;
        pw = e.provFtpPw;
      }
    } else {
      let e = this.localProviders.find(itm => itm.provFtpHost > '');
      if (e) {
        let pKey = e.pKey;
        host = this.localProviders.find(itm => itm.pKey == pKey).provFtpHost;
        user = this.localProviders.find(itm => itm.pKey == pKey).provFtpUser;
        pw = this.localProviders.find(itm => itm.pKey == pKey).provFtpPw;
      }
    }

    if (!host || !user || !pw) {
      return;
    }

    let timeStamp: string = this._help.fmtDate(new Date(), 'js').replace(/\-/g, '').replace('T', '').replace(/:/g, '').replace('Z', '');
    console.log('%c' + 'HL7 Message', 'color: black; background: yellow; font-size: 14px');
    console.log(this.hl7_ADT_Msg(timeStamp)); // Use to troubleshoot in client's browser claims of hl7 txmission not working
    let bdy = {
      host: host,
      user: user,
      password: pw,
      hl7: this.hl7_ADT_Msg(timeStamp),
      fileNm: this.sn + '_' + timeStamp.replace('.', '') + '.hl7',
    }
    this._recordService.postHl7Data2ApiServer(bdy);
  }

  x837encodeClaim(ps: string) { // ps is used to force prim/sec submission, else if undefined deduce it
    const cas: ICase = { ...this.selectedCase[this.selectedCaseIndx] };
    const rec: IPatients = { ...this.pat };
    const prov: ILocalProviders = { ...this.localProviders.find(p => { return cas.casProvID === p.pKey }) };
    const ins1: ILocalInsurances = { ...this.localInsurances.find(i => { return cas.casI1ID === i.pKey }) };
    const ins2: ILocalInsurances = { ...this.localInsurances.find(i => { return cas.casI2ID === i.pKey }) };
    const fac: ILocalFacilities = { ...this.localFacilities.find(f => { return cas.casFacID === f.pKey }) };
    const fc1: ILocalFormConfig = this.localConfig.find(fc => fc.provID === cas.casProvID && fc.insID === cas.casI1ID);
    const fc2: ILocalFormConfig = this.localConfig.find(fc => fc.provID === cas.casProvID && fc.insID === cas.casI2ID);
    let prod: ILocalProductors; // Sec productors should be the same as Prim's

    for (let i = 0; i < this.localProductors.length; i++) {
      if (+this.localProductors[i].pKey) {
        for (let r = 0; r < cas.procs1.length; r++) {
          if (+cas.procs1[r].prod.replace(/\D*/g, '') === +this.localProductors[i].pKey) {
            prod = this.localProductors[i];
            break;
          }
        }
      }
    }

    // Decide if Pro or Inst

    // Decide if primary or secondary if ps = undefined, else use ps to force it
    let sbr01: string;  //P-primary, S-Secondary
    let sbr05: string = '';
    if (!ps) {
      if (!+cas.casID) {  // New case always assume primary submission
        ps = '1';
        sbr01 = 'P' // primary - new case
      } else if (+cas.casI2ID) {
        ps = '2';
        sbr01 = 'S' // primary - new case
      } else {  // No secondary is so assume submission is primary & maybe a correction
        ps = '1';
        sbr01 = 'P' // primary - new case
      }
    } else {  // this was called with ps set tp 1 or 2 to force it
      if (ps === '1') {
        sbr01 = 'P';
      } else {
        sbr01 = 'S';
      }

      // Insurance Type Code
      if (sbr01 === 'S') {
        if (+ins2.x837fmtNo == 8) { // If Medicare Secondary Payer
          sbr05 = '12';
        }
      }
    }

    // Select FormConfig obj from table [FormConf]

    this._x12Service.x837Pro(this.sn, sbr01, this.userID, cas, rec, fc1, fc2, prov, ins1, ins2, fac, prod, this.engLang);

  }

  setS837errCnt(event) {
    this.s837errCnt = event;
  }

  scrollIntoViewBillHxSelectedCase(): void {
    if (document) {
      if (document?.querySelectorAll('table.billHx tr')) {
        const rows = document.querySelectorAll('table.billHx tr');
        if (rows.length) {
          rows.forEach(r => {
            if (r.classList.contains('selectedCase')) {
              r.scrollIntoView({ behavior: 'smooth', block: 'start' });
              return;
            }
          });
        }
      }
    }
  }

  setNewRecordNo(event: any): void {
    // When Deleteing, Updating or Creating the record no for a record it ends up here in obj event
  }

  disableReclaim(ps: string): boolean {
    return !(!this.xtPID && this.selectedCase[this.selectedCaseIndx].pays.some(p => p.payPS === 'S' + ps));
  }

  print() {
    this.printToggle = true;
    setTimeout(() => {
      this.printToggle = false;
    }, 3000); //3 sec debouncer
  }

  printDelete(event: any) {
    this.reclID = event;
  }

  ckUnckReclaimCkBox(event: any) {
    this.reclCkBxCked = event;
  }

  toggleSpinner(event: boolean) {
    // To deal with, NG0100: Expression has changed after it was checked
    // From https://v17.angular.io/errors/NG0100
    setTimeout(() => {
      this.spinner = event;
    }, 0);
  }

  setEnabSaveReclBtn(event: any) {
    this.enabSaveRecl = event;
  }

  resetDeleteToggle(event: any) {
    this.deleteToggle = event;
  }

  resetSaveToggle(event: any) {
    this.saveToggle = event;
  }

  resetIcnsPrevIdx(event) {
    this.icnsPrevIdx = -1;
  }

  onKeyup_escape(event) { // Looking for Escape to exit modal - not using keyboard: true option
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.closeReclaimsModal();
    }
  }

}