import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import Dynamsoft from 'dwt';
import { WebTwain } from 'dwt/dist/types/WebTwain';
import { DataMessageService } from "../shared/data-message.service";

@Component({
  selector: 'dwt-scan',
  templateUrl: './dwt.component.html',
  styleUrls: ['./dwt.component.css']
})
export class DwtComponent implements OnInit {
  @Output() scannedDoc: EventEmitter<string> = new EventEmitter<string>();

  DWObject: WebTwain;
  selectSources: HTMLSelectElement;
  containerId = 'dwtcontrolContainer';
  bWASM = false 
  engLang: boolean;
  scannerFound: boolean = true;

  constructor(private _dataMessageService: DataMessageService) { }

  ngOnInit(): void {
    Dynamsoft.WebTwainEnv.Containers = [{ WebTwainId: 'dwtObject', ContainerId: this.containerId, Width: '300px', Height: '400px' }];
    Dynamsoft.WebTwainEnv.RegisterEvent('OnWebTwainReady', () => { this.Dynamsoft_OnReady(); });
    Dynamsoft.WebTwainEnv.ProductKey = 'f0079xQAAAITDL3lJU2z+CyaCK9b+Bkj50keqDEaoq2VC4zlrxz/jxDQs+E8KIIbmLqhtGhlctWdiDMW0+L8Stqz+d15cML62jNA1HDkBuKEmIw==';

    Dynamsoft.WebTwainEnv.ResourcesPath = 'app/assets/dwt-resources/';
    
    // Dynamsoft.WebTwainEnv.handshakeCode = '1003863-100501389';   <=== 404 err

    let checkScript = () => {
      if (Dynamsoft.Lib.detect.scriptLoaded) {
        Dynamsoft.WebTwainEnv.Load();
      } else {
        setTimeout(() => checkScript(), 100);
      }
    };
    checkScript();

    this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang
  }
  /**
   * Dynamsoft_OnReady is called when a WebTwain instance is ready to use.
   * In this callback we do some initialization.
   */
  Dynamsoft_OnReady(): void {
    this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain(this.containerId);
    //this.bWASM = Dynamsoft.Lib.env.bMobile || !Dynamsoft.WebTwainEnv.UseLocalService;
    if (this.bWASM) {
      this.DWObject.MouseShape = true;
    } else {
      let sources = this.DWObject.GetSourceNames();
      this.selectSources = <HTMLSelectElement>document.getElementById("sources");
      this.selectSources.options.length = 0;
      for (let i = 0; i < sources.length; i++) {
        this.selectSources.options.add(new Option(<string>sources[i], i.toString()));
      }

      if (this.selectSources.options.length < 1) {
        this.scannerFound = false;
      }
    }
  }
  /**
   * Acquire images from scanners or cameras or local files
   */
  acquireImage(): void {
    if (!this.DWObject)
      this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain();
    if (this.bWASM) {
      alert("(dwt.component) Scanning is not supported under the WASM mode!");
    }
    else if (this.DWObject && this.DWObject.SourceCount > 0 && this.DWObject.SelectSourceByIndex(this.selectSources.selectedIndex)) {
      const onAcquireImageSuccess = () => { this.DWObject.CloseSource(); };
      const onAcquireImageFailure = onAcquireImageSuccess;
      this.DWObject.IfShowUI = false; // Don't show scanner's UI
      this.DWObject.OpenSource();
      this.DWObject.IfDuplexEnabled = false;  // Do only one side. Set this property after OpenSource() and before AcquireImage() .
      this.DWObject.AcquireImage({ PixelType : Dynamsoft.EnumDWT_PixelType.TWPT_RGB }, onAcquireImageSuccess, onAcquireImageFailure);
    } else {
      alert("(dwt.component) No Source Available! - ¡No se encontró scanner!");
    }
  }
  /**
   * Open local images.
   */
  openImage(): void {
    if (!this.DWObject)
      this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer');
    this.DWObject.IfShowFileDialog = true;
    /**
     * Note, this following line of code uses the PDF Rasterizer which is an extra add-on that is licensed seperately
     */
    this.DWObject.Addon.PDF.SetConvertMode(Dynamsoft.EnumDWT_ConvertMode.CM_RENDERALL);
    this.DWObject.LoadImageEx("", Dynamsoft.EnumDWT_ImageType.IT_ALL,
      () => {
        //success
      }, () => {
        //failure
      });
  }

  acceptImage(): void {
    let cnt = this.DWObject.SelectAllImages().length;
    if (cnt > 0) {
      let imgs = []
      imgs[0] = 0;  // Do only one image at a time

      this.DWObject.ConvertToBase64(imgs,
        Dynamsoft.EnumDWT_ImageType.IT_PNG,
        function (result, indices, type) {  // Cannot use 'this' in here because it is undefined
          let res = 'data:image/png;base64,' + result.getData(0, result.getLength());
          const elResult64 = <HTMLInputElement>document.getElementById('result64');
          elResult64.value = res;
          elResult64.click(); // Calls this.emitDoc() via DOM manipulation with .click() because this. is not visible here
        },
        function (errorCode, errorString) {
          alert(errorCode + ' ' + errorString);
        });

    }
  }

  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  closeScanners(): void {
    this.scannedDoc.emit('CloseScanners');  // Arbitrary string used to turn off scanLic, scanID1, etc.
  }

  emitDoc(): void {
    const elResult64 = <HTMLInputElement>document.getElementById('result64');
    if (elResult64.value) {
      this.scannedDoc.emit(elResult64.value);
      this.closeScanners();
    }
  }

}
