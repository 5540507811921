// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    font-family: arial, sans-serif;
    font-size: smaller;
    margin: 0 3em 1em 0;
    /* padding: 10px 0 10px 0; */
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.colContainer {
    margin: 0 1em 0 1em;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
}

.flex {
    padding-top: 10px;
    text-align: center;
    position: relative;
    flex-basis: auto;
}

.colFlex {
    padding: 0;
    margin: 0;
}

.border {
    border-style: solid;
}

.txt5_1 {
    font-family: 'Courier New', Courier, monospace;
    margin: auto;
    width: 100%;
    margin-bottom: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/ck-register/recl-xml-sss022309/recl-xml-sss022309.component.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;IACnB,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,8CAA8C;IAC9C,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".container {\r\n    font-family: arial, sans-serif;\r\n    font-size: smaller;\r\n    margin: 0 3em 1em 0;\r\n    /* padding: 10px 0 10px 0; */\r\n    display: flex;\r\n    flex-flow: row wrap;\r\n    justify-content: space-evenly;\r\n}\r\n\r\n.colContainer {\r\n    margin: 0 1em 0 1em;\r\n    display: flex;\r\n    flex-flow: column wrap;\r\n    align-items: flex-start;\r\n}\r\n\r\n.flex {\r\n    padding-top: 10px;\r\n    text-align: center;\r\n    position: relative;\r\n    flex-basis: auto;\r\n}\r\n\r\n.colFlex {\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.border {\r\n    border-style: solid;\r\n}\r\n\r\n.txt5_1 {\r\n    font-family: 'Courier New', Courier, monospace;\r\n    margin: auto;\r\n    width: 100%;\r\n    margin-bottom: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
