
import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
//import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

import { HelperRtnsComponent } from '../shared/helper-rtns.component';
import { ILocalInsurances } from './interfaces/localInsurances';
import { IApptType } from './interfaces/appointmentsType';
import { IApptStatus } from './interfaces/appointmentsStatus';
import { IApptLastUsedParams } from './interfaces/appointmentsLastUsedParams';

@Injectable()
export class DbLookUpService {

    constructor(private _http: HttpClient,
        private _help: HelperRtnsComponent) { }

    getCityStFromZip(zip: string, fld: string): Observable<any> {
        return this._http.get(this._help.urlDB + '/api/get-cityStateFromZip/' + zip + '/' + fld)
            .pipe(map(this.extractReturnedData));
    }

    getCustomerFromSN(sn: string): Observable<any> {
        return this._http.get(this._help.urlDB + '/api/get-customerFromSN/' + sn)
            .pipe(map(this.extractReturnedData));
    }

    extractReturnedData(res: Response) {
        let returnedData = res;
        return returnedData || {};
    }

    sendEmail(email: {}): Observable<any> {
        let body = JSON.stringify(email);
        let headers = { 'Content-Type': 'application/json' };

        return this._http.post<any>(this._help.urlDB + '/api/post-sendEmail', body, { headers })
            .pipe(map(this.extractReturnedData));
    }

}