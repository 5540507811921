import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ToastInfo {
  header: string;
  body: string;
  delay?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AppToastsService {
  toasts: ToastInfo[] = [];

  constructor() { }

  private toastShowDeadCenter = new BehaviorSubject(false); // These 2 lines create deadCenter as an observable
  deadCenter = this.toastShowDeadCenter.asObservable(); // Property that is being observed by other components

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  updateDeadCenter(bool: boolean) { // Updates observable to new value when calling this._appToastsService.updateDeadCenter(true) from originator of new value
    this.toastShowDeadCenter.next(bool);
  }

}
