
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { HelperRtnsComponent } from '../shared/helper-rtns.component';
import { IAppointmentsGroups } from '../shared/interfaces/appointmentsGroups';
import { IApptLastUsedParams } from '../shared/interfaces/appointmentsLastUsedParams';
import { IApptStatus } from '../shared/interfaces/appointmentsStatus';
import { IApptType } from '../shared/interfaces/appointmentsType';
import { ILocalInsurances } from '../shared/interfaces/localInsurances';
import { CACHEABLE } from '../shared/cache.interceptor';

@Injectable()
export class AgendaService {
    sn: string;
    proc: string;
    postHeaders: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    getHeaders: any = { headers: new HttpHeaders({ 'Accept': 'application/json' }) };

    constructor(private _http: HttpClient, private _help: HelperRtnsComponent) { }

    getAppointments1Month(appFrDt: string, appToDt: string, resource: string, getRescs: string, sn: string): Observable<any[]> {
        return this._http.get<any[]>(this._help.urlDB + '/api/get-appointments1Month/' + appFrDt + '/' + appToDt + '/' + resource + '/' + getRescs + '/' + sn,
            this.getHeaders).pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    getAppointmentsSearched(sumary: string, tel: string, rec: string, sn: string): Observable<any[]> {
        return this._http.get<any[]>(this._help.urlDB + '/api/get-appointmentsSearched/' + sumary + '/' + tel + '/' + rec + '/' + sn,
            this.getHeaders).pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    postHideAppointment(sn: string, apptID: string, val: string): Observable<any> {
        let body = JSON.stringify({ sn, apptID, val });
        return this._http.post<any>(this._help.urlDB + '/api/post-hideAppointment', body, this.postHeaders)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    getAvailableTimeSlots(start: string, end: string, duration: string, resource: string, sn: string): Observable<any[]> {
        return this._http.get<any[]>(this._help.urlDB + '/api/get-appointmentAvailableTimes/' + start + '/' + end + '/' + duration + '/' + resource + '/' + sn, this.getHeaders)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    getPatientsFoundForAppt(last: string, first: string, recNo: string, tel: string): Observable<any[]> {
        return this._http.get<any[]>(this._help.urlDB + '/api/get-patientsFoundForAppt/' + last + '/' + first + '/' + recNo + '/' + tel, this.getHeaders)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    getPatientSelected4NewAppt(patID: string): Observable<any> {
        return this._http.get<any>(this._help.urlDB + '/api/get-patient4NewAppt/' + patID, this.getHeaders)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    postInsertUpdateAppointment(pKey: string, SN: string, Summary: string, Start: string, End: string, _Location: string, Description: string, BackgroundID: string,
        StatusID: string, _PatID: string, PatLastNm: string, PatFirstNm: string, PatMidNm: string, PatSx: string, PatTelHome: string, PatTelWork: string, PatTelCell: string,
        PatDOB: string, PatEmail: string, RecNo: string, RecOffice: string, RecYr: string, InsId1: string, InsId2: string, Billed: string, Showed: string,
        RsvpSentCnt: string, RsvpSentDt: string, PatContr1: string, PatGrp1: string, PatContr2: string, PatGrp2: string, _EligibleIns1: string, _EligibleIns2: string, ResourceID: string, updtSql: string): Observable<any> {
        let body = JSON.stringify({
            pKey, SN, Summary, Start, End, _Location, Description, BackgroundID, StatusID, _PatID, PatLastNm, PatFirstNm, PatMidNm, PatSx, PatTelHome,
            PatTelWork, PatTelCell, PatDOB, PatEmail, RecNo, RecOffice, RecYr, InsId1, InsId2, Billed, Showed, RsvpSentCnt, RsvpSentDt, PatContr1, PatGrp1, PatContr2,
            PatGrp2, _EligibleIns1, _EligibleIns2, ResourceID, updtSql
        });

        return this._http.post<any>(this._help.urlDB + '/api/post-insertUpdateAppointment', body, this.postHeaders)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    deleteAppointment(pKey: string, SN: string) {
        return this._http.delete(this._help.urlDB + '/api/delete-appointment/' + pKey + '/' + SN)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    postApiErrorLog(SN: string, errObj: any, proc): Observable<any> {
        let errStr = JSON.stringify(errObj);
        let body = JSON.stringify({ SN, errStr, proc });

        return this._http.post<any>(this._help.urlDB + '/api/post-apiErrorLog', body, this.postHeaders);
    }

    postRenderJsRpt(bdy: any): Observable<any> {
        //let body = JSON.stringify({ "template": { "name": "/demographics/demographics-html" }, "data": { "lastNm": "Deniro", "firstNm": "Robert" }, "options": { "reports": { "save": true } } });
        let body = JSON.stringify(bdy);
        let unm = 'medbiler@gmail.com';
        let pw = 'XaxispjEQZ9r@w7';
        let b64 = 'Basic ' + btoa(unm + ':' + pw);
        let headers = { 'Content-Type': 'application/json', 'Accept': 'text/plain', 'Authorization': b64 };

        return this._http.post<any>('https://medbiller.jsreportonline.net/api/report', body, { headers, 'responseType': 'blob' as 'json' })
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    postAppointmentsLastUsedParams(dt, rescID, userID, sn): Observable<any> {
        let body = JSON.stringify({ dt, rescID, userID, sn });
        return this._http.post<any>(this._help.urlDB + '/api/post-appointmentsLastUsedParams', body, this.postHeaders);
    }

    getAppointmentsGroups(sn: string): Observable<IAppointmentsGroups[] | any> {
        return this._http.get<IAppointmentsGroups[]>(this._help.urlDB + '/api/get-appointmentsGroups/' + sn, this.getHeaders
        ).pipe(
            catchError(err => this.handlrHttpError(err))
        );
    }

    postAppointmentsGroups(newGrp: IAppointmentsGroups): Observable<IAppointmentsGroups> {
        return this._http.post<IAppointmentsGroups>(this._help.urlDB + '/api/post-appointmentsGroup', newGrp, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            catchError(err => this.handlrHttpError(err))
        );
    }

    deleteAppointmentsGroup(sn: string, id: string): Observable<any> {
        return this._http.delete<any>(this._help.urlDB + '/api/delete-appointmentsGroup/' + sn + '/' + id)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    postAppointmentUpdatdRecordNo(data: any) {
        return this._http.post<any>(this._help.urlDB + '/api/post-appointmentRecNoUpdate', data, this.postHeaders)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    getInsuranceLst(sn: string): Observable<ILocalInsurances[] | IApptType[] | IApptStatus[] | any> {
        return this._http.get<ILocalInsurances[] | IApptType[] | IApptStatus[]>(
            this._help.urlDB + '/api/get-InsuranceLst1/' + sn,
            {
                headers: new HttpHeaders({ 'Accept': 'application/json' }),
                context: new HttpContext().set(CACHEABLE, true)
            })
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    getLastUsedParams(sn: string, userID: string): Observable<IApptLastUsedParams | any> {
        return this._http.get<IApptLastUsedParams>(this._help.urlDB + '/api/get-apptLastUsedParams/' + sn + '/' + userID,
            this.getHeaders)
            .pipe(
                catchError(err => this.handlrHttpError(err))
            );
    }

    private handlrHttpError(error: HttpErrorResponse): Observable<any> {
        const errObj = {
            displayMsg: error.status.toString() + ' ' + error.statusText,
            msg: error.message
        }

        this.postApiErrorLog(this.sn, errObj, this.proc)
            .subscribe({
                next: (data: any) => console.log(data),
                error: (err: any) => console.error(err)
            });
        return throwError(() => errObj);
    }

    errMsgSuggestion(engLang: boolean): string {
        return engLang ? "' Try refreshing (Ctrl-F5) the page or check the Internet service."
            : ' Trate refresh (Ctrl-F5) en la página o verifique el servicio de Internet.'
    }

}