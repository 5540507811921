import { AfterContentChecked, AfterViewChecked, Component, HostListener, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

import { DataMessageService } from "../shared/data-message.service";
import { AgendaService } from './agenda.service';
import { IAppointments } from "../shared/interfaces/appointments";
import { IAppointmentsResc } from "../shared/interfaces/appointmentsResc";
import { IAppointmentsGroups } from "../shared/interfaces/appointmentsGroups";
import { HelperRtnsComponent } from '../shared/helper-rtns.component';
import { NgbActiveModal, NgbDropdownConfig, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WebsocketService } from '../shared/websocket.service';
import { PsPipe } from '../shared/ps.pipe';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { RecordComponent } from '../record/record.component';
import { ICommonElementStyles } from '../shared/interfaces/common-element-styles';
import { ActivatedRoute } from '@angular/router';
import { AppToastsService } from '../shared/app-toasts/app-toasts.service';
import { ILocalInsurances } from '../shared/interfaces/localInsurances';
import { IApptLastUsedParams } from '../shared/interfaces/appointmentsLastUsedParams';
import { HttpErr } from '../ck-register/ck-register835-detail/ck-register835-detail.service';

@Component({
    selector: 'agenda',
    templateUrl: 'agenda.component.html',
    styleUrls: ['agenda.component.css'],
    providers: [AgendaService,
        HelperRtnsComponent,
        NgbModal,
        NgbModalConfig,
        NgbActiveModal,
        PsPipe,
        RecordComponent,
        NgbDropdownConfig
    ]
})

export class AgendaComponent implements AfterViewChecked, AfterContentChecked {
    @ViewChild('apptEditModal', { read: TemplateRef, static: true }) apptEditModal: TemplateRef<any>;
    @ViewChild('apptExitWoSaveModal', { read: TemplateRef, static: true }) apptExitWoSaveModal: TemplateRef<any>;
    @ViewChild('apptDeleteModal', { read: TemplateRef, static: true }) apptDeleteModal: TemplateRef<any>;
    @ViewChild('msgBoxModal', { read: TemplateRef, static: true }) msgBoxModal: TemplateRef<any>;

    constructor(private _agendaService: AgendaService,
        private _dataMessageService: DataMessageService,
        private _help: HelperRtnsComponent,
        private _modalService: NgbModal,
        private _modalConfig: NgbModalConfig,
        private _vref: ViewContainerRef,
        private _websocketService: WebsocketService,
        private _activatedRoute: ActivatedRoute,
        private _appToastsService: AppToastsService
    ) { }

    engLang: boolean;
    sn: string;
    siteNm: string;
    userLastNm: string;
    userFirstNm: string;
    userID: string;
    currDt: Date;
    currMo: number;
    currYr: number;
    currRescID: string = '0';
    currRescStartHrMin: string;
    currRescEndHrMin: string;
    currRescForeColor: string;
    currRescBackColor: string;
    currRescDuration: string;
    currApptID: string = '0';   // Selected by click or from search modal window
    currApptIdx: number = 0;    // index of appts[] pointing to appt with currApptID
    currApptTime: string = undefined;
    displayDt: string = '';
    displayDtDesc: string = '';
    initScroll: boolean = false;
    monthKeyVal: number[] = [1, 4, 4, 0, 2, 5, 0, 3, 6, 1, 4, 6];  // jan - dec
    monthMaxDys: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];   // max number of days in a month
    calWeek: number[] = [0, 1, 2, 3, 4, 5];  // weeks (or rows) in calendar
    calDay: number[][] = [  // holds cal day numbers, calDay[w][d] where w=week/row, d=mon - sun
        [0, 0],
        [1, 0],
        [2, 0],
        [3, 0],
        [4, 0],
        [5, 0]
    ];
    dayBold: any[][] = [
        [0, true],
        [1, true],
        [2, true],
        [3, true],
        [4, true],
        [5, true]
    ];
    daySelected: any[][] = [
        [0, false],
        [1, false],
        [2, false],
        [3, false],
        [4, false],
        [5, false]
    ];
    dayTotals: any[][] = [
        ['', ''],
        ['', ''],
        ['', ''],
        ['', ''],
        ['', ''],
        ['', '']
    ];
    holyDay: number[][];
    engMonth: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    spaMonth: string[] = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    engDay: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    spaDay: string[] = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
    monthTblOn: boolean = false;
    appt: IAppointments = {
        pKey: undefined,                   // ID
        patID: undefined,                  // PatID
        d: undefined,
        h: undefined,
        m: undefined,
        start: undefined,                  // Start
        end: undefined,                    // End
        duration: undefined,
        conflict: false,
        summary: undefined,                // Summary
        complaint: undefined,              // Location - use _Location because Location is js reserved
        hxIllness: undefined,              // Description
        recNo: undefined,                  // RecNo
        recOffice: undefined,              // RecOffice
        recYr: undefined,                  // RecYr
        sex: undefined,                    // PatSx
        age: undefined,
        typeId: undefined,                  // BackgroundID
        type: undefined,
        typeColor: undefined,
        typeHatch: undefined,
        statusId: undefined,               // StatusID
        status: undefined,
        statusColor: undefined,
        statusHatch: false,
        confirm: false,
        eligib: false,
        selected: false,
        hidden: false,
        patLastNm: undefined,              // PatLastNm
        patFirstNm: undefined,             // PatFirstNm
        patMidNm: undefined,               // PatMidNm
        patTelHome: undefined,             // PatTelHome
        patTelWork: undefined,             // PatTelWork
        patTelCell: undefined,             // PatTelCell
        patDOB: undefined,                 // PatDOB
        patEmail: undefined,               // PatEmail
        insId1: undefined,                 // InsId1
        insAlias1: undefined,              // InsAlias1
        insId2: undefined,                 // InsId2
        insAlias2: undefined,              // InsAlias2
        billed: undefined,                 // Billed
        showed: undefined,                 // Showed
        rsvpSentCnt: undefined,            // RsvpSentCnt
        rsvpSentDt: undefined,             // RsvpSentDt
        patContr1: undefined,              // PatContr1
        patGrp1: undefined,                // PatGrp1
        patContr2: undefined,              // PatContr2
        patGrp2: undefined,                // PatGrp2
        eligibleIns1: undefined,           // EligibleIns1
        eligibleIns2: undefined,           // EligibleIns2
        resourceID: undefined              // [Resources].[ID]
    };
    appt2: IAppointments = {           // copy of original appointment read to compare and detect individual field edits
        pKey: undefined,                   // ID
        patID: undefined,                  // PatID
        d: undefined,
        h: undefined,
        m: undefined,
        start: undefined,                  // Start
        end: undefined,                    // End
        duration: undefined,
        conflict: false,
        summary: undefined,                // Summary
        complaint: undefined,              // Location - use _Location because Location is js reserved
        hxIllness: undefined,              // Description
        recNo: undefined,                  // RecNo
        recOffice: undefined,              // RecOffice
        recYr: undefined,                  // RecYr
        sex: undefined,                    // PatSx
        age: undefined,
        typeId: undefined,                  // BackgroundID
        type: undefined,
        typeColor: undefined,
        typeHatch: false,
        statusId: undefined,               // StatusID
        status: undefined,
        statusColor: undefined,
        statusHatch: false,
        confirm: false,
        eligib: false,
        selected: false,
        hidden: false,
        patLastNm: undefined,              // PatLastNm
        patFirstNm: undefined,             // PatFirstNm
        patMidNm: undefined,               // PatMidNm
        patTelHome: undefined,             // PatTelHome
        patTelWork: undefined,             // PatTelWork
        patTelCell: undefined,             // PatTelCell
        patDOB: undefined,                 // PatDOB
        patEmail: undefined,               // PatEmail
        insId1: undefined,                 // InsId1
        insAlias1: undefined,              // InsAlias1
        insId2: undefined,                 // InsId2
        insAlias2: undefined,              // InsAlias2
        billed: undefined,                 // Billed
        showed: undefined,                 // Showed
        rsvpSentCnt: undefined,            // RsvpSentCnt
        rsvpSentDt: undefined,             // RsvpSentDt
        patContr1: undefined,              // PatContr1
        patGrp1: undefined,                // PatGrp1
        patContr2: undefined,              // PatContr2
        patGrp2: undefined,                // PatGrp2
        eligibleIns1: undefined,           // EligibleIns1
        eligibleIns2: undefined,           // EligibleIns2
        resourceID: undefined              // [Resources].[ID]
    };

    appts: IAppointments[];
    apptsRescs: IAppointmentsResc[];
    apptsSearched: string[];
    apptsGroups: IAppointmentsGroups[] = [];
    clock: string;
    selectedDayFilter: string = undefined;  // used as argument for agendaPipe
    showHiddenFilter: boolean = false;      // used as argument for agendaPipe, false returns only waiting appointments
    waitingApptsCnt: number = 0;
    finishedApptsCnt: number = 0;
    waiting4Response: boolean = false;  // for visual feedback of ajax calls
    waiting4ResponseSrch: boolean = false;  // for visual feedback of ajax calls while in search mode
    waiting4ResponseNewEditAppt: boolean = false;  // for visual feedback of ajax calls while in adding or editing an appointment
    byPassWaiting4ResponseNewEditAppt: boolean = false; // use to not toggle this.waiting4ResponseNewEditAppt when selecting a date in jquery directive in New/Edit Modal
    apptSavedOk: boolean = false;   // to show a succesfull appt insert/update
    apptDtpSelected: string = this._help.fmtDate(new Date, 'yyyy-mm-dd'); // date pre-selection for jQueryDatePicker; do not manipulate when DTP is opened
    apptDurationMins: string[] = ['5', '6', '10', '12', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'];
    selectedTime: string;
    selectedDuration: string;
    selectedResourceID: string;
    selectedResourceColor: string;
    availableTimes: string[];
    patientsFound4Appt: any[];
    insuranceLstSelected: ILocalInsurances[] = [];
    ps: string; // will be set to '1' or '2' when selecting insurance alias
    labelDefaultClassForegColr: string = this.setColorAttr('#999');
    patLastNmErr: boolean = false;
    patFirstNmErr: boolean = false;
    patDOBErr: boolean = false;
    patSexErr: boolean = false;
    patEmailErr: boolean = false;
    patTelCellErr: boolean = false;
    patTelWorkErr: boolean = false;
    patTelHomeErr: boolean = false;
    insId1Err: boolean = false;
    insAlias1Err: boolean = false;
    insId2Err: boolean = false;
    insAlias2Err: boolean = false;
    durationErr: boolean = false;
    resourceErr: boolean = false;
    timeErr: boolean = false;
    sio: boolean = false;   // true when len(sn) = 4 using socket io in hybrid mode & false when using all web api access.
    tmpVar: any;
    modalStack: string[] = [];
    modalRef: NgbModalRef;
    sioSubscr: Subscription;
    lastUsedParam: IApptLastUsedParams;
    stylesCfg: ICommonElementStyles;
    currentPg: number = 1;
    itemsPerPage: number = 8;
    currentSrchResultsPg: number = 1;
    editGroup = false;
    selectedGrpIndex: number = undefined;  // this.selectedGrpIndex = unselected
    selectedGrpClass: string = 'selectedGrp';
    grpIndexErr: number = 999;   // 999 = no errored index
    grpFilter: boolean = false;
    grpStartTm: string;
    grpEndTm: string;
    viewportWidth: number;

    @HostListener('window:resize', ['$event'])  // window:resize event handler
    onResize(event: Event): void {
        this.setPagingItemsPerPage();
    }

    setPagingItemsPerPage(): void {
        let currVal = this.itemsPerPage;
        this.viewportWidth = window.innerWidth;
        if (this.viewportWidth < 992) {
            this.itemsPerPage = 200;
        } else {
            this.itemsPerPage = 6;
        }
        if (currVal != this.itemsPerPage) {
            this.currentPg = 1;
        }
    }

    ngOnInit(): void {
        this._modalConfig.backdrop = 'static';  // Default for all ngbModals
        this._modalConfig.keyboard = true;

        this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang
        this._dataMessageService.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
        this._dataMessageService.currentSiteStr.subscribe(siteStr => this.siteChange(siteStr)); // Subscription looking for changes in siteName
        this._dataMessageService.currentUserIdStr.subscribe(userIdStr => this.userIdChange(userIdStr)); // Subscription looking for changes in userIdStr
        this._dataMessageService.currentUserLastNmStr.subscribe(userLastNmStr => this.userLastNmChange(userLastNmStr)); // Subscription looking for changes in userLastNmStr
        this._dataMessageService.currentUserFirstNmStr.subscribe(userFirstNmStr => this.userFirstNmChange(userFirstNmStr)); // Subscription looking for changes in userLastNmStr

        // const routeParams = this._activatedRoute.snapshot.paramMap;  // for using parameters in route
        this.stylesCfg = JSON.parse(this._activatedRoute.snapshot.params.stylesCfgJson);

        this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
        // The following might not get the values on time for the view
        this._agendaService.getLastUsedParams(this.sn, this.userID)
            .subscribe({
                next: (data: IApptLastUsedParams) => {
                    this.lastUsedParam = data[0].recordsetApptLastUsedParams[0];
                },
                error: (err: any) => {
                    this._appToastsService.updateDeadCenter(false);
                    this._appToastsService.show(
                        (this.engLang ? "Failed to load Agenda's last used params (resource & date)."
                            : 'No se cargaron los últimos párametros (recurso y fecha) seleccionados en la Agenda.')
                        + this._agendaService.errMsgSuggestion(this.engLang),
                        {
                            header: (
                                err.displayMsg
                            ), autohide: false, error: true
                        }
                    );
                }
            });

        this.sio = (this.sn.length === 4);
        this.ckAndLoadInsuranceLst();
        this.currDt = new Date();
        this.setCalendar(new Date(this.currDt.getFullYear(), this.currDt.getMonth(), 1), this.currDt.getDate());   // set with 1st day of month
        this.startClock();
        setInterval(() => { this.startClock(); }, 30000);
        this.monthTblOn = false;

        this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
        this._agendaService.getAppointmentsGroups(this.sn)
            .subscribe({
                next: (data: IAppointmentsGroups[]) => this.apptsGroups = data,
                error: (err: any) => {
                    this._appToastsService.updateDeadCenter(false);
                    this._appToastsService.show(
                        (this.engLang ? 'Failed to load Groups.' : 'No se cargaron los Grupos.'
                            + this._agendaService.errMsgSuggestion(this.engLang)),
                        { header: err.displayMsg, autohide: false, error: true }
                    );
                }
            });

        if (this.sio) {
            this.sioSubscr = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning events

                console.log('%c' + 'dataSet in AgendaComponent.ngOnInit()', 'color: green; background: yellow; font-size: 12px');
                console.log('dataSet', dataSet);

                this.waiting4ResponseNewEditAppt = false;
                this.patientsFound4Appt = [];
                this.patientsFound4Appt.pop;
                for (let p = 0; p < dataSet.length; p++) {
                    this.patientsFound4Appt.push({
                        'fullNm': dataSet[p].fullNm, 'patID': dataSet[p].patID, 'recNo': dataSet[p].recNo, 'recOffice': dataSet[p].recOffice, 'recYr': dataSet[p].recYr, 'dob': dataSet[p].dob
                        , 'lastNm': dataSet[p].lastNm, 'firstNm': dataSet[p].firstNm, 'midIn': dataSet[p].midIn, 'sex': dataSet[p].sex
                        , 'insId1': dataSet[p].insId1, 'insAlias1': dataSet[p].insAlias1, 'patContr1': dataSet[p].patContr1, 'patGrp1': dataSet[p].patGrp1
                        , 'insId2': dataSet[p].insId2, 'insAlias2': dataSet[p].insAlias2, 'patContr2': dataSet[p].patContr2, 'patGrp2': dataSet[p].patGrp2
                        , 'tel': dataSet[p].tel, 'patTelCell': dataSet[p].patTelCell, 'patTelHome': dataSet[p].tel, 'patTelWk': dataSet[p].patTelWk, 'patEmail': dataSet[p].patEmail
                    });
                }
            });
        }

        this.setPagingItemsPerPage();   // Initial setting of itemsPerPage

    }

    ngOnDestroy(): void {
        this._agendaService.postAppointmentsLastUsedParams(this.displayDt, this.currRescID, this.userID, this.sn)
            .subscribe({
                next: data => console.log('postAppointmentsLastUsedParams() saved!'),
                error: error => console.error(error)
            });

        this.sioSubscr.unsubscribe();
        window.removeEventListener('resize', this.onResize);
        console.log('%c' + 'UNSUSCRIBE-AgendaComponent', 'color: white; background: black; font-size: 10px');
    }

    ngAfterContentChecked(): void {
    }

    ngAfterViewChecked(): void {
        this._vref.createEmbeddedView(this.apptEditModal);
        this._vref.remove();  // Removes the initially appended template
        this._vref.createEmbeddedView(this.apptExitWoSaveModal);
        this._vref.remove();  // Removes the initially appended template
        this._vref.createEmbeddedView(this.apptDeleteModal);
        this._vref.remove();  // Removes the initially appended template
        this._vref.createEmbeddedView(this.msgBoxModal);
        this._vref.remove();  // Removes the initially appended template    

        this._agendaService.sn = this.sn;   // To be used by _agendaService in error logging
    }

    setCalendar(dt: Date, sDayNo: number = -1): void {   // dt = date to display the calendar, sDayNo = selected day number when > 0
        let fstDyOfWk: number = this.dayOfWeekIndex(dt);
        let maxDy: number = this.maxDaysInMonth(dt.getFullYear(), dt.getMonth());
        let dyCnt: number = 1;
        let sDayWk: number = -1;    // selected day week
        let sDayDy: number = -1;    // selected day number
        if (sDayNo > 0) {
            if (sDayNo > this.monthMaxDys[dt.getMonth()]) {
                sDayNo = this.monthMaxDys[dt.getMonth()];
            }
        }
        for (let w = 0; w < 6; w++) {
            for (let d = 0; d < 7; d++) {
                if (w == 0) {
                    if (fstDyOfWk == 2 && d == 0) {
                        for (let n = 0; n < 7; n++) {   // initialize first week (w=0) so that last month's last week can be populated later on
                            this.calDay[w][d + n] = 0;
                            this.dayBold[w][d + n] = false;
                        }
                        w += 1; // bump to 2nd week (w=1) when monday coincides with dyCnt=1 so as to show partially las month's last week

                        this.calDay[w][d] = dyCnt;  // now we start on monday 2nd week (w=1) with the current month instead of first week (w=0) & not showing last month's last week
                        this.dayBold[w][d] = true;
                        if (dyCnt == sDayNo) {
                            sDayWk = w; sDayDy = d;
                        }
                        dyCnt += 1;
                    } else if (fstDyOfWk == 3 && d == 1) {
                        this.calDay[w][d] = dyCnt;
                        this.dayBold[w][d] = true;
                        if (dyCnt == sDayNo) {
                            sDayWk = w; sDayDy = d;
                        }
                        dyCnt += 1;
                    } else if (fstDyOfWk == 4 && d == 2) {
                        this.calDay[w][d] = dyCnt;
                        this.dayBold[w][d] = true;
                        if (dyCnt == sDayNo) {
                            sDayWk = w; sDayDy = d;
                        }
                        dyCnt += 1;
                    } else if (fstDyOfWk == 5 && d == 3) {
                        this.calDay[w][d] = dyCnt;
                        this.dayBold[w][d] = true;
                        if (dyCnt == sDayNo) {
                            sDayWk = w; sDayDy = d;
                        }
                        dyCnt += 1;
                    } else if (fstDyOfWk == 6 && d == 4) {
                        this.calDay[w][d] = dyCnt;
                        this.dayBold[w][d] = true;
                        if (dyCnt == sDayNo) {
                            sDayWk = w; sDayDy = d;
                        }
                        dyCnt += 1;
                    } else if (fstDyOfWk == 0 && d == 5) {
                        this.calDay[w][d] = dyCnt;
                        this.dayBold[w][d] = true;
                        if (dyCnt == sDayNo) {
                            sDayWk = w; sDayDy = d;
                        }
                        dyCnt += 1;
                    } else if (fstDyOfWk == 1 && d == 6) {
                        this.calDay[w][d] = dyCnt;
                        this.dayBold[w][d] = true;
                        if (dyCnt == sDayNo) {
                            sDayWk = w; sDayDy = d;
                        }
                        dyCnt += 1;
                    } else {
                        if (dyCnt > 1) {
                            this.calDay[w][d] = dyCnt;
                            this.dayBold[w][d] = true;
                            if (dyCnt == sDayNo) {
                                sDayWk = w; sDayDy = d;
                            }
                            dyCnt += 1;
                        } else {
                            this.calDay[w][d] = 0;
                            this.dayBold[w][d] = false;
                        }
                    }
                } else {
                    if (dyCnt <= maxDy) {
                        this.calDay[w][d] = dyCnt;
                        this.dayBold[w][d] = true;
                        if (dyCnt == sDayNo) {
                            sDayWk = w; sDayDy = d;
                        }
                        dyCnt += 1;
                    } else {
                        this.calDay[w][d] = 0;
                        this.dayBold[w][d] = false;
                    }
                }
                //console.log(d + ' - ' + this.calDay[w][d]);
                //console.log(d + ' - ' + this.dayBold[w][d]);
            }
            this.clearSelectedDay();
        }

        let prevDt = dt;
        let m = prevDt.getMonth();
        if (m == 0) {
            maxDy = this.maxDaysInMonth(prevDt.getFullYear() - 1, 11);
        } else {
            maxDy = this.maxDaysInMonth(prevDt.getFullYear(), m - 1);
        }
        for (let d = 6; d >= 0; d--) {
            if (this.calDay[0][d] == 0) {
                this.calDay[0][d] = maxDy;
                maxDy -= 1;
            }
        }

        maxDy = 1;
        for (let w = 4; w < 6; w++) {
            for (let d = 0; d < 7; d++) {
                if (this.calDay[w][d] == 0) {
                    this.calDay[w][d] = maxDy;
                    maxDy += 1;
                }
            }
        }

        this.currDt = dt;
        this.currMo = this.currDt.getMonth();
        this.currYr = this.currDt.getFullYear();

        if (sDayWk > -1 && sDayDy > -1) {   // if sDayNo was specified then preselect the date
            this.selectDay(sDayWk, sDayDy);
        }

        this.getAppointments1Month();
    }

    getAppointments1Month(): void {
        this.waiting4Response = true;
        let m: string = (1 + this.currDt.getMonth()).toString();
        let d: string = this.currDt.getDate().toString();
        let appFrDt: string = this.currDt.getFullYear().toString() + '-' + '01'.substring(0, 2 - m.length) + m + '-' + '01' + ' 00:00:00.000';
        d = this.maxDaysInMonth(this.currDt.getFullYear(), this.currDt.getMonth()).toString();
        let appToDt: string = this.currDt.getFullYear().toString() + '-' + '00'.substring(0, 2 - m.length) + m + '-' + '00'.substring(0, 2 - d.length) + d + ' 23:59:59.999';
        // console.log(appFrDt);
        // console.log(appToDt);
        this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
        this._agendaService.getAppointments1Month(appFrDt, appToDt, this.currRescID, (this.apptsRescs == undefined).toString(), this.sn)
            .subscribe({
                next: data => this.backFromGetAppointments1Month(data),
                error: (err: any) => {
                    this._appToastsService.updateDeadCenter(false);
                    this._appToastsService.show(
                        (this.engLang ? 'Error receiving appointments!' : '¡Error recibiendo citas!')
                        + this._agendaService.errMsgSuggestion(this.engLang),
                        {
                            header: (
                                err.displayMsg
                            ), autohide: false, error: true
                        }
                    );
                }
            });
    }

    backFromGetAppointments1Month(returnedData: any): void {
        // console.log(returnedData);
        // console.dir(returnedData);
        this.waiting4Response = false;
        var setTotal: boolean = false;  // turns true while iterating over the active month
        for (var w = 0; w < 6; w++) {
            for (var d = 0; d < 7; d++) {
                this.dayTotals[w][d] = '';  // clear

                if (!setTotal && this.calDay[w][d] == 1) {
                    setTotal = true;
                } else if (setTotal && this.calDay[w][d] == 1) {
                    setTotal = false;
                    //break;  // don't return to keep clearing this.dayTotals[w][d] = ''
                }

                if (setTotal) {
                    for (var i = 0; i < returnedData[0].recordsetTots.length; i++) {
                        if (returnedData[0].recordsetTots[i].d == this.calDay[w][d]) {
                            this.dayTotals[w][d] = returnedData[0].recordsetTots[i].t;
                        }
                    }
                }
            }
        }

        for (var i = 0; i < returnedData[1].recordsetDet.length; i++) { // convert Sql bit to boolean
            returnedData[1].recordsetDet[i].confirm == '1' ? returnedData[1].recordsetDet[i].confirm = true : returnedData[1].recordsetDet[i].confirm = false;
            returnedData[1].recordsetDet[i].eligib == '1' ? returnedData[1].recordsetDet[i].eligib = true : returnedData[1].recordsetDet[i].eligib = false;
            returnedData[1].recordsetDet[i].selected == '1' ? returnedData[1].recordsetDet[i].selected = true : returnedData[1].recordsetDet[i].selected = false;
            returnedData[1].recordsetDet[i].hidden == '1' ? returnedData[1].recordsetDet[i].hidden = true : returnedData[1].recordsetDet[i].hidden = false;

            if (returnedData[1].recordsetDet[i].pKey == this.currApptID) {
                returnedData[1].recordsetDet[i].selected = true;
                this.initScroll = true; // Bring into view if needed
            } else {
                returnedData[1].recordsetDet[i].selected = false;
            }
        }

        this.appts = returnedData[1].recordsetDet || {};
        for (let i = 0; i < this.appts.length; i++) {
            if (this.appts[i].patDOB) {
                this.appts[i].age = this._help.calcAge(this.appts[i].patDOB);
            }
            this.appts[i].conflict = this.setConflict(this.appts[i]);
        }

        // Get insurance aliases
        for (var i = 0; i < this.appts.length; i++) {
            if (this._help.insuranceLst.length > 0) {
                var insObj = this._help.insuranceLst.filter(obj => {
                    return obj.pKey == this.appts[i].insId1;
                });
                insObj && insObj.length > 0 ? this.appts[i].insAlias1 = insObj[0].alias : this.appts[i].insAlias1 = undefined;

                var insObj = this._help.insuranceLst.filter(obj => {
                    return obj.pKey == this.appts[i].insId2;
                });
                insObj && insObj.length > 0 ? this.appts[i].insAlias2 = insObj[0].alias : this.appts[i].insAlias2 = undefined;
            }
        }

        // Get resources
        if (returnedData[2].recordsetResc) {
            this.apptsRescs = returnedData[2].recordsetResc;

            if (this.apptsRescs?.length == 1) {
                this.currRescID = this.apptsRescs[0].pKey;  // Only one resource
            } else if (this.lastUsedParam?.rescID && this.apptsRescs.find(r => r.pKey == this.lastUsedParam.rescID)) {  // last used resource
                this.currRescID = this.lastUsedParam.rescID;
            }

            const pdt: any = moment(this.lastUsedParam?.dt, 'MM/DD/YYYY');
            if (pdt.isValid()) {
                this.currDt = pdt.toDate();
            } else {
                const cdt: any = moment(this.currDt, 'MM/DD/YYYY');
                if (!cdt.isValid()) {
                    this.currDt = new Date();
                }
            }
            this.setCalendar(this.currDt, this.currDt.getDate());
            this.selectResource(undefined, this.currRescID);
        }
        this.finishedVsWaitingApptsTotal();
        this.scrollToAppt();
    }

    dayOfWeekIndex(dt: Date): number {  // using Key value from http://mathforum.org/dr.math/faq/faq.calendar.html
        let y: number = parseInt(dt.getFullYear().toString().substring(2, 5)); // Take the last 2 digits of the year.
        //console.log('y=' + y);
        y = parseInt((y / 4).toString());   // Divide by 4, and drop any remainder.
        //console.log('y/4=' + y);
        y = y + 1   // always look for first day - dt.getDate();    // Add the day of the month.    1 - 31
        //console.log('y + dt.getDate()=' + y);
        y = y + this.monthKeyVal[dt.getMonth()];    // Add the month's key value, from the following table. dt.getMonth() = 0 - 11
        //console.log("y + this.monthKeyVal[dt.getMonth()]=" + y);
        if (dt.getMonth() < 2 && this.isLeapYear(dt.getFullYear())) { // If your date is in January or February of a leap year, subtract 1.
            y = y - 1;
        }
        y = y + 6;  // Add the century code from the following table.   2000s = 6
        y = y + parseInt(dt.getFullYear().toString().substring(2, 5));  // Add the last two digits of the year.
        y = y % 7;  // Divide by 7 and take the remainder.  0 = Saturday, 1 = Sunday, 2 = Monday, 3 = Tuesday...
        return y;
    }

    isLeapYear(year: number): boolean {
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

    maxDaysInMonth(yr: number, mo: number): number {
        // var d = new Date(yr, mo + 1, 0);
        // console.log('maxDy=' + d.getDate() + ' yr=' + yr + ' mo=' + mo);
        return this.monthMaxDys[mo] + ((this.isLeapYear(yr) && mo == 1) ? 1 : 0);
    }

    nextPrevMonth(n: number): void {
        var y = this.currDt.getFullYear();
        var m = this.currDt.getMonth(); // 0 - 11
        var d = isNaN(parseInt(this.selectedDayFilter)) ? -1 : parseInt(this.selectedDayFilter); // if -1 no day will be preselected in new calendar month
        if (n > 0) {   // n positive to move forward
            if (m > 11 - n) {
                m = m + n - 12;
                y += 1;
            } else {
                m += n;
            }
        } else if (n < 0) { // n negative to move backwards
            if (m < Math.abs(n)) {
                m = m - Math.abs(n) + 12;
                y -= 1;
            } else {
                m += n;
            }
        } else {    // n cannot be zero
            alert("agenda.component.ts : function nextPrevMonth(n) - n cannot be zero.");
        }

        var mxd = this.maxDaysInMonth(y, m);
        if (d > mxd) {
            d = mxd;
        }
        this.currDt = new Date(y, m, d);
        this.setCalendar(this.currDt, d);
    }

    selectDay(week: number, idx: number): void {
        let oldDay: number = this.calDay[week][idx];
        this.clearSelectedDay();
        if (week == 0 && !this.dayBold[week][idx]) {
            if (this.currYr == 0) {
                this.currYr -= 1;
            }
            let dt: Date = new Date(this.currYr, this.currMo - 1, this.calDay[week][idx]);
            //console.log("dt=" + dt);
            this.setCalendar(dt);
            week = this.repoDayOnNewMonth(oldDay)[0];
            idx = this.repoDayOnNewMonth(oldDay)[1];
        } else if (week >= 4 && !this.dayBold[week][idx]) {
            if (this.currMo == 11) {
                this.currMo = 0;
                this.currYr += 1;
            } else {
                this.currMo += 1;
            }
            let dt: Date = new Date(this.currYr, this.currMo, this.calDay[week][idx]);
            //console.log("dt=" + dt);
            this.setCalendar(dt);
            week = this.repoDayOnNewMonth(oldDay)[0];
            idx = this.repoDayOnNewMonth(oldDay)[1];
        }
        this.daySelected[week][idx] = true;
        this.selectedDayFilter = this.calDay[week][idx].toString();
        this.displayDt = 1 + this.currMo + '-' + this.selectedDayFilter + '-' + this.currYr.toString().match(/\d{2}$/g);
        let lang = this.engLang ? 'en' : 'es';
        this.displayDtDesc = moment(this.displayDt, 'M-D-YY').locale(lang).format('MMM D-YY');
        this.apptDtpSelected = moment(this.displayDt, 'M-D-YY').format('YYYY-MM-DD');
        this.finishedVsWaitingApptsTotal();
        this.currentPg = 1;
        this.itemsPerPage = 6;
    }

    clearSelectedDay(): void {
        for (let w = 0; w < 6; w++) {
            for (let d = 0; d < 7; d++) {
                this.daySelected[w][d] = false;
            }
        }
    }

    repoDayOnNewMonth(oldDay: number): number[] {
        for (var w = 0; w < 7; w++) {
            for (var d = 0; d < 7; d++) {
                if (this.calDay[w][d] == oldDay && this.dayBold[w][d]) {
                    return [w, d];
                }
            }
        }
        this.selectedDayFilter = undefined;
    }

    showCalYrMonths(): void {
        this.monthTblOn = true;
    }

    selectMonthYear(month: string): void {  // month has 3 letter month abbreviation
        const mo: number = this.spaMonth.findIndex(function (m) { return m == month.trim() }) > -1 ?
            this.spaMonth.findIndex(function (m) { return m == month.trim() }) :
            this.engMonth.findIndex(function (m) { return m == month.trim() });  // try a match in spanish months 1st else use english months

        this.setCalendar(new Date(this.currYr, mo, 1), isNaN(parseInt(this.selectedDayFilter)) ? -1 : parseInt(this.selectedDayFilter));
        this.monthTblOn = false;
    }

    setColorAttr(color: string): string {
        if (color) {
            var colr: string = color.replace('#', '');
            if (colr.length == 8) {
                return 'rgba(' + parseInt(colr.substring(2, 4), 16) + ',' + parseInt(colr.substring(4, 6), 16) + ',' + parseInt(colr.substring(6, 8), 16) + ',' + parseInt(colr.substring(0, 2), 16) + ')';  // #RRGGBBAA format where AA is opacity
            } else {
                return '#' + colr; // hex format without alpha channel for opacity
            }
        }
        // return '#ffffff';   // just return white if color = undefined or empty
    }

    startClock(): void {
        var today = new Date();
        var mo = today.getMonth() + 1;
        var d = today.getDate();
        var y = today.getFullYear();
        var h = today.getHours();
        var m = today.getMinutes();
        //var s = today.getSeconds();   //not used
        this.clock = mo + '-' + d + '-' + y + ' ' + (h > 12 ? h - 12 : h) + ":" + (m < 10 ? '0' + m : m) + (h > 12 ? ' pm' : ' am');
        //console.log(this.clock);
    }

    selectResource($event: Event, id: string): void {
        if ($event) {
            $event.stopPropagation();   // must do since when physically clicking the image this would be called twice, 1 for the image + 1 for td - not needed when programmatically calling selectResource(...)
        }
        id = id.trim();
        //console.log(id);
        this.currRescID = '0';    // no resource selected
        for (var i = 0; i < this.apptsRescs.length; i++) {
            this.apptsRescs[i].selected = false;   // clear previously selected
            if (id == this.apptsRescs[i].pKey) {    // re-select if clicked
                this.apptsRescs[i].selected = true;
                this.currRescID = id;
                this.currRescStartHrMin = this.apptsRescs[i].startHrMi;
                this.currRescEndHrMin = this.apptsRescs[i].endHrMi;
                this.currRescDuration = this.apptsRescs[i].duration;
                this.currRescForeColor = (this.apptsRescs[i].color ? this.getBestContrast(this.apptsRescs[i].color) : 'black');
                this.currRescBackColor = (this.apptsRescs[i].color ? this.apptsRescs[i].color : 'white');
                this.selectedDuration = this.apptsRescs[i].duration;
                this.selectedResourceID = this.currRescID;
                this.selectedResourceColor = this.apptsRescs[i].color;
            }
        }
        this.setCalendar(this.currDt, parseInt(this.selectedDayFilter));
        this.currentPg = 1;
        this.itemsPerPage = 6;
    }

    selectAppointment(appt: any): void {
        this.currApptID = '0';
        this.appts?.forEach(a => {
            if (a.pKey == appt.pKey) {
                a.selected = true;
                this.currApptID = appt.pKey;
                this.currApptIdx = this.appts.findIndex(appt => appt.pKey == this.currApptID);
            } else {
                a.selected = false;
            }
        });
    }

    scrollToAppt(): void {
        if (+this.currApptID > 0 && this.initScroll) {
            this.itemsPerPage = 200;    // Trick to disable paging so that the scrollIntoView works
            this.currentPg = 1;
            let selectedRow = document.getElementById(this.currApptID);
            if (selectedRow) {
                selectedRow.scrollIntoView({ block: 'nearest', behavior: 'auto' });
                this.initScroll = false;    // Don't keep rescrolling to it
            }
        }
    }

    resourceImageBase64(base64String: string): string {
        return 'data:image/png;base64,' + base64String;
    }

    hideAppointment(appt: IAppointments): void {
        let val;
        if (appt.hidden == true) {
            appt.hidden = false;
            val = '0';
        } else {
            appt.hidden = true;
            val = '1';
        }
        this.finishedVsWaitingApptsTotal();
        this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
        this._agendaService.postHideAppointment(this.sn, appt.pKey, val)
            .subscribe({
                next: data => data,
                error: (err: any) => {
                    this._appToastsService.updateDeadCenter(false);
                    this._appToastsService.show(
                        (this.engLang ? 'Error hiding/unhiding appointment!' : '¡Error escondiendo/mostrando cita!')
                        + this._agendaService.errMsgSuggestion(this.engLang),
                        {
                            header: (
                                err.displayMsg
                            ), autohide: false, error: true
                        }
                    );
                }
            });
    }

    finishedVsWaitingApptsTotal(): void {   // following gets hidden vs. shown counts
        if (!this.selectedDayFilter || !this.appts) {
            this.finishedApptsCnt = 0;
            this.waitingApptsCnt = 0;
            return;
        }
        var d = this.selectedDayFilter;
        this.finishedApptsCnt = this.appts.reduce(function (acc: number, appt: IAppointments): number {
            if (appt.hidden == true && appt.d == d) {
                acc++;
            }
            return acc; // count accumulator - hidden appointments in this.selectedDayFilter day
        }, 0)

        this.waitingApptsCnt = this.appts.reduce(function (acc: number, appt: IAppointments): number {
            if (appt.d == d) {
                acc++;  // count accumulator - total appointments in this.selectedDayFilter day
            }
            return acc;
        }, 0) - this.finishedApptsCnt;
    }

    showHiddenAll(id: string, cked: boolean): void {
        let chkShowAllT = <HTMLInputElement>document.getElementById('showAllTop')
        let chkShowAllB = <HTMLInputElement>document.getElementById('showAllBottom')
        let chkShowNotHiddenT = <HTMLInputElement>document.getElementById('showNotHiddenTop')
        let chkShowNotHiddenB = <HTMLInputElement>document.getElementById('showNotHiddenBottom')
        if (id.startsWith('showAll') && cked) {  // clicked showAll and if checked ...
            this.showHiddenFilter = undefined;  // set this.showHiddenFilter = undefined so that AgendaPipe returns all appointments, else ...
            chkShowAllT.checked = true;
            chkShowAllB.checked = true;
        } else {
            this.showHiddenFilter = false;  // set this.showHiddenFilter = false so that AgendaPipe returns only waiting appointments
            chkShowNotHiddenT.checked = true;
            chkShowNotHiddenB.checked = true;
        }
    }

    newAppt(): void {
        this.clearErrs();
        this.ckAndLoadInsuranceLst();
        this.clearAppt();
        this.appt.pKey = '0';   // new appointment when 0
        this.currApptTime = undefined;
        this.selectedTime = undefined;
        this.selectedDuration = this.currRescDuration
        this.appt.duration = this.selectedDuration;
        this.appt.resourceID = this.selectedResourceID;
        this.getAvailableTimes(this._help.fmtDate(new Date(this.apptDtpSelected), 'mm/dd/yyyy'));   // available times for a new appointment on dtp preselected date
        this.equate_appt2_to_appt_forComparisonLater();
        this.patientsFound4Appt = [];
        this.open(this.apptEditModal, { backdrop: 'static' }, 'apptEditModal');
        (<HTMLInputElement>document.getElementById('apptPatSrchParam')).value = '';
    }

    editAppt(apptPkey: string): void {
        //e.stopPropagation(); // needed or the next (click) event within the origin <td> will fire
        this.clearErrs();
        this.waiting4ResponseNewEditAppt = false;
        this.ckAndLoadInsuranceLst();
        let i = this.appts.findIndex(appt => appt.pKey === apptPkey);
        this.appt = this.appts[i];
        this.apptDtpSelected = this.appt.start.match(/^\d{4}-\d{2}-\d{2}/g)[0];

        this.equate_appt2_to_appt_forComparisonLater();

        this.selectedDuration = this.currRescDuration;  // reset this.selectedDuration to as it could have been changed inside last appt
        this.getAvailableTimes(this._help.fmtDate(new Date(this.apptDtpSelected), 'mm/dd/yyyy'));   // available times for a new appointment on dtp preselected date
        let tm = this.appts[i].start.match(/T\d{1,2}:\d{1,2}:\d{1,2}\./g)[0].replace('T', '').replace('00.', '');
        let hr = parseInt(tm.match(/^\d{1,2}/g)[0]);
        if (hr > 12) {
            this.currApptTime = (hr - 12).toString() + ':' + tm.match(/:\d{1,2}:/g)[0].replace(':', '').replace(':', '') + 'p';
        } else if (hr == 12) {
            this.currApptTime = hr.toString() + ':' + tm.match(/:\d{1,2}:/g)[0].replace(':', '').replace(':', '') + 'p';
        } else {
            this.currApptTime = (parseInt(tm.match(/^\d{1,2}/g)[0]).toString() + tm.match(/:\d{1,2}/g)[0] + 'a'); // removes lead zero
        }
        this.selectedTime = this.currApptTime;  // this.selectedTime will hold any changes to the time dropdown but initially this.selectedTime & this.currApptTim are the same
        this.selectResource(undefined, this.selectedResourceID);
        this.selectedDuration = (60 * parseInt(this.appts[i].duration.match(/^\(\d{1,2}:/g)[0].replace('(', '').replace(':', '')) + parseInt(this.appts[i].duration.match(/\:\d{1,2}/g)[0].replace(':', ''))).toString();    // comes in as (hr:min)
        this.open(this.apptEditModal, { backdrop: 'static' }, 'apptEditModal');
    }

    closeAppt(): boolean {
        let edited: boolean = false;
        let newApptTimeTxtBx: HTMLInputElement = <HTMLInputElement>document.getElementById('apptTime');

        let orgApptDate = undefined;    // Original start date
        let yr = 0; // Original year
        let mo = 0; // Original month
        let dy = 0; // Original day
        if (this.appt2.start) {
            yr = parseInt(this.appt2.start.substring(0, 4));
            mo = parseInt(this.appt2.start.substring(5, 7)) - 1;
            dy = parseInt(this.appt2.start.substring(8, 10));
            orgApptDate = new Date(yr, mo, dy, 0, 0, 0, 0)
        }

        if (orgApptDate && this._help.isDate(orgApptDate)) { // new appts have orgApptDate undefined so this check isn't done
            if (1 + new Date(this.apptDtpSelected).getDate() != dy  // Must add 1 because JS defines dates starting at 24th hour of previous day plus hours plus minutes etc. 
                || new Date(this.apptDtpSelected).getMonth() != mo  // Both are zero based  
                || new Date(this.apptDtpSelected).getFullYear() != yr
                || this.selectedTime != (this.currApptTime ? this.currApptTime : newApptTimeTxtBx.value)) {
                edited = true;
            }
        }

        if (!edited) {

            edited = this.appt_notEq_appt2(orgApptDate);

            // for (let key in this.appt) {    // ck original values in appt2 against appt to check for edits when closing the appointment
            //     if (this.appt2.hasOwnProperty(key)) {
            //         if (!orgApptDate && key == 'duration') {
            //             continue;   // continue for - don't ck when exiting a new appt
            //         }

            //         console.log(key + ' = ' + this.appt[key]);
            //         console.log(this.appt[key]);

            //         switch (key) {
            //             case 'selected':    // don't ck values
            //                 continue;

            //             case 'statusHatch': // don't ck values
            //                 continue;

            //             case 'confirm': // boolean ck
            //                 if (this.appt2[key] != this.appt[key]) {
            //                     edited = true;
            //                     break;
            //                 }

            //             case 'conflict': // boolean ck
            //                 if (this.appt2[key] != this.appt[key]) {
            //                     edited = true;
            //                 }
            //                 break;

            //             default:

            //                 if (this.appt2[key] && this.appt[key]) {

            //                     console.log(key);
            //                     console.log(this.appt[key]);
            //                     console.log(this.appt2[key]);

            //                     if (this.appt2[key].trim() != this.appt[key].trim()) {
            //                         console.log('1 >' + this.appt[key] + '<');
            //                         console.log('2 >' + this.appt2[key] + '<');
            //                         console.log('--- changed ---');
            //                         edited = true;
            //                         break;
            //                     }
            //                 } else if ((!this.appt2[key] && this.appt[key]) || (this.appt2[key] && !this.appt[key])) {
            //                     edited = true;
            //                     break;
            //                 }
            //         }
            //     }
            //     if (edited) {   // get out of for loop
            //         break;
            //     }
            // }

        }

        if (edited) {
            this.open(this.apptExitWoSaveModal, { backdrop: 'static', size: 'sm', scrollable: false }, 'apptExitWoSaveModal');
        }
        return edited;
    }

    getSearchResults(id: string, key: string): void {
        this.currentSrchResultsPg = 1;
        let srchTxt = <HTMLInputElement>document.getElementById(id);
        if (srchTxt && srchTxt.value.trim()) {
            let sumary: string;
            let tel: string;
            let rec: string;
            switch (key) {
                case 'summary':
                    sumary = srchTxt.value;
                    break;
                case 'tel':
                    tel = srchTxt.value.replace(/\D*/g, '');
                    break;
                case 'rec':
                    rec = srchTxt.value;
                    break;
            }

            this.waiting4ResponseSrch = true;
            this._agendaService.getAppointmentsSearched(sumary, tel, rec, this.sn)
                .subscribe({
                    next: data => {
                        this.waiting4ResponseSrch = false;
                        this.apptsSearched = data;
                    },
                    error: (err: any) => {
                        this._appToastsService.updateDeadCenter(false);
                        this._appToastsService.show(
                            (this.engLang ? 'Error finding appointments!' : '¡Error buscando citas!')
                            + this._agendaService.errMsgSuggestion(this.engLang),
                            {
                                header: (
                                    err.displayMsg
                                ), autohide: false, error: true
                            }
                        );
                    }
                });
        }
    }

    srchKeyPressEventHndlr(event: any): void {
        if (event.keyCode == 13) {
            let key: string;
            switch (event.target.id) {
                case 'apptNmSrch':
                    key = 'summary';
                    break;
                case 'apptTelSrch':
                    key = 'tel';
                    break;
                case 'apptRecSrch':
                    key = 'rec';
                    break;
            }
            this.getSearchResults(event.target.id, key);
        } else {
            this.apptsSearched = [];
        }
    }

    srchTablSelect(event: any): void {
        //console.log(event.target);
        // TODO: Detach dts from document in next line
        let dts = event.target.parentElement.cells[1].innerHTML.match(/\d{1,2}-\d{1,2}-\d{2}/)[0];  // date string
        if (new Date(dts) instanceof Date && !isNaN(parseInt(dts))) {
            this.currDt = new Date(new Date(dts)); // calendar date
            this.selectedDayFilter = this.currDt.getDate().toString();   // day of month
            this.currApptID = event.target.parentElement.cells[0].innerHTML.trim(); // appt id to be used to select appt selected from search window when backFromGetAppointments1Month(...) finishes
            this.initScroll = true; // Allow auto scroll once if needed
            let rid: string = event.target.parentElement.cells[6].innerHTML.trim(); // resource id
            let evnt: Event;    // dummy var
            this.selectResource(evnt, rid);   // last true causes this.setCalendar(...) not to run so it won't run twice
            document.getElementById('closeSrchModal').click()   // simulated click to close search modal
        }
    }

    getAvailableTimes(dtpDate: string): void {
        let dt: Date = new Date(dtpDate);
        if (!dtpDate) {
            return; // get out, no date selected - should not happen
        }

        if (parseInt(this.selectedDuration) > 0 && this.currRescStartHrMin && this.currRescEndHrMin) {
            let start: string = this._help.fmtDate(
                new Date(dt.getFullYear(),
                    dt.getMonth(),
                    dt.getDate(),
                    parseInt(this.currRescStartHrMin.match(/^\d{1,2}/g)[0]),
                    parseInt(this.currRescStartHrMin.match(/\d*$/g)[0]), 0, 0), 'sqlymd');
            let end: string = this._help.fmtDate(
                new Date(dt.getFullYear(),
                    dt.getMonth(),
                    dt.getDate(),
                    parseInt(this.currRescEndHrMin.match(/^\d{1,2}/g)[0]),
                    parseInt(this.currRescEndHrMin.match(/\d*$/g)[0]), 59, 998), 'sqlymd');
            // go get availble time slots
            if (!this.byPassWaiting4ResponseNewEditAppt) {  // avoids RTErr "ExpressionChangedAfterItHasBeenCheckedError"
                this.waiting4ResponseNewEditAppt = false;   // ck this - went from true to false & fixed it ???
            }
            this.byPassWaiting4ResponseNewEditAppt = false;

            this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
            this._agendaService.getAvailableTimeSlots(start, end, this.selectedDuration, this.selectedResourceID ? this.selectedResourceID : this.currRescID, this.sn)
                .subscribe({
                    next: (data: any) => this.backFromGetAvailableTimes(data),
                    error: (err: any) => {
                        this._appToastsService.updateDeadCenter(false);
                        this._appToastsService.show(
                            (this.engLang ? 'Error finding empty appointment time slot!' : '¡Error buscando espacio disponible para la cita!')
                            + this._agendaService.errMsgSuggestion(this.engLang),
                            {
                                header: (
                                    err.displayMsg
                                ), autohide: false, error: true
                            }
                        );
                    }
                });
        }
    }

    backFromGetAvailableTimes(returnedData: any): void {
        this.waiting4ResponseNewEditAppt = false;
        if (this.currApptTime) {    // initial apt time set when clicking on the appointment to be edited or viewed -- editing
            let h: number = parseInt(this.currApptTime.match(/^\d{1,2}/g)[0]);
            let m: number = parseInt(this.currApptTime.match(/:\d{1,2}/g)[0].replace(':', ''));
            let ap: string = this.currApptTime.match(/[ap]$/g)[0];

            if (!returnedData.some(t => t.availableTimes === this.currApptTime)) {  // this.currApptTime not in availableTimes so insert it

                let rdLen: number = returnedData ? returnedData.length : 0;
                for (let i = 0; i < returnedData.length; i++) {
                    let rdHour: number = parseInt(returnedData[i].availableTimes.match(/^\d{1,2}/g)[0]);
                    let rdMins: number = parseInt(returnedData[i].availableTimes.match(/:\d{1,2}/g)[0].replace(':', ''));
                    let rdAmPm: string = returnedData[i].availableTimes.match(/[ap]$/g)[0];

                    if (rdAmPm == ap) {   // look within same AM or PM
                        if (rdHour == h && rdMins > m) {
                            returnedData.splice(i, 0, { 'availableTimes': h.toString() + ':' + (m < 10 ? '0' + m.toString() : m.toString()) + ap });
                            break;
                        } else if ((rdHour > 11 ? rdHour - 12 : rdHour) > h) {  // later hour AM or PM
                            returnedData.splice(i, 0, { 'availableTimes': h.toString() + ':' + (m < 10 ? '0' + m.toString() : m.toString()) + ap });
                            break;
                        }
                    }
                }

                if (rdLen > 0 && rdLen == returnedData.length) { // this.currApptTime not found in returnedData and was not inserted - must be prepended or appended to am or pm group
                    returnedData = returnedData.filter(obj => obj.availableTimes = obj.availableTimes.replace(/^12/g, '0'));  // swap 12 for 0 in all times

                    let minMax = returnedData.reduce((acc, val) => {
                        acc[0] = (acc[0] === undefined || val.availableTimes.padStart(6, '0') < acc[0].availableTimes.padStart(6, '0')) ? val : acc[0]    // .padStart(6, '0') converts 1:00p to 01:00p for correct string comparison
                        acc[1] = (acc[1] === undefined || val.availableTimes.padStart(6, '0') > acc[1].availableTimes.padStart(6, '0')) ? val : acc[1]
                        return acc;
                    }, []);

                    let loPmHr = minMax[0].availableTimes;  // lowest available time slot
                    let hiPmHr = minMax[1].availableTimes;  // highest available time slot

                    let dtAppt = moment(this.appt.start);   // appt date as moment

                    let hrLo = parseInt(loPmHr.match(/^\d{1,2}/g)[0]) + (loPmHr.match(/\w$/g)[0] == 'p' ? 12 : 0);  // lowest hour
                    //console.log(hrLo);
                    let miLo = parseInt(loPmHr.match(/:\d{2}/g)[0].replace(/:/g, ''));  // lowest minutes

                    let hrHi = parseInt(hiPmHr.match(/^\d{1,2}/g)[0]) + (hiPmHr.match(/\w$/g)[0] == 'p' ? 12 : 0);  // highest hour
                    // console.log(hrHi);
                    let miHi = parseInt(hiPmHr.match(/:\d{2}/g)[0].replace(/:/g, ''));  // highest minutes
                    // console.log(miHi);

                    let dtLo = moment(this.appt.start).startOf('day').add(hrLo, 'h').add(miLo, 'm');    // lowest available date as moment
                    // console.log(dtLo.toDate());

                    let dtHi = moment(this.appt.start).startOf('day').add(hrHi, 'h').add(miHi, 'm');    // highest available date as moment
                    // console.log(dtHi.toDate());

                    // console.log(dtLo.diff(dtAppt, 'minutes'));
                    // console.log(dtHi.diff(dtAppt, 'minutes'));

                    if (dtAppt.diff(dtLo, 'minutes') < 0) {
                        returnedData.unshift({ 'availableTimes': h.toString() + ':' + (m < 10 ? '0' + m.toString() : m.toString()) + ap });    // append
                    }
                    if (dtAppt.diff(dtHi, 'minutes') > 0) {
                        returnedData.push({ 'availableTimes': h.toString() + ':' + (m < 10 ? '0' + m.toString() : m.toString()) + ap }); // prepend
                    }
                } else {    // returnedData empty
                    if (returnedData && returnedData.length == 0) {
                        returnedData.push({ 'availableTimes': this.currApptTime });    // append
                    }
                }
            }
            returnedData = returnedData.filter(obj => obj.availableTimes = obj.availableTimes.replace(/^0:\d{2}p$/g, '12:' + obj.availableTimes.match(/\d{2}/g)[0] + 'p'));  // change 0:xxp back to 12:xxp
        }
        this.availableTimes = returnedData;

        if (this.availableTimes && this.availableTimes.length > 0) {  // this.selectedTime is undefined for new appts
            if (!this.selectedTime) {
                this.selectedTime = this.availableTimes[0]["availableTimes"];
            }
        }
    }

    selectOptionEvent(selectBox: string, rescID: string): void { // selection of duration or resource from modal new/edit appointment raise this event
        if (selectBox == 'resource') {
            rescID.trim(); //this.selectedResourceID ? this.selectedResourceID : this.currRescID;    // this.selectedResourceID is set in New/Edit Appt modal; this.currRescID is set here initially from where the resource was selected
            this.selectedResourceColor = undefined;   // reset
            for (var r = 0; r < this.apptsRescs.length; r++) {  // change start/end according to resource selected
                if (rescID == this.apptsRescs[r].pKey) {
                    this.currRescStartHrMin = this.apptsRescs[r].startHrMi;
                    this.currRescEndHrMin = this.apptsRescs[r].endHrMi;
                    this.selectedDuration = this.apptsRescs[r].duration;
                    this.selectedResourceColor = this.apptsRescs[r].color;
                    this.selectedResourceID = rescID;
                    break;
                } else {    // defaults just in case...
                    this.currRescStartHrMin = '00:00';
                    this.currRescEndHrMin = '23:59';
                    this.selectedDuration = '10';
                }
            }
            this.appt.resourceID = rescID.toString();    // update this.appt.resourceID to compare against this.appt2.resourceID when exiting appt edit
        }
        if (selectBox == 'resource' || selectBox == 'duration') {
            var mins = parseInt(this.selectedDuration) % 60;
            this.appt.duration = '(' + Math.floor(parseInt(this.selectedDuration) / 60).toString() + ':' + (mins < 10 ? '0' + mins.toString() : mins.toString()) + ')';    // update this.appt.duration to compare against this.appt2.duration when exiting appt edit 
        }
        this.getAvailableTimes(this._help.fmtDate(new Date(this.apptDtpSelected), 'mm/dd/yyyy'));
    }

    srchPatientKeyUpEventHndlr(event: any): void {
        let txtBox: HTMLInputElement = <HTMLInputElement>event.target;
        if (txtBox.value) { // make sure something is entered
            if (txtBox.value.match(/^[A-Za-z]/g) && txtBox.value.match(/[^A-Za-z ,\b]/g)) {
                txtBox.value = txtBox.value.replace(event.key, '');   // started with letters but then entered non letter
            } else if (txtBox.value.match(/^[0-9]/g) && txtBox.value.match(/[^0-9\b]/g)) {
                txtBox.value = txtBox.value.replace(event.key, '');   // started numeric but then entered non numeric
            } else if ((txtBox.value.match(/,/g) || []).length > 1) {   // entered more than 1 comma
                txtBox.value = txtBox.value.replace(event.key, '');
            } else if (event.keyCode == 13) {   // Enter
                this.patientsFound4Appt = [];
                this.goSearch4EnteredPatOrRecOrTel(txtBox.value);
            }
        }
    }

    click2Find(id: string, val: string): void {
        if (id == 'apptPatSrchParam') { // pat's name search
            this.goSearch4EnteredPatOrRecOrTel(val);   // Here txtBox.value = '' whe using txtBox (???) so implemente val
        }

        if (id == 'insAlias1' || id == 'insAlias2') {
            let ps = id.match(/\d*$/g)[0];
            this.clearInsuranceLstSelected(ps);

            for (let i = 0; i < this._help.insuranceLst.length; i++) {
                if (this._help.insuranceLst[i].alias.substring(0, val.length).toUpperCase() == val.toUpperCase()) {
                    this.insuranceLstSelected.push(this._help.insuranceLst[i]);
                }
            }
        }
    }

    goSearch4EnteredPatOrRecOrTel(srchKey: string) {
        if (srchKey) { // make sure something is entered
            let last: string = 'undefined';
            let first: string = 'undefined';
            let recNo: string = 'undefined';
            let tel: string = 'undefined';
            let qry: string = "Exec spMBSearch4Record @top = 99, @order = ";
            if (srchKey.match(/[A-za-z, ]/g)) { // search by name(s)
                let i = srchKey.indexOf(',');
                if (i >= 0) {
                    last = srchKey.substring(0, i).trim() ? srchKey.substring(0, i).trim() : 'undefined';
                    first = srchKey.substring(i + 1).trim() ? srchKey.substring(i + 1).trim() : 'undefined';
                    qry += "'1', @lastNm = '" + last.replace(/'/g, "''") + "%', @firstNm = '" + first.replace(/'/g, "''") + "%'";
                } else {
                    last = srchKey.trim() ? srchKey.trim() : 'undefined';
                    qry += "'1', @lastNm = '" + last.replace(/'/g, "''") + "%'";
                }
            } else if (srchKey.match(/\d{10}/g)) {  // search by tel
                tel = srchKey.trim();
                qry += "'2', @tel = '" + tel + "%'";
            } else {    // search by record no
                recNo = srchKey.trim();
                qry += "'3', @recno = '" + recNo + "%'";
            }
            this.waiting4ResponseNewEditAppt = true;

            if (!this.sio) {
                // Untested code!
                this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
                this._agendaService.getPatientsFoundForAppt(last, first, recNo, tel)
                    .subscribe({
                        next: data => this.backFromGetPatientsForAppt(data),
                        error: (err: any) => {
                            this._appToastsService.updateDeadCenter(false);
                            this._appToastsService.show(
                                (this.engLang ? 'Error searching records!' : '¡Error buscando record de pacientes!')
                                + this._agendaService.errMsgSuggestion(this.engLang),
                                {
                                    header: (
                                        err.displayMsg
                                    ), autohide: false, error: true
                                }
                            );
                        }
                    });
            } else {

                this._websocketService.sendChat('query', this.sn, qry);

            }
        }
    }

    backFromGetPatientsForAppt(returnedData: any): void {
        this.waiting4ResponseNewEditAppt = false;
        // TODO Finish this rtn when using Agenda in the web only & no sio.
        alert('backFromGetPatientsForAppt');
        this.patientsFound4Appt = returnedData;
    }

    selectedPatient4Appt(eventTarget: any, idx: string): void {
        if (this.sio) {
            this.appt.patID = this.patientsFound4Appt[idx].patID;
            this.appt.patLastNm = this.patientsFound4Appt[idx].lastNm;
            this.appt.patFirstNm = this.patientsFound4Appt[idx].firstNm;
            this.appt.patMidNm = this.patientsFound4Appt[idx].midIn;
            this.appt.sex = this.patientsFound4Appt[idx].sex;
            this.appt.patDOB = this.patientsFound4Appt[idx].dob;
            this.appt.age = this._help.calcAge(this.patientsFound4Appt[idx].dob);
            this.appt.insId1 = this.patientsFound4Appt[idx].insId1;
            this.appt.insAlias1 = this.patientsFound4Appt[idx].insAlias1;
            this.appt.patContr1 = this.patientsFound4Appt[idx].patContr1;
            this.appt.patGrp1 = this.patientsFound4Appt[idx].patGrp1;
            this.appt.insId2 = this.patientsFound4Appt[idx].insId2;
            this.appt.insAlias2 = this.patientsFound4Appt[idx].insAlias2;
            this.appt.patContr2 = this.patientsFound4Appt[idx].patContr2;
            this.appt.patGrp2 = this.patientsFound4Appt[idx].patGrp2;
            this.appt.patEmail = this.patientsFound4Appt[idx].patEmail;
            this.appt.patTelCell = this.patientsFound4Appt[idx].patTelCell;
            this.appt.patTelHome = this.patientsFound4Appt[idx].tel;
            this.appt.patTelWork = this.patientsFound4Appt[idx].patTelWk;
            this.appt.summary = this.patientsFound4Appt[idx].fullNm;
            this.appt.recNo = this.patientsFound4Appt[idx].recNo;
            this.appt.recOffice = this.patientsFound4Appt[idx].recOffice;
            this.appt.recYr = this.patientsFound4Appt[idx].recYr;
        } else {
            // Untested code!
            let patID: string;
            if (eventTarget.parentElement.children.length == 3) {   // clicked <a>
                this.appt.summary = eventTarget.parentElement.children[0].innerText;    // patFullNm
                patID = eventTarget.parentElement.children[1].innerText;
            } else {    // clicked <li>
                this.appt.summary = eventTarget.parentElement.children[0].children[0].innerText;    // patFullNm
                patID = eventTarget.parentElement.children[0].children[1].innerText;
            }

            this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
            this._agendaService.getPatientSelected4NewAppt(patID)
                .subscribe({
                    next: data => this.appt = data[0],
                    error: (err: any) => {
                        this._appToastsService.updateDeadCenter(false);
                        this._appToastsService.show(
                            (this.engLang ? 'Error fetching record for appointment!' : '¡Error leyendo record para la cita!')
                            + this._agendaService.errMsgSuggestion(this.engLang),
                            {
                                header: (
                                    err.displayMsg
                                ), autohide: false, error: true
                            }
                        );
                    }
                });
        }
    }

    clearInsuranceLstSelected(ps: string): void {
        this.ps = ps;
        this.insuranceLstSelected = [];
    }

    prepareLoad_InsuranceLstSelected_fromVal(val: string, id: string): void {   // val=value of element, id=id of element
        console.log('prepareLoad_InsuranceLstSelected_fromVal');
        if (id) {
            val = (<HTMLInputElement>document.getElementById(id)).value;
        }
        if (val) {
            let ps = id.match(/\d*$/g)[0];
            this.clearInsuranceLstSelected(ps);
            for (let i = 0; i < this._help.insuranceLst.length; i++) {
                if (this._help.insuranceLst[i].alias.substring(0, val.length).toUpperCase() == val.toUpperCase()) {
                    this.insuranceLstSelected.push(this._help.insuranceLst[i]);
                }
            }
        }
    }

    onKeyUp_insurance_dd_EventHndlr(event: any, idSrch: boolean, ps: string): void {   // idSrch = true looking up insID, ps=1(prim), 2(sec)
        console.log('onKeyUp_insurance_dd_EventHndlr');
        if (idSrch && event.target.value.trim().length == 0) {  // in case id was blanked
            event.target.value = '0';
            if (ps == '1') {
                this.appt.insAlias1 = '';
            }
            if (ps == '2') {
                this.appt.insAlias2 = '';
            }
        }

        if (event.target.value && event.key != 'Backspace') { // make sure something is entered & is not a Backspace
            if (event.keyCode == 13 || event.keyCode == 9) {  // Enter or Tab
                if (idSrch) {
                    for (let i = 0; i < this._help.insuranceLst.length; i++) {
                        if (+this._help.insuranceLst[i].pKey == +event.target.value) {   // found it
                            if (ps == '1') {
                                this.appt.insId1 = this._help.insuranceLst[i].pKey
                                this.appt.insAlias1 = this._help.insuranceLst[i].alias
                                this.insId1Err = false;
                                return;
                            } else {
                                this.appt.insId2 = this._help.insuranceLst[i].pKey
                                this.appt.insAlias2 = this._help.insuranceLst[i].alias
                                this.insId2Err = false;
                                return;
                            }
                        }
                    }
                    // not found by id
                    if (ps == '1') {
                        this.appt.insAlias1 = '';
                        this.insId1Err = true;
                    } else {
                        this.appt.insAlias2 = '';
                        this.insId2Err = true;
                    }
                    return;
                } else {
                    if (event.key == 'Tab' && event.shiftKey) { // get out if Shift-Tab in reverse
                        return;
                    }

                    if (event.target.value) {    // Something was entered in alias
                        if (event.key == 'Enter' || event.key == 'Tab') {    // Enter or Tab moving forward
                            let val = event.target.value;
                            let ins: ILocalInsurances[] = this.insuranceLstSelected.filter(itm => itm.alias.toUpperCase().substring(0, val.length) == val.toUpperCase()); // First matching entry(es)
                            if (ins.length == 1) {  // One insurance was matched
                                if (ps == '1') {
                                    this.appt.insId1 = ins[0].pKey
                                    this.appt.insAlias1 = ins[0].alias
                                    this.insId1Err = false;
                                    if (this.appt.insId1 == '0') {
                                        this.clearInsuranceLstSelected(ps);
                                    }
                                } else {
                                    this.appt.insId2 = ins[0].pKey
                                    this.appt.insAlias2 = ins[0].alias
                                    this.insId2Err = false;
                                    if (this.appt.insId2 == '0') {
                                        this.clearInsuranceLstSelected(ps);
                                    }
                                }
                            } else {    // Entry not matched or multiple array members match
                                if (this.insuranceLstSelected.length > 1) {
                                    if (this.isValidInsuranceSelected(ps, false)) {
                                        if (ps == '1') {
                                            this.insId1Err = false;
                                        } else {
                                            this.insId2Err = false;
                                        }
                                    } else {
                                        if (ps == '1') {
                                            this.insId1Err = true;
                                        } else {
                                            this.insId2Err = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        this.ps = ps;   // Used in onClick_selectInsurance(...) to set ins 1 or 2 fields respectively
        if (ps == '1') {
            this.click2Find('insAlias1', event.target.value);
        } else if (ps == '2') {
            this.click2Find('insAlias2', event.target.value);
        }
    }

    onKeyDown_insurance_dd_EventHndlr(event: any, idSrch: boolean, ps: string) { // Happens before KeyUp event
        console.log('onKeyDown_insurance_dd_EventHndlr');
        if (event.key == 'Enter' || event.key == 'Tab') {

            if (idSrch) {   // Looking by ins ID, idSrch = true. 
                this.onKeyUp_insurance_dd_EventHndlr(event, idSrch, ps);
            } else {    // Looking by ins alias, idSrch = false.
                if (this.insuranceLstSelected.length == 1) {    // Found one looking by alias
                    if (ps == '1') {
                        this.appt.insId1 = this.insuranceLstSelected[0].pKey;
                        this.appt.insAlias1 = this.insuranceLstSelected[0].alias;
                        this.insId1Err = false;
                    } else {
                        this.appt.insId2 = this.insuranceLstSelected[0].pKey;
                        this.appt.insAlias2 = this.insuranceLstSelected[0].alias;
                        this.insId2Err = false;
                    }
                } else if (this.insuranceLstSelected.length > 1) { // Found several > 1 looking by alias
                    if (ps == '1') {
                        if (this.isValidInsuranceSelected(ps, false)) {
                            this.insId1Err = false;
                        } else {
                            this.insId1Err = true;
                            // The following to open the dropdown with alternatives automatically not working well
                            // document.getElementById('selIns1').focus();
                            // document.getElementById('selIns1').click();
                        }
                    }
                    if (ps == '2') {
                        if (this.isValidInsuranceSelected(ps, false)) {
                            this.insId2Err = false;
                        } else {
                            this.insId2Err = true;
                            // The following to open the dropdown with alternatives automatically not working well
                            // document.getElementById('selIns2').focus();
                            // document.getElementById('selIns2').click();
                        }
                    }
                } else {    // Nothing found with alias entered and pressing Enter or Tab
                    if (ps == '1') {
                        if (this.appt.insId1 != '0' || this.appt.insAlias1.match(/\w+/g)[0]) {  // Something entered in alias
                            this.insId1Err = true;
                        } else {
                            this.insId1Err = false;
                        }
                    }
                    if (ps == '2') {
                        if (this.appt.insId2 != '0' || this.appt.insAlias2.match(/\w+/g)[0]) {  // Something entered in alias
                            this.insId2Err = true;
                        } else {
                            this.insId2Err = false;
                        }
                    }
                }
            }
        }
    }

    ckAndLoadInsuranceLst(): void {
        if (!this._help.insuranceLst || this._help.insuranceLst.length == 0
            || !this._help.apptTypes || this._help.apptTypes.length == 0
            || !this._help.apptStatuses || this._help.apptStatuses.length == 0) {  // global insurance list to be used everywhere
            this._help.insuranceLst = [];
            this._help.apptTypes = [];
            this._help.apptStatuses = [];

            this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
            this._agendaService.getInsuranceLst(this.sn)
                .subscribe({
                    next: (data: any[]) => {
                        this._help.insuranceLst = data[0].recordsetInsLst;
                        this._help.apptTypes = data[1].recordsetApptTypes;
                        this._help.apptStatuses = data[2].recordsetApptStatuses;
                    },
                    error: (err: any) => {
                        console.dir(err);
                        this._appToastsService.updateDeadCenter(false);
                        this._appToastsService.show(
                            (this.engLang ? "Failed to load Agenda's Insurances/Types/Statuses."
                                : 'No se cargaron los Seguros/Tipos/Statuses en la Agenda.')
                            + this._agendaService.errMsgSuggestion(this.engLang),
                            {
                                header: (
                                    err.displayMsg
                                ), autohide: false, error: true
                            }
                        );
                    }
                });
        }
    }

    setPatRecNo(): string {
        return this.appt.recNo ? this.appt.recNo + '-' + this.appt.recOffice + '-' + this.appt.recYr : '';
    }

    onClick_selectInsurance(eventTarget: any, ins: any): void {
        let id: string;
        let alias: string;
        switch (eventTarget.children.length) {
            case 0: {
                id = eventTarget.parentElement.children[1].innerText;
                alias = eventTarget.parentElement.children[0].innerText;
                break;
            }
            case 1: {
                id = eventTarget.children[0].children[1].innerText;
                alias = eventTarget.children[0].children[0].innerText;
                break;
            }
            case 2: {
                id = eventTarget.children[1].innerText;
                alias = eventTarget.children[0].innerText;
                break;
            }
        }

        if (this.ps == '1') {
            this.appt.insId1 = ins.pKey;
            this.appt.insAlias1 = ins.alias;
            this.insId1Err = false;
        } else if (this.ps == '2') {
            this.appt.insId2 = ins.pKey;
            this.appt.insAlias2 = ins.alias;
            this.insId2Err = false;
        }
        this.clearInsuranceLstSelected(this.ps);  // Clears array this.insuranceLstSelected
        //  Good place to set focus to patContr1 or patContr2 but the focus halo/outline is lost, so just press Tab after selecting
    }

    clearAppt(): void {
        var newAppt: IAppointments = {
            pKey: '0',
            patID: undefined,
            d: undefined,
            h: undefined,
            m: undefined,
            start: undefined,
            end: undefined,
            duration: undefined,
            conflict: false,
            summary: undefined,
            complaint: undefined,
            hxIllness: undefined,
            recNo: undefined,
            recOffice: undefined,
            recYr: undefined,
            sex: undefined,
            age: undefined,
            typeId: undefined,
            type: undefined,
            typeColor: undefined,
            typeHatch: false,
            statusId: undefined,
            status: undefined,
            statusColor: undefined,
            statusHatch: false,
            confirm: false,
            eligib: false,
            selected: false,
            hidden: false,
            patLastNm: undefined,
            patFirstNm: undefined,
            patMidNm: undefined,
            patTelHome: undefined,
            patTelWork: undefined,
            patTelCell: undefined,
            patDOB: undefined,
            patEmail: undefined,
            insId1: '0',
            insAlias1: undefined,
            insId2: '0',
            insAlias2: undefined,
            billed: undefined,
            showed: undefined,
            rsvpSentCnt: undefined,
            rsvpSentDt: undefined,
            patContr1: undefined,
            patGrp1: undefined,
            patContr2: undefined,
            patGrp2: undefined,
            eligibleIns1: undefined,
            eligibleIns2: undefined,
            resourceID: undefined
        }
        this.appts.push(newAppt);
        this.appt = this.appts[this.appts.length - 1];
    }

    clearInsurance(ps: string): void {
        switch (ps) {
            case '1': {
                this.appt.insId1 = '0';
                this.appt.insAlias1 = undefined;
                this.insId1Err = false;
                break;
            }
            case '2': {
                this.appt.insId2 = '0';
                this.appt.insAlias2 = undefined;
                this.insId2Err = false;
                break;
            }
        }
        this.insuranceLstSelected = [];
    }

    clearTypeStatus(eventTarget: any): void {
        if (eventTarget && eventTarget.id == 'clearTp') {
            let tp: any = this._help.apptTypes.filter(obj => {
                return obj['sn'] == this.sn && (obj['type'] == 'Seguimiento' || obj['type'] == 'FollowUp');  // ID = 1 corresponds to default type 'Seguimiento'
            });
            this.appt.type = tp[0].type     // type 'Seguimiento'
            this.appt.typeId = tp[0].ID.toString();  // default
            this.appt.typeColor = tp[0].color;    // default type background color
            this.appt.typeHatch = tp[0].hatch;    // default type hatch
        }

        if (eventTarget && eventTarget.id == 'clearSt') {
            let st: any = this._help.apptStatuses.filter(obj => {
                return obj['sn'] == this.sn && (obj['status'] == 'Nada' || obj['status'] == 'Nothing');  // ID = 2 corresponds to default status 'Nada'
            });
            this.appt.status = st[0].status;    // status 'Nada'
            this.appt.statusId = st[0].ID.toString();    // default
            this.appt.statusColor = st[0].color;    // default status background color
            this.appt.statusHatch = st[0].hatch;    // default status hatch
        }
    }

    selectedType_Status(apptFld: string, eventTarget: any): void {
        var desc: string;
        var id: string;
        var color: string;
        var hatch: boolean;
        if (eventTarget.parentElement) {
            if (eventTarget.parentElement.tagName.toUpperCase() == 'DIV') {   // clicked on <div>
                desc = eventTarget.children[0].innerText.trim();
                id = eventTarget.children[1].innerText.trim();
                color = eventTarget.children[2].innerText.trim();
                if (apptFld == 'status') {
                    hatch = eventTarget.children[3].innerText.trim() == 'true' ? true : false;
                }
            }
            if (eventTarget.parentElement.tagName.toUpperCase() == 'A') {  // clicked on <a>
                desc = eventTarget.parentElement.children[0].innerText.trim();
                id = eventTarget.parentElement.children[1].innerText.trim();
                color = eventTarget.parentElement.children[2].innerText.trim();
                if (apptFld == 'status') {
                    hatch = eventTarget.parentElement.children[3].innerHTML.trim() == 'true' ? true : false;
                }
            }
        }

        switch (apptFld) {
            case 'type': {
                this.appt.type = desc;
                this.appt.typeId = id;
                this.appt.typeColor = color;
                this.appt.typeHatch = hatch;
                break;
            }
            case 'status': {
                this.appt.status = desc;
                this.appt.statusId = id;
                this.appt.statusColor = color;
                this.appt.statusHatch = hatch;
                break;
            }
        }
    }

    setHatchBackgroundClass(eventTarget: any): string {
        if (eventTarget && eventTarget.name == 'apptStatus') {
            return this.appt.statusHatch ? eventTarget.add.class('hatched') : eventTarget.remove.class('hatched');
        }
    }

    getBestContrast(hexcolor: string): string {  // calculates a foreground color for the given hexcolor to improve readability
        if (hexcolor) {
            var r = parseInt(hexcolor.replace('#', '').substr(0, 2), 16);
            var g = parseInt(hexcolor.replace('#', '').substr(2, 2), 16);
            var b = parseInt(hexcolor.replace('#', '').substr(4, 2), 16);
            var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
            //return (yiq >= 128) ? 'black' : 'white';

            // return ((0.2126 * (r/255)^2.2  +  0.7151 * (g/255)^2.2  +  0.0721 * (b/255)^2.2) > .18) ? 'black':'white';

            return (255 - ((r * 0.299) + (g * 0.587) + (b * 0.114)) < 105) ? 'black' : 'white';
        }
    }

    onClick_activateDropDown(event: any, buttId: string, ps: string): void { // buttId = button to click
        if (buttId.startsWith('selIns')) {   // clicked ins 1 or 2 caret
            if (ps == '1') {   // used in onClick_selectInsurance(...) to set ins 1 or 2 fields respectively
                this.ps = '1';  // local copy of ps
            } else if (ps == '2') {
                this.ps = '2';
            }

            if (this.insuranceLstSelected.length == 0) {
                this._help.insuranceLst.forEach(ins => this.insuranceLstSelected.push(ins));
            }

            event.stopPropagation();
        }

        let btn = <HTMLButtonElement>document.getElementById(buttId);
        btn.click();    // activates click2Find(...)
    }

    onFocus_ddToggleBtn(event): void {
        let ps = event.target.id.replace(/\D/g, '');
        if (this.isValidInsuranceSelected(ps, false)) {    // Show all insurances in drop down
            this._help.insuranceLst.forEach(ins => this.insuranceLstSelected.push(ins));
        }
    }

    isValidInsuranceSelected(ps: string, saving: boolean): boolean { // true = Nothing selected or just 1 insurance selected
        let pKey = (<HTMLInputElement>document.getElementById('insId' + ps)).value;
        let alias = (<HTMLInputElement>document.getElementById('insAlias' + ps)).value;
        let selection;
        if (saving) {
            selection = this._help.insuranceLst.filter(ins => ins.pKey == pKey && ins.alias == alias);
        } else {
            selection = this.insuranceLstSelected.filter(ins => ins.pKey == pKey && ins.alias == alias);
        }

        if (!selection || selection.length == 1) {
            return true;
        }
        return false;
    }

    apptSexChange(event: any): void {
        var radBut = <HTMLInputElement>event.target;
        if (radBut.id == 'radioM') {  // male selected
            this.appt.sex = 'M';
        } else if (radBut.id == 'radioF') {    // female selected
            this.appt.sex = 'F';
        }
    }

    save(): void {
        this.patLastNmErr = false;
        this.patFirstNmErr = false;
        this.patDOBErr = false;
        this.patSexErr = false;
        this.patEmailErr = false;
        this.patTelCellErr = false;
        this.patTelWorkErr = false;
        this.patTelHomeErr = false;
        this.insId1Err = false;
        this.insAlias1Err = false;
        this.insId2Err = false;
        this.insAlias2Err = false;
        this.durationErr = false;
        this.resourceErr = false;
        this.timeErr = false;

        if (this.selectedTime && this.selectedTime.match(/^\d{1,2}:\d{2}[ap]/g)) {
            this.appt.d = moment(moment(this.apptDtpSelected).format('MM/dd/yyyy')).date().toString();  //new Date(this.apptDtpSelected).getDate().toString(); <- would change a 28 for 27
            this.appt.m = parseInt(this.selectedTime.match(/:\d{2}/g)[0].replace(':', '')).toString();
            this.appt.h = this.selectedTime.match(/^\d{1,2}/g)[0];
            if (this.selectedTime.match(/\w{1}$/g)[0] == 'p' && parseInt(this.appt.h) < 12) {
                this.appt.h = (12 + parseInt(this.appt.h)).toString();
            }
        } else {
            this.appt.h = undefined;
            this.appt.m = undefined;
            this.timeErr = true;
        }
        if (!this.appt.h || !this.appt.m) {
            this.timeErr = true;
        }

        if (!this.appt.summary) {
            this.appt.summary = (this.appt.patLastNm ? this.appt.patLastNm : '') + (this.appt.patFirstNm ? ', ' + this.appt.patFirstNm : '')
        }
        if ((!this.appt.patLastNm && !this.appt.summary) || (this.appt.patLastNm && this.appt.patLastNm.match(/[^A-Za-z ']/g))) {
            this.patLastNmErr = true;
        }
        if ((!this.appt.patFirstNm && !this.appt.summary) || (this.appt.patFirstNm && this.appt.patFirstNm.match(/[^A-Za-z ']/g))) {
            this.patFirstNmErr = true;
        }
        if (this.appt.patDOB && this._help.compDates(this.appt.patDOB, this._help.todaysDt(4)) == 1) {
            this.patDOBErr = true;
        }
        if ((this.appt.patLastNm || this.appt.patFirstNm) && this.appt.sex != 'M' && this.appt.sex != 'F') {
            this.patSexErr = true;
        }
        if (this.appt.patEmail && !this.appt.patEmail.trim().match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$|^\s*$/g)) {
            this.patEmailErr = true;
        }
        if (this.appt.patTelCell && !this.appt.patTelCell.trim().match(/^[(]\d{3}[)]\s*\d{3}\-\d{4}\s*\d{0,5}$|^\d{10}$|^\d{7}$/g)) {
            this.patTelCellErr = true;
        }
        if (this.appt.patTelWork && !this.appt.patTelWork.trim().match(/^[(]\d{3}[)]\s*\d{3}\-\d{4}\s*\d{0,5}$|^\d{10}$|^\d{7}$/g)) {
            this.patTelWorkErr = true;
        }
        if (this.appt.patTelHome && !this.appt.patTelHome.trim().match(/^[(]\d{3}[)]\s*\d{3}\-\d{4}\s*\d{0,5}$|^\d{10}$|^\d{7}$/g)) {
            this.patTelHomeErr = true;
        }
        // if (this.appt.insAlias1 && ((this.appt.insId1 && parseInt(this.appt.insId1) < 1) || !this.appt.insId1)) {
        //     this.insId1Err = true;
        // }
        // if (this.appt.insAlias2 && ((this.appt.insId2 && parseInt(this.appt.insId2) < 1) || !this.appt.insId2)) {
        //     this.insId2Err = true;
        // }
        if (!this.isValidInsuranceSelected('1', true)) {
            this.insId1Err = true;
        }
        // if (this.appt.insId1) {
        //     this.appt.insId1 = parseInt(this.appt.insId1).toString();   // removes lead zeros
        //     if (this._help.insuranceLst.length > 0) {
        //         var insObj = this._help.insuranceLst.filter(obj => {
        //             return obj.pKey == this.appt.insId1;
        //         });
        //         if (insObj.length == 0) {
        //             this.insId1Err = true;
        //         }
        //     }
        // }
        if (!this.isValidInsuranceSelected('2', true)) {
            this.insId2Err = true;
        }
        // if (this.appt.insId2) {
        //     this.appt.insId2 = parseInt(this.appt.insId2).toString();   // removes lead zeros
        //     if (this._help.insuranceLst.length > 0) {
        //         var insObj = this._help.insuranceLst.filter(obj => {
        //             return obj.pKey == this.appt.insId2;
        //         });
        //         if (insObj.length == 0) {
        //             this.insId2Err = true;
        //         }
        //     }
        // }
        if (!this.appt.duration || !this.appt.duration.match(/\d+/g)) {
            this.durationErr = true;
        }
        if (!this.appt.resourceID) {
            this.resourceErr = true;
        }

        if (!this.patLastNmErr &&
            !this.patFirstNmErr &&
            !this.patDOBErr &&
            !this.patSexErr &&
            !this.patEmailErr &&
            !this.patTelCellErr &&
            !this.patTelWorkErr &&
            !this.patTelHomeErr &&
            !this.insId1Err &&
            !this.insAlias1Err &&
            !this.insId2Err &&
            !this.insAlias2Err &&
            !this.timeErr &&
            !this.resourceErr) {

            let pKey: string;
            let SN: string;
            let Summary: string;
            let Start: string;
            let End: string;
            let _Location: string;
            let Description: string;
            let BackgroundID: string;
            let StatusID: string;
            let _PatID: string;
            let PatLastNm: string;
            let PatFirstNm: string;
            let PatMidNm: string;
            let PatSx: string;
            let PatTelHome: string;
            let PatTelWork: string;
            let PatTelCell: string;
            let PatDOB: string;
            let PatEmail: string;
            let RecNo: string;
            let RecOffice: string;
            let RecYr: string;
            let InsId1: string;
            let InsId2: string;
            let Billed: string;
            let Showed: string;
            let RsvpSentCnt: string;
            let RsvpSentDt: string;
            let PatContr1: string;
            let PatGrp1: string;
            let PatContr2: string;
            let PatGrp2: string;
            let _EligibleIns1: string;
            let _EligibleIns2: string;
            let ResourceID: string;
            let updtSql: string = '';
            // following needed for Insert & Update
            pKey = this.appt.pKey;
            Start = this.prepareStartDt();
            End = this.prepareEndDt(Start);
            ResourceID = this.appt.resourceID;
            SN = this.sn;

            if (this.appt.pKey == '0') {
                Summary = this.appt.summary ? this._help.escApos(this.appt.summary) : '';
                _Location = this.appt.complaint ? this._help.escApos(this.appt.complaint) : '';
                Description = this.appt.hxIllness ? this._help.escApos(this.appt.hxIllness) : '';
                BackgroundID = this.appt.typeId ? this.appt.typeId : '1';    // 1 = Seguimiento (default)

                this.appt.type = (this._help.apptTypes.find(item => item['ID'] == BackgroundID))['type'];

                StatusID = this.appt.statusId ? this.appt.statusId : '2';    // nada (default)

                this.appt.status = (this._help.apptStatuses.find(item => item['ID'] == StatusID))['status'];

                this.appt.start = Start
                this.appt.end = End

                _PatID = this.appt.patID ? this.appt.patID : '0';
                PatLastNm = this.appt.patLastNm ? this._help.escApos(this.appt.patLastNm) : '';
                PatFirstNm = this.appt.patFirstNm ? this._help.escApos(this.appt.patFirstNm) : '';
                PatMidNm = this.appt.patMidNm ? this._help.escApos(this.appt.patMidNm) : '';
                PatSx = this.appt.sex ? this.appt.sex : 'M'; // default in MB desktop
                PatTelHome = (this.appt.patTelHome && this.appt.patTelHome.replace(/\D*/g, '')) ? this.appt.patTelHome.replace(/\D*/g, '') : '';
                PatTelWork = (this.appt.patTelWork && this.appt.patTelWork.replace(/\D*/g, '')) ? this.appt.patTelWork.replace(/\D*/g, '') : '';
                PatTelCell = (this.appt.patTelCell && this.appt.patTelCell.replace(/\D*/g, '')) ? this.appt.patTelCell.replace(/\D*/g, '') : '';
                PatDOB = this.appt.patDOB ? this._help.fmtDate(new Date(this.appt.patDOB), 'ymd') : '';
                PatEmail = this.appt.patEmail ? this._help.escApos(this.appt.patEmail) : '';
                RecNo = this.appt.recNo ? this.appt.recNo : '';
                RecOffice = this.appt.recOffice ? this.appt.recOffice : '';
                RecYr = this.appt.recYr ? this.appt.recYr : '';
                InsId1 = this.appt.insId1 ? this.appt.insId1 : '0';
                InsId2 = this.appt.insId2 ? this.appt.insId2 : '0';
                Billed = this.appt.billed ? this.appt.billed : '0';
                Showed = this.appt.showed ? this.appt.showed : '0';
                RsvpSentCnt = this.appt.rsvpSentCnt ? this.appt.rsvpSentCnt : '0';
                RsvpSentDt = this.appt.rsvpSentDt ? this.appt.rsvpSentDt : '';
                PatContr1 = this.appt.patContr1 ? this._help.escApos(this.appt.patContr1) : '';
                PatGrp1 = this.appt.patGrp1 ? this._help.escApos(this.appt.patGrp1) : '';
                PatContr2 = this.appt.patContr2 ? this._help.escApos(this.appt.patContr2) : '';
                PatGrp2 = this.appt.patGrp2 ? this._help.escApos(this.appt.patGrp2) : '';
                _EligibleIns1 = this.appt.eligibleIns1 ? '1' : '0';
                _EligibleIns2 = this.appt.eligibleIns2 ? '1' : '0';
            } else {
                if (!this._help.deepEqual(this.appt, this.appt2) || this.appt.start != Start || this.appt.end != End) {
                    if (this.appt.summary != this.appt2.summary) {
                        updtSql += ",[Summary] = ''" + this.appt.summary.replace(/'/g, '`') + "''";
                    }
                    if (Start != this.appt.start || End != this.appt.end) {
                        updtSql += ",[Start] = ''" + Start + "'',[End] = ''" + End + "''";
                    }
                    if (this.appt.complaint != this.appt2.complaint) {
                        updtSql += ",[Location] = ''" + this.appt.complaint.replace(/'/g, '`') + "''";
                    }
                    if (this.appt.hxIllness != this.appt2.hxIllness) {
                        updtSql += ",[Description] = ''" + this.appt.hxIllness.replace(/'/g, '`') + "''";
                    }
                    if (this.appt.typeId != this.appt2.typeId) {
                        updtSql += ',[BackgroundID] = ' + this.appt.typeId;
                    }
                    if (this.appt.statusId != this.appt2.statusId) {
                        updtSql += ',[StatusID] = ' + this.appt.statusId;
                    }
                    if (this.appt.patID != this.appt2.patID) {
                        updtSql += ',[PatID] = ' + this.appt.patID;
                    }
                    if (this.appt.patLastNm != this.appt2.patLastNm) {
                        updtSql += ",[PatLastNm] = ''" + this.appt.patLastNm.replace(/'/g, '`') + "''";
                    }
                    if (this.appt.patFirstNm != this.appt2.patFirstNm) {
                        updtSql += ",[PatFirstNm] = ''" + this.appt.patFirstNm.replace(/'/g, '`') + "''";
                    }
                    // PatMidNm
                    if (this.appt.sex != this.appt2.sex) {
                        updtSql += ",[PatSx] = ''" + this.appt.sex + "''";
                    }
                    if (this.appt.patTelHome != this.appt2.patTelHome) {
                        updtSql += ",[PatTelHome] = ''" + this.appt.patTelHome.replace(/\D*/g, '') + "''";
                    }
                    if (this.appt.patTelWork != this.appt2.patTelWork) {
                        updtSql += ",[PatTelWork] = ''" + this.appt.patTelWork.replace(/\D*/g, '') + "''";
                    }
                    if (this.appt.patTelCell != this.appt2.patTelCell) {
                        updtSql += ",[PatTelCell] = ''" + this.appt.patTelCell.replace(/\D*/g, '') + "''";
                    }
                    if (this.appt.patDOB != this.appt2.patDOB) {
                        updtSql += ",[PatDOB] = ''" + this._help.fmtDate(new Date(this.appt.patDOB), 'ymd') + "''";
                    }
                    if (this.appt.patEmail != this.appt2.patEmail) {
                        updtSql += ",[PatEmail] = ''" + this.appt.patEmail.replace(/'/g, '`') + "''";
                    }
                    if (this.appt.recNo != this.appt2.recNo) {
                        updtSql += ",[RecNo] = ''" + this.appt.recNo + "''";
                    }
                    if (this.appt.recOffice != this.appt2.recOffice) {
                        updtSql += ",[RecOffice] = ''" + this.appt.recOffice + "''";
                    }
                    if (this.appt.recYr != this.appt2.recYr) {
                        updtSql += ",[RecYr] = ''" + this.appt.recYr + "''";
                    }
                    if (this.appt.insId1 != this.appt2.insId1) {
                        updtSql += ',[InsId1] = ' + this.appt.insId1;
                    }
                    if (this.appt.insId2 != this.appt2.insId2) {
                        updtSql += ',[InsId2] = ' + this.appt.insId2;
                    }
                    if (this.appt.billed != this.appt2.billed) {
                        updtSql += ',[Billed] = ' + this.appt.billed;
                    }
                    if (this.appt.showed != this.appt2.showed) {
                        updtSql += ',[Showed] = ' + this.appt.showed;
                    }
                    if (this.appt.rsvpSentCnt != this.appt2.rsvpSentCnt) {
                        updtSql += ',[RsvpSentCnt] = ' + this.appt.rsvpSentCnt;
                    }
                    if (this.appt.rsvpSentDt != this.appt2.rsvpSentDt) {
                        updtSql += ",[RsvpSentDt] = ''" + this.appt.rsvpSentDt + "''";
                    }
                    if (this.appt.patContr1 != this.appt2.patContr1) {
                        updtSql += ",[PatContr1] = ''" + this.appt.patContr1.replace(/'/g, '`') + "''";
                    }
                    if (this.appt.patGrp1 != this.appt2.patGrp1) {
                        updtSql += ",[PatGrp1] = ''" + this.appt.patGrp1.replace(/'/g, '`') + "''";
                    }
                    if (this.appt.patContr2 != this.appt2.patContr2) {
                        updtSql += ",[PatContr2] = ''" + this.appt.patContr2.replace(/'/g, '`') + "''";
                    }
                    if (this.appt.patGrp2 != this.appt2.patGrp2) {
                        updtSql += ",[PatGrp2] = ''" + this.appt.patGrp2.replace(/'/g, '`') + "''";
                    }
                    if (this.appt.eligibleIns1 != this.appt2.eligibleIns1) {
                        updtSql += ',[EligibleIns1] = ' + this.appt.eligibleIns1;
                    }
                    if (this.appt.eligibleIns2 != this.appt2.eligibleIns2) {
                        updtSql += ',[EligibleIns2] = ' + this.appt.eligibleIns2;
                    }
                    if (this.appt.resourceID != this.appt2.resourceID) {
                        ResourceID = this.appt.resourceID;
                    }

                    if (!updtSql && this.appt.resourceID == this.appt2.resourceID) {
                        return;
                    }
                } else {
                    return;
                }
            }

            this.waiting4ResponseNewEditAppt = true;
            this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
            this._agendaService.postInsertUpdateAppointment(pKey, SN, Summary, Start, End, _Location, Description, BackgroundID, StatusID, _PatID, PatLastNm, PatFirstNm, PatMidNm, PatSx, PatTelHome,
                PatTelWork, PatTelCell, PatDOB, PatEmail, RecNo, RecOffice, RecYr, InsId1, InsId2, Billed, Showed, RsvpSentCnt, RsvpSentDt, PatContr1, PatGrp1, PatContr2,
                PatGrp2, _EligibleIns1, _EligibleIns2, ResourceID, updtSql)
                .subscribe({
                    next: data => this.backFromInsertUpdateAppointment(data),
                    error: (err: any) => {
                        this.apptSavedOk = false;   // clear green check mark on top if it was previously saved ok
                        this.waiting4ResponseNewEditAppt = false;
                        this.waiting4Response = false;
                        this._appToastsService.updateDeadCenter(false);
                        this._appToastsService.show(
                            (this.engLang ? "Error saving the appointment!"
                                : '¡Error grabando la cita!')
                            + this._agendaService.errMsgSuggestion(this.engLang),
                            {
                                header: (
                                    err.displayMsg
                                ), autohide: false, error: true
                            }
                        );
                    }
                });
        }
    }

    backFromInsertUpdateAppointment(returnedData: any): void {

        // console.log(returnedData);

        this.waiting4ResponseNewEditAppt = false;
        if (returnedData && returnedData.length > 0 && returnedData[0].hasOwnProperty('pKey') && parseInt(returnedData[0]['pKey']) > 0) {  // parseInt(returnedData[0]['pKey']) > 0 means there was an sql insert/update

            if (returnedData[0]['hidden'] == 'false') {  //  if left as string 'true'/'false' appts will hide
                returnedData[0]['hidden'] = false;
            } else if (returnedData[0]['hidden'] == 'true') {
                returnedData[0]['hidden'] == true
            }

            if (returnedData[0]['confirm'] == '1') {    // if left as 1 or 0 confirm will be true
                returnedData[0]['confirm'] = true;
            } else {
                returnedData[0]['confirm'] = false;
            }

            let returnedSavedApptIdx: number = this.appts.findIndex((appt => appt.pKey == returnedData[0]['pKey'])); // -1 when inserting new appt because appts.pKey = '0'

            let newApptMonth = new Date(returnedData[0]["start"]).getMonth();
            let newApptDay = new Date(returnedData[0]["start"]).getDate();
            let newApptYear = new Date(returnedData[0]["start"]).getFullYear();
            let oldApptMonth = new Date(this.appt["start"]).getMonth();
            let oldApptDay = new Date(this.appt["start"]).getDate();
            let oldApptYear = new Date(this.appt["start"]).getFullYear();

            if (newApptMonth == oldApptMonth && newApptYear == oldApptYear && returnedData[0]["resourceID"] == this.appt2.resourceID) { // The appointment stayed within those selected in this.appts initially
                if (newApptDay != oldApptDay) { //  The appt changed day within current month
                    this.updtDaySelectedTotals(-1);  // Delete 1 from totals in currently displayed month
                }
                for (let key in this.appt) {    // preserve original values in appt2 to check for edits when closing the appointment
                    if (returnedData[0].hasOwnProperty(key)) {
                        this.appt[key] = returnedData[0][key];
                        if (returnedSavedApptIdx >= 0) {    // updated
                            this.appts[returnedSavedApptIdx][key] = returnedData[0][key];  // appts is the month appointments array being observed
                        }
                    }
                }
                if (newApptDay != oldApptDay) {
                    this.updtDaySelectedTotals(1);  // Increment by 1 totals in appt day in currently displayed month
                }
            } else {    // The saved appointment is not in the initially selected this.appts collection.
                if (oldApptMonth >= 0 && oldApptMonth < 12 && oldApptDay > 0 && oldApptDay < 32) {   // when oldApptMonth & oldApptDay = NaN its a new appt
                    this.updtDaySelectedTotals(-1);  // Delete 1 from totals in currently displayed month
                    this.appts.splice(returnedSavedApptIdx, 1); // The appt was moved to another month so remove it from this.appts
                    this.clearAppt();
                    this.appt.resourceID = this.currRescID;
                    this.appt.duration = this.currRescDuration;
                    this.clearErrs();
                    this.currApptID = '0';
                    this.initScroll = false;
                    this.apptSavedOk = true;    // Show green check mark saved ok
                    this.equate_appt2_to_appt_forComparisonLater(); // Prevents Exit w/o saving prompt when exiting
                    let saveBtn = <HTMLButtonElement>document.getElementById('saveNewEditAppt');
                    saveBtn.disabled = true;    // Don't allow anymore saves here
                    return;
                }
            }

            this.equate_appt2_to_appt_forComparisonLater();
            // TODO this.setConflict();

            if (this.currRescID && this.currRescID > '0' && returnedSavedApptIdx == -1) { // Do only if a resource was selected & a new appt was inserted (apptIndex = -1)
                this.updtDaySelectedTotals(1);
            }

            this.currApptTime = this.selectedTime;  // reset initial this.currApptTime to recently set & selected this.selectedTime

            let apptRescId: number = parseInt(this.appt.resourceID); // rescource ID of inserted/updated appt
            if (this.appts && this.appts.length > 0) {
                let newApptStart: any = this._help.dtFromStrJS(this.appt.start); // date to be inserted

                let i: number = 0;
                while (i < this.appts.length && parseInt(this.appts[i]['resourceID']) == apptRescId) {
                    let testDt: any = this._help.dtFromStrJS(this.appts[i]['start']);   // date to test against this.appt.start new appt
                    if (testDt / 1000 > newApptStart / 1000) {
                        break;
                    }
                    i++
                }

                if (i < this.appts.length) {
                    this.appts.splice(i, 0, this.appt);
                    if (returnedSavedApptIdx > -1) {  // appt updated
                        this.appts.splice(returnedSavedApptIdx + 1, 1); // remove updated appt
                    } else {
                        this.appts.splice(this.appts.length - 1, 1); // remove appended appt
                    }
                } else {
                    this.appts.push(this.appt);
                    this.appts.splice(returnedSavedApptIdx, 1); // remove updated appt
                }
            }

            this.clearErrs

            this.currApptID = this.appt.pKey;
            for (let i = 0; i < this.appts.length; i++) {
                this.appts[i].selected = false;    // clear previously selected
                if (this.currApptID == this.appts[i].pKey) {    // re-select if clicked
                    this.appts[i].selected = true;
                    this.appt2.selected = true;
                    this.initScroll = true; // Scroll to appt in case it moved
                }
            }
            this.apptSavedOk = true;    // show green check mark saved ok
        }
    }

    prepareStartDt(): string {
        let pm12: number = (this.selectedTime.match(/p$/g) && parseInt(this.selectedTime.match(/^\d{1,2}/g)[0]) < 12) ? 12 : 0;
        let hr: number = parseInt(this.selectedTime.match(/^\d{1,2}/g)[0]) + pm12;
        let min: number = parseInt(this.selectedTime.match(/\:\d{1,2}/g)[0].replace(':', ''));
        let dt = new Date(this.apptDtpSelected);
        return this._help.fmtDate(new Date(dt.getFullYear(), dt.getMonth(), 1 + dt.getDate(), hr, min), 'js');
    }

    prepareEndDt(Start: string): string {   // example: Start = '2021-11-18T13:00:00.000Z'  for 1pm
        let offset = new Date().getTimezoneOffset();    // offset = 240 minutes or 4 hrs for PR
        let en = moment(Start).add(offset + parseInt(this.selectedDuration), "minutes").toDate();   // en = Thu Nov 18 2021 14:00:00 GMT-0400 (Bolivia Time)
        return this._help.fmtDate(en, 'js'); // returns '2021-11-18T14:00:00.000Z' if this.selectedDuration = '60'
    }

    focusOutJqryElement(event: any): void { // since using jQuery [(ngModel)] doesnt work on time & need this event to update the model
        let id = event.target.id;
        switch (id) {
            case 'patDOB': {
                this.appt.patDOB = event.target.value;
                break;
            }
            case 'patTelCell': {
                this.appt.patTelCell = event.target.value;
                break;
            }
            case 'patTelWork': {
                this.appt.patTelWork = event.target.value;
                break;
            }
            case 'patTelHome': {
                this.appt.patTelHome = event.target.value;
                break;
            }
        }
    }

    undueApptEditsAfterYesExitWoSaving(): void {
        // for (var key in this.appt2) {    // preserve original values in appt2 to check for edits when closing the appointment
        //     if (this.appt.hasOwnProperty(key)) {
        //         if (this.appt2[key] != this.appt[key]) {
        //             this.appt[key] = this.appt2[key];
        //         }
        //     }
        // }
        if (this.selectedResourceID) {
            this.selectedDuration = this.currRescDuration;
        }   // restore to original selection just in case it was changed within the appointment
        // this.selectedDuration = this.appt2.duration;
    }

    equate_appt2_to_appt_forComparisonLater(): void {
        for (var key in this.appt) {    // preserve original values in appt2 to check for edits when closing the appointment
            this.appt2[key] = this.appt[key];
        }
    }

    clearErrs(): void {
        this.patLastNmErr = false;
        this.patFirstNmErr = false;
        this.patDOBErr = false;
        this.patSexErr = false;
        this.patEmailErr = false;
        this.patTelCellErr = false;
        this.patTelWorkErr = false;
        this.patTelHomeErr = false;
        this.insId1Err = false;
        this.insAlias1Err = false;
        this.insId2Err = false;
        this.insAlias2Err = false;
        this.durationErr = false;
        this.resourceErr = false;
        this.timeErr = false;

        this.apptSavedOk = false;
    }

    deleteApptPrompt(apptPkey: string): void {
        let i = this.appts.findIndex(a => a.pKey === apptPkey);
        this.appt2 = this.appts[i];
        let dt: Date = this._help.dtFromStrJS(this.appts[i].start);

        let engMo: string = this.engMonth[dt.getMonth()];
        let spaMo: string;
        let engDy: string = this.engDay[dt.getDay()];
        let spaDy: string;
        if (!this.engLang) {
            spaMo = this.spaMonth[dt.getMonth()];
            spaDy = this.spaDay[dt.getDay()];
        } else {
            spaMo = engMo;  // to substitute engMo for itself when english
            spaDy = engDy;  // to substitute engDy for itself when english
        }

        let apM: string;
        if (dt.getHours() >= 12) {
            apM = ' PM';
        } else {
            apM = ' AM';
        }

        let hr: string = dt.getHours() > 12 ? (dt.getHours() - 12).toString() : dt.getHours().toString();
        let mi: string = dt.getMinutes() < 10 ? '0' + dt.getMinutes().toString() : dt.getMinutes().toString();

        this.open(this.apptDeleteModal, { backdrop: 'static' }, 'apptDeleteModal');

        document.getElementById('apptDelDtTm').innerHTML = '<div class="text-center">'
            + dt.toDateString().replace(engMo, spaMo).replace(engDy, spaDy)
            + '</div><div class="text-center">'
            + hr + ':' + mi + apM + '</div>';
    }

    deleteAppt(pKey: string): void {
        document.getElementById('apptDelDtTm').innerHTML
            += '<div class="text-center"><img src="app/assets/images/waiting4Response.gif" width="42" height="42" alt="Waiting for response"></div>'
        this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
        this._agendaService.deleteAppointment(pKey, this.sn)
            .subscribe({
                next: data => {
                    if (data[0]['rows'] == 1) {
                        let i = this.appts.findIndex(item => item.pKey === this.appt2.pKey);
                        this.appts.splice(i, 1);
                        this.updtDaySelectedTotals(-1); // substract 1 to total appts in day selected
                        this.currApptID = '0';  // No appt selected
                        this.initScroll = false;
                    }
                },
                error: (err: any) => {
                    this.apptSavedOk = false;   // clear green check mark on top if it was previously saved ok
                    this.waiting4ResponseNewEditAppt = false;
                    this.waiting4Response = false;
                    this._appToastsService.updateDeadCenter(false);
                    this._appToastsService.show(
                        (this.engLang ? "Error deleteing the appointment!"
                            : '¡Error eliminando la cita!')
                        + this._agendaService.errMsgSuggestion(this.engLang),
                        {
                            header: (
                                err.displayMsg
                            ), autohide: false, error: true
                        }
                    );
                }
            });
    }

    updtDaySelectedTotals(inc: number): void {  // inc = 1 to increment by 1 when added an appt, - to decrement
        let prevMoOffset: number = 0;   // Remaining days of previous month that show in calendar's first week.
        for (let d = 0; d < 7; d++) {   // Iterate through week 0 (topmost)
            if (this.calDay[0][d] == 1) {    // Find index d of day 1st
                prevMoOffset = d;
                break;
            }
        }

        for (let w = 0; w < 5; w++) {   // Iterate thrugh all weeks (calendar rows)
            for (let d = 0; d < 7; d++) {   // Iterate through days in current week w
                if (inc == -1) { // decrement by 1
                    if (w > 0 || (d >= prevMoOffset && w == 0)) {   // only applies to week 0 (w = 0); start cking on 1st of month
                        if (this.calDay[w][d] == parseInt(this.appt2.d)) {
                            this.dayTotals[w][d] = (!this.dayTotals[w][d] || this.dayTotals[w][d] == 1) ? '' : this.dayTotals[w][d] - 1;
                            return;
                        }
                    }
                } else {    // increment by 1
                    if (w > 0 || (d >= prevMoOffset && w == 0)) {   // only applies to week 0 (w = 0); start cking on 1st of month
                        if (this.calDay[w][d] == parseInt(this.appt2.d)) {
                            this.dayTotals[w][d] = !this.dayTotals[w][d] ? 1 : 1 + this.dayTotals[w][d];
                            return;
                        }
                    }
                }
            }
        }
    }

    engLangChange(engLangStr: string) { // for when language changes
        engLangStr === 'true' ? this.engLang = true : this.engLang = false;
    }

    snChange(snStr: string) { // for when sn changes
        this.sn = snStr;
    }

    siteChange(siteStr: string) { // for when site changes
        this.siteNm = siteStr;
    }

    userIdChange(userIdStr) {
        this.userID = userIdStr;
    }

    userLastNmChange(userLastNmStr) {
        this.userLastNm = userLastNmStr;
    }

    userFirstNmChange(userFirstNmStr) {
        this.userFirstNm = userFirstNmStr;
    }

    confirmAppt(event, appt): void {
        this.tmpVar = appt['pKey'];
        let st;
        if (event.target.checked) {
            st = this._help.apptStatuses.find(item => item['status'] == 'Confirmado');
        } else {
            st = this._help.apptStatuses.find(item => item['status'] == 'Nada');
        }
        let statusId = st['ID'];

        this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
        this._agendaService.postInsertUpdateAppointment(appt.pKey, this.sn, '', appt.start, appt.end, '', '', '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
            '', '', '', appt.resourceID, ',[StatusID] = ' + statusId)
            .subscribe({
                next: data => {
                    if (data?.length == 1) {
                        let i = this.appts.findIndex(item => item['pKey'] == this.tmpVar);
                        this.appts[i].status = data[0]['status'];
                        this.appts[i].statusId = data[0]['statusId'];
                        this.appts[i].statusColor = data[0]['statusColor'];
                        this.appts[i].confirm = data[0]['confirm'] == 1 ? true : false;
                    }
                    this.tmpVar = undefined;    // Clean temp variable
                },
                error: (err: any) => {
                    this.apptSavedOk = false;   // clear green check mark on top if it was previously saved ok
                    this.waiting4ResponseNewEditAppt = false;
                    this.waiting4Response = false;
                    this._appToastsService.updateDeadCenter(false);
                    this._appToastsService.show(
                        (this.engLang ? "Error saving the appointment to confirm!"
                            : '¡Error grabando la cita para confirmar!')
                        + this._agendaService.errMsgSuggestion(this.engLang),
                        {
                            header: (
                                err.displayMsg
                            ), autohide: false, error: true
                        }
                    );
                }
            });
    }

    setConflict(cita: any): boolean {
        return this.appts.filter(item =>
            item.pKey != cita.pKey
            && !cita.start.match(/T00:00:00.000Z$/g)
            && item.start.match(/\d{4}\-\d{2}\-\d{2}/g)[0] == cita.start.match(/\d{4}\-\d{2}\-\d{2}/g)[0]
            && ((moment(item.start).isSameOrAfter(cita.start) && moment(item.start).isBefore(cita.end))
                || (moment(item.end).isAfter(cita.start) && moment(item.end).isSameOrBefore(cita.end)))
        ).length == 0 ? false : true;
    }

    appt_notEq_appt2(orgApptDate): boolean {    // returns true if appt was edited
        for (let key in this.appt) {    // ck original values in appt2 against appt to check for edits when closing the appointment
            if (this.appt2.hasOwnProperty(key)) {
                if (!orgApptDate && key == 'duration') {
                    continue;   // continue for - don't ck when exiting a new appt
                }

                // console.log(key + ' = ' + this.appt[key]);
                // console.log(this.appt[key]);

                switch (key) {
                    case 'selected':    // don't ck values
                        continue;

                    case 'statusHatch': // don't ck values
                        continue;

                    case 'confirm': // boolean ck
                        if (this.appt2[key] != this.appt[key]) {
                            return true;
                        }
                        break;

                    case 'conflict': // boolean ck
                        break;

                    default:

                        if (this.appt2[key] && this.appt[key]) {

                            // console.log(key);
                            // console.log(this.appt[key]);
                            // console.log(this.appt2[key]);

                            console.log(key);
                            if (this.appt2[key].trim() != this.appt[key].trim()) {
                                // console.log('1 >' + this.appt[key] + '<');
                                // console.log('2 >' + this.appt2[key] + '<');
                                // console.log('--- changed ---');
                                return true;
                            }
                        } else if ((!this.appt2[key] && this.appt[key]) || (this.appt2[key] && !this.appt[key])) {
                            return true;
                        }
                }
            }
        }
        return false;
    }

    escapeApptEditModal(event): void {
        if (event.keyCode == 27) {
            event.stopPropagation();
            this.closeAppt();
        }
    }

    open(content, opts, modalNm) {
        // this.clearPoppers();
        this.modalStack.push(modalNm);
        if (modalNm == 'apptEditModal') {
            this.modalRef = this._modalService.open(content);   // Reference to parent of other forms so can be closed when closing child.
        }

        this._modalService.open(content, opts).result.then((result) => {    // Buttons

            if (result == 'closeApptEditModal' || result == 'x_closeApptEditModal') {
                if (!this.closeAppt()) {    // True if appt was edited
                    this.undueApptEditsAfterYesExitWoSaving();
                    this.modalStack.pop();
                    this.modalStack.pop();
                    this.modalRef.close();
                    this.scrollToAppt();
                }
            }
            if (result == 'yes_apptExitWoSaveModal') {
                this.undueApptEditsAfterYesExitWoSaving();
                this.modalRef.close();
                this.modalStack.pop();
                this.modalStack.pop();
                this.scrollToAppt();
            }
            if (result == 'no_apptExitWoSaveModal') {
                this.modalStack.pop();
                this.modalStack.pop();
                this.modalRef.close();
                this.open(this.apptEditModal, { backdrop: 'static' }, 'apptEditModal');
            }
            if (result == 'yes_apptDeleteModal') {
                this.deleteAppt(this.appt2.pKey);
                this.modalStack.pop();
            }
            if (result == 'oK_recordCaseModal') {
                this.modalStack.pop();
            }

            this.waiting4Response = false;
            console.log('result = ' + result);

        }, (reason) => {    // Close

            console.log(this.modalStack);
            switch (this.modalStack[this.modalStack.length - 1]) {
                case 'apptEditModal':
                    if (reason == 'closeApptEditModal' || reason == 1) {    // 1 => Esc key
                        if (this.closeAppt()) {
                            return;
                        }
                        this.modalRef.close();
                    }
                    break;
                case 'apptExitWoSaveModal':
                    if (reason == 'yes_apptExitWoSaveModal') {
                        this.modalStack.pop();  // Pop out apptExitWoSaveModal
                        this.undueApptEditsAfterYesExitWoSaving();
                        this.modalRef.close();
                    } else {
                        return;
                    }
                    break;
                default:
            }

            this.modalStack.pop();
            this.waiting4Response = false;
            console.log('reason = ' + reason); // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    prepHoursMins12(st: string, en: string, idx: number): string {
        if (idx < this.apptsGroups.length - 1) {
            return (moment(st, 'HH:mm').format('h:mmA').replace(/M/gi, ' - ') + moment(en, 'HH:mm').format('h:mmA').replace(/M/gi, '')).toLowerCase();
        } else {
            return this.engLang ? 'All' : 'Todos';
        }
    }

    deleteGroup(id: string): void {
        this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
        this._agendaService.deleteAppointmentsGroup(this.sn, id)
            .subscribe({
                next: (data: any) => {
                    if (data && data[0]['deletedRows'] == 1) {
                        this._appToastsService.updateDeadCenter(false);
                        this._appToastsService.show(this.engLang ? 'Group deleted.' : 'Grupo eliminado.',
                            { header: 'OK', autohide: true, success: true });
                        let i: number = this.apptsGroups.findIndex(grp => grp.pkID == data[0]['pkID']);
                        this.apptsGroups.splice(i, 1);
                        this.selectedGrpIndex = undefined;
                        this.grpFilter = false;
                    }
                },
                error: (err: any) => {
                    this._appToastsService.updateDeadCenter(false);
                    this._appToastsService.show(
                        (this.engLang ? "Failed to delete Group."
                            : 'No se pudo eliminar el Grupo.')
                        + this._agendaService.errMsgSuggestion(this.engLang),
                        {
                            header: (
                                err.displayMsg
                            ), autohide: false, error: true
                        }
                    );
                }
            });
    }

    saveGroup(newGrp: IAppointmentsGroups, idx: number, event: any): void {
        if (newGrp.startTm.match(/^\d{2}:\d{2}$/g) && newGrp.endTm.match(/^\d{2}:\d{2}$/g)) {
            let dtSt: Date = new Date(1980, 1, 1, parseInt(newGrp.startTm.substring(0, 2)), parseInt(newGrp.startTm.substring(3, 5)));
            let dtEn: Date = new Date(1980, 1, 1, parseInt(newGrp.endTm.substring(0, 2)), parseInt(newGrp.endTm.substring(3, 5)));
            if (dtSt < dtEn) {
                event.stopPropagation();    // To block event selectGroupRw(idx)
                newGrp.name = (newGrp.name === 'undefined' ? '' : newGrp.name);    // Get rid of undefined
                this.grpIndexErr = 999; // No error
                this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
                this._agendaService.postAppointmentsGroups(newGrp)
                    .subscribe({
                        next: (data: IAppointmentsGroups) => {
                            // console.log(data);
                            let i: number = this.apptsGroups.findIndex(grp => grp.pkID == newGrp.pkID);
                            this.apptsGroups[idx] = data[0];

                            if (idx == 0) {
                                // Add new blank group
                                this.apptsGroups.splice(0, 0, {
                                    "pkID": '0',
                                    "startTm": "",
                                    "endTm": "",
                                    "name": "",
                                    "sn": this.sn,
                                    "saveBtn": 0
                                });
                            }

                            this._appToastsService.updateDeadCenter(true);
                            this._appToastsService.show(this.engLang ? 'Group saved!' : '¡Grupo grabado!',
                                { header: 'OK', autohide: true, success: true });
                        },
                        error: (err: any) => {
                            this._appToastsService.updateDeadCenter(false);
                            this._appToastsService.show(err.displayMsg,
                                {
                                    header: (this.engLang ? 'Failed to save group.' : 'No se pudo grabar el grupo.')
                                        + this._agendaService.errMsgSuggestion(this.engLang)
                                    , autohide: false, error: true
                                });
                        }
                    });
            } else {
                this.grpIndexErr = idx; // Error, don't save
                this.selectedGrpIndex = undefined;
                this.grpFilter = false;
            }
        }
    }

    selectGroupRw(idx: number) {
        if (this.grpIndexErr < 999) {    // There is an error
            this.selectedGrpIndex = undefined;
            this.grpFilter = false;
        } else {
            if (idx >= this.apptsGroups.length - 1) {
                this.selectedGrpIndex = undefined;
                this.grpFilter = false;
            } else {
                this.selectedGrpIndex = idx;
                this.grpStartTm = this.apptsGroups[idx].startTm;
                this.grpEndTm = this.apptsGroups[idx].endTm;
                this.apptsGroups[idx].saveBtn = 0;
                this.grpFilter = true;
                this.itemsPerPage = 200;    // Disables paging temporarily
                this.currentPg = 1;
            }
        }
    }

    groupEditErr(idx: number): boolean {
        return this.grpIndexErr == idx;
    }

    grpIsDirty(grp: any): boolean {
        let id: number = grp.pkID;
        let st: HTMLInputElement = <HTMLInputElement>document.getElementById('st' + id);
        let en: HTMLInputElement = <HTMLInputElement>document.getElementById('en' + id);
        let nm: HTMLInputElement = <HTMLInputElement>document.getElementById('nm' + id);
        if (grp.saveBtn || (st?.classList && st.classList.contains('ng-dirty'))) {
            grp.saveBtn = 1;
            return true;
        } else if (grp.saveBtn || (en?.classList && en.classList.contains('ng-dirty'))) {
            grp.saveBtn = 1;
            return true;
        } else if (grp.saveBtn || (nm?.classList && nm.classList.contains('ng-dirty'))) {
            grp.saveBtn = 1;
            return true;
        } else {
            return false;
        }
    }

    onKeyUp_saveGroup(event, grp, idx): void {
        if (event?.key == 'Enter') {
            event.stopPropagation();
            this.saveGroup(grp, idx, event)
        }
    }

    setNewRecordNo(event: any): void {
        console.log(event);
        if ((event.patRecNo.match(/^\d+\-\d{4}\-\d{2}$/g) && +event.patID > 0) || (event.patRecNo == '' && +event.patID == 0)) {    // Created the record or deleted it
            let recNo = this.appts[this.currApptIdx].recNo + '-' + this.appts[this.currApptIdx].recOffice + '-' + this.appts[this.currApptIdx].recYr;
            if (+event.patID > 0) { // Updating the record no.
                if (recNo != event.patRecNo || this.appts[this.currApptIdx].patID != event.patID) {
                    this.appts[this.currApptIdx].recNo = (+event.patRecNo.match(/^\d+/g)[0]).toString();    // Removes leading zeroes
                    this.appts[this.currApptIdx].recOffice = (event.patRecNo.match(/\-\d{4}\-/g)[0]).replaceAll('-', '');
                    this.appts[this.currApptIdx].recYr = event.patRecNo.match(/\d{2}$/g)[0];
                    this.appts[this.currApptIdx].patID = event.patID;
                }
            } else {    // Deleted the record
                this.appts[this.currApptIdx].recNo = '';
                this.appts[this.currApptIdx].recOffice = '';
                this.appts[this.currApptIdx].recYr = '';
                this.appts[this.currApptIdx].patID = '0';
            }

            let data = {
                'sn': this.sn,
                'pKey': this.appts[this.currApptIdx].pKey,
                'patID': this.appts[this.currApptIdx].patID,
                'recNo': this.appts[this.currApptIdx].recNo,
                'recOffice': this.appts[this.currApptIdx].recOffice,
                'recYr': this.appts[this.currApptIdx].recYr
            };
            this._agendaService.postAppointmentUpdatdRecordNo(data)
                .subscribe({
                    next: (data: any) => {
                        // console.log(data);
                        if (data[0].rows == 1) {
                            this._appToastsService.updateDeadCenter(true);
                            this._appToastsService.show(this.engLang ? 'Appointment Record No. updated!' : '¡No. de Record actualizado en cita!',
                                { header: 'OK', autohide: true, success: true });
                        }
                    },
                    error: (err: HttpErr) => {
                        this._appToastsService.updateDeadCenter(false);
                        this._appToastsService.show(err.message,
                            {
                                header: (this.engLang ? 'Failed to update Record No.' : 'No se actualizó el No. de Record.')
                                    + this._agendaService.errMsgSuggestion(this.engLang)
                                , autohide: false, error: true
                            });
                    }
                });
        }
    }

    printAppts(): void {
        let dy = this.currDt.getDay();
        let dys: string;
        if (this.engLang) {
            dys = this.engDay[dy];
        } else {
            dys = this.spaDay[dy];
        }

        let citas: any = {
            "siteNm": this.siteNm,
            "startDt": this.displayDt,
            "dayOfWk": dys,
            "resourceNm": this.apptsRescs.find(itm => { return itm.pKey == this.currRescID }).name + '(' + this.currRescID + ')',
            "appts": [],
            "printDt": moment(new Date(), moment.defaultFormatUtc).format("m/d/yy hh:mm a"),
            "total": 0,
            "p1": 0,
            "p2": 99
        }

        let cnt = 0;    // Total count
        for (let i = 0; i < this.appts.length; i++) {
            if (new Date(this.appts[i].start).getDate() == new Date(this.displayDt).getDate()) {
                cnt += 1;
                citas.appts.push({
                    "startTm": moment(this.appts[i].start, moment.defaultFormatUtc).format("hh:mm a")   // Without moment.defaultFormatUtc you get local time - 4:00
                    , "recordNo": this.appts[i].recNo
                    , "ofic": this.appts[i].recOffice
                    , "yr": this.appts[i].recYr
                    , "summary": this.appts[i].summary
                    , "telHm": this.appts[i].patTelHome
                    , "telCl": this.appts[i].patTelCell
                    , "ins1": this.appts[i].insAlias1 + '(' + this.appts[i].insId1 + ')'
                    , "ins2": this.appts[i].insAlias2 + '(' + this.appts[i].insId2 + ')'
                    , "typ": this.appts[i].type
                    , "stat": this.appts[i].status
                    , "complaint": this.appts[i].complaint
                });
            }
        }
        citas.total = cnt;

        let bdy = {
            "template":
            {
                "name": "/appointments/appointments-html"
            }, "data": citas,
            "options": { "reports": { "save": true } }
        };

        this._agendaService.proc = new Error().stack.split('\n')[1];    // For error logging in _agendaService
        this._agendaService.postRenderJsRpt(bdy).subscribe({
            next: blob => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
                    let win = window.open();
                    win.document.open();
                    win.document.write(iframe);
                    win.document.close();
                };
            },
            error: (err: any) => {
                this._appToastsService.updateDeadCenter(false);
                this._appToastsService.show(err.displayMsg,
                    {
                        header: (this.engLang ? 'Failed to render appointment list.' : 'No se pudo presentar el listado de citas.')
                            + this._agendaService.errMsgSuggestion(this.engLang)
                        , autohide: false, error: true
                    });
            }
        });
    }

}