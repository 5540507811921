
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HelperRtnsComponent } from '../shared/helper-rtns.component';
import { ICustomer } from '../shared/interfaces/customer';

@Injectable()
export class LoginService {
    
    constructor(private http: HttpClient, private _http: HttpClient, private _help: HelperRtnsComponent) { }

    private extractData(res: Response)  {
        let returnedData = res;
        // console.log('returnedData = ' + returnedData);
        return returnedData || { };
    }

    getCredsAuthenticated(emailOrUserNm: string, email: string, userNm: string, pw: string, lastNm: string, firstNm: string): Observable<any> {
        return this._http.get(this._help.urlDB + '/api/get-authenticated/' + emailOrUserNm + '/' + email + '/' + userNm + '/' + pw + '/' + lastNm + '/' + firstNm)
            .pipe(map(this.extractData));
    }

    postNewRegistration(newRegistration: ICustomer): Observable<any> {
        let body = JSON.stringify(newRegistration);
        let headers = { 'Content-Type': 'application/json' };

        return this._http.post(this._help.urlDB + '/api/post-newRegistration', body, { headers })
            .pipe(map(this.extractData));
    }

    get_MB_DesktopRevisions(): Observable<any> {
        return this._http.get(this._help.urlDB + '/api/get-mbDesktopRevisions')    
            .pipe(map(this.extractData));
    }

}