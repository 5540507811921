import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Key } from 'protractor';

@Component({
  selector: 'app-claim-adjust-modal-prompt',
  templateUrl: './claim-adjust-modal-prompt.component.html',
  styleUrls: ['./claim-adjust-modal-prompt.component.css']
})
export class ClaimAdjustModalPromptComponent implements OnInit {
  @Input() engLang: boolean = true;
  @Input() adjustRemove: boolean;
  @Input() undoPayment: boolean;
  @Output() adjModalPromptResult: EventEmitter<string> = new EventEmitter();

  @ViewChild('claimAdjustConfirmModal', { read: TemplateRef, static: true }) claimAdjustConfirmModal: TemplateRef<any>;

  constructor(private _modalService: NgbModal,) { }

  ngOnInit(): void {
    this._modalService.open(this.claimAdjustConfirmModal,
      { backdrop: 'static', size: 'sm', scrollable: false })
      .result.then(
        (result) => {
          this.adjModalPromptResult.emit(result);
        },
        (reason) => {
          this.adjModalPromptResult.emit(reason);
        }
      )
  }

}
