import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { HelperRtnsComponent } from './helper-rtns.component';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  postHeaders: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  sendSMS(telTo: string, msg: string) {

    let body = JSON.stringify({ telTo, msg });
    let headers = { 'Content-Type': 'application/json' };

    this._http.post(this._help.urlDB + '/api/post-twilioSMS', body, { headers })
      .subscribe(response => response);
  }

  handleError(error: any): any {
    let act = undefined;
    if (error && parseInt(error.status) > 0) {
      act = "VERIFIQUE SI TIENE INTERNET - VERIFY YOU HAVE INTERNET"
    }

    let err = {
      "status": error.status,
      "statusText": error.statusText,
      "message": error.message,
      "action": act
    };
    return err;
  }

  constructor(private _http: HttpClient,
    private _help: HelperRtnsComponent) { }
}
