import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { IReclaims, IReclaimsDet } from 'src/app/shared/interfaces/reclaims';
import { CkRegisterService } from '../ck-register.service';
import { AppToastsService } from 'src/app/shared/app-toasts/app-toasts.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-recl-xml-sss-formulario-ajuste070114',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './recl-xml-sss-formulario-ajuste070114.component.html',
  styleUrls: ['./recl-xml-sss-formulario-ajuste070114.component.css']
})
export class ReclXmlSssFormularioAjuste070114Component implements OnDestroy {

  @Input() reclaims: IReclaims[];
  @Input() reclaimsDet: IReclaimsDet[];
  @Input() idxSeld: number;
  @Input() reclXml: string;
  @Input() reclFormImg64Seled: string;
  @Input() reclLineReasonEntered: any;
  @Input() clrData: boolean = false;  // Set true after deleteing
  @Input() print: boolean = false;

  @Output() need2Save = new EventEmitter<string>();

  e: any = {
    opt0_plan: '',

    txt2_1: '',
    txt2_1_1: '',
    txt2_1_2: '',
    txt2_1_3: '',
    txt2_1_4: '',
    txt2_1_5: '',
    txt2_1_6: '',
    txt2_1_7: '',
    txt2_1_8: '',
    txt2_1_9: '',
    txt2_1_10: '',
    txt2_1_11: '',
    txt2_1_12: '',
    txt2_1_13: '',

    txt2_2: '',
    txt2_2_1: '',
    txt2_2_2: '',
    txt2_2_3: '',
    txt2_2_4: '',
    txt2_2_5: '',
    txt2_2_6: '',
    txt2_2_7: '',
    txt2_2_8: '',
    txt2_2_9: '',
    txt2_2_10: '',
    txt2_2_11: '',
    txt2_2_12: '',
    txt2_2_13: '',
    txt2_2_14: '',
    txt2_2_15: '',

    txt3_101: '',
    med3_102: '',
    med3_103: '',
    txt3_104: '',
    txt3_105: '',
    txt3_106: '',
    txt3_107: '',
    txt3_108: '',
    txt3_109: '',
    txt3_110: '',
    txt3_111: '',
    med3_112: '',

    txt3_201: '',
    med3_202: '',
    med3_203: '',
    txt3_204: '',
    txt3_205: '',
    txt3_206: '',
    txt3_207: '',
    txt3_208: '',
    txt3_209: '',
    txt3_210: '',
    txt3_211: '',
    med3_212: '',

    txt3_301: '',
    med3_302: '',
    med3_303: '',
    txt3_304: '',
    txt3_305: '',
    txt3_306: '',
    txt3_307: '',
    txt3_308: '',
    txt3_309: '',
    txt3_310: '',
    txt3_311: '',
    med3_312: '',

    chk4_1: '',
    txt4_2: '',
    txt4_2_1: '',
    txt4_2_2: '',
    txt4_2_3: '',
    txt4_2_4: '',
    txt4_2_5: '',

    txt4_3: '',
    txt4_3_1: '',
    txt4_3_2: '',
    txt4_3_3: '',
    txt4_3_4: '',
    txt4_3_5: '',

    med4_4: '',
    txt4_4_1: '',
    txt4_4_2: '',
    txt4_4_3: '',

    txt5_1: '',  // Must be initialized as such because string will be appended to
    txt5_1_1: ''
  }

  constructor(private _ckRegisterService: CkRegisterService,
    private _appToastsService: AppToastsService
  ) { }

  ngOnInit(): void {
    // this.setReclaimDefaults();

    // if (this.reclaims[this.idxSeld].reclXml.match(/>\w+</g)) { // If xml has data
    //   this.decodeXmlRecl();
    // }

    this.decodeXmlRecl();
    this.setReclaimDefaults();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check if @Input() reclLineReasonEntered has changed
    if (changes.reclLineReasonEntered && this.reclLineReasonEntered) {
      console.log(this.reclLineReasonEntered)
      if (this.e.txt5_1_1) {
        this.e.txt5_1_1 += ', ';
      }
      this.e.txt5_1_1 += (this.reclLineReasonEntered.cpt
        + ' ' + this.reclLineReasonEntered.mods
        + ' ' + this.reclLineReasonEntered.from
        + ' ' + this.reclLineReasonEntered.reason.trim());
    }
    if (changes.clrData?.currentValue) {
      this.clearData();
    }
    if (changes.print?.currentValue) {
      this.printReclaim();
    }
    if (changes.idxSeld) {
      this.e = {};  // Clear form data
      this.decodeXmlRecl();
    }
  }

  ngOnDestroy() {
    this.e = undefined;
  }

  clearData(): void { // Clears all elements with default data.
    Object.keys(this.e).forEach(key => this.e[key] = '');
    this.setReclaimDefaults();
  }

  onChange_need2Save(event): void {
    this.setTxtsDefinedInXml();
    this.need2Save.emit('');
  }

  setTxtsDefinedInXml() {
    this.e.txt2_1 = (this.e.txt2_1_1 + ' ').substring(0, 1) +
      (this.e.txt2_1_2 + ' ').substring(0, 1) +
      (this.e.txt2_1_3 + ' ').substring(0, 1) +
      (this.e.txt2_1_4 + ' ').substring(0, 1) +
      (this.e.txt2_1_5 + ' ').substring(0, 1) +
      (this.e.txt2_1_6 + ' ').substring(0, 1) +
      (this.e.txt2_1_7 + ' ').substring(0, 1) +
      (this.e.txt2_1_8 + ' ').substring(0, 1) +
      (this.e.txt2_1_9 + ' ').substring(0, 1) +
      (this.e.txt2_1_10 + ' ').substring(0, 1) +
      (this.e.txt2_1_11 + ' ').substring(0, 1) +
      (this.e.txt2_1_12 + ' ').substring(0, 1) +
      (this.e.txt2_1_13 + ' ').substring(0, 1);

    this.e.txt2_2 = (this.e.txt2_2_1 + ' ').substring(0, 1) +
      (this.e.txt2_2_2 + ' ').substring(0, 1) +
      (this.e.txt2_2_3 + ' ').substring(0, 1) +
      (this.e.txt2_2_4 + ' ').substring(0, 1) +
      (this.e.txt2_2_5 + ' ').substring(0, 1) +
      (this.e.txt2_2_6 + ' ').substring(0, 1) +
      (this.e.txt2_2_7 + ' ').substring(0, 1) +
      (this.e.txt2_2_8 + ' ').substring(0, 1) +
      (this.e.txt2_2_9 + ' ').substring(0, 1) +
      (this.e.txt2_2_10 + ' ').substring(0, 1) +
      (this.e.txt2_2_11 + ' ').substring(0, 1) +
      (this.e.txt2_2_12 + ' ').substring(0, 1) +
      (this.e.txt2_2_13 + ' ').substring(0, 1) +
      (this.e.txt2_2_14 + ' ').substring(0, 1) +
      (this.e.txt2_2_15 + ' ').substring(0, 1);

    this.e.txt3_101 = (this.e.txt3a_1_1 + ' ').substring(0, 1) +
      (this.e.txt3a_1_2 + ' ').substring(0, 1);
    this.e.med3_102 = (this.e.txt3a_2_1 + ' ').substring(0, 1) +
      (this.e.txt3a_2_2 + ' ').substring(0, 1) +
      (this.e.txt3a_2_3 + ' ').substring(0, 1) +
      (this.e.txt3a_2_4 + ' ').substring(0, 1) +
      (this.e.txt3a_2_5 + ' ').substring(0, 1) +
      (this.e.txt3a_2_6 + ' ').substring(0, 1) +
      (this.e.txt3a_2_7 + ' ').substring(0, 1) +
      (this.e.txt3a_2_8 + ' ').substring(0, 1);
    this.e.med3_103 = (this.e.txt3a_3_1 + ' ').substring(0, 1) +
      (this.e.txt3a_3_2 + ' ').substring(0, 1) +
      (this.e.txt3a_3_3 + ' ').substring(0, 1) +
      (this.e.txt3a_3_4 + ' ').substring(0, 1) +
      (this.e.txt3a_3_5 + ' ').substring(0, 1) +
      (this.e.txt3a_3_6 + ' ').substring(0, 1) +
      (this.e.txt3a_3_7 + ' ').substring(0, 1) +
      (this.e.txt3a_3_8 + ' ').substring(0, 1);
    this.e.txt3_104 = (this.e.txt3a_4_1 + ' ').substring(0, 1) +
      (this.e.txt3a_4_2 + ' ').substring(0, 1);
    this.e.txt3_105 = (this.e.txt3a_5_1 + ' ').substring(0, 1) +
      (this.e.txt3a_5_2 + ' ').substring(0, 1) +
      (this.e.txt3a_5_3 + ' ').substring(0, 1) +
      (this.e.txt3a_5_4 + ' ').substring(0, 1) +
      (this.e.txt3a_5_5 + ' ').substring(0, 1);
    this.e.txt3_106 = (this.e.txt3a_6_1 + ' ').substring(0, 1) +
      (this.e.txt3a_6_2 + ' ').substring(0, 1);
    this.e.txt3_107 = (this.e.txt3a_7_1 + ' ').substring(0, 1) +
      (this.e.txt3a_7_2 + ' ').substring(0, 1);
    this.e.txt3_108 = (this.e.txt3a_8_1 + ' ').substring(0, 1) +
      (this.e.txt3a_8_2 + ' ').substring(0, 1);
    this.e.txt3_109 = (this.e.txt3a_9_1 + ' ').substring(0, 1) +
      (this.e.txt3a_9_2 + ' ').substring(0, 1);
    this.e.txt3_110 = (this.e.txt3a_10_1 + ' ').substring(0, 1) +
      (this.e.txt3a_10_2 + ' ').substring(0, 1) +
      (this.e.txt3a_10_3 + ' ').substring(0, 1) +
      (this.e.txt3a_10_4 + ' ').substring(0, 1) +
      (this.e.txt3a_10_5 + ' ').substring(0, 1) +
      (this.e.txt3a_10_6 + ' ').substring(0, 1) +
      (this.e.txt3a_10_7 + ' ').substring(0, 1);
    this.e.txt3_111 = (this.e.txt3a_11_1 + ' ').substring(0, 1) +
      (this.e.txt3a_11_2 + ' ').substring(0, 1) +
      (this.e.txt3a_11_3).substring(0, 1);
    this.e.med3_112 = (this.e.txt3a_12_1 + ' ').substring(0, 1) +
      (this.e.txt3a_12_2 + ' ').substring(0, 1) +
      (this.e.txt3a_12_3 + ' ').substring(0, 1) +
      (this.e.txt3a_12_4 + ' ').substring(0, 1) +
      (this.e.txt3a_12_5 + ' ').substring(0, 1) +
      (this.e.txt3a_12_6 + ' ').substring(0, 1) +
      (this.e.txt3a_12_7 + ' ').substring(0, 1) +
      (this.e.txt3a_12_8 + ' ').substring(0, 1) +
      (this.e.txt3a_12_9 + ' ').substring(0, 1) +
      (this.e.txt3a_12_10 + ' ').substring(0, 1);

    this.e.txt3_201 = (this.e.txt3b_1_1 + ' ').substring(0, 1) +
      (this.e.txt3b_1_2 + ' ').substring(0, 1);
    this.e.med3_202 = (this.e.txt3b_2_1 + ' ').substring(0, 1) +
      (this.e.txt3b_2_2 + ' ').substring(0, 1) +
      (this.e.txt3b_2_3 + ' ').substring(0, 1) +
      (this.e.txt3b_2_4 + ' ').substring(0, 1) +
      (this.e.txt3b_2_5 + ' ').substring(0, 1) +
      (this.e.txt3b_2_6 + ' ').substring(0, 1) +
      (this.e.txt3b_2_7 + ' ').substring(0, 1) +
      (this.e.txt3b_2_8 + ' ').substring(0, 1);
    this.e.med3_203 = (this.e.txt3b_3_1 + ' ').substring(0, 1) +
      (this.e.txt3b_3_2 + ' ').substring(0, 1) +
      (this.e.txt3b_3_3 + ' ').substring(0, 1) +
      (this.e.txt3b_3_4 + ' ').substring(0, 1) +
      (this.e.txt3b_3_5 + ' ').substring(0, 1) +
      (this.e.txt3b_3_6 + ' ').substring(0, 1) +
      (this.e.txt3b_3_7 + ' ').substring(0, 1) +
      (this.e.txt3b_3_8 + ' ').substring(0, 1);
    this.e.txt3_204 = (this.e.txt3b_4_1 + ' ').substring(0, 1) +
      (this.e.txt3b_4_2 + ' ').substring(0, 1);
    this.e.txt3_205 = (this.e.txt3b_5_1 + ' ').substring(0, 1) +
      (this.e.txt3b_5_2 + ' ').substring(0, 1) +
      (this.e.txt3b_5_3 + ' ').substring(0, 1) +
      (this.e.txt3b_5_4 + ' ').substring(0, 1) +
      (this.e.txt3b_5_5 + ' ').substring(0, 1);
    this.e.txt3_206 = (this.e.txt3b_6_1 + ' ').substring(0, 1) +
      (this.e.txt3b_6_2 + ' ').substring(0, 1);
    this.e.txt3_207 = (this.e.txt3b_7_1 + ' ').substring(0, 1) +
      (this.e.txt3b_7_2 + ' ').substring(0, 1);
    this.e.txt3_208 = (this.e.txt3b_8_1 + ' ').substring(0, 1) +
      (this.e.txt3b_8_2 + ' ').substring(0, 1);
    this.e.txt3_209 = (this.e.txt3b_9_1 + ' ').substring(0, 1) +
      (this.e.txt3b_9_2 + ' ').substring(0, 1);
    this.e.txt3_210 = (this.e.txt3b_10_1 + ' ').substring(0, 1) +
      (this.e.txt3b_10_2 + ' ').substring(0, 1) +
      (this.e.txt3b_10_3 + ' ').substring(0, 1) +
      (this.e.txt3b_10_4 + ' ').substring(0, 1) +
      (this.e.txt3b_10_5 + ' ').substring(0, 1) +
      (this.e.txt3b_10_6 + ' ').substring(0, 1) +
      (this.e.txt3b_10_7 + ' ').substring(0, 1);
    this.e.txt3_211 = (this.e.txt3b_11_1 + ' ').substring(0, 1) +
      (this.e.txt3b_11_2 + ' ').substring(0, 1) +
      (this.e.txt3b_11_3 + ' ').substring(0, 1);
    this.e.med3_212 = (this.e.txt3b_12_1 + ' ').substring(0, 1) +
      (this.e.txt3b_12_2 + ' ').substring(0, 1) +
      (this.e.txt3b_12_3 + ' ').substring(0, 1) +
      (this.e.txt3b_12_4 + ' ').substring(0, 1) +
      (this.e.txt3b_12_5 + ' ').substring(0, 1) +
      (this.e.txt3b_12_6 + ' ').substring(0, 1) +
      (this.e.txt3b_12_7 + ' ').substring(0, 1) +
      (this.e.txt3b_12_8 + ' ').substring(0, 1) +
      (this.e.txt3b_12_9 + ' ').substring(0, 1) +
      (this.e.txt3b_12_10 + ' ').substring(0, 1);

    this.e.txt3_301 = (this.e.txt3c_1_1 + ' ').substring(0, 1) +
      (this.e.txt3c_1_2 + ' ').substring(0, 1);
    this.e.med3_302 = (this.e.txt3c_2_1 + ' ').substring(0, 1) +
      (this.e.txt3c_2_2 + ' ').substring(0, 1) +
      (this.e.txt3c_2_3 + ' ').substring(0, 1) +
      (this.e.txt3c_2_4 + ' ').substring(0, 1) +
      (this.e.txt3c_2_5 + ' ').substring(0, 1) +
      (this.e.txt3c_2_6 + ' ').substring(0, 1) +
      (this.e.txt3c_2_7 + ' ').substring(0, 1) +
      (this.e.txt3c_2_8 + ' ').substring(0, 1);
    this.e.med3_303 = (this.e.txt3c_3_1 + ' ').substring(0, 1) +
      (this.e.txt3c_3_2 + ' ').substring(0, 1) +
      (this.e.txt3c_3_3 + ' ').substring(0, 1) +
      (this.e.txt3c_3_4 + ' ').substring(0, 1) +
      (this.e.txt3c_3_5 + ' ').substring(0, 1) +
      (this.e.txt3c_3_6 + ' ').substring(0, 1) +
      (this.e.txt3c_3_7 + ' ').substring(0, 1) +
      (this.e.txt3c_3_8 + ' ').substring(0, 1);
    this.e.txt3_304 = (this.e.txt3c_4_1 + ' ').substring(0, 1) +
      (this.e.txt3c_4_2 + ' ').substring(0, 1);
    this.e.txt3_305 = (this.e.txt3c_5_1 + ' ').substring(0, 1) +
      (this.e.txt3c_5_2 + ' ').substring(0, 1) +
      (this.e.txt3c_5_3 + ' ').substring(0, 1) +
      (this.e.txt3c_5_4 + ' ').substring(0, 1) +
      (this.e.txt3c_5_5 + ' ').substring(0, 1);
    this.e.txt3_306 = (this.e.txt3c_6_1 + ' ').substring(0, 1) +
      (this.e.txt3c_6_2 + ' ').substring(0, 1);
    this.e.txt3_307 = (this.e.txt3c_7_1 + ' ').substring(0, 1) +
      (this.e.txt3c_7_2 + ' ').substring(0, 1);
    this.e.txt3_308 = (this.e.txt3c_8_1 + ' ').substring(0, 1) +
      (this.e.txt3c_8_2 + ' ').substring(0, 1);
    this.e.txt3_309 = (this.e.txt3c_9_1 + ' ').substring(0, 1) +
      (this.e.txt3c_9_2 + ' ').substring(0, 1);
    this.e.txt3_310 = (this.e.txt3c_10_1 + ' ').substring(0, 1) +
      (this.e.txt3c_10_2 + ' ').substring(0, 1) +
      (this.e.txt3c_10_3 + ' ').substring(0, 1) +
      (this.e.txt3c_10_4 + ' ').substring(0, 1) +
      (this.e.txt3c_10_5 + ' ').substring(0, 1) +
      (this.e.txt3c_10_6 + ' ').substring(0, 1) +
      (this.e.txt3c_10_7 + ' ').substring(0, 1);
    this.e.txt3_311 = (this.e.txt3c_11_1 + ' ').substring(0, 1) +
      (this.e.txt3c_11_2 + ' ').substring(0, 1) +
      (this.e.txt3c_11_3 + ' ').substring(0, 1);
    this.e.med3_312 = (this.e.txt3c_12_1 + ' ').substring(0, 1) +
      (this.e.txt3c_12_2 + ' ').substring(0, 1) +
      (this.e.txt3c_12_3 + ' ').substring(0, 1) +
      (this.e.txt3c_12_4 + ' ').substring(0, 1) +
      (this.e.txt3c_12_5 + ' ').substring(0, 1) +
      (this.e.txt3c_12_6 + ' ').substring(0, 1) +
      (this.e.txt3c_12_7 + ' ').substring(0, 1) +
      (this.e.txt3c_12_8 + ' ').substring(0, 1) +
      (this.e.txt3c_12_9 + ' ').substring(0, 1) +
      (this.e.txt3c_12_10 + ' ').substring(0, 1);

    this.e.chk4_1 = this.e.txt4_1_1 ? 1 : 0;
    this.e.txt4_2 = (this.e.txt4_2_1 + ' ').substring(0, 1) +
      (this.e.txt4_2_2 + ' ').substring(0, 1) +
      (this.e.txt4_2_3 + ' ').substring(0, 1) +
      (this.e.txt4_2_4 + ' ').substring(0, 1) +
      (this.e.txt4_2_5 + ' ').substring(0, 1);
    this.e.txt4_3 = (this.e.txt4_3_1 + ' ').substring(0, 1) +
      (this.e.txt4_3_2 + ' ').substring(0, 1) +
      (this.e.txt4_3_3 + ' ').substring(0, 1) +
      (this.e.txt4_3_4 + ' ').substring(0, 1) +
      (this.e.txt4_3_5 + ' ').substring(0, 1);
    this.e.med4_4 = (this.e.txt4_4_1 + ' ').substring(0, 1) +
      (this.e.txt4_4_2 + ' ').substring(0, 1) +
      (this.e.txt4_4_3 + ' ').substring(0, 1);

    this.e.txt5_1 = this.e.txt5_1_1;
  }

  setReclaimDefaults(): void {
    const r: number = this.idxSeld;

    this.e.txt1_1_1 = this.reclaims[r].reclICN.substring(0, 1);
    this.e.txt1_1_2 = this.reclaims[r].reclICN.substring(1, 2);
    this.e.txt1_1_3 = this.reclaims[r].reclICN.substring(2, 3);
    this.e.txt1_1_4 = this.reclaims[r].reclICN.substring(3, 4);
    this.e.txt1_1_5 = this.reclaims[r].reclICN.substring(4, 5);
    this.e.txt1_1_6 = this.reclaims[r].reclICN.substring(5, 6);
    this.e.txt1_1_7 = this.reclaims[r].reclICN.substring(6, 7);
    this.e.txt1_1_8 = this.reclaims[r].reclICN.substring(7, 8);
    this.e.txt1_1_9 = this.reclaims[r].reclICN.substring(8, 9);
    this.e.txt1_1_10 = this.reclaims[r].reclICN.substring(9, 10);
    this.e.txt1_1_11 = this.reclaims[r].reclICN.substring(10, 11);
    this.e.txt1_1_12 = this.reclaims[r].reclICN.substring(11, 12);
    this.e.txt1_1_13 = this.reclaims[r].reclICN.substring(12, 13);

    let x: number = 0;
    if ((this.reclaims[r].reclPS == '1' && this.reclaims[r].reclContr1.match(/^\D{3}/g))) {
      this.e.txt1_2_1 = (this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(0, 1) : this.reclaims[r].reclContr2.substring(0, 1));
      this.e.txt1_2_2 = (this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(1, 2) : this.reclaims[r].reclContr2.substring(1, 2));
      this.e.txt1_2_3 = (this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(2, 3) : this.reclaims[r].reclContr2.substring(2, 3));
      x = 3;
    }
    if ((this.reclaims[r].reclPS == '2' && this.reclaims[r].reclContr2.match(/^\D{3}/g))) {
      this.e.txt1_2_1 = (this.reclaims[r].reclPS == '2' ? this.reclaims[r].reclContr1.substring(0, 1) : this.reclaims[r].reclContr2.substring(0, 1));
      this.e.txt1_2_2 = (this.reclaims[r].reclPS == '2' ? this.reclaims[r].reclContr1.substring(1, 2) : this.reclaims[r].reclContr2.substring(1, 2));
      this.e.txt1_2_3 = (this.reclaims[r].reclPS == '2' ? this.reclaims[r].reclContr1.substring(2, 3) : this.reclaims[r].reclContr2.substring(2, 3));
      x = 3;
    }

    this.e.txt1_3_1 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 0, x + 1) : this.reclaims[r].reclContr2.substring(x + 0, x + 1);
    this.e.txt1_3_2 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 1, x + 2) : this.reclaims[r].reclContr2.substring(x + 1, x + 2);
    this.e.txt1_3_3 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 2, x + 3) : this.reclaims[r].reclContr2.substring(x + 2, x + 3);
    this.e.txt1_3_4 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 3, x + 4) : this.reclaims[r].reclContr2.substring(x + 3, x + 4);
    this.e.txt1_3_5 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 4, x + 5) : this.reclaims[r].reclContr2.substring(x + 4, x + 5);
    this.e.txt1_3_6 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 5, x + 6) : this.reclaims[r].reclContr2.substring(x + 5, x + 6);
    this.e.txt1_3_7 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 6, x + 7) : this.reclaims[r].reclContr2.substring(x + 6, x + 7);
    this.e.txt1_3_8 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 7, x + 8) : this.reclaims[r].reclContr2.substring(x + 7, x + 8);
    this.e.txt1_3_9 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 8, x + 9) : this.reclaims[r].reclContr2.substring(x + 8, x + 9);
    this.e.txt1_3_10 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 9, x + 10) : this.reclaims[r].reclContr2.substring(x + 9, x + 10);
    this.e.txt1_3_11 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 10, x + 11) : this.reclaims[r].reclContr2.substring(x + 10, x + 11);
    this.e.txt1_3_12 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 11, x + 12) : this.reclaims[r].reclContr2.substring(x + 11, x + 12);
    this.e.txt1_3_13 = this.reclaims[r].reclPS == '1' ? this.reclaims[r].reclContr1.substring(x + 12, x + 13) : this.reclaims[r].reclContr2.substring(x + 12, x + 13);

    this.e.txt1_4_1 = this.reclaims[r].reclNPI.substring(0, 1);
    this.e.txt1_4_2 = this.reclaims[r].reclNPI.substring(1, 2);
    this.e.txt1_4_3 = this.reclaims[r].reclNPI.substring(2, 3);
    this.e.txt1_4_4 = this.reclaims[r].reclNPI.substring(3, 4);
    this.e.txt1_4_5 = this.reclaims[r].reclNPI.substring(4, 5);
    this.e.txt1_4_6 = this.reclaims[r].reclNPI.substring(5, 6);
    this.e.txt1_4_7 = this.reclaims[r].reclNPI.substring(6, 7);
    this.e.txt1_4_8 = this.reclaims[r].reclNPI.substring(7, 8);
    this.e.txt1_4_9 = this.reclaims[r].reclNPI.substring(8, 9);
    this.e.txt1_4_10 = this.reclaims[r].reclNPI.substring(9, 10);

    this.e.txt1_5_1 = this.reclaims[r].reclProv;
  }

  decodeXmlRecl(): void {
    const r: number = this.idxSeld;

    if (r >= 0 && this.reclaims && (this.reclaims[r].reclXml || this.reclaimsDet[r].reclDetXml)) {
      let parser = new DOMParser();
      let xmlDoc;
      if (this.reclaims[r].reclXml) {
        xmlDoc = parser.parseFromString(this.reclaims[0].reclXml, "text/xml");
      } else if (this.reclaimsDet[r].reclDetXml) {  // UNTESTED
        xmlDoc = parser.parseFromString(this.reclaimsDet[r].reclDetXml, "text/xml");
      } else {
        return;
      }

      for (let i = 0; i < xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes.length; i++) {
        let fullNod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i];
        let nod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i].nodeName;

        if (nod.match(/txt\d*[a-z]?_\d+/g) || nod.match(/med\d*[a-z]?_\d+/g) || nod.match(/chk\d*_\d+/g) || nod.match(/opt\d*_\d+/g)) {
          let val = fullNod.textContent.replace(/^%/, '');

          switch (nod) {
            case 'opt0_1':
              val === 'True' ? this.e.opt0_plan = '1' : null;
              break;
            case 'opt0_2':
              val === 'True' ? this.e.opt0_plan = '2' : null;
              break;
            case 'opt0_3':
              val === 'True' ? this.e.opt0_plan = '3' : null;
              break;
            case 'opt0_4':
              val === 'True' ? this.e.opt0_plan = '4' : null;
              break;

            case 'txt2_1':
              val = this.padWithXspaces(val, 13);
              this.e.txt2_1 = val;
              this.e.txt2_1_1 = val.substring(0, 1);
              this.e.txt2_1_2 = val.substring(1, 2);
              this.e.txt2_1_3 = val.substring(2, 3);
              this.e.txt2_1_4 = val.substring(3, 4);
              this.e.txt2_1_5 = val.substring(4, 5);
              this.e.txt2_1_6 = val.substring(5, 6);
              this.e.txt2_1_7 = val.substring(6, 7);
              this.e.txt2_1_8 = val.substring(7, 8);
              this.e.txt2_1_9 = val.substring(8, 9);
              this.e.txt2_1_10 = val.substring(9, 10);
              this.e.txt2_1_11 = val.substring(10, 11);
              this.e.txt2_1_12 = val.substring(11, 12);
              this.e.txt2_1_13 = val.substring(12, 13);
              break;
            case 'txt2_2':
              val = this.padWithXspaces(val, 15);
              this.e.txt2_2 = val;
              this.e.txt2_2_1 = val.substring(0, 1);
              this.e.txt2_2_2 = val.substring(1, 2);
              this.e.txt2_2_3 = val.substring(2, 3);
              this.e.txt2_2_4 = val.substring(3, 4);
              this.e.txt2_2_5 = val.substring(4, 5);
              this.e.txt2_2_6 = val.substring(5, 6);
              this.e.txt2_2_7 = val.substring(6, 7);
              this.e.txt2_2_8 = val.substring(7, 8);
              this.e.txt2_2_9 = val.substring(8, 9);
              this.e.txt2_2_10 = val.substring(9, 10);
              this.e.txt2_2_11 = val.substring(10, 11);
              this.e.txt2_2_12 = val.substring(11, 12);
              this.e.txt2_2_13 = val.substring(12, 13);
              this.e.txt2_2_14 = val.substring(13, 14);
              this.e.txt2_2_15 = val.substring(14, 15);
              break;

            case 'txt3_101':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_101 = val;
              this.e.txt3a_1_1 = val.substring(0, 1);
              this.e.txt3a_1_2 = val.substring(1, 2);
              break;

            case 'med3_102':
              val = this.padWithXspaces(val, 8);
              this.e.med3_102 = val;
              this.e.txt3a_2_1 = val.substring(0, 1);
              this.e.txt3a_2_2 = val.substring(1, 2);
              this.e.txt3a_2_3 = val.substring(2, 3);
              this.e.txt3a_2_4 = val.substring(3, 4);
              this.e.txt3a_2_5 = val.substring(4, 5);
              this.e.txt3a_2_6 = val.substring(5, 6);
              this.e.txt3a_2_7 = val.substring(6, 7);
              this.e.txt3a_2_8 = val.substring(7, 8);
              break;

            case 'med3_103':
              val = this.padWithXspaces(val, 8);
              this.e.med3_103 = val;
              this.e.txt3a_3_1 = val.substring(0, 1);
              this.e.txt3a_3_2 = val.substring(1, 2);
              this.e.txt3a_3_3 = val.substring(2, 3);
              this.e.txt3a_3_4 = val.substring(3, 4);
              this.e.txt3a_3_5 = val.substring(4, 5);
              this.e.txt3a_3_6 = val.substring(5, 6);
              this.e.txt3a_3_7 = val.substring(6, 7);
              this.e.txt3a_3_8 = val.substring(7, 8);
              break;

            case 'txt3_104':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_104 = val;
              this.e.txt3a_4_1 = val.substring(0, 1);
              this.e.txt3a_4_2 = val.substring(1, 2);
              break;

            case 'txt3_105':
              val = this.padWithXspaces(val, 5);
              this.e.txt3_105 = val;
              this.e.txt3a_5_1 = val.substring(0, 1);
              this.e.txt3a_5_2 = val.substring(1, 2);
              this.e.txt3a_5_3 = val.substring(2, 3);
              this.e.txt3a_5_4 = val.substring(3, 4);
              this.e.txt3a_5_5 = val.substring(4, 5);
              break;

            case 'txt3_106':
              val = this.padWithXspaces(val, 5);
              this.e.txt3_106 = val;
              this.e.txt3a_6_1 = val.substring(0, 1);
              this.e.txt3a_6_2 = val.substring(1, 2);
              break;

            case 'txt3_107':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_107 = val;
              this.e.txt3a_7_1 = val.substring(0, 1);
              this.e.txt3a_7_2 = val.substring(1, 2);
              break;

            case 'txt3_108':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_108 = val;
              this.e.txt3a_8_1 = val.substring(0, 1);
              this.e.txt3a_8_2 = val.substring(1, 2);
              break;

            case 'txt3_109':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_109 = val;
              this.e.txt3a_9_1 = val.substring(0, 1);
              this.e.txt3a_9_2 = val.substring(1, 2);
              break;

            case 'txt3_110':
              val = this.padWithXspaces(val, 7);
              this.e.txt3_110 = val;
              this.e.txt3a_10_1 = val.substring(0, 1);
              this.e.txt3a_10_2 = val.substring(1, 2);
              this.e.txt3a_10_3 = val.substring(2, 3);
              this.e.txt3a_10_4 = val.substring(3, 4);
              this.e.txt3a_10_5 = val.substring(4, 5);
              this.e.txt3a_10_6 = val.substring(5, 6);
              this.e.txt3a_10_7 = val.substring(6, 7);
              break;

            case 'txt3_111':
              val = this.padWithXspaces(val, 3);
              this.e.txt3_111 = val;
              this.e.txt3a_11_1 = val.substring(0, 1);
              this.e.txt3a_11_2 = val.substring(1, 2);
              this.e.txt3a_11_3 = val.substring(2, 3);
              break;

            case 'med3_112':
              val = this.padWithXspaces(val, 10);
              this.e.med3_112 = val;
              this.e.txt3a_12_1 = val.substring(0, 1);
              this.e.txt3a_12_2 = val.substring(1, 2);
              this.e.txt3a_12_3 = val.substring(2, 3);
              this.e.txt3a_12_4 = val.substring(3, 4);
              this.e.txt3a_12_5 = val.substring(4, 5);
              this.e.txt3a_12_6 = val.substring(5, 6);
              this.e.txt3a_12_7 = val.substring(6, 7);
              this.e.txt3a_12_8 = val.substring(7, 8);
              this.e.txt3a_12_9 = val.substring(8, 9);
              this.e.txt3a_12_10 = val.substring(9, 10);
              break;

            case 'txt3_201':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_201 = val;
              this.e.txt3b_1_1 = val.substring(0, 1);
              this.e.txt3b_1_2 = val.substring(1, 2);
              break;

            case 'med3_202':
              val = this.padWithXspaces(val, 8);
              this.e.med3_202 = val;
              this.e.txt3b_2_1 = val.substring(0, 1);
              this.e.txt3b_2_2 = val.substring(1, 2);
              this.e.txt3b_2_3 = val.substring(2, 3);
              this.e.txt3b_2_4 = val.substring(3, 4);
              this.e.txt3b_2_5 = val.substring(4, 5);
              this.e.txt3b_2_6 = val.substring(5, 6);
              this.e.txt3b_2_7 = val.substring(6, 7);
              this.e.txt3b_2_8 = val.substring(7, 8);
              break;

            case 'med3_203':
              val = this.padWithXspaces(val, 8);
              this.e.med3_203 = val;
              this.e.txt3b_3_1 = val.substring(0, 1);
              this.e.txt3b_3_2 = val.substring(1, 2);
              this.e.txt3b_3_3 = val.substring(2, 3);
              this.e.txt3b_3_4 = val.substring(3, 4);
              this.e.txt3b_3_5 = val.substring(4, 5);
              this.e.txt3b_3_6 = val.substring(5, 6);
              this.e.txt3b_3_7 = val.substring(6, 7);
              this.e.txt3b_3_8 = val.substring(7, 8);
              break;

            case 'txt3_204':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_204 = val;
              this.e.txt3b_4_1 = val.substring(0, 1);
              this.e.txt3b_4_2 = val.substring(1, 2);
              break;

            case 'txt3_205':
              val = this.padWithXspaces(val, 5);
              this.e.txt3_205 = val;
              this.e.txt3b_5_1 = val.substring(0, 1);
              this.e.txt3b_5_2 = val.substring(1, 2);
              this.e.txt3b_5_3 = val.substring(2, 3);
              this.e.txt3b_5_4 = val.substring(3, 4);
              this.e.txt3b_5_5 = val.substring(4, 5);
              break;

            case 'txt3_206':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_206 = val;
              this.e.txt3b_6_1 = val.substring(0, 1);
              this.e.txt3b_6_2 = val.substring(1, 2);
              break;

            case 'txt3_207':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_207 = val;
              this.e.txt3b_7_1 = val.substring(0, 1);
              this.e.txt3b_7_2 = val.substring(1, 2);
              break;

            case 'txt3_208':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_208 = val;
              this.e.txt3b_8_1 = val.substring(0, 1);
              this.e.txt3b_8_2 = val.substring(1, 2);
              break;

            case 'txt3_209':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_209 = val;
              this.e.txt3b_9_1 = val.substring(0, 1);
              this.e.txt3b_9_2 = val.substring(1, 2);
              break;

            case 'txt3_210':
              val = this.padWithXspaces(val, 7);
              this.e.txt3_210 = val;
              this.e.txt3b_10_1 = val.substring(0, 1);
              this.e.txt3b_10_2 = val.substring(1, 2);
              this.e.txt3b_10_3 = val.substring(2, 3);
              this.e.txt3b_10_4 = val.substring(3, 4);
              this.e.txt3b_10_5 = val.substring(4, 5);
              this.e.txt3b_10_6 = val.substring(5, 6);
              this.e.txt3b_10_7 = val.substring(6, 7);
              break;

            case 'txt3_211':
              val = this.padWithXspaces(val, 3);
              this.e.txt3_211 = val;
              this.e.txt3b_11_1 = val.substring(0, 1);
              this.e.txt3b_11_2 = val.substring(1, 2);
              this.e.txt3b_11_3 = val.substring(2, 3);
              break;

            case 'med3_212':
              val = this.padWithXspaces(val, 10);
              this.e.med3_212 = val;
              this.e.txt3b_12_1 = val.substring(0, 1);
              this.e.txt3b_12_2 = val.substring(1, 2);
              this.e.txt3b_12_3 = val.substring(2, 3);
              this.e.txt3b_12_4 = val.substring(3, 4);
              this.e.txt3b_12_5 = val.substring(4, 5);
              this.e.txt3b_12_6 = val.substring(5, 6);
              this.e.txt3b_12_7 = val.substring(6, 7);
              this.e.txt3b_12_8 = val.substring(7, 8);
              this.e.txt3b_12_9 = val.substring(8, 9);
              this.e.txt3b_12_10 = val.substring(9, 10);
              break;

            case 'txt3_301':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_301 = val;
              this.e.txt3c_1_1 = val.substring(0, 1);
              this.e.txt3c_1_2 = val.substring(1, 2);
              break;

            case 'med3_302':
              val = this.padWithXspaces(val, 8);
              this.e.med3_302 = val;
              this.e.txt3c_2_1 = val.substring(0, 1);
              this.e.txt3c_2_2 = val.substring(1, 2);
              this.e.txt3c_2_3 = val.substring(2, 3);
              this.e.txt3c_2_4 = val.substring(3, 4);
              this.e.txt3c_2_5 = val.substring(4, 5);
              this.e.txt3c_2_6 = val.substring(5, 6);
              this.e.txt3c_2_7 = val.substring(6, 7);
              this.e.txt3c_2_8 = val.substring(7, 8);
              break;

            case 'med3_303':
              val = this.padWithXspaces(val, 8);
              this.e.med3_303 = val;
              this.e.txt3c_3_1 = val.substring(0, 1);
              this.e.txt3c_3_2 = val.substring(1, 2);
              this.e.txt3c_3_3 = val.substring(2, 3);
              this.e.txt3c_3_4 = val.substring(3, 4);
              this.e.txt3c_3_5 = val.substring(4, 5);
              this.e.txt3c_3_6 = val.substring(5, 6);
              this.e.txt3c_3_7 = val.substring(6, 7);
              this.e.txt3c_3_8 = val.substring(7, 8);
              break;

            case 'txt3_304':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_304 = val;
              this.e.txt3c_4_1 = val.substring(0, 1);
              this.e.txt3c_4_2 = val.substring(1, 2);
              break;

            case 'txt3_305':
              val = this.padWithXspaces(val, 5);
              this.e.txt3_305 = val;
              this.e.txt3c_5_1 = val.substring(0, 1);
              this.e.txt3c_5_2 = val.substring(1, 2);
              this.e.txt3c_5_3 = val.substring(2, 3);
              this.e.txt3c_5_4 = val.substring(3, 4);
              this.e.txt3c_5_5 = val.substring(4, 5);
              break;

            case 'txt3_306':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_306 = val;
              this.e.txt3c_6_1 = val.substring(0, 1);
              this.e.txt3c_6_2 = val.substring(1, 2);
              break;

            case 'txt3_307':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_307 = val;
              this.e.txt3c_7_1 = val.substring(0, 1);
              this.e.txt3c_7_2 = val.substring(1, 2);
              break;

            case 'txt3_308':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_308 = val;
              this.e.txt3c_8_1 = val.substring(0, 1);
              this.e.txt3c_8_2 = val.substring(1, 2);
              break;

            case 'txt3_309':
              val = this.padWithXspaces(val, 2);
              this.e.txt3_309 = val;
              this.e.txt3c_9_1 = val.substring(0, 1);
              this.e.txt3c_9_2 = val.substring(1, 2);
              break;

            case 'txt3_310':
              val = this.padWithXspaces(val, 7);
              this.e.txt3_310 = val;
              this.e.txt3c_10_1 = val.substring(0, 1);
              this.e.txt3c_10_2 = val.substring(1, 2);
              this.e.txt3c_10_3 = val.substring(2, 3);
              this.e.txt3c_10_4 = val.substring(3, 4);
              this.e.txt3c_10_5 = val.substring(4, 5);
              this.e.txt3c_10_6 = val.substring(5, 6);
              this.e.txt3c_10_7 = val.substring(6, 7);
              break;

            case 'txt3_311':
              val = this.padWithXspaces(val, 3);
              this.e.txt3_311 = val;
              this.e.txt3c_11_1 = val.substring(0, 1);
              this.e.txt3c_11_2 = val.substring(1, 2);
              this.e.txt3c_11_3 = val.substring(2, 3);
              break;

            case 'med3_312':
              val = this.padWithXspaces(val, 10);
              this.e.med3_312 = val;
              this.e.txt3c_12_1 = val.substring(0, 1);
              this.e.txt3c_12_2 = val.substring(1, 2);
              this.e.txt3c_12_3 = val.substring(2, 3);
              this.e.txt3c_12_4 = val.substring(3, 4);
              this.e.txt3c_12_5 = val.substring(4, 5);
              this.e.txt3c_12_6 = val.substring(5, 6);
              this.e.txt3c_12_7 = val.substring(6, 7);
              this.e.txt3c_12_8 = val.substring(7, 8);
              this.e.txt3c_12_9 = val.substring(8, 9);
              this.e.txt3c_12_10 = val.substring(9, 10);
              break;

            case 'chk4_1':
              this.e.chk4_1 = val;
              this.e.txt4_1_1 = +val ? 'X' : '';
              break;

            case 'txt4_2':
              val = this.padWithXspaces(val, 5);
              this.e.txt4_2 = val;
              this.e.txt4_2_1 = val.substring(0, 1);
              this.e.txt4_2_2 = val.substring(1, 2);
              this.e.txt4_2_3 = val.substring(2, 3);
              this.e.txt4_2_4 = val.substring(3, 4);
              this.e.txt4_2_5 = val.substring(4, 5);
              break;

            case 'txt4_3':
              val = this.padWithXspaces(val, 5);
              this.e.txt4_3 = val;
              this.e.txt4_3_1 = val.substring(0, 1);
              this.e.txt4_3_2 = val.substring(1, 2);
              this.e.txt4_3_3 = val.substring(2, 3);
              this.e.txt4_3_4 = val.substring(3, 4);
              this.e.txt4_3_5 = val.substring(4, 5);
              break;

            case 'med4_4':
              val = this.padWithXspaces(val, 3);
              this.e.med4_4 = val;
              this.e.txt4_4_1 = val.substring(0, 1);
              this.e.txt4_4_2 = val.substring(1, 2);
              this.e.txt4_4_3 = val.substring(2, 3);
              break;

            case 'txt5_1':
              this.e.txt5_1 = val;
              this.e.txt5_1_1 = val;
              break;

            default:
              break;
          }
        }
      }
    }
  }

  padWithXspaces(val: string, x: number): string {
    if (val.length !== x) {
      return (val + ' '.repeat(x)).substring(0, x);
    }
    return val;
  }

  printReclaim(): void {
    let data: any = {
      "e": this.e,
    };
    let bdy = {
      "template":
      {
        "name": "/reclaimForms/sss_070114/sss_070114-html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    this._ckRegisterService.postRenderJsRpt(bdy).subscribe({
      next: blob => { this.backFromPrintRpt(blob) },
      error: (error: any) => { this.backFromServerError(error) }
    });
  }

  backFromPrintRpt(blob: Blob): void {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
      let win = window.open();
      win.document.open();
      win.document.write(iframe);
      win.document.close();
    };
  }

  backFromServerError(error): void {
    this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
    this._appToastsService.show(error.message, { header: 'Error preparing report * Error preparando reporte', autohide: false, error: true });
  }

}
