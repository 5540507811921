// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.evenIcd10 {
    background-color: #f7c6bc;
  }
  
  .oddIcd10 {
    background-color:#fae7a5;
  }

  .rowsHover tr:hover {
    background-color: #1ecfd6;
  }

  .warning {
    background-color: orange;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/shared/message-dialog.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,wBAAwB;EAC1B","sourcesContent":[".evenIcd10 {\r\n    background-color: #f7c6bc;\r\n  }\r\n  \r\n  .oddIcd10 {\r\n    background-color:#fae7a5;\r\n  }\r\n\r\n  .rowsHover tr:hover {\r\n    background-color: #1ecfd6;\r\n  }\r\n\r\n  .warning {\r\n    background-color: orange;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
