import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBasic } from './modal-basic.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPayPalModule } from 'ngx-paypal';

import { JqueryMaskededitDirective } from './jquery-maskededit.directive';
import { PaymentFormComponent } from './payment-form.component';
import { HelperRtnsComponent } from './helper-rtns.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { InputDialogComponent } from './input-dialog.component';
import { MessageDialogComponent } from './message-dialog.component';
import { SelectedServiceItemPipe } from './selected-service-item.pipe';
import { FilterNewCasePipe } from './filter-new-case.pipe';
import { SafePipe } from './safe.pipe';
import { PsPipe } from './ps.pipe';
import { DwtComponent } from './dwt.component';
import { AgendaComponent } from '../agenda/agenda.component';
import { AgendaPipe } from '../agenda/agenda.pipe';
import { ClaimAdjustModalPromptComponent } from './claim-adjust-modal-prompt/claim-adjust-modal-prompt.component';
import { RecordComponent } from '../record/record.component';
import { DdNgbdropdownComponent } from './dd-ngbdropdown.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SignatureCaptureComponent } from '../externalTemplates/signature-capture/signature-capture.component';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';
import { MatMsgDlgComponent } from './mat-msg-dlg/mat-msg-dlg.component';
import { MatDialogModule } from '@angular/material/dialog';
import { Q837Component } from './q837/q837.component';
import { MatTableModule } from '@angular/material/table';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ReclaimComponent } from './reclaim/reclaim.component';

@NgModule({
    declarations: [JqueryMaskededitDirective,
        PaymentFormComponent,
        HelperRtnsComponent,
        ConfirmDialogComponent,
        InputDialogComponent,
        MessageDialogComponent,
        SelectedServiceItemPipe,
        FilterNewCasePipe,
        NgbdModalBasic,
        SafePipe,
        PsPipe,
        DwtComponent,
        AgendaComponent,
        AgendaPipe,
        ClaimAdjustModalPromptComponent,
        RecordComponent,
        DdNgbdropdownComponent,
        SignatureCaptureComponent,
        FloatingMenuComponent,
        MatMsgDlgComponent,
        Q837Component
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        NgxPayPalModule,
        NgxPaginationModule,
        NgxPopperjsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatTableModule,
        NgxMaskDirective,
        NgxMaskPipe,
        ReclaimComponent
    ],
    providers: [
        provideNgxMask()
    ],
    exports: [
        CommonModule,
        FormsModule,
        JqueryMaskededitDirective,
        PaymentFormComponent,
        FilterNewCasePipe,
        SafePipe,
        PsPipe,
        DwtComponent,
        AgendaComponent,
        ClaimAdjustModalPromptComponent,
        RecordComponent,
        DdNgbdropdownComponent,
        Q837Component
    ]
})
export class SharedModule { }