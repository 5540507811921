import * as io from 'socket.io-client';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { HelperRtnsComponent } from './helper-rtns.component';

@Injectable()
export class WebsocketService {

  private socket;

  constructor(_helperRtns: HelperRtnsComponent) {

    this.socket = io(_helperRtns.urlDB);
  }


  sendChat(type: string, sn: string, chat: string): any {
    console.log('sendChat ' + this.socket.id);
    console.log('%c' + 'WebsocketService.sendChat(...) chat this id = ' + this.socket.id, 'color: white; background: black; font-size: 14px');
    console.log(chat);
    console.log('type = ' + type);
    console.log('sn = ' + sn);
    if (this.socket.id) {
      this.socket.emit('chat', JSON.stringify({
        'type': type,
        'sid': this.socket.id,
        'sn': sn,
        'resp': chat
      }));
    }
  }

  sendMessage(msg) {
    this.socket.emit('message', msg);
  }

  getMessages() {
    return new Observable((observer: Observer<any>) => {
      this.socket.on('chat', (chat) => {
        console.log('%c' + 'WebsocketService.getMessages() chat this id = ' + this.socket.id, 'color: white; background: black; font-size: 10px');
        // console.log(chat);
        let dataSet = JSON.parse(chat).resp;
        // console.log('dataSet:');
        // console.log('dataSet', dataSet);
        observer.next(dataSet);
      });

      // this.socket.on('message', (msg) => {
      //   console.log('message ' + this.socket.id);
      //   this.socket.nsp
      //   console.log(msg);
      //   console.log('===');
      //   observer.next(msg);
      // });
    });
  }

}