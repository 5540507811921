import { Injectable } from '@angular/core';
import {
	NgbDateAdapter,
	NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 * It handles & manipulates the OUTPUT.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
	readonly DELIMITER = '/';

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				month: parseInt(date[0], 10),
				day: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): string | null {
		let dt: string = null;
		if (date?.year < 100) {	// MM/DD/yy
			dt = date.month.toString().padStart(2, '0')
				+ this.DELIMITER + date.day.toString().padStart(2, '0')
				+ this.DELIMITER + date.year.toString().padStart(2, '0');
		} else if (date?.year > 99) {	// MM/DD/yyyy
			dt = date.month.toString().padStart(2, '0')
				+ this.DELIMITER + date.day.toString().padStart(2, '0')
				+ this.DELIMITER + date.year.toString();
		}
		return dt;
	}
}

