
import {Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { HelperRtnsComponent } from '../shared/helper-rtns.component';

@Injectable()
export class PaymentService {

    constructor(private _http: HttpClient, private _help: HelperRtnsComponent) { }

    private extractData(res: Response)  {
        let returnedData = res;
        return returnedData || { };
    }

    getServiceItemPrices(productKey: string, sn: string): Observable<any> {
        return this._http.get(this._help.urlDB + '/api/get-serviceItemPrices/' + (productKey ? productKey: 'nul') + '/' + (sn ? sn: 'nul'))
            .pipe(map(this.extractData));
    }

    getServiceItemPricesExcepts(sn: string): Observable<any> {
        return this._http.get(this._help.urlDB + '/api/get-serviceItemPricesExcepts/' + sn)
            .pipe(map(this.extractData));
    }

    postCcChargeReceivedResponse(authorized: any): Observable<any> {
        let body = JSON.stringify(authorized);
        let headers = { 'Content-Type': 'application/json' };

        return this._http.post(this._help.urlDB + '/api/post-ccChargeReceivedResponse', body, { headers }) 
            .pipe(map(this.extractData));
    }
        
}