import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { CkRegisterService } from 'src/app/ck-register/ck-register.service';
import { IReclaims, IReclaimsDet } from 'src/app/shared/interfaces/reclaims';
import { AppToastsService } from '../../app-toasts/app-toasts.service';
import { DataMessageService } from '../../data-message.service';
import * as moment from 'moment';

@Component({
  selector: 'app-recl-xml-humana-rev17112010',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    NgxMaskDirective
  ],
  templateUrl: './recl-xml-humana-rev17112010.component.html',
  styleUrls: ['./recl-xml-humana-rev17112010.component.css']
})
export class ReclXmlHumanaRev17112010Component implements OnInit, OnDestroy {

  @Input() reclaims: IReclaims[];
  @Input() reclaimsDet: IReclaimsDet[];
  @Input() idxSeld: number;
  @Input() reclFormImg64Seled: string;
  @Input() reclLineReasonEntered: IReclaimReason;
  @Input() clrData: boolean = false;  // Set true after deleteing
  @Input() print: boolean = false;

  @Output() need2Save = new EventEmitter<string>();

  sn: string;
  userLastNm: string;
  userFirstNm: string;
  userID: string;
  currIndx: number = -1;
  e: any = {
    txtHUMProvNm: '',
    txtHUMNPI: '',
    txtHUMDate: '',
    txtHUMAd1: '',
    txtHUMAd2: '',
    txtHUMAd3: '',

    plan: '',
    optHUMMedicare: '',
    optHUMComercial: '',
    optHUMReforma: '',
    txtProvEmail: '',
    chkHUMPFFS: false,
    txtHUM_User: '',
    txtHUMCasNo: '',

    txtHUMContr1: '',
    txtHUMIcn1: '',
    txtHUMNm1: '',
    txtHUMFrom1: '',
    txtHUMBal1: '',
    txtHUMCom1: '',

    txtHUMContr2: '',
    txtHUMIcn2: '',
    txtHUMNm2: '',
    txtHUMFrom2: '',
    txtHUMBal2: '',
    txtHUMCom2: '',

    txtHUMContr3: '',
    txtHUMIcn3: '',
    txtHUMNm3: '',
    txtHUMFrom3: '',
    txtHUMBal3: '',
    txtHUMCom3: '',

    txtHUMContr4: '',
    txtHUMIcn4: '',
    txtHUMNm4: '',
    txtHUMFrom4: '',
    txtHUMBal4: '',
    txtHUMCom4: '',

    txtHUMContr5: '',
    txtHUMIcn5: '',
    txtHUMNm5: '',
    txtHUMFrom5: '',
    txtHUMBal5: '',
    txtHUMCom5: '',

    txtHUMContr6: '',
    txtHUMIcn6: '',
    txtHUMNm6: '',
    txtHUMFrom6: '',
    txtHUMBal6: '',
    txtHUMCom6: '',

    txtHUMContr7: '',
    txtHUMIcn7: '',
    txtHUMNm7: '',
    txtHUMFrom7: '',
    txtHUMBal7: '',
    txtHUMCom7: '',

    txtHUMContr8: '',
    txtHUMIcn8: '',
    txtHUMNm8: '',
    txtHUMFrom8: '',
    txtHUMBal8: '',
    txtHUMCom8: '',

    txtHUMContr9: '',
    txtHUMIcn9: '',
    txtHUMNm9: '',
    txtHUMFrom9: '',
    txtHUMBal9: '',
    txtHUMCom9: '',

    txtHUMContr10: '',
    txtHUMIcn10: '',
    txtHUMNm10: '',
    txtHUMFrom10: '',
    txtHUMBal10: '',
    txtHUMCom10: '',

    txtHUMBalTot: ''
  }

  constructor(private _ckRegisterService: CkRegisterService,
    private _appToastsService: AppToastsService,
    private _dataMessageService: DataMessageService,
  ) { }

  ngOnInit(): void {
    this._dataMessageService.currentUserLastNmStr.subscribe(userLastNmStr => this.userLastNmChange(userLastNmStr)); // Subscription 
    this._dataMessageService.currentUserFirstNmStr.subscribe(userFirstNmStr => this.userFirstNmChange(userFirstNmStr)); // Subscription 
    this._dataMessageService.currentUserIdStr.subscribe(userIdStr => this.userIdChange(userIdStr)); // Subscription 

    this.setReclaimDefaults();

    if (this.reclaims[this.idxSeld].reclXml.match(/>\w+</g)) { // If xml has data
      this.decodeXmlRecl();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createColReclaimXmlContent_developmentHelperRtn();  // <-- Use to get reclXml col contents value for this form

    // Check if @Input() reclLineReasonEntered & others have changed
    if (changes.reclLineReasonEntered && this.reclLineReasonEntered) {
      console.log('reclLineReasonEntered', this.reclLineReasonEntered);
      if (this.reclLineReasonEntered.reason) {
        // Fill or accumulate .reason in any other extra comment field 
        // not already filled by defaults or in switch(){} block below
      }

      switch (this.currIndx) {
        case -1:
          this.currIndx = 0;
        // break;   In this case the first line is not filled in by default so no break; to go on to case 0
        case 0:
          this.e.txtHUMContr1 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn1 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm1 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom1 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal1 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom1 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
          break;
        case 1:
          this.e.txtHUMContr2 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn2 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm2 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom2 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal2 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom2 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
          break;
        case 2:
          this.e.txtHUMContr3 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn3 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm3 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom3 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal3 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom3 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
          break;
        case 3:
          this.e.txtHUMContr4 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn4 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm4 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom4 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal4 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom4 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
          break;
        case 4:
          this.e.txtHUMContr5 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn5 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm5 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom5 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal5 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom5 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
          break;
        case 5:
          this.e.txtHUMContr6 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn6 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm6 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom6 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal6 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom6 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
          break;
        case 6:
          this.e.txtHUMContr7 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn7 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm7 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom7 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal7 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom7 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
          break;
        case 7:
          this.e.txtHUMContr8 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn8 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm8 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom8 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal8 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom8 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
          break;
        case 8:
          this.e.txtHUMContr9 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn9 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm9 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom9 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal9 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom9 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
        case 9:
          this.e.txtHUMContr10 = this.reclLineReasonEntered.contrNo;
          this.e.txtHUMIcn10 = this.reclLineReasonEntered.icn;
          this.e.txtHUMNm10 = this.reclLineReasonEntered.name;
          this.e.txtHUMFrom10 = this.reclLineReasonEntered.from;
          this.e.txtHUMBal10 = (+this.reclLineReasonEntered.xpected - +this.reclLineReasonEntered.payed).toFixed(2);
          this.e.txtHUMCom10 = (this.reclLineReasonEntered.cpt
            + ' ' + this.reclLineReasonEntered.mods
            + ' ' + this.reclLineReasonEntered.reason.trim());
          this.currIndx++;
        default:
      }
      this.setTotal();
    }
    if (changes.clrData?.currentValue) {
      this.clearData();
    }
    if (changes.print?.currentValue) {
      this.printReclaim();
    }
  }

  userLastNmChange(userLastNmStr) {
    this.userLastNm = userLastNmStr;
  }

  userFirstNmChange(userFirstNmStr) {
    this.userFirstNm = userFirstNmStr;
  }

  userIdChange(userIdStr) {
    this.userID = userIdStr;
  }

  setReclaimDefaults(): void {
    const r: number = this.idxSeld;

    this.e.txtHUMProvNm = this.reclaims[0].reclProv;
    this.e.txtHUMNPI = this.reclaims[0].reclNPI;
    this.e.txtHUMDate = moment(new Date()).format('MM/DD/YY');

    this.e.txtHUMAd1 = this.reclaims[0].reclProvPOaddr1;
    this.e.txtHUMAd2 = this.reclaims[0].reclProvPOaddr2;
    this.e.txtHUMAd3 = this.reclaims[0].reclProvPOcity + ' ' + this.reclaims[0].reclProvPOst + ' ' + this.reclaims[0].reclProvPOzip;
    this.e.txtHUM_Email = this.reclaims[0].reclProvEmail;
    this.e.optHUMMedicare = '';
    this.e.optHUMComercial = '';
    this.e.optHUMReforma = '';
    this.e.chkHUMPFFS = false;
    this.e.txtHUM_User = this.userLastNm + ', ' + this.userFirstNm + ' (' + this.userID + ')';
    this.e.txtProvEmail = this.reclaims[0].reclProvEmail;
    this.e.txtHUMCasNo = this.reclaims[0].reclCasNo;
  }

  decodeXmlRecl(): void {
    const r: number = this.idxSeld;

    if (r >= 0 && this.reclaims && (this.reclaims[r].reclXml || this.reclaimsDet[r].reclDetXml)) {
      let parser = new DOMParser();
      let xmlDoc;
      if (this.reclaims[r].reclXml) {
        xmlDoc = parser.parseFromString(this.reclaims[0].reclXml, "text/xml");
      } else if (this.reclaimsDet[r].reclDetXml) {  // UNTESTED
        xmlDoc = parser.parseFromString(this.reclaimsDet[r].reclDetXml, "text/xml");
      } else {
        return;
      }

      for (let i = 0; i < xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes.length; i++) {
        let fullNod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i];
        let nod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i].nodeName;

        if (nod.match(/txt\w+/g) || nod.match(/chk\w+/g) || nod.match(/opt\w+/g)) {
          let val = fullNod.textContent;

          switch (nod) {
            case 'optHUMMedicare':
              if (+val) {
                this.e.plan = 'medicare';
              }
              break;
            case 'optHUMComercial':
              if (+val) {
                this.e.plan = 'comercial';
              }
              break;
            case 'optHUMReforma':
              if (+val) {
                this.e.plan = 'reforma'
              }
              break;
            case 'chkHUMPFFS':
              if (+val) {
                this.e.chkHUMPFFS = true;
              }
              break;

            default:
              if (nod.match(/txt\w+/g)) {
                this.e[nod] = val.replace(/^%/, '');
              }
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.currIndx = 0;
    this.e = undefined;
  }

  clearData(): void { // Clears all elements with default data.
    Object.keys(this.e).forEach(key => this.e[key] = '');
    this.setReclaimDefaults();
  }

  onChange_need2Save(event): void {
    this.setTotal()

    this.need2Save.emit('');
  }

  setTotal() {
    let tot: number = +this.e.txtHUMBal1 + +this.e.txtHUMBal2 + +this.e.txtHUMBal3 + +this.e.txtHUMBal4 + +this.e.txtHUMBal5 + +this.e.txtHUMBal6 + +this.e.txtHUMBal7 + +this.e.txtHUMBal8 + +this.e.txtHUMBal9 + +this.e.txtHUMBal10;
    this.e.txtHUMBalTot = tot.toFixed(2);
  }

  printReclaim(): void {
    let data: any = {
      "e": this.e,
    };
    let bdy = {
      "template":
      {
        "name": "/reclaimForms/humana_solicitud_ajuste_17-11-2010/humana_solicitud_ajuste_17-11-2010.html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    this._ckRegisterService.postRenderJsRpt(bdy).subscribe({
      next: blob => { this.backFromPrintRpt(blob) },
      error: (error: any) => { this.backFromServerError(error) }
    });
  }

  backFromPrintRpt(blob: Blob): void {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
      let win = window.open();
      win.document.open();
      win.document.write(iframe);
      win.document.close();
    };
  }

  backFromServerError(error): void {
    this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
    this._appToastsService.show(error.message, { header: 'Error preparing report * Error preparando reporte', autohide: false, error: true });
  }

  createColReclaimXmlContent_developmentHelperRtn(): void {
    const reclFormDiv = document.getElementById('ReclaimFormFields');
    if (reclFormDiv) {
      let reclXml: string = '<ReclaimFormFields>';  // This id="ReclaimFormFields" must be set temporarily in top <div> for this to work
      const txts = reclFormDiv.querySelectorAll('input');
      txts.forEach(e => {
        reclXml += '<' + e.id + '></' + e.id + '>';
      });
      reclXml += '</ReclaimFormFields>';
      console.log('*** reclXml ***');
      console.log(reclXml);
    }
  }

}
