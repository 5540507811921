import { Component } from '@angular/core';
import { ILocalInsurances } from './interfaces/localInsurances';
import * as moment from 'moment';

@Component({
    template: ``
})
export class HelperRtnsComponent {

    urlDB: string = 'https://medicalbiller.us:5250';   // server with the database & api
    insuranceLst: ILocalInsurances[];
    apptTypes: string[];
    apptStatuses: string[];
    usStates: any = ['PR', 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY', 'RD'];
    monthMaxDys: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];   // Max number of days in a month

    fmtDate(dt: Date, fmt: string): string {
        var formatedDt: string = undefined;     // output date in a string
        var m: number = 1 + dt.getMonth();      // month 0 based from Date type
        var d: number = dt.getDate();           // day of the month 1 based from Date type
        var y: number = dt.getFullYear();       // year 1 based from Date type
        var h: number = dt.getHours();          // hour from 0 to 23
        var o: number = dt.getMinutes()         // minutes from 0 to 59
        var s: number = dt.getSeconds();        // seconds from 0 to 59
        var l: number = dt.getMilliseconds();   // milliseconds from 0 to 999

        switch (fmt.toLowerCase()) {    // allowed fmt values
            case 'sql':
                formatedDt = (m < 10 ? '0' + m.toString() : m.toString())
                    + '/' + (d < 10 ? '0' + d.toString() : d.toString())
                    + '/' + y.toString()
                    + ' ' + (h < 10 ? '0' + h.toString() : h.toString())
                    + ':' + (o < 10 ? '0' + o.toString() : o.toString())
                    + ':' + (s < 10 ? '0' + s.toString() : s.toString())
                    + '.' + (l < 10 ? '00' + l.toString() : (l < 100 ? '0' + l.toString() : l.toString()));
                break;

            case 'sqlymd':
                formatedDt = y.toString()
                    + '-' + (m < 10 ? '0' + m.toString() : m.toString())
                    + '-' + (d < 10 ? '0' + d.toString() : d.toString())
                    + ' ' + (h < 10 ? '0' + h.toString() : h.toString())
                    + ':' + (o < 10 ? '0' + o.toString() : o.toString())
                    + ':' + (s < 10 ? '0' + s.toString() : s.toString())
                    + ':' + (l < 10 ? '00' + l.toString() : (l < 100 ? '0' + l.toString() : l.toString()));
                break;

            case 'mm/dd/yy':
                formatedDt = (m < 10 ? '0' + m.toString() : m.toString())
                    + '/' + (d < 10 ? '0' + d.toString() : d.toString())
                    + '/' + y.toString().substring(2, 4);
                break;

            case 'mm/dd/yyyy':
                formatedDt = (m < 10 ? '0' + m.toString() : m.toString())
                    + '/' + (d < 10 ? '0' + d.toString() : d.toString())
                    + '/' + y.toString();
                break;

            case 'ymd':
                formatedDt = y.toString() + (m < 10 ? '0' + m.toString() : m.toString()) + (d < 10 ? '0' + d.toString() : d.toString());
                break;

            case 'yyyy-mm-dd':
                formatedDt = y.toString() + '-' + (m < 10 ? '0' + m.toString() : m.toString()) + '-' + (d < 10 ? '0' + d.toString() : d.toString());
                break;

            case 'js':
                formatedDt = y.toString()
                    + '-' + (m < 10 ? '0' + m.toString() : m.toString())
                    + '-' + (d < 10 ? '0' + d.toString() : d.toString())
                    + 'T' + (h < 10 ? '0' + h.toString() : h.toString())
                    + ':' + (o < 10 ? '0' + o.toString() : o.toString())
                    + ':' + (s < 10 ? '0' + s.toString() : s.toString())
                    + '.' + (l < 10 ? '00' + l.toString() : (l < 100 ? '0' + l.toString() : l.toString())) + 'Z';
                break;

            default:
                formatedDt = 'ERROR(fmtDate): Invalid format ' + '"' + fmt + '".'
        }
        return formatedDt;
    }

    compDates(dts1: string, dts2: string): number {
        if (dts1.match(/^\d{2}\/\d{2}\/\d{2,4}$/g) && dts2.match(/^\d{2}\/\d{2}\/\d{2,4}$/g)) {
            var result = '0';   // both dates are equal
            let rgx1 = /^(\d{2})?\/(\d{2})?\/(\d{2,4})?$/g;  // isolate mo-day-yr - cannot use rgx1 twice
            let rgx2 = /^(\d{2})?\/(\d{2})?\/(\d{2,4})?$/g;  // isolate mo-day-yr
            var dt1 = rgx1.exec(dts1);
            var dt2 = rgx2.exec(dts2);
            if (dt1[3] > dt2[3]) {
                return 1;   // dts1 > dts2
            } else if (dt1[3] < dt2[3]) {
                return -1;  // dts1 < dts2
            }
            if (dt1[2] > dt2[2]) {
                return 1;   // dts2 > dts1
            } else if (dt1[2] < dt2[2]) {
                return -1;  // dts1 < dts2
            }
            if (dt1[1] > dt2[1]) {
                return 1;   // dts1 > dts2
            } else if (dt1[1] < dt2[1]) {
                return -1;  // dts1 < dts2
            }
            return 0     // dts1 = dts2
        } else {
            return undefined;
        }
    }

    todaysDt(yrLen: number): string {
        var today = new Date();
        return (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString())
            + '/' + (today.getDate() < 10 ? '0' + today.getDate().toString() : today.getDate().toString())
            + '/' + (yrLen && yrLen == 4 ? today.getFullYear().toString() : today.getFullYear().toString().substring(2, 4));
    }

    ckDateInStr(dts: string): string {
        // var rx = new RegExp(/^[ ]?\d{1,2}[ ]?\/[ ]?\d{1,2}[ ]?\/\d{2}|\d{4}$/,'g'); // allows 01/04/17 , 01/04/2017 , 1 /4 /17 , 1 /4 /2017 , 1/ 4/2017 , 1 4 17 etc.
        var rx = new RegExp(/^[ ]?\d{1,2}[ -/]{1}\d{1,2}[ -/]{1}\d{2}$|^[ ]?\d{1,2}[ -/]{1}\d{1,2}[ -/]{1}\d{4}$/, 'g'); // allows 01/04/17 , 01/04/2017 , 1 /4 /17 , 1 /4 /2017 , 1/ 4/2017 , 1 4 17 etc.
        var dtStrOut: string;   // resulting formated date string outputed; '' if date error
        if (dts.match(rx)) {    // if input date dts has expected date pattern
            var dtDate = new Date(dts);    // convert to date type
            var mDate: number = 1 + dtDate.getMonth();    // month 0 based from Date type
            var dDate: number = dtDate.getDate();   //day of the month 1 based from Date type
            var yDate: number = dtDate.getFullYear();   // year 1 based from Date type

            var yrLen: number;  // year length, 2 or 4 digits
            if (dts.match(/[ -/]\d{2}$/g)) {
                yrLen = 2;
            } else if (dts.match(/[ -/]\d{4}$/g)) {
                yrLen = 4;
            } else {
                return '';  // entered date is invalid
            }

            dtStrOut = ('00' + mDate.toString()).slice(-2) + '/' + ('00' + dDate.toString()).slice(-2) + '/' + ('0000' + yDate.toString()).slice(-yrLen);   // use Date numbers to format a date string

            var mStr = dts.match(/^\d{1,2}[ -/]{1}/g).toString().replace(/[ -/]/g, "").trim() // month from input string type
            var dStr = dts.match(/[ -/]{1}\d{1,2}[ -/]{1}/g).toString().replace(/[ -/]/g, "").trim();   // day from input string type
            /*            
            console.log('dtDate=' + dtDate);
            console.log('dtStrOut=' + dtStrOut);
            console.log('dt2s=' + ('00' + mStr).slice(-2) + '/' + ('00' + dStr).slice(-2) + '/' + ('0000' + yDate.toString()).slice(-yrLen));
            */
            if (dtStrOut == ('00' + mStr).slice(-2) + '/' + ('00' + dStr).slice(-2) + '/' + ('0000' + yDate.toString()).slice(-yrLen)) {    // if 02/30/16 is entered it will be compared to 03/01/16 & fail
                return dtStrOut;
            } else {
                return '';  // entered date is invalid
            }
        } else {
            return '';   // doesn't even resembles a date
        }
    }

    isDate(dt: any): boolean {
        if (dt instanceof Date && !isNaN(dt.valueOf())) {
            return true;
        }
        return false;
    }

    escApos(val: string): string {
        return val.replace(/'/g, "''");
    }

    dtFromStrJS(dtStr: string): Date {
        // ex: dtStr = '2018-12-28T08:00:00.000Z'
        return new Date(parseInt(dtStr.match(/^\d{4}/g)[0]),
            parseInt(dtStr.match(/\-\d{1,2}\-/g)[0].replace('-', '')) - 1,
            parseInt(dtStr.match(/\d{2}T/g)[0].replace('T', '')),
            parseInt(dtStr.match(/T\d{2}/g)[0].replace('T', '')),
            parseInt(dtStr.match(/\:\d{2}/g)[0].replace(':', '')),
            parseInt(dtStr.match(/\d{2}\./g)[0].replace('.', '')),
            parseInt(dtStr.match(/\.\d{3}/g)[0].replace('.', '')));
    }

    isICD10(code: string): boolean {
        var regX = RegExp('^[A-TV-Za-tv-z][0-9][A-z0-9]\.?[A-Z0-9]{0,4}$');
        return regX.test(code);
    }

    isNumeric(numStr: string): boolean {
        if (numStr && !isNaN(parseFloat(numStr)) && isFinite(parseFloat(numStr))) {
            return true;
        }
        return false;
    }

    isNPIvalid(npi: string): boolean {
        if (this.isNumeric(npi) && npi.trim().length == 10) {
            let d = new Array();
            for (var i = 8; i >= 0; i--) {
                d[i + 1] = parseInt(npi.substring(i, i + 1));
                if ((i + 1) % 2) { // Odd ?
                    d[i + 1] = 2 * d[i + 1];
                }
            }
            d[0] = 0;
            for (var i = 1; i < 10; i++) {
                d[0] = d[0] + Math.floor(d[i] / 10) + d[i] % 10;
            }
            d[0] = d[0] + 24;
            if (parseInt(npi.substring(9, 10)) === (10 * (1 + Math.floor(d[0] / 10)) - d[0]) % 10) {
                return true;
            }
        }
        return false;
    }

    dateDiffDays(fromDtStr: string, toDtStr: string): number {
        if (this.isDate(new Date(fromDtStr)) && this.isDate(new Date(toDtStr))) {
            let fromDt = new Date(fromDtStr);
            let toDt = new Date(toDtStr);
            return (toDt.getTime() - fromDt.getTime()) / (1000 * 3600 * 24);
        } else {
            return undefined;  // Invalid input to/from date
        }
    }

    deepEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;   // Objects not equal
        }

        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];
            const areObjects = (val1 && typeof val1 === 'object' && val2 && typeof val2 === 'object');
            if (areObjects && !this.deepEqual(val1, val2) || !areObjects && val1 !== val2) {
                return false;   // Objects not equal
            }
        }

        return true;    // Objects are equal
    }

    calcAge(dob: string): string {
        // let dobDt: Date = new Date(dob);
        // let tdyDt: Date = new Date();
        // let dt: string;

        // if (dob.length == 8 && parseInt(dob.substring(0, 4)) >= 1850) {
        //     dt = dob.substring(4, 6) + '/' + dob.substring(6, 8) + '/' + dob.substring(0, 4);
        // } else {
        //     dt = dob;
        // }
        // if (this.isDate(new Date(dt))) {
        //     let lpYrs = 0;
        //     for (let y = dobDt.getFullYear(); y < tdyDt.getFullYear() + 1; y++) {
        //         if (y % 4 == 0) {
        //             lpYrs += 1;
        //         }
        //     }

        //     let dys = Math.floor((tdyDt.getTime() - dobDt.getTime()) / (1000 * 60 * 60 * 24)); // Days between today & dob exact
        //     let yrs = Math.floor((dys - (lpYrs * 366)) / 365) + lpYrs;  // Years elapsed
        //     let mos = 0;
        //     if (Math.floor((dys - (lpYrs * 366)) / 365) + lpYrs === (dys - (lpYrs * 366)) / 365 + lpYrs) {
        //         dys = 0;
        //     } else {
        //         mos = yrs * 12 + (12 - dobDt.getMonth()) + tdyDt.getMonth();  // Months elapsed 
        //         mos = mos % 12;

        //         let mi = dobDt.getMonth() + 1;
        //         let mf = 11;
        //         if (dobDt.getFullYear() === tdyDt.getFullYear()) {
        //             mf = tdyDt.getMonth();
        //         }

        //         for (let y = dobDt.getFullYear(); y < tdyDt.getFullYear() + 1; y++) {
        //             if (y === tdyDt.getFullYear()) {
        //                 mf = tdyDt.getMonth();
        //             }

        //             for (let m = mi; m <= mf; m++) {
        //                 if (dys > this.monthMaxDys[m]) {
        //                     dys -= this.monthMaxDys[m];
        //                 }
        //                 if (y % 4 == 0 && m == 1 && dys > 0) {
        //                     dys -= 1;   // Substract 1 day if leap year && m = february
        //                 }
        //             }
        //             mi = 0; // Start next year in january
        //         }

        //         // console.log(yrs);       if (dob.match(/^[0-1][0-2]\/[0-3][0-9]\/(19|20)\d{2}$/g)) {

        //         // console.log(mos);
        //         // console.log(dys);
        //     }

        if (dob.match(/^[0-1][0-9]\/[0-3][0-9]\/(19|20)\d{2}$/g)) {
            const dobMom = moment(dob, 'MM/DD/YYYY');
            const tdyMom = moment(new Date());
            let yrs = tdyMom.diff(dobMom, 'years');
            let mos = tdyMom.diff(dobMom, 'months');
            let dys = tdyMom.diff(dobMom, 'days');

            if (yrs < 0 || mos < 0 || dys < 0) {
                return '';  //ERROR - possible wrong system date
            } else if (yrs > 10) {
                return yrs.toString() + 'a';
            } else if (yrs >= 1 && yrs <= 10) {
                if (mos > 0 && dys > 0) {
                    return yrs.toString() + 'a' + mos.toString() + 'm' + dys.toString() + 'd';
                } else if (mos > 0) {
                    return yrs + 'a' + mos + 'm';
                } else if (dys > 0) {
                    return yrs.toString() + 'a' + dys.toString() + 'd';
                }
            } else {
                if (mos > 0 && dys > 0) {
                    return mos.toString() + "m" + dys.toString() + "d";
                } else if (mos > 0) {
                    return mos.toString() + "m";
                } else if (dys > 0) {
                    return dys + 'd';
                }
            }
        }
        return '';
    }

    fmtAmount(amnt: string, symb: string, decs: number): string {   // Formats a numeric string as a decimal/currency
        if (this.isNumeric(amnt)) {   // Must have only numbers & optionally one decimal point
            let result: string;
            decs = decs ? decs : 2;  // Defaults to 2 decimals
            let dec = amnt.indexOf('.');    // Decimal point location if included
            let frac;
            if (dec > -1) {
                frac = (amnt.substring(dec + 1) + '0'.repeat(decs)).substring(0, decs);
                amnt = amnt.replace(/\.[0-9]*$/g, '');
                console.log(amnt);
                if (!amnt) {
                    return '0.' + frac;
                }
            } else {
                frac = '0'.repeat(decs);  // To complete missing 0s
            }

            let revAmnt = amnt.split('').reverse().join('');  // Convert to [], reverse [], join elements in string
            let rgx = /(?<tres>\d{0,3})(?<dos>\d{0,3})(?<uno>\d{0,3})/g;
            let m = rgx.exec(revAmnt);

            for (let i = 3; i > 0; i--) { // Reverse matched groups
                if (m[i]) {
                    result = m[i] + (i < 3 ? ',' : '') + (result ? result : '');
                }
            };
            result = result.replace(/^,|,$/g, '');  // Remove leading/trailing comma
            return (symb ? symb : '') + result.split('').reverse().join('') + '.' + frac;
        }
        return '';
    }

}