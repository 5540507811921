import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-signature-capture',
  templateUrl: './signature-capture.component.html',
  styleUrls: ['./signature-capture.component.css']
})
export class SignatureCaptureComponent {
  @Input() queryString: string;
  engLang: boolean = false;
  isSigning = false;

  ngOnInit(): void {
    this.parseQueryString(this.queryString)

    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('signCanvas');
    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = 'blue';
    this.isSigning = false;

    // const modalOffsetLeft = 300;  // Guessed but should be read from properties

    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseleave', stopDrawing);

    // For touch events
    canvas.addEventListener('touchstart', startDrawing);
    canvas.addEventListener('touchend', stopDrawing);
    canvas.addEventListener('touchmove', draw);

    function getTransformedPoint(x, y) {
      const transform = ctx.getTransform();
      const transformedX = x - transform.e;
      const transformedY = y - transform.f;
      return { x: transformedX, y: transformedY };
    }

    function startDrawing(event) {
      this.isSigning = true;
      ctx.beginPath();

      // Sum of distances for each element from left border of element to parent all the way to left border of viewport from top of canvas
      const modalOffsetLeft = event.target.offsetLeft + event.target.parentElement.parentElement.parentElement.offsetLeft;
      // Sum of distances for each element from top border of element to parent all the way to top border of viewport from top of canvas
      const modalOffsetTop = event.target.offsetTop + event.target.parentElement.offsetTop + event.target.parentElement.parentElement.parentElement.offsetTop;

      // Note, .clientX & .offsetX are absolute distances from the viewport's origin to where the click or point happened within the canvas
      const adjustedX = event.type.startsWith('touch')
        ? event.touches[0].clientX - modalOffsetLeft
        : getTransformedPoint(event.offsetX, event.offsetY).x;

      const adjustedY = event.type.startsWith('touch')
        ? event.touches[0].clientY - modalOffsetTop
        : getTransformedPoint(event.offsetX, event.offsetY).y;

      ctx.moveTo(adjustedX, adjustedY);
    }

    function stopDrawing() {
      this.isSigning = false;
      ctx.closePath();
    }

    function draw(event) {
      if (this.isSigning) {

        // Sum of distances for each element from left border of element to parent all the way to left border of viewport from top of canvas
        const modalOffsetLeft = event.target.offsetLeft + event.target.parentElement.parentElement.parentElement.offsetLeft;
        // Sum of distances for each element from top border of element to parent all the way to top border of viewport from top of canvas
        const modalOffsetTop = event.target.offsetTop + event.target.parentElement.offsetTop + event.target.parentElement.parentElement.parentElement.offsetTop;

        // Note, .clientX & .offsetX are absolute distances from the viewport's origin to where the click or point happened within the canvas
        const adjustedX = event.type.startsWith('touch')
          ? event.touches[0].clientX - modalOffsetLeft
          : getTransformedPoint(event.offsetX, event.offsetY).x;

        const adjustedY = event.type.startsWith('touch')
          ? event.touches[0].clientY - modalOffsetTop
          : getTransformedPoint(event.offsetX, event.offsetY).y;

        ctx.lineTo(adjustedX, adjustedY);
        ctx.stroke();
      }
    }

  }

  parseQueryString(queryString) {
    if (!queryString) {
      return
    }

    let params = [],
      values = [];
    let queries = decodeURIComponent(queryString).substring(1).split('&');

    for (var i = 0; i < queries.length; i++) {
      params[i] = queries[i].split('=')[0];
      values[i] = queries[i].split('=')[1];

      switch (params[i].trim()) {
        case 's': {
          (<HTMLInputElement>document.getElementById('sn')).value = values[i];
          break;
        }
        case 'd': {
          (<HTMLInputElement>document.getElementById('db')).value = values[i];
          break;
        }
        case 'pi': {
          (<HTMLInputElement>document.getElementById('patID')).value = values[i];
          break;
        }
        case 'dt': {
          if (!moment.isDate(new Date(moment(values[i], 'MMDDYY').format('MM/DD/YYYY')))
            || moment.duration((moment(new Date())).diff(moment(new Date(moment(values[i], 'MMDDYY').format('MM/DD/YYYY'))))).asHours() > 24) { // Give 24hrs for this page to deactivate
            console.log(moment.isDate(new Date(moment(values[i], 'MMDDYY').format('MM/DD/YYYY'))));
            console.log(moment.duration((moment(new Date())).diff(moment(new Date(moment(values[i], 'MMDDYY').format('MM/DD/YYYY'))))).asHours());
          }
          break;
        }
        case 'it': {
          (document.getElementById('infoTel')).innerText = values[i];
          break;
        }
        case 'ie': {
          (document.getElementById('infoEmail')).innerText = values[i];
          break;
        }
        case 'o': {
          (document.getElementById('siteNm')).innerText = values[i];
          break;
        }
        case 'l': {
          (<HTMLInputElement>document.getElementById('lang')).value = values[i];
          break;
        }
        case 'st': {
          if (values[i] == 'H') {
            (<HTMLInputElement>document.getElementById('signType')).value = 'H';
            (document.getElementById('spanSignType')).innerText = 'HIPAA'
          } else {
            if (this.engLang) {
              (<HTMLInputElement>document.getElementById('signType')).value = 'E';
              (document.getElementById('spanSignType')).innerText = 'Appointment Encounter';
            } else {
              (<HTMLInputElement>document.getElementById('signType')).value = 'E';
              (document.getElementById('spanSignType')).innerText = 'Asistencia a Cita';
            }
          }
          break;
        }
      }
    }
  }

}
