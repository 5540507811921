import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbDropdown, NgbDropdownConfig, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dd-ngbdropdown',
  imports: [NgbDropdownModule],
  template: `<div ngbDropdown container="body" display="dynamic" autoClose="inside" class="d-inline-block"
                (keyup)="onKeyup_search($event)" >

              <button ngbDropdownToggle ngbTooltip="{{tt}}" type="button"
                  class="btn btn-default py-0 px-1"> <!-- Customize button here! -->
                  <span [ngClass]="{'font90': font90, 'font75': font75, 'font50': font50, 'fontsmall': fontsmall, 'fontxsmall': fontxsmall, 'fontxxsmall': fontxxsmall, 'fontsmaller': fontsmaller}">
                    {{ labl }}
                </span>
              </button>

              <div ngbDropdownMenu class="radius25 unselectable"
                  style="overflow-y: auto; background-color: rgb(240,240,240);"
                  [ngStyle]="{ width: width, height: height  }">
                  
                  <button ngbDropdownItem *ngFor="let i of vList; let idx = index;" class="radius25" 
                    style="margin: 0 3px; padding: 0 2px; height: 30px;"
                    (click)="selected(i)" 
                    [ngStyle]="{ 'color': '#000000'
                      , 'background-color': (idx % 2 == 0 ? evenBackgColr: oddBackgColr)
                      , 'font-weight': ((i.value && preVal == i.value) 
                                          || (i.name && preVal == i.name)
                                          || (i.pKey && preVal == i.pKey && i.pKey != 0) ? 'bold': 'normal') }">
                      
                    <span class="px-0" style="cursor: pointer;">
                      <span style="font-size: 75%;">
                          {{ i.value }}
                      </span>
                      <span style="font-size: smaller; float: right; padding: 0 3px 0 0;">
                          {{ i.name }}
                      </span>
                    </span>
                  </button>
              </div>
            </div>`,
  styles: [`
    .font90 {
      font-size: 90%;
    }
    .font75 {
      font-size: 75%;
    }
    .font50 { 
      font-size: 50%;
    }
    .fontsmall {
        font-size: small;
    }
    .fontxsmall {
        font-size: x-small;
    }
    .fontxxsmall {
        font-size: xx-small;
    }
    .fontsmaller {
      font-size: smaller;
    }
`]
})
export class DdNgbdropdownComponent implements OnInit {

  @Input() labl: string = '';  // Selection as label
  @Input() preVal: string;  // Previous value
  @Input() rawList: []; // List of objects
  @Input() stylesCfg: any;  // Universal styles
  @Input() listTyp: string; // List type
  @Input() engLang: boolean = false;
  @Input() tt: string = this.engLang ? 'Show list' : 'Muestra listado';
  @Input() width: string = '280px';
  @Input() height: string = '300px';
  @Input() srchStr: string = '';
  @Input() font90: boolean = false;
  @Input() font75: boolean = false;
  @Input() font50: boolean = false;
  @Input() fontsmall: boolean = false;
  @Input() fontxsmall: boolean = false;
  @Input() fontxxsmall: boolean = false;
  @Input() fontsmaller: boolean = false;

  @Output() ddnListSelectionClkd = new EventEmitter();

  list: [{ value: string, name: string, pKey: string }] = [{ value: '', name: '', pKey: '' }];  // Input array mapped.
  vList: any;   // [{ value: string, name: string }];  // View array.
  evenBackgColr: string;
  oddBackgColr: string;
  kCnt: number = -1;

  constructor(private _ngbDropdown: NgbDropdown,
    public _ngbDropdownConfig: NgbDropdownConfig) {
    _ngbDropdownConfig.placement = 'start-top auto';
  }

  ngOnInit(): void {

    this.list.pop();  // Make it zero length initially

    switch (this.listTyp) { // Set styles for dropdown
      case 'provNpi':
        this.evenBackgColr = this.stylesCfg.provNpi_DDn_backgEvenColr;
        this.oddBackgColr = this.stylesCfg.provNpi_DDn_backgOddColr;
        break;
      case 'pInsID1':
      case 'pInsID2':
      case 'cInsID1':
      case 'cInsID2':
      case 'insPayerID':
        this.evenBackgColr = this.stylesCfg.insPayerId_DDn_backgEvenColr;
        this.oddBackgColr = this.stylesCfg.insPayerId_DDn_backgOddColr;
        break;
      case 'provID':
        this.evenBackgColr = this.stylesCfg.provNpi_DDn_backgEvenColr;
        this.oddBackgColr = this.stylesCfg.provNpi_DDn_backgOddColr;
        break;
      case 'prodID1':
      case 'prodID2':
      case 'prodID0':
        this.evenBackgColr = this.stylesCfg.prodNpi_DDn_backgEvenColr;
        this.oddBackgColr = this.stylesCfg.prodNpi_DDn_backgOddColr;
        break;
      case 'refNpi':
        this.evenBackgColr = this.stylesCfg.refNpi_DDn_backgEvenColr;
        this.oddBackgColr = this.stylesCfg.refNpi_DDn_backgOddColr;
        break;
      case 'facID':
        this.evenBackgColr = this.stylesCfg.facId_DDn_backgEvenColr;
        this.oddBackgColr = this.stylesCfg.facId_DDn_backgOddColr;
        break;
      case 'payMode':
        this.evenBackgColr = this.stylesCfg.payMode_DDn_backgEvenColr;
        this.oddBackgColr = this.stylesCfg.payMode_DDn_backgOddColr;
        break;
      default:
        this.programmingError();
    }

    this.rawList.forEach(i => { // Map data to dropdown
      if (this.listTyp == 'provNpi') {
        this.list.push({ value: i['ckgNpi'], name: String(i['ProvLNm']).substring(0, 17), pKey: '' });
      } else if (this.listTyp == 'insPayerID') {
        this.list.push({ value: i['insPayID'], name: String(i['InLNm']).substring(0, 17), pKey: '' });
      } else if (this.listTyp == 'provID') {
        this.list.push({ value: i['alias'], name: String(i['pKey']).substring(0, 17), pKey: i['pKey'] });
      } else if (this.listTyp == 'refNpi') {
        this.list.push({ value: i['name'], name: String(i['npi']).substring(0, 10), pKey: i['pKey'] });
      } else if (this.listTyp == 'facID') {
        this.list.push({ value: i['alias'], name: String(i['pKey']).substring(0, 10), pKey: i['pKey'] });
      } else if (this.listTyp.match(/^[pc]InsID\d$/g)) {
        this.list.push({ value: i['alias'], name: String(i['pKey']).substring(0, 10), pKey: i['pKey'] });
      } else if (this.listTyp.startsWith('prodID')) {
        this.list.push({ value: i['alias'], name: String(i['pKey']).substring(0, 17), pKey: i['pKey'] });
      } else if (this.listTyp = 'payMode') {
        this.list.push({ value: i['value'], name: '', pKey: '' });
      } else {
        this.programmingError();
        return;
      }
    });

    if (this.list && this.list.length > 0) {  // Configure item 0 for clearing selection
      const st = '(none/ninguno)';
      let i = this.list.findIndex(o => o.name == st || o.value == st || (o.name == '' && o.value == ''));
      if (i == 0) {
        this.list[0].name = this.engLang ? '(none)' : '(ninguno)';
        this.list[0].value = '';
        this.list[0].pKey = '';
      } else {
        if (!this.listTyp.match(/^[pc]InsID\d$/g)) {
          this.list.unshift({ name: this.engLang ? '(none)' : '(ninguno)', value: '', pKey: '' });
        }
      }
      if (this.listTyp == 'refNpi') {
        this.list[0].pKey = '0';
      }
    }

    this.vList = this.list;
  }

  ngOnChanges(): void { // Fires whenever any input changes
    if (this.list) {
      this.srchStr = this.srchStr.toUpperCase();
      const c: number = this.srchStr.indexOf(',');  // Comma position in srchStr
      if (c > -1) {
        this.vList = this.list.filter(o =>
          o.value.substring(0, o.value.indexOf(',')).toUpperCase().includes(this.srchStr.substring(0, c))
          && o.value.substring(o.value.indexOf(',') + 1).toUpperCase().includes(this.srchStr.substring(c + 1)));
      } else {
        this.vList = this.list.filter(o => o.name.toUpperCase().includes(this.srchStr) || o.value.toUpperCase().includes(this.srchStr));
      }

      if (this.listTyp.startsWith('prodID')) {
        this.preVal = this.preVal.replace(/\D*/g, '');
      }

      // console.log('ngOnChanges()');
      // console.log(this.srchStr);
      // console.dir(this.list);
    }
  }

  selected(itm: any): void {
    switch (this.listTyp) {
      case 'refNpi':
        this.ddnListSelectionClkd.emit({
          value: itm.value.replace(/\(ninguno\)/g, '').replace(/\(none\)/g, '').trim(),
          name: itm.name.replace(/\(ninguno\)/g, '').replace(/\(none\)/g, '').trim(),
          pKey: itm.pKey,
          typ: this.listTyp
        });
        break;
      case 'payMode':
        this.ddnListSelectionClkd.emit({
          value: itm.value.replace(/\(ninguno\)/g, '').replace(/\(none\)/g, '').trim(),
          name: '',
          pKey: '',
          typ: this.listTyp
        });
        break;
      default:
        this.ddnListSelectionClkd.emit({
          value: itm.value.replace(/\(ninguno\)/g, '').replace(/\(none\)/g, '').trim(),
          name: itm.name.replace(/\(ninguno\)/g, '').replace(/\(none\)/g, '').trim(),
          pKey: itm.pKey.trim(),
          typ: this.listTyp
        });
    }

    this.srchStr = '';
    this.kCnt = -1;
    this._ngbDropdown.close();
  }

  onKeyup_search(event: any): boolean {
    if (event?.key == 'Escape') {
      this.kCnt = -1;
    }

    if (event?.key == 'Enter') {  // Select upon Enter when only one selection is shown
      if (this.vList.length == 1) {
        this.selected(this.vList[0]);
        this.kCnt = -1;
        this._ngbDropdown.close();
        return true;
      } else if (this.kCnt > -1 && this.kCnt < this.vList.length) {
        this.selected(this.vList[this.kCnt]);
        this.kCnt = -1;
        this._ngbDropdown.close();
        return true;
      } else {
        event.stopPropagation();
        this.srchStr = '';
        return false;
      }
    }

    this.srchStr = this.srchStr.toUpperCase();
    if (event?.key.length == 1 && event?.key.toUpperCase().match(/[ \w,]/g)) {
      this.srchStr += event.key.toUpperCase();
    }

    if (event?.key == 'Backspace') {
      let l = this.srchStr.length;
      if (l > 1) {
        this.srchStr = this.srchStr.substring(0, l - 1);
      } else {
        this.srchStr = '';
        this.vList = this.list;
        return;
      }
    }

    this.vList = this.list.filter(o => o.name.toUpperCase().includes(this.srchStr) || o.value.toUpperCase().includes(this.srchStr));

    if (event?.key == 'ArrowDown') {
      if (this.kCnt < this.vList.length) {
        this.kCnt += 1;
        return;
      }
    }

    if (event?.key == 'ArrowUp') {
      if (this.kCnt > -1) {
        this.kCnt += 1;
        return;
      }
    }

    if (event?.key == ' ') {
      return false;  // So (space) key doent close the dropdown
    }
  }

  programmingError(): void {
    let msg = 'Unspecified/invalid `listTyp` = ' + this.listTyp + ' @ dd-ngbdropdown.component:ngOnInit()';
    console.log('%c' + msg, 'color: black; background: red; font-size: 14px');
    alert(msg);
  }

}
