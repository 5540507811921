import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataMessageService {

  private userValidLoginSource = new BehaviorSubject('false');  // initial default value
  currentUserValidLoginStr = this.userValidLoginSource.asObservable();

  private engLangSource = new BehaviorSubject('false');
  currentEngLangStr = this.engLangSource.asObservable();

  private snSource = new BehaviorSubject('0000');
  currentSnStr = this.snSource.asObservable();

  private siteSource = new BehaviorSubject('');
  currentSiteStr = this.siteSource.asObservable();

  private siteAd1Source = new BehaviorSubject('');
  currentSiteAd1Str = this.siteAd1Source.asObservable();

  private siteAd2Source = new BehaviorSubject('');
  currentSiteAd2Str = this.siteAd2Source.asObservable();

  private siteCtSource = new BehaviorSubject('');
  currentSiteCtStr = this.siteCtSource.asObservable();

  private siteStSource = new BehaviorSubject('');
  currentSiteStStr = this.siteStSource.asObservable();

  private siteZpSource = new BehaviorSubject('');
  currentSiteZpStr = this.siteZpSource.asObservable();

  private siteTl1Source = new BehaviorSubject('');
  currentSiteTl1Str = this.siteTl1Source.asObservable();

  private siteXt1Source = new BehaviorSubject('');
  currentSiteXt1Str = this.siteXt1Source.asObservable();

  private siteTl2Source = new BehaviorSubject('');
  currentSiteTl2Str = this.siteTl2Source.asObservable();

  private siteXt2Source = new BehaviorSubject('');
  currentSiteXt2Str = this.siteXt2Source.asObservable();

  private siteEm1Source = new BehaviorSubject('');
  currentSiteEm1Str = this.siteEm1Source.asObservable();

  private siteEm2Source = new BehaviorSubject('');
  currentSiteEm2Str = this.siteEm2Source.asObservable();

  private userIdSource = new BehaviorSubject('');
  currentUserIdStr = this.userIdSource.asObservable();

  private userSource = new BehaviorSubject('');
  currentUserStr = this.userSource.asObservable();

  private userLastNmSource = new BehaviorSubject('');
  currentUserLastNmStr = this.userLastNmSource.asObservable();

  private userFirstNmSource = new BehaviorSubject('');
  currentUserFirstNmStr = this.userFirstNmSource.asObservable();

  private userSecOptsSource = new BehaviorSubject('');
  currentUserSecOptsStr = this.userSecOptsSource.asObservable();

  constructor() { }

  changeUserValidLoginStr(userValidLoginStr: string) {
    this.userValidLoginSource.next(userValidLoginStr)
  }

  changeEngLangStr(engLangStr: string) {
    this.engLangSource.next(engLangStr);
  }

  changeSnStr(snStr: string) {
    this.snSource.next(snStr);
  }

  changeSiteStr(siteStr: string) {
    this.siteSource.next(siteStr);
  }

  changeSiteAd1Str(siteAd1Str: string) {
    this.siteAd1Source.next(siteAd1Str);
  }

  changeSiteAd2Str(siteAd2Str: string) {
    this.siteAd2Source.next(siteAd2Str);
  }

  changeSiteCtStr(siteCtStr: string) {
    this.siteCtSource.next(siteCtStr);
  }

  changeSiteStStr(siteStStr: string) {
    this.siteStSource.next(siteStStr);
  }

  changeSiteZpStr(siteZpStr: string) {
    this.siteZpSource.next(siteZpStr);
  }

  changeSiteTl1Str(siteTl1Str: string) {
    this.siteTl1Source.next(siteTl1Str);
  }

  changeSiteXt1Str(siteXt1Str: string) {
    this.siteXt1Source.next(siteXt1Str);
  }

  changeSiteTl2Str(siteTl2Str: string) {
    this.siteTl2Source.next(siteTl2Str);
  }

  changeSiteXt2Str(siteXt2Str: string) {
    this.siteXt2Source.next(siteXt2Str);
  }

  changeSiteEm1Str(siteEm1Str: string) {
    this.siteEm1Source.next(siteEm1Str);
  }

  changeSiteEm2Str(siteEm2Str: string) {
    this.siteEm2Source.next(siteEm2Str);
  }

  changeUserIdStr(userIdStr: string) {
    this.userIdSource.next(userIdStr);
  }

  changeUserLastNmStr(userLastNmStr: string) {
    this.userLastNmSource.next(userLastNmStr);
  }

  changeUserFirstNmStr(userFirstNmStr: string) {
    this.userFirstNmSource.next(userFirstNmStr);
  }

  changeUserSecOptsStr(userSecOptsStr: string) {
    this.userSecOptsSource.next(userSecOptsStr);
  }

}
