// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* * {
    font-size: small;
} */

.w20 {
    width: 20px;
}

.w30 {
    width: 30px;
}

.w70 {
    width: 70px;
}

.w80 {
    width: 80px;
}

.w95 {
    width: 95px;
}

.w125 {
    width: 125px;
}

.oddCkDet {
    background-color: #f1f2f1;
}

.evenCkDet {
    background-color: #dad8d9;
}

.small {
    font-size: small;
}

.currency {
    width: inherit;
    /* Inherits width from parent */
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/ck-register/ck-register-detail/ck-register-detail.component.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,+BAA+B;IAC/B,iBAAiB;AACrB","sourcesContent":["/* * {\r\n    font-size: small;\r\n} */\r\n\r\n.w20 {\r\n    width: 20px;\r\n}\r\n\r\n.w30 {\r\n    width: 30px;\r\n}\r\n\r\n.w70 {\r\n    width: 70px;\r\n}\r\n\r\n.w80 {\r\n    width: 80px;\r\n}\r\n\r\n.w95 {\r\n    width: 95px;\r\n}\r\n\r\n.w125 {\r\n    width: 125px;\r\n}\r\n\r\n.oddCkDet {\r\n    background-color: #f1f2f1;\r\n}\r\n\r\n.evenCkDet {\r\n    background-color: #dad8d9;\r\n}\r\n\r\n.small {\r\n    font-size: small;\r\n}\r\n\r\n.currency {\r\n    width: inherit;\r\n    /* Inherits width from parent */\r\n    text-align: right;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
