// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font10 {
    font-size: 10px;
}

.rtalign {
    text-align: right;
}

.collapsedRow {
    display: none;
}

.clmTable {
    background-color: #f5f7f8;
}

.detTable {
    background-color: #e2effa;
}

.payTable {
    background-color: #e0f1e0;
}

.padTable {
    background-color: #fae1e1;
}

.insRow:hover,
.clmRow:hover,
.detRow:hover,
.payRow:hover,
.padRow:hover {
    background-color: #e6e6e6 !important;
    cursor: pointer;
}

.clmRow,
.detRow,
.payRow,
.padRow {
    height: 25px;
}

.expandedRw {
    font-style: italic;
    background-color: rgb(241, 202, 162);
}

.link-button {
    background: none;
    border: none;
    padding: 0;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
}

.link-button:hover {
    text-decoration: none;
}

.link-button:focus {
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/reports/rpt-aging/rpt-aging.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;;;;IAKI,oCAAoC;IACpC,eAAe;AACnB;;AAEA;;;;IAII,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,WAAW;IACX,0BAA0B;IAC1B,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".font10 {\r\n    font-size: 10px;\r\n}\r\n\r\n.rtalign {\r\n    text-align: right;\r\n}\r\n\r\n.collapsedRow {\r\n    display: none;\r\n}\r\n\r\n.clmTable {\r\n    background-color: #f5f7f8;\r\n}\r\n\r\n.detTable {\r\n    background-color: #e2effa;\r\n}\r\n\r\n.payTable {\r\n    background-color: #e0f1e0;\r\n}\r\n\r\n.padTable {\r\n    background-color: #fae1e1;\r\n}\r\n\r\n.insRow:hover,\r\n.clmRow:hover,\r\n.detRow:hover,\r\n.payRow:hover,\r\n.padRow:hover {\r\n    background-color: #e6e6e6 !important;\r\n    cursor: pointer;\r\n}\r\n\r\n.clmRow,\r\n.detRow,\r\n.payRow,\r\n.padRow {\r\n    height: 25px;\r\n}\r\n\r\n.expandedRw {\r\n    font-style: italic;\r\n    background-color: rgb(241, 202, 162);\r\n}\r\n\r\n.link-button {\r\n    background: none;\r\n    border: none;\r\n    padding: 0;\r\n    color: blue;\r\n    text-decoration: underline;\r\n    cursor: pointer;\r\n    font-size: inherit;\r\n}\r\n\r\n.link-button:hover {\r\n    text-decoration: none;\r\n}\r\n\r\n.link-button:focus {\r\n    outline: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
