import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { I835svc } from '../../shared/interfaces/835svc';
import { I835cas } from '../../shared/interfaces/835cas';
import { I835adj } from '../../shared/interfaces/835adj';
import { AppToastsService } from '../../shared/app-toasts/app-toasts.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CkRegister835DetailService } from './ck-register835-detail.service';
import { WebsocketService } from '../../shared/websocket.service';
import { Subscription } from 'rxjs';
import { Imc } from '../../shared/interfaces/matchCandidate';
import { ImcDet } from '../../shared/interfaces/matchCandidateDet';
import { CkRegisterService } from '../../ck-register/ck-register.service';
import * as moment from 'moment';
import { HelperRtnsComponent } from '../../shared/helper-rtns.component';

@Component({
  selector: 'app-ck-register835-detail-component',
  templateUrl: './ck-register835-detail.component.html',
  styleUrls: ['./ck-register835-detail.component.css']
})
export class CkRegister835DetailComponent implements OnDestroy {
  @ViewChild('modalBody') modalBody: ElementRef;
  @ViewChild('successToast', { read: TemplateRef, static: true }) successToast: TemplateRef<any>;

  @Input() sn: string;
  @Input() userID: string;
  @Input() engLang: boolean;
  @Input() cas: I835cas;
  @Input() _835svc: I835svc[];
  @Input() casSeqNo: string;
  @Input() _835adj: I835adj[];
  @Input() svcCaseSeqNo: string;
  @Input() payDt: string;
  @Input() npi: string;
  @Input() payerId: string;
  @Input() filNm: string;
  @Input() filDt: string;
  @Input() filAmnt: string;
  @Input() filPayerNm: string;
  @Input() filPayeeNm: string;
  @Input() chkgNo: string;
  @Input() ckPayeeNpi: string;
  @Input() ckPayerID: string;
  @Input() carcNotes: any[];
  @Input() sortAsc: boolean;
  @Input() prevSrtClass: string;
  @Input() reclCkBxId: string;
  @Input() reclCkBxCked: boolean;
  @Output() reSortedCasArr: EventEmitter<any> = new EventEmitter();
  @Output() print835remittance: EventEmitter<any> = new EventEmitter();
  @Output() unCreditedPaymentsCnt: EventEmitter<string> = new EventEmitter();
  @Output() updtTotAdjCnt: EventEmitter<any> = new EventEmitter();
  @Output() reclaim: EventEmitter<any> = new EventEmitter();

  sio: boolean = false;   // true when len(sn) = 4 using socket io in hybrid mode & false when using all web api access.
  sioSubscr: Subscription;
  modalStack: string[] = [];
  waiting4Response: boolean = false;  // for visual feedback of ajax calls
  mcs: Imc[] = []; // Array of mc = matched cases
  mcsDet: ImcDet[] = [];
  seldCasSeqNo2match: string;

  chkMchBalance: boolean = false;
  matchedMcsPatID: string;
  matchedMcsCasID: string;
  matchedMcsCasPS: string;
  matchedMcsCasProvID: string;
  matchedMcsCasBal: string;
  matchedMcsInsID: string;
  matchedMcsReclTyp: string;
  matchedMcsIns: string;
  matchedMcsArrCnt: string;
  adjustPromptModal: boolean;
  adjustRemove: boolean;

  mcParamFiltr: string = '1';
  mcBalZeroFiltr: boolean = false;
  mcNpiFiltr: string = '';
  mcPayerIdFiltr: string = '';

  constructor(private _clipboard: Clipboard,
    private _websocketService: WebsocketService,
    private _appToastsService: AppToastsService,
    private _ckRegister835DetailService: CkRegister835DetailService,
    private _modalService: NgbModal,
    private _ckRegisterService: CkRegisterService,
    private _helpRtns: HelperRtnsComponent) { }

  ngOnInit(): void {
    this.sio = this.sn.length > 4 ? false : true;

    if (this.sio) {
      this.sioSubscr = this._websocketService.getMessages().subscribe((dataSet) => {

        if (dataSet?.length > 0) {
          this.waiting4Response = false;

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_Match835Payments2casesManual-Cas') {
            this.mcs = dataSet;
            if (this.mcs.some(mc => mc.mcParam == '1')) {
              this.mcParamFiltr = '1';
            } else if (this.mcs.some(mc => mc.mcParam == '2')) {
              this.mcParamFiltr = '2';
            } else if (this.mcs.some(mc => mc.mcParam == '3')) {
              this.mcParamFiltr = '3';
            } else if (this.mcs.some(mc => mc.mcParam == '4')) {
              this.mcParamFiltr = '4';
            } else {
              this.mcParamFiltr = '1';
            }
            this.mcNpiFiltr = this.npi;
            this.mcPayerIdFiltr = this.payerId;
            this.mcBalZeroFiltr = false;
            this.matchedMcsArrCnt = this.matchedClaimsCnt();
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_Match835Payments2casesManual-Det') {
            this.mcsDet = dataSet;
            this.mcsDet.forEach(det => {
              det.mcDetMatched = false;
              const momDet = moment(det.mcDetFromDt, 'YYYYMMDD');
              this._835svc.forEach(svc => {
                if (svc.svcCasSeqNo === this.seldCasSeqNo2match) {
                  const momSvc = moment(svc.svcDtFrom, 'YYYYMMDD');
                  if (momDet.isSame(momSvc)
                    && svc.svcProc == det.mcDetPcode
                    && (svc.svcMod1 + svc.svcMod2 + svc.svcMod3 + svc.svcMod4) == det.mcDetMods.replace(/ /g, '')) {
                    this.mcParamFiltr = this.mcs.find(mc => mc.mcCasID == det.mcDetCasID).mcParam;
                    det.mcDetMatched = true;
                  }
                }
              });
            });

            setTimeout(() => {
              this.scrolToMatchedClaim();
            }, 250)
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_CaseAdjust_835') {
            console.log(dataSet[0]?.sqlProcNm);
            if (this.cas.casID == dataSet[0].adjustedCasID && this.cas.casPS == dataSet[0].ps) {
              this.updtTotAdjCnt.emit(dataSet[0]);
            }
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_CaseAdjustRemove_835') {
            console.log(dataSet[0]?.sqlProcNm);
            if (this.cas.casID == dataSet[0].adjustedCasID && this.cas.casPS == dataSet[0].ps) {
              this.updtTotAdjCnt.emit(dataSet[0]);
            }
            this.adjustRemove = false;
          }

        } else {  // Nothing found
          this.waiting4Response = false;
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.prevSrtClass) {
      (document.querySelectorAll('.' + this.prevSrtClass)).forEach(d => d['hidden'] = false);
    } else {
      this.sortAsc = true;
      (document.querySelectorAll('.srt835Nm')).forEach(d => d['hidden'] = false);  // Initial default
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reclCkBxCked && changes.reclCkBxCked.currentValue) {  // Toggels R labeled checkbox
      const rCkBx: HTMLInputElement = <HTMLInputElement>document.getElementById(this.reclCkBxId);
      if (rCkBx) {
        console.log('rCkBx', rCkBx);
        console.log('changes.reclCkBxCked.currentValue', changes.reclCkBxCked.currentValue)
        rCkBx.checked = changes.reclCkBxCked.currentValue;
      }
    }
  }

  ngOnDestroy(): void {
    this._appToastsService.clear();
    this._835svc = [];
    this._835adj = [];
    this.mcs = [];
    this.mcsDet = [];
  }

  scrolToMatchedClaim() {
    if (document?.querySelectorAll('table.mcDets tr')) {
      const rows = document.querySelectorAll('table.mcDets tr');
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].children[0].children[0].children[0].classList.contains('fa-check')) {
          rows[i].scrollIntoView({ behavior: 'smooth', block: 'start' });
          return;
        }
      }
    }
  }

  copy2ClipBoard(str: string, desc: string) {
    this._clipboard.copy(str);
    this._appToastsService.updateDeadCenter(false);
    this._appToastsService.show(str, { header: desc + (this.engLang ? ' copied ' : ' copiado'), autohide: true, warning: true });
    // this.populateUpdateCarcSqlTable();  //Unremm and execute to populate and/or update Sql table [CARC] - done 4/24/2023
  }

  redBalColr(amnt): string {
    return parseFloat(amnt) >= .01 ? 'red' : 'black';
  }

  caseBalance(cas: any): string {
    let paySaved: boolean = this.strikeCreditedPayment(cas.casSeqNo);
    if (cas.casPsDesc === '???') {
      return cas.casPsDesc;
    } else if (cas.casBal?.match(/^\-?\d?\.?\d{0,2}/g) && cas.casThisPayment?.match(/^\-?\d?\.?\d{0,2}/g)) {
      let bal: number = (paySaved ? parseFloat(cas.casBal) : parseFloat(cas.casBal) - parseFloat(cas.casThisPayment));
      this.cas.casNewBal = bal.toString();
      return this.cas.casNewBal;
    } else {
      return '';
    }
  }

  strikeCreditedPayment(casSeqNo: string): boolean {  // paymentSavedLocation = PayID + '-' + PayCasID) + '-' + PayPS
    return this._835svc.some(obj => obj.svcCasSeqNo === casSeqNo && obj.paymentSavedLocation.match(/^\d+\-\d+\-S(1|2)/g)) ? true : false;
  }

  paymetSpanToolTipText(seqNo: string, ps: string): string {
    const spanEle = <HTMLSpanElement>document.querySelector('#spanPay' + seqNo + ps);
    if (this.engLang) {
      if (spanEle.classList.contains('strike')) {
        return 'Payment credited';
      } else {
        return 'Payment to credit';
      }
    } else {
      if (spanEle.classList.contains('strike')) {
        return 'Pago acreditado';
      } else {
        return 'Pago pendiente a acreditar';
      }
    }
  }

  balanceSpanToolTipText(seqNo: string, ps: string): string {
    const spanEle = <HTMLSpanElement>document.querySelector('#spanPay' + seqNo + ps);
    if (this.engLang) {
      if (spanEle.classList.contains('strike')) {
        return 'Current balance';
      } else {
        return 'Final balance after crediting payment';
      }
    } else {
      if (spanEle.classList.contains('strike')) {
        return 'Balance actual';
      } else {
        return 'Balance final después de acreditar pago';
      }
    }
  }

  balanceColrClass(cas: any): string {
    if (cas.casPsDesc === '???') {
      return 'redBal';
    }
    if (!this.strikeCreditedPayment(cas.casSeqNo)) {  // Current payment not credited yet
      if (parseFloat(cas.casBal) - parseFloat(cas.casThisPayment) >= .01) {
        return 'redBal';
      } else if (parseFloat(cas.casBal) - parseFloat(cas.casThisPayment) <= -.01) {
        return 'greenBal';
      } else {
        return 'blackBal';
      }
    } else {  // After current payment was credited
      if (parseFloat(cas.casBal) >= .01) {
        return 'redBal';
      } else if (parseFloat(cas.casBal) <= -.01) {
        return 'greenBal';
      } else {
        return 'blackBal';
      }
    }
  }

  getCarcDescrFromAdj(adj: any): void { // Get adj CARC data from Sql table [CARC]
    if (!adj) {
      return;
    }

    this._appToastsService.updateDeadCenter(false); // Show toast @ top of viewport
    let grpCodeDesc: string = adj.adjGrpCode ? (adj.adjGrpCode + ' = ' + this._ckRegister835DetailService.carcGroupCodes.find(obj => obj.code == adj.adjGrpCode).descr) : adj;
    let reasonCode: string = adj.adjReasonCode ? adj.adjReasonCode : adj;
    this._ckRegister835DetailService.getCarcDescrFromReasonCode(reasonCode)
      .subscribe({
        next: data => {
          data.forEach(itm => {
            this._appToastsService.updateDeadCenter(false);
            this._appToastsService.show(adj.adjReasonCode + ' = ' + itm.descr, { header: grpCodeDesc, autohide: false, success: true });
          });
        },
        error: (err: any) => {
          this._appToastsService.updateDeadCenter(false);
          this._appToastsService.show(err.displayMsg, { header: 'ERROR', autohide: false, error: true });
          return;
        }
      });
  }

  populateUpdateCarcSqlTable(): void {  // Helper rtn to create - update carcs 1 time
    this._appToastsService.updateDeadCenter(false); // Show toast @ top of viewport
    this._ckRegister835DetailService.populateUpdateCarcSqlTable(this._ckRegister835DetailService.rawCarcs)
      .subscribe({
        next: data => {
          if (!data) {
            debugger;
          } else {
            data.forEach(itm => {
              this._appToastsService.updateDeadCenter(false);
              this._appToastsService.show(this.successToast, { autohide: true, delay: 1500 });
              debugger;
            });
          }
        },
        error: (err: any) => {
          this._appToastsService.updateDeadCenter(false);
          this._appToastsService.show(err.displayMsg, { header: 'ERROR', autohide: false, error: true });
          return;
        }
      }
      );
  }

  open(content, opts, modalNm) {
    this.modalStack.push(modalNm);

    this._modalService.open(content, opts).result.then((result) => {
      // console.log('result=' + result);
      switch (result) {
        case 'oK_matchCaseModal':
          this.cas.casPatID = this.matchedMcsPatID;
          this.cas.casID = this.matchedMcsCasID;
          this.cas.casPS = this.matchedMcsCasPS;
          this.cas.casPsDesc = this.cas.casPS == '1' ? 'pri' : (this.cas.casPS == '2' ? 'sec' : '???');
          this.cas.casBal = this.matchedMcsCasBal;
          this.cas.casProvID = this.matchedMcsCasProvID
          this.cas.insID = this.matchedMcsInsID;
          this.cas.ins = this.matchedMcsIns;
          this.cas.casReclTyp = this.matchedMcsReclTyp;
          this.modalStack.pop();
          this.unCreditedPaymentsCnt.emit('');
          return;
        default:
      }

    }, (reason) => {

      // console.log(this.modalStack);

      this.modalStack.pop();
      // console.log('reason=' + reason); // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  match835Payments2casesManual(cas: any): void {
    this.mcs = [];
    this.mcsDet = [];
    this.seldCasSeqNo2match = cas.casSeqNo;

    console.log('%c' + 'q ' + "Exec spMB_Sio_Match835Payments2casesManual"
      + " @sn ='" + this.sn
      + "', @payDt ='" + this.payDt
      + "', @lastNm ='" + cas.casPatLstNm
      + "', @firstNm ='" + cas.casPatFstNm
      + "', @casNo ='" + cas.casNo.substring(0, cas.casNo.length - 4)
      + "', @casOfNo ='" + cas.casNo.substring(cas.casNo.length - 4)
      + "', @contrNo ='" + cas.casContNo
      + "';", 'color: black; background: #90EE90; font-size: 12px');

    this.waiting4Response = true;

    this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_Match835Payments2casesManual"
      + " @sn ='" + this.sn
      + "', @payDt ='" + this.payDt
      + "', @lastNm ='" + cas.casPatLstNm
      + "', @firstNm ='" + cas.casPatFstNm
      + "', @casNo ='" + cas.casNo.substring(0, cas.casNo.length - 4)
      + "', @casOfNo ='" + cas.casNo.substring(cas.casNo.length - 4)
      + "', @contrNo ='" + cas.casContNo
      + "';");
  }

  onChange_MchLastNm(cas): void {
    this.mcParamFiltr = '1';
    this.matchedMcsArrCnt = this.matchedClaimsCnt();
  }

  onChange_MchFirstNm(): void {
    this.mcParamFiltr = '2';
    this.matchedMcsArrCnt = this.matchedClaimsCnt();
  }

  onChange_MchContr(): void {
    this.mcParamFiltr = '4';
    this.matchedMcsArrCnt = this.matchedClaimsCnt();
  }

  onChange_MchCasNo(): void {
    this.mcParamFiltr = '3';
    this.matchedMcsArrCnt = this.matchedClaimsCnt();
  }

  onClick_MchNpi(): void {
    if (this.mcNpiFiltr) {
      this.mcNpiFiltr = '';
    } else {
      this.mcNpiFiltr = this.npi;
    }
    this.matchedMcsArrCnt = this.matchedClaimsCnt();
  }

  onClick_MchPayerID(): void {
    if (this.mcPayerIdFiltr) {
      this.mcPayerIdFiltr = '';
    } else {
      this.mcPayerIdFiltr = this.payerId;
    }
    this.matchedMcsArrCnt = this.matchedClaimsCnt();
  }

  onChange_MchBalanceZero(): void {
    this.matchedMcsArrCnt = this.matchedClaimsCnt();
  }

  onChange_caseReclaim(event): void {
    // event.preventDefault(); // Don't toggle yet, wait for saved result
    // event.target.checked = !event.target.checked;
    const task: string = this.engLang ? 'reclaim' : 'reclamar';
    if (!this.arePaymentsPending(event, task)) {
      this.reclCkBxId = event.target.id;
      console.log('this.reclCkBxId', this.reclCkBxId);
      this.reclCkBxCked = false;  // Initial known value
      console.log('this.reclCkBxCked', this.reclCkBxCked);
      this.reclaim.emit(this.cas);  // Causes modal in ck-register-comp that shows reclaim-comp
    }
  }

  matchedClaimsCnt(): string {
    let arr = this.mcs.filter(mc => {
      return (
        mc.mcParam == this.mcParamFiltr
        && ((!this.mcBalZeroFiltr && mc.mcBalance && +mc.mcBalance !== 0) || (this.mcBalZeroFiltr && mc.mcBalance && +mc.mcBalance === 0))
        && ((!this.mcNpiFiltr || (this.mcNpiFiltr && this.mcNpiFiltr == mc.mcNpi))
          || (!this.mcPayerIdFiltr || (this.mcPayerIdFiltr && this.mcPayerIdFiltr == mc.mcPayerId))
        )
      )
    });
    if (arr?.length) {
      return arr.length.toString();
    } else {
      return '0';
    }
  }

  onChange_MatchCase(event, mc): void {
    this.mcs.forEach(obj => obj.mcSelect = ''); // Clear all
    if (event.target.checked) {
      mc.mcSelect = '1';
      this.matchedMcsPatID = mc.mcPatID;
      this.matchedMcsCasID = mc.mcCasID;
      this.matchedMcsCasPS = mc.mcCasPS;
      this.matchedMcsCasProvID = mc.mcProvID;
      this.matchedMcsCasBal = mc.mcBalance;
      this.matchedMcsInsID = mc.mcInsID;
      this.matchedMcsIns = mc.mcIns;
      this.matchedMcsReclTyp = mc.mcReclTyp
    } else {
      this.matchedMcsPatID = undefined;
      this.matchedMcsCasID = undefined;
      this.matchedMcsCasPS = undefined;
      this.matchedMcsCasProvID = undefined;
      this.matchedMcsCasBal = undefined;
      this.matchedMcsInsID = undefined;
      this.matchedMcsIns = undefined;
      this.matchedMcsReclTyp = undefined;
      mc.mcSelect = '';
    }
  }

  enableMatchBtn(): boolean {
    if (this.mcs.some(obj => obj.mcSelect == '1')) {
      return false;
    } else {
      return true;
    }
  }

  toFloat(strAmnt: string): number {
    return parseFloat(strAmnt);
  }

  isIdValid(id: string): boolean {  // Use for casID & patID 
    return (id.match(/^\d+$/g) ? true : false);
  }

  uniqueRarcs(rarc: string): string[] {
    return [...new Set(rarc.split(' '))];
  }

  sortClaims(srtClass: string): void {
    if (!this.prevSrtClass) { // Initially when this happens this.sortAsc = true
      this.sortAsc = false;
      (document.querySelectorAll('.' + srtClass)).forEach(d => d['hidden'] = false);
    } else if (srtClass == this.prevSrtClass) {
      this.sortAsc = !this.sortAsc;
      (document.querySelectorAll('.' + srtClass)).forEach(d => d['hidden'] = false);
    } else {  // Sort criteria changed
      (document.querySelectorAll('.' + this.prevSrtClass)).forEach(d => d['hidden'] = true);
      (document.querySelectorAll('.' + srtClass)).forEach(d => d['hidden'] = false);
      this.sortAsc = true;
    }
    this.prevSrtClass = srtClass;
    console.log('%c' + this.sortAsc, 'color: red; background: yellow; font-size: 24px');
    this.reSortedCasArr.emit({ 'class': srtClass, 'prevSrtClass': this.prevSrtClass, 'dir': this.sortAsc });
  }

  onChange_caseAdjust(event: Event): void {
    const task: string = this.engLang ? 'adjusting a balance to zero' : 'ajustar un balance a cero';
    if (!this.arePaymentsPending(event, task)) {
      this.adjustPromptModal = true;
      let chkAdj = event.target as HTMLInputElement;
      if (chkAdj.checked) {
        this.adjustRemove = false;  // adjust
        chkAdj.checked = false; // Revert until adjustment saved
      } else {
        this.adjustRemove = true;  // remove adjust
        chkAdj.checked = true;  // Revert until adjustment removed
      }
    }
  }

  arePaymentsPending(event: any, task: string): boolean {
    let svcPyd: boolean;
    for (let i = 0; i < this._835svc.length; i++) {
      svcPyd = this._835svc.some(sv => sv.svcCasSeqNo === this.cas.casSeqNo && sv.paymentSavedLocation.match(/^\d+\-\d+\-S(1|2)/g));
      if (svcPyd) {
        return false;
      }

      this._appToastsService.updateDeadCenter(false);
      this._appToastsService.show(
        this.engLang ? 'Before ' + task + ' you must credit all pending payments in the claim.'
          : 'Antes de ' + task + ' debe acreditar todo pago pendiente al caso.',
        { header: (this.engLang ? 'Credit payments first.' : 'Acredite pagos primero.'), autohide: true, warning: true });
      event.preventDefault();
      return true;
    }
  }

  adjustModalResult(event) {
    this.adjustPromptModal = false;
    console.log(event);
    if (event == 'ok') {
      if (this.adjustRemove) {
        this.caseAdjustRemove(this.cas.casID, this.cas.casAdjDetID, this.cas.casPS);
      } else {
        this.caseAdjust(this.cas.casID, this.cas.casProvID, this.cas.casPS);
      }
    }
  }

  caseAdjust(casID, casProvID, ps): void {
    let adjQry = "EXEC spMB_Sio_CaseAdjust "
      + "@sn = '" + this.sn
      + "', @casID = '" + casID
      + "', @payDt = '" + moment(this.payDt, 'MM/DD/YY').format('YYYYMMDD')
      + "', @provID = '" + casProvID
      + "', @ps = '" + ps
      + "', @payCkNo = '" + this._helpRtns.escApos(this.chkgNo)
      + "', @payDtFmt = '" + moment(this.payDt, 'MM/DD/YY').format('MM/DD/YYYY')
      + "', @payUserID = '" + this.userID
      + "', @payType = '" + '835'
      + "';"

    console.log('%c' + "CkRegisterComponent.caseAdjust query = " + adjQry, 'color: black; background: yellow; font-size: 12px');
    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, adjQry)
  }

  caseAdjustRemove(casID, adjDetID, ps): void {
    let adjQry = "EXEC spMB_Sio_CaseAdjustRemove "
      + "@sn = '" + this.sn
      + "', @casID = '" + casID
      + "', @detID = '" + adjDetID
      + "', @ps = '" + ps
      + "', @payType = '" + '835'
      + "';"

    console.log('%c' + "CkRegisterComponent.Remove query = " + adjQry, 'color: black; background: yellow; font-size: 12px');
    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, adjQry)
  }

  fmtCasNo(casNo) {
    if (casNo.match(/\d{12,}/g)) {
      return casNo.match(/^\d{8}/g)[0] + '-' + casNo.match(/\d{4}$/g)[0];
    }
    return casNo;
  }

  // The following works aasigning next td contents a variable font-size as per length but it's a performance hog
  // adjustFontSize(id): string {  // Function to adjust font size based on content width
  //   const content = document.getElementById(id);
  //   const contentWidth = content.offsetWidth;
  //   const parentWidth = content.parentElement.offsetWidth;
  //   const fontSize = 9 * contentWidth / parentWidth + 'px';
  //   // content.style.fontSize = fontSize;
  //   setTimeout(
  //     () => {
  //       return fontSize;
  //     }, 0
  //   );
  //   return;
  // };

  print835check() {
    let svc: I835svc[] = this._835svc.filter(obj => obj.svcCasSeqNo == this.cas.casSeqNo);
    let ads: any[] = [];
    svc.forEach(sv =>
      ads.push(
        this._835adj.filter(ad => ad.adjSvcSeqNo == sv.svcSeqNo)
      ));
    let adj: I835adj[] = [];
    ads.forEach(ele => { ele.forEach(el => adj.push(el)) });
    this.print835remittance.emit({
      filNm: this.filNm,
      filDt: this.filDt,
      filAmnt: this.filAmnt,
      filPayerNm: this.filPayerNm,
      filPayeeNm: this.filPayeeNm,
      chkgNo: this.chkgNo,
      chkgDt: this.payDt,
      ckPayeeNpi: this.ckPayeeNpi,
      ckPayerID: this.ckPayerID,
      cas: [this.cas],
      svc: svc,
      adj: adj,
      carcNotes: this.carcNotes,
      sn: this.sn
    });
  }
}
