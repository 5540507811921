// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
    width: 100%;
    height: 700px;
    margin: 4px;
    border: 1px solid #555;
    /* The background property is added to clearly distinguish the borders between drawer and main
       content */
    background: #eee;
}

.selected {
    background-color: grey;
}

.report-actions {
    text-align: right;
}

.label,
.dt-hint {
    font-size: 10px;
}

.title {
    margin-right: 10px;
}

#fromDt,
#toDt {
    font-size: 11px;
}

.toolbar {
    background-color: inherit;
    /* Set the toolbar's background color to inherit from its parent */
}

/* Reduce the height of the toolbar */
mat-toolbar {
    height: 15px;
    min-height: 15px;
}

/* Adjust style inside the toolbar */
mat-toolbar .mat-toolbar-row,
mat-toolbar .mat-toolbar-single-row {
    background-color: white;
}

.maticon {
    margin: 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/reports.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB;gBACY;IACZ,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,kEAAkE;AACtE;;AAEA,qCAAqC;AACrC;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA,oCAAoC;AACpC;;IAEI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".main {\r\n    width: 100%;\r\n    height: 700px;\r\n    margin: 4px;\r\n    border: 1px solid #555;\r\n    /* The background property is added to clearly distinguish the borders between drawer and main\r\n       content */\r\n    background: #eee;\r\n}\r\n\r\n.selected {\r\n    background-color: grey;\r\n}\r\n\r\n.report-actions {\r\n    text-align: right;\r\n}\r\n\r\n.label,\r\n.dt-hint {\r\n    font-size: 10px;\r\n}\r\n\r\n.title {\r\n    margin-right: 10px;\r\n}\r\n\r\n#fromDt,\r\n#toDt {\r\n    font-size: 11px;\r\n}\r\n\r\n.toolbar {\r\n    background-color: inherit;\r\n    /* Set the toolbar's background color to inherit from its parent */\r\n}\r\n\r\n/* Reduce the height of the toolbar */\r\nmat-toolbar {\r\n    height: 15px;\r\n    min-height: 15px;\r\n}\r\n\r\n/* Adjust style inside the toolbar */\r\nmat-toolbar .mat-toolbar-row,\r\nmat-toolbar .mat-toolbar-single-row {\r\n    background-color: white;\r\n}\r\n\r\n.maticon {\r\n    margin: 0 8px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
