// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rowContainer {
    font-family: arial, sans-serif;
    font-size: small;
    margin: 0 0 1em 1em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-start;
    gap: 20px;
}

.colContainer {
    margin: 0 0 0 0;
    display: flex;
    flex-flow: column wrap;
    align-items:flex-start;
}

.rowFlex {
    padding: 5px;
    text-align: center;
    position: relative;
    flex-basis: auto;
}

.zeroPadMar {
    padding: 0;
    margin: 0;
}

.border {
    border-style: solid;
}

input[type=text] {
    width: 5em;
}`, "",{"version":3,"sources":["webpack://./src/app/ck-register/recl-xml-medicareBRetermination/recl-xml-medicare-bretermination/recl-xml-medicare-bretermination.component.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,4BAA4B;IAC5B,SAAS;AACb;;AAEA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".rowContainer {\r\n    font-family: arial, sans-serif;\r\n    font-size: small;\r\n    margin: 0 0 1em 1em;\r\n    display: flex;\r\n    flex-flow: row wrap;\r\n    justify-content: space-start;\r\n    gap: 20px;\r\n}\r\n\r\n.colContainer {\r\n    margin: 0 0 0 0;\r\n    display: flex;\r\n    flex-flow: column wrap;\r\n    align-items:flex-start;\r\n}\r\n\r\n.rowFlex {\r\n    padding: 5px;\r\n    text-align: center;\r\n    position: relative;\r\n    flex-basis: auto;\r\n}\r\n\r\n.zeroPadMar {\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.border {\r\n    border-style: solid;\r\n}\r\n\r\ninput[type=text] {\r\n    width: 5em;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
