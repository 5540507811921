// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-input-placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 75%;
    text-align: center;
  }

  :-moz-placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 75%;
    text-align: center;
  }

  :-ms-input-placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 75%;
    text-align: center;
  }

  .demogrBackgColr {
    background-color: #CBF0FE;
  }

  .insurBackgColr {
    background-color: #DFF0D8;
  }

  .contcBackgColr {
    background-color: #E9DBEE;
  }

  .conditBackgColr {
    background-color: #FCCECD;
  }

  .operatBackgColr {
    background-color: #E1E1E8;
  }

  .allergyBackgColr {
    background-color: #DDE7EC;
  }

  .rxBackgColr {
    background-color: #E9ECDD;
  }

  .borders {
    border: 1px solid rgba(201, 76, 76, 0.3);
  }

  .silverBordr {
    border: 1px solid silver;
  }

  .rad10 {
    border-radius: 10px;
  }

  .cmplBackgColr {
    background-color: #DCDAEF;
  }

  .thumb {
    height: 75px;
    border: 1px solid #000;
    margin: 10px 5px 0 0;
  }
`, "",{"version":3,"sources":["webpack://./src/app/externalTemplates/signature-capture/signature-capture.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,wCAAwC;EAC1C;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,sBAAsB;IACtB,oBAAoB;EACtB","sourcesContent":["::-webkit-input-placeholder {\r\n    font-style: italic;\r\n    font-weight: normal;\r\n    font-size: 75%;\r\n    text-align: center;\r\n  }\r\n\r\n  :-moz-placeholder {\r\n    font-style: italic;\r\n    font-weight: normal;\r\n    font-size: 75%;\r\n    text-align: center;\r\n  }\r\n\r\n  :-ms-input-placeholder {\r\n    font-style: italic;\r\n    font-weight: normal;\r\n    font-size: 75%;\r\n    text-align: center;\r\n  }\r\n\r\n  .demogrBackgColr {\r\n    background-color: #CBF0FE;\r\n  }\r\n\r\n  .insurBackgColr {\r\n    background-color: #DFF0D8;\r\n  }\r\n\r\n  .contcBackgColr {\r\n    background-color: #E9DBEE;\r\n  }\r\n\r\n  .conditBackgColr {\r\n    background-color: #FCCECD;\r\n  }\r\n\r\n  .operatBackgColr {\r\n    background-color: #E1E1E8;\r\n  }\r\n\r\n  .allergyBackgColr {\r\n    background-color: #DDE7EC;\r\n  }\r\n\r\n  .rxBackgColr {\r\n    background-color: #E9ECDD;\r\n  }\r\n\r\n  .borders {\r\n    border: 1px solid rgba(201, 76, 76, 0.3);\r\n  }\r\n\r\n  .silverBordr {\r\n    border: 1px solid silver;\r\n  }\r\n\r\n  .rad10 {\r\n    border-radius: 10px;\r\n  }\r\n\r\n  .cmplBackgColr {\r\n    background-color: #DCDAEF;\r\n  }\r\n\r\n  .thumb {\r\n    height: 75px;\r\n    border: 1px solid #000;\r\n    margin: 10px 5px 0 0;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
