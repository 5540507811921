import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { AppToastsService } from '../shared/app-toasts/app-toasts.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CkRegister835DetailService, HttpErr } from './ck-register835-detail/ck-register835-detail.service';

@Injectable({
  providedIn: 'root'
})
export class CkRegisterService {
  postHeaders: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  waiting4ResponseSubj = new BehaviorSubject(false);

  constructor(private _ckRegister835DetailService: CkRegister835DetailService,
    private _appToastsService: AppToastsService,

    private _http: HttpClient) { }

  backFromHttpError(err: HttpErr): void {
    debugger;
    console.table(err);
    this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
    this._appToastsService.show(err.message, { header: err.displayMsg, autohide: false, error: true });
    this.waiting4ResponseSubj.next(false);
  }

  carcSortByCodeAsc(carc1, carc2): number {
    if (carc1.code > carc2.code) {
      return 1;
    } else if (carc1.code == carc2.code) {
      return 0;
    } else if (carc1.code < carc2.code) {
      return -1;
    }
  }

  postRenderJsRpt(bdy: any): Observable<any> {
    // let body = JSON.stringify({ "template": { "name": "/demographics/demographics-html" }, "data": { "lastNm": "Deniro", "firstNm": "Robert" }, "options": { "reports": { "save": true } } });
    let body = JSON.stringify(bdy);
    let unm = 'medbiler@gmail.com';
    let pw = 'XaxispjEQZ9r@w7';
    let b64 = 'Basic ' + btoa(unm + ':' + pw);
    let headers = { 'Content-Type': 'application/json', 'Accept': 'text/plain', 'Authorization': b64 };

    return this._http.post<any>('https://medbiller.jsreportonline.net/api/report', body, { headers, 'responseType': 'blob' as 'json' })
      .pipe(map(this.extractData));
  }

  private extractData(res: Response) {
    let returnedData = res;
    return returnedData || {};
  }

}
