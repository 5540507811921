
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.css'],
    // template: `
    //    <div>
    //      <div class="modal-header">
    //          <h4 class="modal-title">{{msgBoxTitle}}</h4>
    //      </div>
    //      <div class="modal-body">
    //          <p>{{msgBoxMessage}}</p>
    //      </div>
    //          <div class="modal-footer">
    //          <button type="button"
    //              class="btn btn-outline-dark"
    //              (click)="activeModal.close(true)">OK
    //           </button>
    //      </div>
    //    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageDialogComponent {

    returndObj: any = {
        response: false,
        selectedCodes: []
    };

    subTotalFound: string = '';
    currentPage: number = 1;    // For paging component

    constructor(public _activeModal: NgbActiveModal) { }

    closeResult: string;

    // properties flagged as missing in 7/31/19 build
    msgBoxImgSrc: string;
    msgBoxTitle: string;
    bodyBackgColr: string;
    msgBoxMessage: string;
    codeSearchResults: string[];
    resultsView: string[];
    imgSrc: string;
    deleteBtn: boolean;
    printBtn: boolean;

    onClick_SelectCode(event: any, result: any): void {
        if (result && event?.target) {
            let pKey: string = result.pKey;
            let code: string = result.code;
            let desc: string = result.desc;
            if (event.target.checked) {
                if (this.returndObj.selectedCodes.length == 1 && !this.returndObj.selectedCodes[0]) {
                    this.returndObj.selectedCodes.pop();    // Clears the initial undefined value
                }

                this.returndObj.response = true;
                this.returndObj.selectedCodes.push({ 'pKey': pKey, 'code': code, 'desc': desc });

            } else {
                this.returndObj.selectedCodes.splice(
                    this.returndObj.selectedCodes.findIndex(element => element.pKey === pKey)
                    , 1);
            }
        }

    }

    inStrSearch(event: any, codeSearchResults: any): void {
        if (event?.key == 'Enter') {
            let skey: string = event.target.value.toLowerCase();
            this.resultsView = [];

            codeSearchResults.forEach(element => {
                if (element.desc.toLowerCase().includes(skey)) {
                    this.resultsView.push(element);
                }
            });

            this.subTotalFound = ' ' + this.resultsView.length;
        }
    }

}
