// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .font90 {
      font-size: 90%;
    }
    .font75 {
      font-size: 75%;
    }
    .font50 { 
      font-size: 50%;
    }
    .fontsmall {
        font-size: small;
    }
    .fontxsmall {
        font-size: x-small;
    }
    .fontxxsmall {
        font-size: xx-small;
    }
    .fontsmaller {
      font-size: smaller;
    }
`, "",{"version":3,"sources":["webpack://./src/app/shared/dd-ngbdropdown.component.ts"],"names":[],"mappings":";IACI;MACE,cAAc;IAChB;IACA;MACE,cAAc;IAChB;IACA;MACE,cAAc;IAChB;IACA;QACI,gBAAgB;IACpB;IACA;QACI,kBAAkB;IACtB;IACA;QACI,mBAAmB;IACvB;IACA;MACE,kBAAkB;IACpB","sourcesContent":["\n    .font90 {\n      font-size: 90%;\n    }\n    .font75 {\n      font-size: 75%;\n    }\n    .font50 { \n      font-size: 50%;\n    }\n    .fontsmall {\n        font-size: small;\n    }\n    .fontxsmall {\n        font-size: x-small;\n    }\n    .fontxxsmall {\n        font-size: xx-small;\n    }\n    .fontsmaller {\n      font-size: smaller;\n    }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
