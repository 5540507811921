import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPrintModule } from 'ngx-print';
import { CookieService } from 'ngx-cookie-service';

import { LoginComponent } from './login.component';
import { RegisterNewclientComponent } from './register-newclient.component';
import { SharedModule } from '../shared/shared.module';
import { AgendaComponent } from '../agenda/agenda.component';
import { RecordComponent } from '../record/record.component';
import { CkRegisterComponent } from '../ck-register/ck-register.component';
import { PaymentFormComponent } from '../shared/payment-form.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { ReportsComponent } from '../reports/reports.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxPrintModule,
        NgxPopperjsModule,
        RouterModule.forRoot([
            { path: 'agenda/:stylesCfgJson', component: AgendaComponent },
            { path: 'record/:stylesCfgJson', component: RecordComponent },
            { path: 'ck-register/:stylesCfgJson', component: CkRegisterComponent },
            { path: 'reports/:stylesCfgJson', component: ReportsComponent },
            { path: 'shared/payment-form', component: PaymentFormComponent },
            { path: '', redirectTo: 'login', pathMatch: 'full' },
            { path: '**', redirectTo: 'login', pathMatch: 'full' }
        ])
    ],
    declarations: [
        LoginComponent,
        RegisterNewclientComponent
    ],
    providers: [CookieService],
    exports: [
        LoginComponent,
        RegisterNewclientComponent
    ],
})
export class LoginModule { }