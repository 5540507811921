import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'psPipe',
    pure: false    // true is stateless & faster but will not detect changes; false is dynamic and detects changes in input array
})
export class PsPipe implements PipeTransform {
    transform(input: any[], ps: string): any[] {
        if (!input) {
            return [];
        }
        return input.filter(item => {
            return item.ps == ps;
        });
    }
}