// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexCol {
    display: flex;
    /* or inline-flex */
    flex-direction: column;
    justify-content: flex-start;
}

.small {
    font-size: small;
}

.xSmallFont {
    font-size: x-small;
}

.mediumFont {
    font-size: medium;
}

.vmid {
    vertical-align: middle;
}

ul {
    list-style-type: none;
    /* Remove bullets */
    padding: 0;
    margin: 0;
    font-size: x-small;
}

.redBal {
    color: red;
}

.greenBal {
    color: green;
}

.blackBal {
    color: black;
}

.strike {
    text-decoration: line-through;
}

.pointer {
    cursor: pointer;
}

.srt835Nm,
.srt835Cas,
.srt835Contr {
    color: #b544b5;
}

.currency {
    width: 100%;
    /* Inherits width from parent */
    text-align: right;
    display: inline-block;
}

.rgtAlign {
    text-align: right;
}

.hide {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/ck-register/ck-register835-detail/ck-register835-detail.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,UAAU;IACV,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;;;IAGI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,+BAA+B;IAC/B,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".flexCol {\r\n    display: flex;\r\n    /* or inline-flex */\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n}\r\n\r\n.small {\r\n    font-size: small;\r\n}\r\n\r\n.xSmallFont {\r\n    font-size: x-small;\r\n}\r\n\r\n.mediumFont {\r\n    font-size: medium;\r\n}\r\n\r\n.vmid {\r\n    vertical-align: middle;\r\n}\r\n\r\nul {\r\n    list-style-type: none;\r\n    /* Remove bullets */\r\n    padding: 0;\r\n    margin: 0;\r\n    font-size: x-small;\r\n}\r\n\r\n.redBal {\r\n    color: red;\r\n}\r\n\r\n.greenBal {\r\n    color: green;\r\n}\r\n\r\n.blackBal {\r\n    color: black;\r\n}\r\n\r\n.strike {\r\n    text-decoration: line-through;\r\n}\r\n\r\n.pointer {\r\n    cursor: pointer;\r\n}\r\n\r\n.srt835Nm,\r\n.srt835Cas,\r\n.srt835Contr {\r\n    color: #b544b5;\r\n}\r\n\r\n.currency {\r\n    width: 100%;\r\n    /* Inherits width from parent */\r\n    text-align: right;\r\n    display: inline-block;\r\n}\r\n\r\n.rgtAlign {\r\n    text-align: right;\r\n}\r\n\r\n.hide {\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
