import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErr } from '../ck-register/ck-register835-detail/ck-register835-detail.service';

@Injectable({
  providedIn: 'root'
})
export class JsReportOnlineService {

  constructor(private _http: HttpClient) { }

  postRenderJsRpt(bdy: any): Observable<any | HttpErr> {
    //let body = JSON.stringify({ "template": { "name": "/demographics/demographics-html" }, "data": { "lastNm": "Deniro", "firstNm": "Robert" }, "options": { "reports": { "save": true } } });
    let dispMsg = 'Internet Connection Problem';
    let body = JSON.stringify(bdy);
    let unm = 'medbiler@gmail.com';
    let pw = 'XaxispjEQZ9r@w7';
    let b64 = 'Basic ' + btoa(unm + ':' + pw);
    let headers = { 'Content-Type': 'application/json', 'Accept': 'text/plain', 'Authorization': b64 };

    return this._http.post<any>('https://medbiller.jsreportonline.net/api/report', body, { headers, 'responseType': 'blob' as 'json' })
      .pipe(
        map(data => data),
        catchError(err => this.HttpErr(err, dispMsg))
      );
  }

  private HttpErr(error: HttpErrorResponse, dispMsg: string): Observable<HttpErr> {
    let dataErr = new HttpErr();
    dataErr.errorNumber = error.status;
    dataErr.message = error.message;
    dataErr.displayMsg = dispMsg;
    return throwError(() => dataErr);
  }

}
