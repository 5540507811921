// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dwtMenu, #dwtcontrolContainer, #dwtClose {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 7px;
  }

  i {
      cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./src/app/shared/dwt.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;MACI,eAAe;EACnB","sourcesContent":["#dwtMenu, #dwtcontrolContainer, #dwtClose {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n    padding-top: 10px;\r\n    padding-bottom: 7px;\r\n  }\r\n\r\n  i {\r\n      cursor: pointer;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
