import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    AppComponent,
    LoginModule,
    SharedModule,
    DbLookUpService,
    HelperRtnsComponent,
    WebsocketService,
    PaymentService,
    CkRegisterComponent,
    CkRegisterDetailComponent,
    ReclXmlMedicareBReterminationComponent,
    CkRegisterPipe,
    CkRegister835DetailComponent,
    AppToastsContainer,
    AppToastsService,
    CacheInterceptor,
    CasSeqNoPipe,
    NgxPaginationModule,
    CasFoundPipe,
    CkRegisterDetailModalHeaderComponent,
    DetPcodeFilterPipe,
    BrowserAnimationsModule,
    McFilterPipe,
    CommonModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSortModule,
    MatCheckboxModule,
    ReclMedicarePartBRedeterminationFcsoComponent,
    RptAgingComponent,
    ReportsComponent,
    RptAgingClaimsComponent,
    RptAgingDetailsComponent,
    HttpClientModule,
    NgbModule,
    OverlayModule,
    NgxPopperjsModule,
    NgbDropdown,
    NgbDropdownConfig,
    NgbActiveModal,
    HTTP_INTERCEPTORS,
    ReclaimComponent
} from './index';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        LoginModule,
        SharedModule,
        RouterModule,
        NgbModule,
        OverlayModule,
        NgxPaginationModule,
        NgxPopperjsModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSidenavModule,
        MatListModule,
        MatCardModule,
        MatButtonModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        MatTableModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatChipsModule,
        MatFormFieldModule,
        MatSortModule,
        MatCheckboxModule,
        ReclaimComponent
    ],
    providers: [
        PaymentService,
        DbLookUpService,
        HelperRtnsComponent,
        WebsocketService,
        NgbDropdown,
        NgbDropdownConfig,
        NgbActiveModal,
        AppToastsService,
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
    ],
    declarations: [
        AppComponent,
        CkRegisterComponent,
        CkRegisterDetailComponent,
        ReclXmlMedicareBReterminationComponent,
        CkRegisterPipe,
        CkRegister835DetailComponent,
        CasSeqNoPipe,
        AppToastsContainer,
        CkRegisterDetailModalHeaderComponent,
        CasFoundPipe,
        DetPcodeFilterPipe,
        McFilterPipe,
        ReclMedicarePartBRedeterminationFcsoComponent,
        ReportsComponent,
        RptAgingComponent,
        RptAgingClaimsComponent,
        RptAgingDetailsComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
