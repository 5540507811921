import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'json2',
  standalone: true
})
export class Json2Pipe implements PipeTransform {

  transform(value: any): string {
    return JSON.stringify(value, null, 2); // Pretty print with 2-space indentation
  }

}
