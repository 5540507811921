import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppToastsService } from 'src/app/shared/app-toasts/app-toasts.service';
import { IReclaims, IReclaimsDet } from 'src/app/shared/interfaces/reclaims';
import { Json2Pipe } from 'src/app/shared/json2.pipe';

@Component({
  selector: 'recl-xml-sss022309',
  templateUrl: './recl-xml-sss022309.component.html',
  styleUrls: ['./recl-xml-sss022309.component.css'],
  standalone: true,
  imports: [FormsModule, Json2Pipe],
})
export class ReclXmlSSS022309Component implements OnInit {

  @Input() reclLineReasonEntered: any;
  @Input() reclaims: IReclaims[];
  @Input() reclaimsDet: IReclaimsDet[];
  @Input() idxSeld: number;
  @Input() reclXml: string;
  @Input() print: boolean = false;

  @Output() need2Save = new EventEmitter<string>();

  e: any = {
    opt0_plan: '',

    txt2_1: '',
    txt2_2: '',

    txt3_101: '',
    med3_102: '',
    med3_103: '',
    txt3_104: '',
    txt3_105: '',
    txt3_106: '',
    txt3_107: '',
    txt3_108: '',
    txt3_109: '',
    txt3_110: '',
    txt3_111: '',
    med3_112: '',

    txt3_201: '',
    med3_202: '',
    med3_203: '',
    txt3_204: '',
    txt3_205: '',
    txt3_206: '',
    txt3_207: '',
    txt3_208: '',
    txt3_209: '',
    txt3_210: '',
    txt3_211: '',
    med3_212: '',

    txt3_301: '',
    med3_302: '',
    med3_303: '',
    txt3_304: '',
    txt3_305: '',
    txt3_306: '',
    txt3_307: '',
    txt3_308: '',
    txt3_309: '',
    txt3_310: '',
    txt3_311: '',
    med3_312: '',

    chk4_1: '',
    txt4_2: '',
    txt4_3: '',
    med4_4: '',

    txt5_1: '',  // Must be initialized as such because string will be appended to
  }

  constructor(private _appToastsService: AppToastsService
  ) { }

  ngOnInit(): void {
    this.decodeXmlRecl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.idxSeld) {
      this.e = {};  // Clear form data
      this.decodeXmlRecl();
    }
    if (changes.reclLineReasonEntered && this.reclLineReasonEntered) {

    }
    if (changes.print?.currentValue) {
      // this.printReclaim();
      setTimeout(() => {
        this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
        this._appToastsService.show('A partir del 1 de julio de 2024, toda petición de ajuste debe ser enviada electrónicamente vía la transacción estándar de HIPAA 837.', { header: 'Véase CARTA CIRCULAR #M2405061 de SSS', autohide: false, warning: true });
      }, 0);
    }
  }

  ngOnDestroy() {
    this.e = undefined;
  }

  clearRadios(): void { // Clears all radio bittons.
    let opts = document.querySelectorAll('input');
    opts.forEach(rad => {
      rad.type == 'radio';
      rad.checked = false;
    });
    this.onChange_need2Save();
  }

  onChange_need2Save(): void {
    this.need2Save.emit('');
  }

  decodeXmlRecl(): void {
    const r: number = this.idxSeld;

    if (r >= 0 && this.reclaims && (this.reclaims[r].reclXml || this.reclaimsDet[r].reclDetXml)) {
      let parser = new DOMParser();
      let xmlDoc;
      if (this.reclaims[r].reclXml) {
        xmlDoc = parser.parseFromString(this.reclaims[r].reclXml, "text/xml");
      } else {
        return;
      }

      for (let i = 0; i < xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes.length; i++) {
        let fullNod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i];
        let nod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i].nodeName;

        if (nod.match(/txt\d*[a-z]?_\d+/g) || nod.match(/med\d*[a-z]?_\d+/g) || nod.match(/chk\d*_\d+/g) || nod.match(/opt\d*_\d+/g)) {
          let val = fullNod.textContent;

          switch (nod) {
            case 'opt0_1':
              val === 'True' ? this.e.opt0_plan = '1' : null;
              break;
            case 'opt0_2':
              val === 'True' ? this.e.opt0_plan = '2' : null;
              break;
            case 'opt0_3':
              val === 'True' ? this.e.opt0_plan = '5' : null;
              break;
            case 'opt0_4':
              val === 'True' ? this.e.opt0_plan = '12' : null;
              break;
            case 'chk4_1':
              this.e.chk4_1 = +val;
            default:
              if (nod.match(/(txt|med)\d*[a-z]?_\d+/g)) {
                this.e[nod] = val.replace(/^%/, ''); // Remove initial % used in previous version
              }
              break;
          }
        }
      }
    }
  }

}
