import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'detPcodeFilter'
})
export class DetPcodeFilterPipe implements PipeTransform {

  transform(input: any[], casID: string, casPS: string): any {
    if (!input) {
      return input;
    }
    return input.filter(obj => {
      if (obj?.mcDetCasID == casID && obj?.mcDetPS == casPS) {
        return input;
      }
      return null;
    });
  }

}
