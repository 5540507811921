/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 * It handles & manipulates the INPUT.
 */

import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
@Injectable()
export class CustomDateParserFormatterYYYY extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {  // Called when using the keyboard
    if (value) {
      if (value.match(/^\d{8}$/g)) {
        value = value.substring(0, 2) + this.DELIMITER + value.substring(2, 4) + this.DELIMITER + value.substring(4, 8);
      }
      const date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {  // Date displayed in datepicker as MM/DD/yyyy
    return date ? date.month.toString().padStart(2, '0')
      + this.DELIMITER + date.day.toString().padStart(2, '0')
      + this.DELIMITER + (date.year < 10 ? date.year.toString().padStart(2, '0') : date.year.toString())
      : null;
  }

}
