import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

import { ICreditCard } from './interfaces/creditCard';
import { IAch } from './interfaces/ach';
import { IPayment } from './interfaces/payment';
import { IServiceItemPrice } from './interfaces/service-item-price';
import { ICustomer } from './interfaces/customer';
import { IServiceItemOrder } from './interfaces/service-item-order';
import { PaymentService } from '../shared/payment.service';
import { DbLookUpService } from '../shared/db-look-up.service';
import { RegisterNewclientComponent } from '../login/register-newclient.component';
import { HelperRtnsComponent } from './helper-rtns.component';

@Component({
    selector: 'payment-form',
    templateUrl: 'payment-form.component.html',
    styleUrls: ['payment-form.component.css'],
    providers: [RegisterNewclientComponent,
        PaymentService]
})
export class PaymentFormComponent implements OnChanges {
    constructor(private _paymentService: PaymentService,
        private _helperRtnsComponent: HelperRtnsComponent,
        private _dbLookUpService: DbLookUpService) { }

    @Input() engLang: boolean;
    @Input() userValidLogin: boolean;
    @Input() customer: ICustomer;
    @Input() serviceItemPrices: [IServiceItemPrice];
    @Input() total: number;
    @Input() totalItems: number;
    @Input() type: string;
    totalTax: number = 0;

    @ViewChild('btnPaymentSubmit', { static: false }) btnPaymentSubmit: ElementRef;

    showPopr = false;
    errMsg: string = undefined;
    serviceItemPricesExcepts: any = [{
        svcItmPkey: undefined,
        charge: undefined,
        tax: undefined,
        descrEng: undefined,
        descrSpan: undefined
    }];
    cc: ICreditCard = {
        name: undefined,
        number: '',
        exp: '',
        cvv: '',
        billAddr1: undefined,
        billAddr2: undefined,
        billCity: undefined,
        billZip: '',
        billState: 'PR',
        billTel: '',
        billEmail: undefined
    };
    ach: IAch = {
        bankNm: undefined,
        bankBr: undefined,
        bankRtNo: undefined,
        acctNo: undefined,
        acctNm: undefined,
        acctAddr1: undefined,
        acctAddr2: undefined,
        acctCity: undefined,
        acctZip: '',
        acctTel: '',
        acctEmail: undefined
    };
    payment: IPayment = {
        pKey: undefined,
        payDate: undefined,
        cc: undefined,
        ach: undefined
    };
    serviceItemOrders: [IServiceItemOrder] = [undefined];

    snErr: boolean = false;
    ccNameErr: boolean = false;
    ccNumberErr: boolean = false;
    ccExpErr: boolean = false;
    ccCvvErr: boolean = false;
    ccBillAddr1Err: boolean = false;
    ccBillAddr2Err: boolean = false;
    ccBillZipErr: boolean = false;
    ccBillCityErr: boolean = false;
    ccBillEmailErr: boolean = false;
    ccBillTelErr: boolean = false;
    achBankNmErr: boolean = false;
    achBankBrErr: boolean = false;
    achAcctNmErr: boolean = false;
    achAcctAddr1Err: boolean = false;
    achAcctAddr2Err: boolean = false;
    achAcctZipErr: boolean = false;
    achAcctCityErr: boolean = false;
    achAcctEmailErr: boolean = false;
    achAcctTelErr: boolean = false;
    achAcctNoErr: boolean = false;
    achBankRtNoErr: boolean = false;
    noSelectColorErr: string = undefined;

    submitBtnDisabled: boolean = true;
    ccDisabled: boolean = false;
    achDisabled: boolean = false;
    emailSent: boolean = false;
    emailErrMsg: string = undefined;
    emailSentDlgClass: string = 'alert alert-success';
    prTax: number = .115;
    waiting4Response: boolean = false;

    public payPalConfig?: IPayPalConfig;
    payPal: boolean = false;    // to submit via PayPal

    ngOnChanges(changes: SimpleChanges): void {
        if (this.userValidLogin) {
            this.cc.name = this.customer.firstNm && this.customer.lastNm ? this.customer.firstNm + ' ' + this.customer.lastNm : '';
            this.cc.billTel = this.customer.cellPhone ? this.customer.cellPhone : (this.customer.otherPhone ? this.customer.otherPhone : '');
            this.ach.acctNm = this.customer.companyNm ? this.customer.companyNm : '';
            this.ach.acctTel = this.customer.cellPhone ? this.customer.cellPhone : (this.customer.otherPhone ? this.customer.otherPhone : '');
            this.cc.billEmail = this.customer.email ? this.customer.email : '';
            this.ach.acctEmail = this.customer.email ? this.customer.email : '';
        } else if (!this.customer.sn) {
            this.ach.acctNm = '';
            this.cc.name = '';
            this.cc.billTel = '';
            this.ach.acctTel = '';
            this.cc.billEmail = '';
            this.ach.acctEmail = '';
            this.cc.billAddr1 = '';
            this.cc.billAddr2 = '';
            this.cc.billCity = '';
            this.cc.billState = 'PR';
            this.cc.billZip = '';
            this.ach.acctAddr1 = '';
            this.ach.acctAddr2 = '';
            this.ach.acctCity = '';
            this.ach.acctZip = '';
        }
    }

    private initConfig(total: string, items: any[]): void {
        this.payPalConfig = {
            currency: 'USD',
            clientId: 'AexPCniIavP1OP7D5wUvhdzBBhqJCDE5riOfdvmXmndUDWGstivBqjh4Cy9zZ0H3ENREHybm3nWgUzYz',
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                intent: 'CAPTURE',
                purchase_units: [{
                    amount: {
                        currency_code: 'USD',
                        value: total,
                        breakdown: {
                            item_total: {
                                currency_code: 'USD',
                                value: total
                            }
                        }
                    },
                    items: items
                }]
            },
            advanced: {
                commit: 'true'
            },
            style: {
                label: 'paypal',
                layout: 'vertical'
            },
            onApprove: (data, actions) => {
                console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then(details => {
                    console.log('onApprove - you can get full order details inside onApprove: ', details);
                });
            },
            onClientAuthorization: (data) => {
                console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
                // this.showSuccess = true;
                this.payPal = false;
                this.completePayment(data);
            },
            onCancel: (data, actions) => {
                console.log('OnCancel', data, actions);
                // this.showCancel = true;

            },
            onError: err => {
                console.log('OnError', err);
                // this.showError = true;
            },
            onClick: (data, actions) => {
                console.log('onClick', data, actions);
                // this.resetStatus();
            }
        };
    }

    onCcAchClick(eventTarget: any): void {
        if (!this.ccDisabled && !this.achDisabled) {
            if ((eventTarget.id == 'credCard' && this.type != 'cc') || (eventTarget.id == 'achTxfer' && this.type != 'ach')) {
                if (this.type == 'cc') {
                    this.type = 'ach';
                } else {
                    this.type = 'cc';
                }
            }
        }
    }

    submitPayment(): void {
        // this.test();
        // return;

        var navigate = true;    // continue button navigation when true
        this.errMsg = undefined;    // clrs any previous submission errors

        if (this.snErr || !this.customer.sn || !this.customer.sn.match(/^[0-9A-Fa-f]*$/g)) {
            navigate = false;
        }

        if (this.type == 'cc') {    // this block disabled because credit card will be entered in PayPal
            if (!this.cc.name || !this.cc.name.trim().match(/^[-a-z ]{3,50}$/gi)) {
                this.ccNameErr = true;    // displays error message
                navigate = false;
            } else {
                this.ccNameErr = false;   // clears error message
            }

            // if (!this.cc.number || !this.cc.number.match(/^\d{4}-\d{4}-\d{4}-\d{3,4}[ ]?$/g)) {
            //     this.ccNumberErr = true;    // displays error message
            //     navigate = false;
            // } else {
            //     this.ccNumberErr = false;   // clears error message
            // }

            // if (!this.cc.exp || !this.cc.exp.match(/^\d{2}\/\d{2}$/g)) {
            //     this.ccExpErr = true;    // displays error message
            //     navigate = false;
            // } else {
            //     if ((+this.cc.exp.substring(0,2) < 1 || +this.cc.exp.substring(0,2) > 12) || 
            //         (new Date(2000 + +this.cc.exp.substring(3,5), +this.cc.exp.substring(0,2) - 1, new Date().getDate(), 59, 59, 999) < new Date())) {
            //         this.ccExpErr = true;    // displays error message
            //         navigate = false;
            //     } else {
            //         this.ccExpErr = false;   // clears error message
            //     }
            // }

            // if (!this.cc.cvv || !this.cc.cvv.match(/^\d{3,4}[ ]?$/g)) {
            //     this.ccCvvErr = true;    // displays error message
            //     navigate = false;
            // } else {
            //     this.ccCvvErr = false;   // clears error message
            // }

            if (!this.cc.billAddr1 || !this.cc.billAddr1.match(/^[ \w\.#,\-]{10,50}$/gi)) {
                this.ccBillAddr1Err = true;    // displays error message
                navigate = false;
            } else {
                this.ccBillAddr1Err = false;   // clears error message
            }

            if (this.cc.billAddr2 && !this.cc.billAddr2.match(/^[ \w\.#,\-]{0,50}$/gi)) {
                this.ccBillAddr2Err = true;    // displays error message
                navigate = false;
            } else {
                this.ccBillAddr2Err = false;   // clears error message
            }

            if (!this.cc.billZip || !this.cc.billZip.trim().match(/^\d{5}$|^\d{5}\-[ ]*$|^\d{5}\-\d{4}$/g)) {
                this.ccBillZipErr = true;    // displays error message
                navigate = false;
            } else {
                this.ccBillZipErr = false;   // clears error message
            }

            if (!this.cc.billCity || !this.cc.billCity.trim().match(/^[ a-z]{3,50}$/gi)) {
                this.ccBillCityErr = true;    // displays error message
                navigate = false;
            } else {
                this.ccBillCityErr = false;   // clears error message
            }

            if (!this.cc.billEmail || !this.cc.billEmail.match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$|^\s*$/g)) {
                this.ccBillEmailErr = true;    // displays error message
                navigate = false;
            } else {
                this.ccBillEmailErr = false;   // clears error message
            }

            if (!this.cc.billTel || this.cc.billTel.replace(/\D/g, '').length != 10) {
                this.ccBillTelErr = true;   // bad phone
                navigate = false;
            } else {
                this.ccBillTelErr = false;
            }
        }

        if (this.type == 'ach') {
            if (!this.ach.bankNm || !this.ach.bankNm.match(/^[-\w &@()#+'%$.,ñÑü]*$/gi)) {
                this.achBankNmErr = true;  // displays error message
                navigate = false;   // block button navigation until error is fixed
            } else {
                this.achBankNmErr = false;   // clears error message
            }

            if (!this.ach.bankBr || !this.ach.bankBr.match(/^[-\w &@()#+'%$.,ñÑü]*$/gi)) {
                this.achBankBrErr = true;  // displays error message
                navigate = false;   // block button navigation until error is fixed
            } else {
                this.achBankBrErr = false;   // clears error message
            }

            if (!this.ach.acctNm || !this.ach.acctNm.trim().match(/^[-a-z ]{3,50}$/gi)) {
                this.achAcctNmErr = true;    // displays error message
                navigate = false;
            } else {
                this.achAcctNmErr = false;   // clears error message
            }

            if (!this.ach.acctAddr1 || !this.ach.acctAddr1.match(/^[ \w\.#,\-]{10,50}$/gi)) {
                this.achAcctAddr1Err = true;    // displays error message
                navigate = false;
            } else {
                this.achAcctAddr1Err = false;   // clears error message
            }

            if (this.ach.acctAddr2 && !this.ach.acctAddr2.match(/^[ \w\.#,\-]{0,50}$/gi)) {
                this.achAcctAddr2Err = true;    // displays error message
                navigate = false;
            } else {
                this.achAcctAddr2Err = false;   // clears error message
            }

            if (!this.ach.acctZip || !this.ach.acctZip.match(/^\d{5}$|^\d{5}\-[ ]*$|^\d{5}\-\d{4}$/g)) {
                this.achAcctZipErr = true;    // displays error message
                navigate = false;
            } else {
                this.achAcctZipErr = false;   // clears error message
            }

            if (!this.ach.acctCity || !this.ach.acctCity.trim().match(/^[ a-z]{3,50}$/gi)) {
                this.achAcctCityErr = true;    // displays error message
                navigate = false;
            } else {
                this.achAcctCityErr = false;   // clears error message
            }

            if (!this.ach.acctEmail || !this.ach.acctEmail.match(/^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$|^\s*$/g)) {
                this.achAcctEmailErr = true;    // displays error message
                navigate = false;
            } else {
                this.achAcctEmailErr = false;   // clears error message
            }

            if (!this.ach.acctTel || this.ach.acctTel.replace(/\D/g, '').length != 10) {
                this.achAcctTelErr = true;   // bad phone
                navigate = false;
            } else {
                this.achAcctTelErr = false;
            }

            if (!this.ach.acctNo || !this.ach.acctNo.match(/^[-\d]{3,50}$/g)) {
                this.achAcctNoErr = true;    // displays error message
                navigate = false;
            } else {
                this.achAcctNoErr = false;   // clears error message
            }

            if (!this.ach.bankRtNo || !this.ach.bankRtNo.match(/^\d{3,50}$/g)) {
                this.achBankRtNoErr = true;    // displays error message
                navigate = false;
            } else {
                this.achBankRtNoErr = false;   // clears error message
            }
        }

        if (this.total == 0) {   // no charges selected
            this.noSelectColorErr = 'yellow';
            navigate = false;
        } else {
            this.noSelectColorErr = undefined;
        }

        if (navigate) {
            this.payment.cc = this.cc;
            this.payment.ach = this.ach;
            // this.newRegistration.customer = this.customer
            // this.newRegistration.customer.cellPhone = this.newRegistration.customer.cellPhone ? this.newRegistration.customer.cellPhone.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, '') : undefined;
            // this.newRegistration.customer.otherPhone = this.newRegistration.customer.otherPhone ? this.newRegistration.customer.otherPhone.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, '') : undefined;
            // this.payment.payDate = this._helperRtnsComponent.fmtDate(new Date(), 'sql');
            // this.newRegistration.payment = this.payment;
            // this.newRegistration.chargOrders = this.serviceItemOrders;

            this.calculateTotal();

            if (this.type == 'cc') {
                this.payPal = true; // turn on the paypal buttons
            } else {
                var data = {
                    "billingAddr": {
                        "name": this.ach.acctNm,
                        "addrLine1": this.ach.acctAddr1,
                        "addrLine2": this.ach.acctAddr2,
                        "city": this.ach.acctCity,
                        "state": this.cc.billState,
                        "zipCode": this.ach.acctZip.replace(/\D/g, ''),
                        "country": 'USA',
                        "email": this.ach.acctEmail,
                        "phoneNumber": this.ach.acctTel
                    }
                }
                this.completePayment(data);
            }

            // initially used to register within a sale
            // this._paymentService.postNewRegistration(this.newRegistration)
            //     .subscribe(
            //         returnedData => this.backFromNewClientRegister(returnedData),
            //         error => this.errMsg = error // error.json().message + ' status=' + error.status + ' ' + error.statusText;
            //     );
        }
    }

    // backFromNewClientRegister(returnedData: any): void {    // registers client & payment type
    //     this.errMsg = undefined;    // clr any past error
    //     this.customer.pKey = JSON.parse(returnedData._body)[0]["pKey"];
    //     this.customer.sn = JSON.parse(returnedData._body)[0]["sn"];

    //     for (var i = 0; i < this.serviceItemOrders.length; i++) {
    //         this.serviceItemOrders[i].pKeyOrder = JSON.parse(returnedData._body)[0]["orderNo"];
    //     }

    //     console.log('this.customer.pKey=', this.customer.pKey);
    //     console.log('this.customer.sn=', this.customer.sn);
    //     console.log('this.serviceItemOrders[0].pKeyOrder=', this.serviceItemOrders[0].pKeyOrder);

    //     if (this.payment.type == 'cc') {
    //         this.achDisabled = true;    // cannot allow user to change to ach now
    //     } else {
    //         this.ccDisabled = true;     // cannot allow user to change to credit card now
    //         this.submitBtnDisabled = true;  // don't resubmit
    //         this.payPal = false;
    //         this.sendWelcomeEmail();
    //     }
    // }

    backFromSendingEmail(returnedData: any): void {
        this.waiting4Response = false;
        if (!returnedData.emailSent) {
            this.emailSentDlgClass = 'alert alert-danger';  // change dialog color to reddish from green
            this.emailErrMsg = returnedData.msg;
            this.emailSent = true;  // to make dialog show
        } else {
            this.emailSent = returnedData.emailSent;    // shows a welcom message
        }
    }

    completePayment(data: any): void {
        var lineItems: any[] = [];
        for (var i = 0; i < this.serviceItemPrices.length; i++) {
            if (this.serviceItemPrices[i].selected) {
                lineItems.push({
                    "pKeyServiceItem": this.serviceItemPrices[i].pKey,
                    "descr": this.engLang ? this.serviceItemPrices[i].descrEng : this.serviceItemPrices[i].descrSpan,
                    "qty": this.serviceItemPrices[i].qty,
                    "charge": this.serviceItemPrices[i].charge,
                    "frequency": this.serviceItemPrices[i].frequency,
                    "duration": this.serviceItemPrices[i].duration,
                    "tax": this.serviceItemPrices[i].tax
                })
            }
        }

        var authorized = {  // PayPal returned data except lineItems[], this.customer.pKey, this.customer.sn, this.total, this.totalItems, this.totalTax
            "payType": this.type,
            "intent": data.intent,
            "orderID": data.orderID,
            "payerID": data.payerID,
            "paymentID": data.paymentID,
            "paymentToken": data.paymentToken,
            "currency": data.currency,
            "billingAddr": {
                "name": (this.type == 'cc' ? this.cc.name : this.ach.acctNm),
                "addrLine1": this.cc.billAddr1,
                "addrLine2": this.cc.billAddr2,
                "city": this.cc.billCity,
                "state": this.cc.billState,
                "zipCode": this.cc.billZip.replace(/\D/g, ''),
                "country": 'USA',
                "email": this.cc.billEmail,
                "phoneNumber": this.cc.billTel
            },
            "lineItems": lineItems,
            "ach": {
                "bankNm": this.ach.bankNm,
                "bankBr": this.ach.bankBr,
                "bankRtNo": this.ach.bankRtNo,
                "acctNo": this.ach.acctNo
            },
            "pKeyCust": this.customer.pKey,
            "sn": this.customer.sn,
            "totalCharge": this.total,
            "totalItems": this.totalItems,
            "totalTax": this.totalTax
        };

        this.waiting4Response = true;
        this._paymentService.postCcChargeReceivedResponse(authorized)
            .subscribe(
                returnedData => this.backFrompostCcChargeReceivedResponse(returnedData),
                error => this.backFrompostCcChargeReceivedResponseErr(error)
            );
    }

    backFrompostCcChargeReceivedResponse(returnedData: any): void {     // DEBUG
        this.waiting4Response = false;
        this.payPal = false;    // turn off PayPal buttons
        this.serviceItemOrders[0].pKeyOrder = returnedData.pKeyOrder;
        if (this.serviceItemPrices.filter(item => item.email)) {
            this.sendOrderEmail();
        }
    }

    backFrompostCcChargeReceivedResponseErr(error: any): void {
        this.waiting4Response = false;
        var msg: string = (this.engLang ? 'Try one at a time:' : 'Trate uno a la vez:') + '<br />';
        msg += (error ? 'error = ' + error + '<br />' : '');
        msg += (error._body ? '_body = ' + error._body + '<br />' : '');

        // TODO: unfinished!

        // this._modalService.message(msg,
        //     this.engLang ? 'Error after PayPal payment.' : 'Error después recibido pago PayPal.',
        //     'app/assets/images/dataReceiveError.png',
        //     'Error', '40', '40', this.engLang);
    }

    calculateTotal(): void {
        this.submitBtnDisabled = true;
        this.noSelectColorErr = undefined;  // clears any possible selection errpr
        this.total = 0;  // clear
        this.totalTax = 0;
        this.totalItems = 0;

        this.serviceItemOrders = [undefined];  // creates index 0 with null
        this.serviceItemOrders.pop(); // clears the array setting length = 0 & getting rid of undefined values for index = 0

        var items = []; // Accumulates items sold for this.initConfig(this.total.toFixed(2), items);
        for (let i = 0; i < this.serviceItemPrices.length; i++) {
            if (this.serviceItemPrices[i].selected) {
                this.totalItems += 1;

                let tdt = this._helperRtnsComponent.fmtDate(new Date(), 'sql');  // todays date formatted for sql
                let selectedOrder: IServiceItemOrder = {    // Not for PayPall
                    pKey: undefined,
                    pKeyOrder: undefined,
                    pKeyCust: undefined,
                    pKeyChargOpts: this.serviceItemPrices[i].pKey,
                    charge: this.serviceItemPrices[i].charge,
                    qty: parseInt(this.serviceItemPrices[i].qty),
                    orderDate: tdt,
                    cancelDate: undefined,
                    description: (this.engLang ? this.serviceItemPrices[i].descrEng : this.serviceItemPrices[i].descrSpan)
                };

                this.serviceItemOrders.push(selectedOrder);
                this.submitBtnDisabled = false;

                items.push(
                    {
                        name: selectedOrder.description.substr(0, 125),  // error INVALID_STRING_LENGTH caused with len = 128
                        quantity: selectedOrder.qty,
                        category: 'DIGITAL_GOODS',
                        unit_amount: {
                            currency_code: 'USD',
                            value: selectedOrder.charge
                        },
                    });

                this.total += Number(this.serviceItemPrices[i].charge) * Number(this.serviceItemPrices[i].qty);    // Running total w/o tax  => subtotal
            }
        }

        if (this.serviceItemPrices.filter(item => item.selected).length > 0) {
            this.initConfig(this.total.toFixed(2), items);
            console.log('%c' + "this.payPalConfig @ calculateTotal()", 'color: green; background: yellow; font-size: 15px');
            console.dir(this.payPalConfig); // Final object to be submitted to PayPall
        }
    }

    getCustomerFromSN(event: any): void {
        var sn: string = event.target.value;
        if (sn && sn.trim().match(/^[0-9A-Fa-f]*$/g)) {
            this._dbLookUpService.getCustomerFromSN(sn.trim().match(/^[0-9A-Fa-f]*$/g)[0].toUpperCase())
                .subscribe(
                    returnedData => this.backFromGetCustomerFromSN(returnedData),
                    error => this.backFromGetCustomerFromSNErr(error)
                );
        } else {
            this.snErr = true;
            this.customer.companyNm = undefined;
            this.submitBtnDisabled = true;
            this.payPal = false;
        }
    }

    keyupOnGetCustomerFromSN(event: any): void {
        if (event.keyCode === 13) {
            this.getCustomerFromSN(event);
        }
        // event.preventDefault();
    }

    backFromGetCustomerFromSN(returnedData: any): void {
        this.waiting4Response = false;
        if (returnedData.customer.length == 1) {
            this.snErr = false;
            this.customer.sn = returnedData.customer[0]['sn'];
            if (returnedData.customer[0]['officeName'] && returnedData.customer[0]['officeName'].trim().length) {
                this.waiting4Response = true;
                this._paymentService.getServiceItemPricesExcepts(this.customer.sn)
                    .subscribe(
                        returnedData => this.backFromGetServiceItemPricesExcepts(returnedData),
                        error => console.log(error)
                    );
            }
            this.customer.pKey = returnedData.customer[0]['pKey'];
            this.customer.companyNm = returnedData.customer[0]['officeName'];
            this.customer.firstNm = returnedData.customer[0]['firstNm'];
            this.customer.lastNm = returnedData.customer[0]['lastNm'];
            this.customer.otherPhone = returnedData.customer[0]['officeTel1'];
            this.customer.email = returnedData.customer[0]['officeEmail1'];

            this.cc.name = returnedData.customer[0]['name'] ? returnedData.customer[0]['name'] : this.customer.firstNm && this.customer.lastNm ? this.customer.firstNm + ' ' + this.customer.lastNm : '';
            this.cc.billAddr1 = returnedData.customer[0]['billAddr1'];
            this.cc.billAddr2 = returnedData.customer[0]['billAddr2'];
            this.cc.billZip = returnedData.customer[0]['billZip'];
            this.cc.billCity = returnedData.customer[0]['billCity'];
            this.cc.billTel = returnedData.customer[0]['billTel'] ? returnedData.customer[0]['billTel'] : returnedData.customer[0]['officeTel1'] ? returnedData.customer[0]['officeTel1'] : '';
            this.cc.billEmail = returnedData.customer[0]['billEmail'] ? returnedData.customer[0]['billEmail'] : this.customer.email ? this.customer.email : '';

            this.ach.acctNm = returnedData.customer[0]['acctNm'] ? returnedData.customer[0]['acctNm'] : this.customer.firstNm && this.customer.lastNm ? this.customer.firstNm + ' ' + this.customer.lastNm : '';
            this.ach.acctAddr1 = returnedData.customer[0]['acctAddr1'];
            this.ach.acctAddr2 = returnedData.customer[0]['acctAddr2'];
            this.ach.acctZip = returnedData.customer[0]['acctZip'];
            this.ach.acctCity = returnedData.customer[0]['acctCity'];
            this.ach.acctTel = returnedData.customer[0]['acctTel'] ? returnedData.customer[0]['acctTel'] : returnedData.customer[0]['officeTel1'] ? returnedData.customer[0]['officeTel1'] : '';
            this.ach.acctEmail = returnedData.customer[0]['acctEmail'] ? returnedData.customer[0]['acctEmail'] : this.customer.email ? this.customer.email : '';
            this.ach.acctNo = returnedData.customer[0]['acctNo'];
            this.ach.bankNm = returnedData.customer[0]['bankNm'];
            this.ach.bankBr = returnedData.customer[0]['bankBr'];
            this.ach.bankRtNo = returnedData.customer[0]['bankRtNo'];

            this.submitBtnDisabled = false;
        } else {
            this.snErr = true;
            this.customer.companyNm = undefined;
            this.submitBtnDisabled = true;
            this.payPal = false;
        }
    }

    backFromGetCustomerFromSNErr(error: any): void {    // PaymentFormComponent.html:58 ERROR Error: ExpressionChangedAfterItHasBeenCheckedError
        this.waiting4Response = false;
        var msg: string = (this.engLang ? 'Try one at a time:' : 'Trate uno a la vez:') + '<br />';
        msg += (this.engLang ? '1 - Check Internet service' : '1 - Verifique Internet') + '<br />';
        msg += (this.engLang ? '2 - Switch browser' : '2 - Cambie el navegador') + '<br />';
        msg += (this.engLang ? '3 - Try again' : '3 - Trate otra vez') + '<br /><br />';
        msg += (error._body ? '_body = ' + error._body + '<br />' : '');

        // TODO: unfinished!

        // this._modalService.message(msg,
        //     this.engLang ? 'Error reading Serial Number.' : 'Error leyendo Número de Serie.',
        //     'app/assets/images/dataReceiveError.png',
        //     'Error', '40', '40', this.engLang);
    }

    backFromGetServiceItemPricesExcepts(returnedData: any): void {
        this.waiting4Response = false;
        if (returnedData.length > 0) {
            this.serviceItemPricesExcepts = returnedData;
            for (var i = 0; i < this.serviceItemPricesExcepts.length; i++) {
                for (var j = 0; j < this.serviceItemPrices.length; j++) {
                    if (this.serviceItemPricesExcepts[i].svcItmPkey == this.serviceItemPrices[j].pKey) {
                        this.serviceItemPrices[j].charge = this.serviceItemPricesExcepts[i].charge;
                        this.serviceItemPrices[j].tax = this.serviceItemPricesExcepts[i].tax;
                        if (this.serviceItemPricesExcepts[i].descrEng && this.serviceItemPricesExcepts[i].descrEng.length > 0) {
                            this.serviceItemPrices[j].descrEng = this.serviceItemPricesExcepts[i].descrEng;
                        }
                        if (this.serviceItemPricesExcepts[i].descrSpan && this.serviceItemPricesExcepts[i].descrSpan.length > 0) {
                            this.serviceItemPrices[j].descrSpan = this.serviceItemPricesExcepts[i].descrSpan;
                        }
                    }
                }
            }
        }
        this.calculateTotal();
    }

    backFromGetServiceItemPricesExceptsError(error: any): void {
        this.waiting4Response = false;
        var msg: string = (this.engLang ? 'Try one at a time:' : 'Trate uno a la vez:') + '<br />';
        msg += (this.engLang ? '1 - Check Internet service' : '1 - Verifique Internet') + '<br />';
        msg += (this.engLang ? '2 - Switch browser' : '2 - Cambie el navegador') + '<br />';
        msg += (this.engLang ? '3 - Try again' : '3 - Trate otra vez') + '<br /><br />';
        msg += (error._body ? '_body = ' + error._body + '<br />' : '');

        // TODO: unfinished!

        // this._modalService.message(msg,
        //     this.engLang ? 'Error reading Charge Exceptions.' : 'Error leyendo Excepciónes a Cargos.',
        //     'app/assets/images/dataReceiveError.png',
        //     'Error', '40', '40', this.engLang);
    }

    getCityStFromZip(zip: string, fld: string): void {
        if (zip.match(/[\d]{5}/g)) {
            this._dbLookUpService.getCityStFromZip(zip.replace(/[\D]*/g, '').substring(0, 5), fld)
                .subscribe(
                    returnedData => this.backFromGetCityStFromZip(returnedData),
                    error => this.backFromGetCityStFromZipFailure(error)
                );
        }
    }

    backFromGetCityStFromZip(returnedData: any): void {
        this.waiting4Response = false;
        if (returnedData.address[0]) {
            switch (returnedData.address[0].fld) {
                case 'billZip':
                    this.cc.billCity = returnedData.address[0].ZipCity;
                    break;
                case 'acctZip':
                    this.ach.acctCity = returnedData.address[0].ZipCity;
                    break;
            }
        }
    }

    backFromGetCityStFromZipFailure(error: any): void {
        this.waiting4Response = false;
        console.log('backFromGetCityStFromZipFailure=' + error);
    }

    sendWelcomeEmail(): void {
        var email = {
            "email": this.ach.acctEmail,
            "subject": (this.engLang ? 'Welcome to MedicalBiller!' : '¡Bienvenido al MedicalBiller!'),
            "text": "",
            "fullName": this.customer.firstNm + ' ' + this.customer.lastNm,
            "sn": this.customer.sn,
            "pKey": this.customer.pKey,
            "items": [{}],
            "total": this.total.toFixed(2),
            "orderDate": this.serviceItemOrders[0].orderDate.substring(0, 2) + '/' + this.serviceItemOrders[0].orderDate.substring(3, 5) + '/' + this.serviceItemOrders[0].orderDate.substring(8, 10),
            "orderNo": this.serviceItemOrders[0].pKeyOrder,
            "engLang": this.engLang,
            "emailType": 'welcome',
            "responseKey": encodeURIComponent('iVBORw0KGgoAAAANSUhEUgAAA1IAAAEsCAYAAADJ+kYKfAAAgAElEQVR/42uy9a5hc1Xnn~988ziROJvHu1qUFxKaEg5AENiXJyeQ4BlVzk4wdVPLJ+jO0PsarPBGRsie7G5HN3f4%pbjCWwczpUp4z58xzfFEJjHGQUJeIwcnJeFTiImTwRCUcELp09%ZkJnHOhfd82Lu6q6r3fa99q%r%e/Aqkpqq6au13r7V~6%IugBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBB+CCCGEEEIIIYQQQgghhBBCCCH2aCyC7HL/t2FIBgoL11yIAHQAE~JD1d0dEOv7m9pyTXT~ut%7w3mODdV4FQgghhBBCKFKZ43fHl0oACtbjFgC6yIo02TmQ~HjfACLl%j7mf5rWwwBw2vpvA0Dz4mODTV5FQgghhBBCkSKx8OHxpQLMWaQigJ2yIk%egiPBRarzfcSncLnKlBt1iLQkqw6gefEbayhYhBBCCCGEIkUCi1OpJU0ASmjNMPmQEy~R8itTCYqU3Ru1Zq1OWv~tX%zGGoORQQghhBBCKFJkmY88ZBQtYdoJSNlLULzkpOP1~RQpO5oA6gI5CaB~6Rtrm4wcQgghpDf5zf%9f9EBzMNpX7ePbQj~Oz2Bei1~~jUGgOF%~tLhBq8koUjFI09lAHssgSq0381RBUXF8j7F~6RUiFSrdFp%aAKoATh56fG1NUYUIYQQ0icSlW2RMiBCiSIUKdVc95BRacmTdC3X676bo8xK9YlIdVZa5v6qowBqlx5fy2WAhBBCSB4l6v%wIVFBZMr35nAfT%Z~L3Mm6k~rlChCkVIkT62ZpzJ873WSWEXKT12QeZFyf%MagKMiqF3~JqWKEEIIyYVE%SdLosSHRGVPpChRhCKlgsLXjAKAigj2wSG7XpwitcpNUtwnlZJItb9tDZCjl7~5rsrIJIQQQrLJB9tnogLJT4Ii5fwUQ9MoUYQiFVWgygD2wZx98nSEKCLldbvnKuFEvCLVeqJhShVmL39zHSs6QgghJIsSFVh~EhQpB4kCMPzPX6JEEYpUGHnSLXGagM3sU1iR8uMXPSNSAWQqgki10wAwC6B2~ZvruPSPEEIIyYpE5UukKFGEIhWGjQ8bOoAxAKMiTokjoshQ5H1STZgpwwGgCcH5gPXKzjYRKcDvAcD5EKn2CrAGYOryN9c1eUsTQgghKUtUfkSKEkUoUlEECl+byCP/HdoY9FpOow/Z1')
        }
        email.items.pop();    // clears the array
        for (var i = 0; i < this.serviceItemPrices.length; i++) {
            if (this.serviceItemPrices[i].selected) {
                email.items.push({
                    "qty": this.serviceItemPrices[i].qty,
                    "descr": (this.engLang ? this.serviceItemPrices[i].descrEng : this.serviceItemPrices[i].descrSpan),
                    "charge": (i == 0 ? '$' : '') + (+this.serviceItemPrices[i].qty * +this.serviceItemPrices[i].charge).toString()
                })
            }
        }

        this._dbLookUpService.sendEmail(email)
            .subscribe(
                returnedData => this.backFromSendingEmail(returnedData),
                error => this.errMsg = error    // for any http error but not email err
            );
    }

    sendOrderEmail(): void {
        let email = {
            "email": this.ach.acctEmail,
            "subject": (this.engLang ? 'Thanks for your order!' : '¡Gracias por su orden!'),
            "text": "",
            "fullName": this.cc.name ? this.cc.name : this.ach.acctNm,
            "sn": this.customer.sn,
            "pKey": this.customer.pKey,
            "items": [{}],
            "total": this.total.toFixed(2),
            "orderDate": this.serviceItemOrders[0].orderDate.substring(0, 2) + '/' + this.serviceItemOrders[0].orderDate.substring(3, 5) + '/' + this.serviceItemOrders[0].orderDate.substring(8, 10),
            "orderNo": this.serviceItemOrders[0].pKeyOrder,
            "engLang": this.engLang,
            "emailType": 'order',
            "responseKey": encodeURIComponent('iVBORw0KGgoAAAANSUhEUgAAA1IAAAEsCAYAAADJ+kYKfAAAgAElEQVR/42uy9a5hc1Xnn~988ziROJvHu1qUFxKaEg5AENiXJyeQ4BlVzk4wdVPLJ+jO0PsarPBGRsie7G5HN3f4%pbjCWwczpUp4z58xzfFEJjHGQUJeIwcnJeFTiImTwRCUcELp09%ZkJnHOhfd82Lu6q6r3fa99q%r%e/Aqkpqq6au13r7V~6%IugBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQQQgghhBB+CCCGEEEIIIYQQQgghhBBCCCH2aCyC7HL/t2FIBgoL11yIAHQAE~JD1d0dEOv7m9pyTXT~ut%7w3mODdV4FQgghhBBCKFKZ43fHl0oACtbjFgC6yIo02TmQ~HjfACLl%j7mf5rWwwBw2vpvA0Dz4mODTV5FQgghhBBCkSKx8OHxpQLMWaQigJ2yIk%egiPBRarzfcSncLnKlBt1iLQkqw6gefEbayhYhBBCCCGEIkUCi1OpJU0ASmjNMPmQEy~R8itTCYqU3Ru1Zq1OWv~tX%zGGoORQQghhBBCKFJkmY88ZBQtYdoJSNlLULzkpOP1~RQpO5oA6gI5CaB~6Rtrm4wcQgghpDf5zf%9f9EBzMNpX7ePbQj~Oz2Bei1~~jUGgOF%~tLhBq8koUjFI09lAHssgSq0381RBUXF8j7F~6RUiFSrdFp%aAKoATh56fG1NUYUIYQQ0icSlW2RMiBCiSIUKdVc95BRacmTdC3X676bo8xK9YlIdVZa5v6qowBqlx5fy2WAhBBCSB4l6v%wIVFBZMr35nAfT%Z~L3Mm6k~rlChCkVIkT62ZpzJ873WSWEXKT12QeZFyf%MagKMiqF3~JqWKEEIIyYVE%SdLosSHRGVPpChRhCKlgsLXjAKAigj2wSG7XpwitcpNUtwnlZJItb9tDZCjl7~5rsrIJIQQQrLJB9tnogLJT4Ii5fwUQ9MoUYQiFVWgygD2wZx98nSEKCLldbvnKuFEvCLVeqJhShVmL39zHSs6QgghJIsSFVh~EhQpB4kCMPzPX6JEEYpUGHnSLXGagM3sU1iR8uMXPSNSAWQqgki10wAwC6B2~ZvruPSPEEIIyYpE5UukKFGEIhWGjQ8bOoAxAKMiTokjoshQ5H1STZgpwwGgCcH5gPXKzjYRKcDvAcD5EKn2CrAGYOryN9c1eUsTQgghKUtUfkSKEkUoUlEECl+byCP/HdoY9FpOow/Z1')
        }
        email.items.pop();    // clears the array
        for (var i = 0; i < this.serviceItemPrices.length; i++) {
            if (this.serviceItemPrices[i].selected) {
                email.items.push({
                    "qty": this.serviceItemPrices[i].qty,
                    "descr": (this.engLang ? this.serviceItemPrices[i].descrEng : this.serviceItemPrices[i].descrSpan),
                    "charge": (i == 0 ? '$' : '') + (+this.serviceItemPrices[i].qty * +this.serviceItemPrices[i].charge).toString()
                })
            }
        }

        this._dbLookUpService.sendEmail(email)
            .subscribe(
                returnedData => this.backFromSendingEmail(returnedData),
                error => this.errMsg = error    // for any http error but not email err
            );
    }

    resetCheckoutMnuItem(): void {
        this.payPal = false;
        var ckoutItem = this.serviceItemPrices.filter(item => item.mnuEng.match(/\(CHECKOUT\)/g))[0];
        this.totalItems ? ckoutItem.mnuShow = true : ckoutItem.mnuShow = false;
    }

    closeAndReload(): void {
        location.reload();
    }

    itemQtyChanged(event: any, pKey: string): void {
        if (event) {
            let qtyBox: HTMLInputElement = <HTMLInputElement>event.target;
            let qty = qtyBox.value;

            for (var i = 0; i < this.serviceItemPrices.length - 1; i++) {
                if (this.serviceItemPrices[i].pKey == pKey) {
                    if (this._helperRtnsComponent.isNumeric(qty) && parseInt(qty) > 0 && parseInt(qty) < 100) {
                        this.serviceItemPrices[i].qty = qtyBox.value;
                        this.calculateTotal();
                        return;
                    } else {
                        qtyBox.value = this.serviceItemPrices[i].qty.toString();    // Restore previous value
                        return;
                    }
                }
            }
        }
    }

    test(): void {
        var authorized = {
            "payType": this.type,
            "intent": "sale",
            "orderID": "EC-23B23845R5799982U",
            "payerID": "5DSZDNE47QKBC",
            "paymentID": "PAYID-LRJCYWY1P01314072027692K",
            "paymentToken": "EC-23B23845R5799982U",
            "currency": "USD",
            "billingAddr": {
                "name": this.cc.name,
                "addrLine1": this.cc.billAddr1,
                "addrLine2": this.cc.billAddr2,
                "city": this.cc.billCity,
                "state": this.cc.billState,
                "zipCode": this.cc.billZip.replace(/\D/g, '').trim(),
                "country": 'USA',
                "email": this.cc.billEmail,
                "phoneNumber": this.cc.billTel,
            },
            "lineItems": [{
                "pKeyServiceItem": "999",
                "descr": "MB Servicio remoto 1 hr o menos",
                "qty": "1",
                "charge": "249.99",
                "frequency": "1m",
                "duration": "99999",
                "tax": "1"

            }],
            "ach": {
                "bankNm": "BANCO SANTANDER",
                "bankBr": "GUAYAMA BRANCH",
                "bankRtNo": "ROUTE NUMBER",
                "acctNo": "ACCOUNT NUMBER"
            },
            "pKeyCust": this.customer.pKey,
            "sn": this.customer.sn,
            "totalCharge": "123.45",
            "totalItems": "1",
            "totalTax": ".11"
        };

        this.waiting4Response = true;
        this._paymentService.postCcChargeReceivedResponse(authorized)
            .subscribe(
                returnedData => this.backFrompostCcChargeReceivedResponse(returnedData),
                error => this.backFrompostCcChargeReceivedResponseErr(error)
            );
    }

    onClick_showPopr(): void {
        this.showPopr = !this.showPopr;
        if (this.showPopr) {
            let coll = document.querySelector('div.popper-content');   // collection
            if (coll) {
                let popr = <HTMLDivElement>coll;
                popr.style.width = "425px";
            }
        }
    }

}