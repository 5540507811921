import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IReclaims, IReclaimsDet } from '../interfaces/reclaims';
import { HelperRtnsComponent } from '../helper-rtns.component';
import { WebsocketService } from '../websocket.service';
import { Subscription } from 'rxjs';
import { AppToastsService } from '../app-toasts/app-toasts.service';
import { NgbModal, NgbModalRef, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { FormsModule } from '@angular/forms';
import { ReclXmlTriplesAdvantageAnejo1Component } from 'src/app/ck-register/recl-xml-triples-advantage-anejo1/recl-xml-triples-advantage-anejo1.component';
import { ReclXmlSssFormularioAjuste070114Component } from 'src/app/ck-register/recl-xml-sss-formulario-ajuste070114/recl-xml-sss-formulario-ajuste070114.component';
import { ReclXmlMmmPmcRevB01162014Component } from 'src/app/ck-register/recl-xml-mmm-pmc-rev-b01162014/recl-xml-mmm-pmc-rev-b01162014.component';
import { ReclXmlHumanaRev17112010Component } from 'src/app/shared/reclaim/recl-xml-humana-rev17112010/recl-xml-humana-rev17112010.component';
import { ReclXmlSSS022309Component } from 'src/app/ck-register/recl-xml-sss022309/recl-xml-sss022309.component';
import { CkRegister835DetailService } from 'src/app/ck-register/ck-register835-detail/ck-register835-detail.service';
import { FiltrDetailsPipe } from './filtr-details-pipe.pipe';
import { MatSelectModule } from '@angular/material/select';
import { ReclXmlMmmGobiernoComponent } from './recl-xml-mmm-gobierno/recl-xml-mmm-gobierno.component';
import { Json2Pipe } from '../json2.pipe';

@Component({
  selector: 'app-reclaim',
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule,
    FormsModule,
    ReclXmlTriplesAdvantageAnejo1Component,
    ReclXmlSssFormularioAjuste070114Component,
    ReclXmlMmmPmcRevB01162014Component,
    ReclXmlHumanaRev17112010Component,
    ReclXmlSSS022309Component,
    ReclXmlMmmGobiernoComponent,
    FiltrDetailsPipe,
    MatSelectModule,
    Json2Pipe
  ],
  templateUrl: './reclaim.component.html',
  styleUrls: ['./reclaim.component.css']
})
export class ReclaimComponent implements OnInit, OnDestroy {
  @ViewChild('reclaimFormSelectModal', { read: TemplateRef, static: true }) reclaimFormSelectModal: TemplateRef<any>;
  @ViewChild('reclaimExitWoSaveModal', { read: TemplateRef, static: true }) reclaimExitWoSaveModal: TemplateRef<any>;
  @ViewChild('reclaimDeleteModal', { read: TemplateRef, static: true }) reclaimDeleteModal: TemplateRef<any>;

  @Input() sn: string;
  @Input() engLang: boolean = false;
  @Input() casID: string;
  @Input() ps: string;
  @Input() ckNo: string;
  @Input() ckDt: string;
  @Input() reclTyp: string; // Reclaim type to use saved in [InsuranceCo]
  @Input() insIdSeld: string; // Use to  insert reclaimType into [InsuranceCo] when the reclaim form had to be selected.
  @Input() saveToggle: boolean;
  @Input() deleteToggle: boolean;
  @Input() printToggle: boolean;
  @Input() reclID: string;
  @Input() reclaimForms: IReclForms[];
  @Input() idxSeld: number = -1;
  @Input() icnsPrevIdx: number = -1;
  @Output() closeEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() enabSaveReclEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() resetSaveToggleEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() resetDeleteToggleEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() showPrintDeleteBtnEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() ckUnckClmReclaimEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() spinnerEventEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() resetIcnsPrevIdxEventEmitter: EventEmitter<string> = new EventEmitter();

  reclaims: IReclaims[];
  reclaimsDet: IReclaimsDet[];
  icns: { icn: string, i: number }[] = [];
  reclForm: IReclForms;
  img64: string;
  base64prefix: string = 'data:image/png;base64, ';
  reclLineReasonEntered: IReclaimReason;  // Individual procedure reclaim reason just entered
  clrReclData: boolean;
  print: boolean;

  modalStack: string[] = [];
  enabSaveRecl: boolean;
  modalRef: NgbModalRef;
  showPopr1: boolean = false;
  showPopr2: boolean = false;
  showPopr3: boolean = false;

  sioSubscrpt: Subscription;

  constructor(private _helpRtns: HelperRtnsComponent,
    private _websocketService: WebsocketService,
    private _appToastsService: AppToastsService,
    private _modalService: NgbModal,
    private _ckRegister835DetailService: CkRegister835DetailService
  ) { }

  ngOnInit(): void {
    this.getReclaimForms();

    this.sioSubscrpt = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning events
      console.log('%c' + 'dataSet ReclaimComponent', 'color: green; background: yellow; font-size: 12px');
      console.log('dataSet', dataSet);

      if (dataSet?.error) {

        this._appToastsService.show(
          (this.engLang ? 'Data reception/acquisition/internet ERROR.' :
            'ERROR recibiendo/adquiriendo/internet data.') + dataSet?.error
          , { header: 'ERROR', autohide: false, error: true });

      } else {

        if (dataSet?.length > 0) {
          this._appToastsService.clear();

          if (dataSet[0]?.sqlProcNm.startsWith('spMB_Sio_GetReclaims')) {
            this.idxSeld = 0; // Start with latest reclaim

            if (dataSet[0]?.sqlProcNm === 'spMB_Sio_GetReclaims') {
              this.reclaims = dataSet;
              this.reclTyp = this.reclaims[0].reclType;
              this.reclForm = this.reclaimForms.find(itm => itm.typ == this.reclTyp);
              if (this.reclForm) {
                this.img64 = this.base64prefix + this.reclForm.img64;
                // console.log('this.img64',this.img64);
                this.reclaims.forEach(recl => {
                  if (!recl.reclXml) {
                    recl.reclXml = this.reclForm.xml; // Default blank for a new reclaim form data
                  }
                });
              }
              this.icns = this.reclaims.map((r, index) => { return { 'icn': r.reclICN, 'i': index } });
            }

            if (dataSet[0]?.sqlProcNm === 'spMB_Sio_GetReclaims-det') {
              this.reclaimsDet = dataSet;
              let xml = '';
              if (this.reclForm && +this.reclForm.saveDetXml) {
                xml = this.reclForm.xml;
              }

              this.reclaimsDet.forEach(r => {
                if (!r.reclDetXml) {
                  r.reclDetXml = xml; // Default blank for a new reclaim detail xml *** UNTESTED
                }
              });
            }

            this.reclID = this.reclaims[0].reclID; // In case the reclaim is to be deleted or printed
            this.showPrintDeleteBtnEventEmitter.emit(this.reclID);

            if (!this.reclForm && dataSet[0]?.sqlProcNm === 'spMB_Sio_GetReclaims-det') { // Use sqlProcNm to open this modal only once
              this.img64 = undefined;
              let msg: string;
              if (this.reclTyp) {
                const t = this.reclTyp ? this.reclTyp : '?';
                msg = this.engLang ? 'Reclaim form (type = ' + t + ') is not included in this application version. Select a new form or just continue w/o any form & submit reclaim/dispute/adjust explanations per CPT codes via 837 claim file as corrections. New forms to be included in the application can be submitted through medbiler@gmail.com' :
                  'La forma de Reclamo (tipo = ' + t + ') no está incluida en esta versión de la aplicación. Seleccione nueva forma o continúe sin forma para someter reclamo/disputa/ajustes vía archivo de facturación 837 como corrección. Puede someter formas de reclamo para ser incluidas en la aplicación por medbiler@gmail.com';
                this._appToastsService.updateDeadCenter(true); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
                this._appToastsService.show(msg, { header: this.engLang ? 'Warning' : 'Aviso', autohide: true, warning: true });
              }

              this.open(this.reclaimFormSelectModal, { backdrop: 'static', size: 'lg', scrollable: false }, 'reclaimFormSelectModal');
            }
            this.ckUnckClmReclaimEventEmitter.emit(false); // UnChecks reclaim R ckbox in ck-reg835-det & evaluate again before ngOnDestroy
            this.spinnerEventEmitter.emit(false); // Spinner off
          }

          if (dataSet[0]?.sqlProcNm.startsWith('spMB_Sio_InsUpdReclaim')) {
            if (dataSet[0]?.sqlProcNm == 'spMB_Sio_InsUpdReclaim') {  // Back from saving reclaims
              if (+dataSet[0].reclID) {
                this.reclID = dataSet[0].reclID;
                this.reclaims[this.idxSeld].reclID = dataSet[0].reclID;

                this.reclaimsDet.forEach(d => {
                  if (d.reclDetReclID === dataSet[0].reclID || d.reclDetReclID === '0') { // The edited details on screen or new
                    if (+d.need2Save || d.reclDetICN === dataSet[0].reclICN) {
                      d.reclDetReclID = dataSet[0].reclID;
                    }
                  }
                });

                let q = this.reclDetailsQry(dataSet[0].reclID);
                if (q) {
                  console.log('%c' + "CkRegisterComponent.saveReclaimsDetails(r) q = " + q, 'color: black; background: #90EE90; font-size: 12px');
                  this._websocketService.sendChat('query', this.sn, q);
                }
              }
            }

            if (dataSet[0]?.sqlProcNm == 'spMB_Sio_InsUpdReclaimDetails') {  // Back from saving reclaimsDet
              this.reclaimsDet.forEach(d => {
                if (+dataSet[0].indx === +d.indx) {
                  console.log('UPDATE ReclaimsDet index', d.indx);
                  d.reclDetReclID = dataSet[0].reclID;
                  d.reclDetID = dataSet[0].reclDetID;
                  d.reclDetICN = dataSet[0].reclDetICN;
                  d.reclDetReclaimReason = dataSet[0].reclDetReason;
                  d.need2Save = '0';
                }
              });
            }

            this.showPrintDeleteBtnEventEmitter.emit(this.reclID);
            this.resetSaveToggleEventEmitter.emit(false);
            this.spinnerEventEmitter.emit(false); // Spinner off
          }

          if (dataSet[0].sqlProcNm == 'spMB_Sio_DeleteReclaim') {
            if (+dataSet[0].deletedReclaimsCnt) {
              this.idxSeld = -1;
              this.reclaims = [];
              this.reclaimsDet = [];
              this.getReclaims();
            }
          }
        }
      }
    });
    console.log('reclaim.component - ngOnInit()');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.icnsPrevIdx && +changes.icnsPrevIdx.currentValue > -1) {
      const icnsSel = <HTMLSelectElement>document.getElementById('icns');
      icnsSel.selectedIndex = +changes.icnsPrevIdx.currentValue;
      setTimeout(() => {  // Cures, ExpressionChangedAfterItHasBeenCheckedError
        this.resetIcnsPrevIdxEventEmitter.emit('');
      }, 0);
    }
    if (changes.reclIdxSeld && changes.reclIdxSeld.currentValue) {  // For changing ICNs rippling down to reclaim form
      this.idxSeld = +changes.reclIdxSeld;
    }
    if (changes.reclID && changes.reclID.currentValue) {
      this.reclID = changes.reclID.currentValue;
    }

    if (changes.saveToggle && changes.saveToggle.currentValue) {
      this.saveToggle = false;
      this.saveReclaims(true);
    }

    if (changes.deleteToggle && changes.deleteToggle.currentValue) {
      this.deleteToggle = false;
      this.open(this.reclaimDeleteModal, { backdrop: 'static' }, 'reclaimDeleteModal');
    }

    if (changes.printToggle) {
      if (changes.printToggle.currentValue) {
        this.printToggle = false;
        this.saveReclaims(true);
        this.print = true;  // this.print is an @Input() to the individual ins reclaim components & setting it true causes the property to call print rtn in that component
      } else {
        this.print = false;  // Reset this.print in ins reclaim components & setting it false and enabling the print function
      }
    }
    // console.log('reclaim.component - ngOnChanges()');
  }

  ngOnDestroy(): void {
    this.updateReclaimCkBx();

    this.sioSubscrpt.unsubscribe();
    this._appToastsService.clear(); // Not sure it's working
    this.reclaims = undefined;
    this.reclID = undefined;
    this.reclForm = undefined;
    this.idxSeld = -1;
    this.reclaims = [];
    this.reclTyp = undefined;
    this.img64 = undefined;
    this.deleteToggle = false;
    this.printToggle = false;
    this.enabSaveRecl = false;
    this.enabSaveReclEventEmitter.emit(this.enabSaveRecl);
  }

  updateReclaimCkBx() {
    if (this.reclaims?.some(r => +r.reclID !== 0)) {
      console.log('true', true);
      this.ckUnckClmReclaimEventEmitter.emit(true); // Checks reclaim R ckbox in main component
    } else {
      console.log('false', false);
      this.ckUnckClmReclaimEventEmitter.emit(false); // UnChecks reclaim R ckbox in main component
    }
  }

  onMouseDown_captureIcnsCurrIndex(event: MouseEvent) {
    const icnsSel = event.target as HTMLSelectElement;
    this.icnsPrevIdx = icnsSel.selectedIndex;
  }

  onChange_icn(event) {
    if (event?.target) {
      const obj = {
        nxtIcnIdx: event.target.options.selectedIndex,
        nxtReclID: this.reclaims[+event.target.options.selectedIndex].reclID,
        prvIcnIdx: this.icnsPrevIdx
      }
      this.closeEventEmitter.emit(obj);
    }
  }

  onChange_selectReclLineDet(event, det: IReclaimsDet, r): void {
    let reasonEle: HTMLInputElement = <HTMLInputElement>document.getElementById('linReason' + r.toString());
    det.reclDetCodeSeled = event.target.checked ? '1' : '0';
    if (det.reclDetCodeSeled === '1' && +det.reclDetReclID === +this.reclaims[this.idxSeld].reclID && (det.reclDetICN === this.reclaims[this.idxSeld].reclICN || +det.reclDetID > 0)) {
      this.reclLineReasonEntered = {
        name: this.reclaims[this.idxSeld].reclPatNm,
        contrNo: +this.reclaims[this.idxSeld].reclPS == 1 ? this.reclaims[this.idxSeld].reclContr1 : this.reclaims[this.idxSeld].reclContr2,
        cpt: det.reclDetPcode,
        mods: det.reclDetMods.trim(),
        from: det.reclDetFromDt,
        to: det.reclDetToDt,
        dx: this.txlateDxPtrToDx(det.reclDetDxPtr),
        casNo: this.reclaims[this.idxSeld].reclCasNo,
        reason: reasonEle.value,
        xpected: det.reclDetXpected,
        payed: det.reclDetPayed,
        payDt: det.reclDetPayDt,
        bal: (+det.reclDetXpected * +det.reclDetQty) - +det.reclDetPayed,
        ckNo: this.reclaims[this.idxSeld].reclCkNo,
        icn: det.reclDetICN ? det.reclDetICN : this.reclaims[this.idxSeld].reclICN
      }
    } else {
      det.reclDetReclaimReason = '';
    }
    det.need2Save = '1';
    this.enabSaveRecl = true;
    this.enabSaveReclEventEmitter.emit(this.enabSaveRecl);
  }

  findAnyIcn(r) {
    if (this.reclaims[r].reclICN) {
      return this.reclaims[r].reclICN;
    } else {
      let icn: string = '';
      const r = this.reclaims.some(e => {
        if (!icn && e.reclICN) {
          icn = e.reclICN;
        }
      });
      return icn;
    }
  }

  txlateDxPtrToDx(ptrs: string): string {
    if (ptrs) {
      const p = ptrs.match(/^[1-9,a,c,d]/)[0]; // Isolate 1st pointer
      if (p) {
        const pi = ptrs.substring(0, 1);
        switch (pi) {
          case '1':
            return this.reclaims[this.idxSeld].reclDx1;
          case '2':
            return this.reclaims[this.idxSeld].reclDx2;
          case '3':
            return this.reclaims[this.idxSeld].reclDx3;
          case '4':
            return this.reclaims[this.idxSeld].reclDx4;
          case '5':
            return this.reclaims[this.idxSeld].reclDx5;
          case '6':
            return this.reclaims[this.idxSeld].reclDx6;
          case '7':
            return this.reclaims[this.idxSeld].reclDx7;
          case '8':
            return this.reclaims[this.idxSeld].reclDx8;
          case '9':
            return this.reclaims[this.idxSeld].reclDx9;
          case 'a':
            return this.reclaims[this.idxSeld].reclDxa;
          case 'b':
            return this.reclaims[this.idxSeld].reclDxb;
          case 'c':
            return this.reclaims[this.idxSeld].reclDxc;
          default:
            return this.reclaims[this.idxSeld].reclDx1 + ' ' + this.reclaims[this.idxSeld].reclDx2 + ' ' + this.reclaims[this.idxSeld].reclDx3 + ' ' + this.reclaims[this.idxSeld].reclDx4;
        }
      }
    }
    return this.reclaims[this.idxSeld].reclDx1 + ' ' + this.reclaims[this.idxSeld].reclDx2 + ' ' + this.reclaims[this.idxSeld].reclDx3 + ' ' + this.reclaims[this.idxSeld].reclDx4;
  }

  open(content, opts, modalNm, obj?: any) {
    this.clearPoppers();
    this.modalStack.push(modalNm);

    this.modalRef = this._modalService.open(content, opts);
    this.modalRef.result.then((result) => {
      console.log('modalStack', this.modalStack);
      console.log('%c' + "modal closed result=" + result, 'color: black; background: yellow; font-size: 14px');

      if (result === 'save_reclaimModal') {
        this.saveToggle = !this.saveToggle;
        this.modalStack.pop();
        return;
      }
      if (result === 'oK_reclaimFormSelectModal') { // A reclaim form was selected
        this.onChange_selectReclForm(); // Reclaim form selected with Ok
        if (this.reclTyp) {
          this.reclaimsDet.forEach(d => {
            if (+this.reclForm.saveDetXml) {
              d.reclDetXml = this.reclForm.xml;
            } else {
              this.reclaims[this.idxSeld].reclXml = this.reclForm.xml;
            }
          });
        }
        this.modalStack.pop();
      }
      if (result === 'oK_deleteReclaimModal') {
        this.clrReclData = false;
        this.deleteToggle = false;
        this.resetDeleteToggleEventEmitter.emit(this.deleteToggle);
        this.deleteReclaim();
        this.clrReclData = true;
        this.modalStack.pop();
      }

    }, (reason) => {  // Dimiss

      console.log('modalStack', this.modalStack);
      console.log('%c' + "modal dismissed reason=" + reason, 'color: black; background: yellow; font-size: 14px');
      switch (this.modalStack[this.modalStack.length - 1]) {

        case 'reclaimDeleteModal':
          this.deleteToggle = false;
          this.resetDeleteToggleEventEmitter.emit(this.deleteToggle);
          break;
        case 'reclaimExitWoSaveModal':  // Stay in selected reclaim
          break;
        case 'reclaimFormSelectModal':
          this.closeEventEmitter.emit(undefined); // Full exit from Reclaims Modal
          break;

        default:
      }

      this.modalStack.pop();
    });
  }

  onClick_showPopr1(): void {
    this.showPopr1 = !this.showPopr1;
    this.showPopr2 = false;
    this.showPopr3 = false;
  }

  onClick_showPopr2(): void {
    this.showPopr1 = false;
    this.showPopr2 = !this.showPopr2;
    this.showPopr3 = false;
  }

  onClick_showPopr3(): void {
    this.showPopr1 = false;
    this.showPopr2 = false;
    this.showPopr3 = !this.showPopr3;
  }

  clearPoppers(): void {
    this.showPopr1 = false;
    this.showPopr2 = false;
    this.showPopr3 = false;
  }

  saveReclaims(save: boolean): void { // When save = false this just cks if data was changed
    this.enabSaveRecl = false;
    this.enabSaveReclEventEmitter.emit(this.enabSaveRecl);  // Turns off save button

    let reclSaveQry = '';
    const p = this.reclForm ? ((this.reclForm.typ === '5' || this.reclForm.typ === '11' || this.reclForm.typ === '16') ? '%' : '') : '';  // p = percent sign legacy prepending text values

    // Ck for edits in reclaimsDet[] vs html view
    let r = 0;
    this.reclaimsDet.forEach(det => {
      if ((+det.reclDetReclID === +this.reclID && +det.reclDetReclID > 0) || (+det.reclDetReclID === +this.reclID && +det.reclDetReclID === 0 && det.reclDetICN === this.reclaims[this.idxSeld].reclICN)) {  // Do for CPTs shown on secreen only
        let reason = <HTMLInputElement>document.getElementById('linReason' + r);  // From html element
        if (reason) { // Do only if the element is visible
          if (reason.value != this.reclaimsDet[r].reclDetReclaimReason) {
            det.need2Save = '1';
            this.enabSaveRecl = true;
          }
          console.log('det.need2Save', det);
          r++;
        }
      }
    });

    if (!this.reclaims[this.idxSeld].reclXml) {
      this.reclaims[this.idxSeld].reclXml = this.reclForm ? this.reclForm.xml : '';
    }
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(this.reclaims[this.idxSeld].reclXml, "text/xml");

    // Ck for edits in xml vs html
    for (let i = 0; i < xmlDoc.getElementsByTagName("ReclaimFormFields")[0]?.childNodes.length; i++) {
      let fullNod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i];
      let id = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i].nodeName;
      let ele = <HTMLInputElement>document.getElementById(id);
      if (ele) {
        if (id.match(/^txt\w+/g) || id.match(/^med\w+/g)) {
          if (p + ele.value != fullNod.textContent) {
            this.reclaims[this.idxSeld].need2Save = '1';
            if (!save) {
              this.enabSaveRecl = true;
            }
            break;
          }
        }

        if (id.match(/^chk\w+/g) || id.match(/^opt\w+/g)) {
          let initSt: boolean = (fullNod.textContent.toLowerCase() == 'true' || fullNod.textContent == '1') ? true : false;
          if (!(ele.checked === initSt) || +this.reclaims[this.idxSeld].need2Save) {
            this.reclaims[this.idxSeld].need2Save = '1';

            if (!save) {  // If just checking if edited get out
              this.enabSaveRecl = true;
            }
            break;
          } else {
            this.reclaims[this.idxSeld].need2Save = '0';
          }
        }
      }
    }

    if (!save) {
      this.enabSaveReclEventEmitter.emit(this.enabSaveRecl);
      return; // Get out, just needed to know if data changed & enable save button when save = false
    }

    // Encode xml
    let xml = this.reclForm ? this.reclForm.xml : '';  // Initial xml
    let elMatchs = xml.match(/\<\w+\>/gi);  // Collection of elements in xml
    let result = '';  // Will contain xml with values
    elMatchs?.forEach(m => {  // elMatchs could be null if no reclaim form is specified , this.reclForm = undefined
      // console.log('saving ' + m);

      let el = <HTMLInputElement>document.getElementById(m.replace('<', '').replace('>', ''));
      if (el) {
        if (el.type === 'checkbox') {
          result += m + (el.checked ? '1' : '0');
        }
        if (el.type === 'radio') {
          if (p === '%') {  // Legacy
            result += m + (el.checked ? 'True' : 'False');
          } else {
            result += m + (el.checked ? '1' : '0');
          }
        }
        if (el.type === 'text') {
          result += m + p + el.value;
        }
        if (el.type === 'textarea') {
          result += m + p + el.value;
        }
        if (el.type === 'med') {
          result += m + p + el.value;
        }
        result += m.replace('<', '</');
      }
    });

    if (result) { // xml has data
      this.reclaims[this.idxSeld].reclXml = '<ReclaimFormFields>' + result.trim() + '</ReclaimFormFields>';
    } else {
      this.reclaims[this.idxSeld].reclXml = ''; // No data in xml
    }

    // Save
    this.reclaims.forEach((r, index) => {
      if (index === this.idxSeld) { // Only save reclaim with icn selected
        if (+r.need2Save || +r.reclID === 0) { // Edited or new reclaim
          reclSaveQry += "EXEC spMB_Sio_InsUpdReclaim "
            + "@reclID = '" + r.reclID
            + "', @reclCasID = '" + r.reclCasID
            + "', @reclPS = '" + r.reclPS
            + "', @reclTyp = '" + this.reclTyp
            + "', @reclIcn = '" + this._helpRtns.escApos(r.reclICN)
            + "', @reclCkNo = '" + this._helpRtns.escApos(r.reclCkNo)
            + "', @reclPayDt = '" + r.reclCkDate
            + "', @reclXml = '" + this._helpRtns.escApos(r.reclXml).replace(/&/g, '&amp;')
            + "', @reclInsId = '" + this.insIdSeld
            + "';";
        }
      }
    });

    if (reclSaveQry) {
      console.log('%c' + "CkRegisterComponent.saveReclaims(r) q = " + reclSaveQry, 'color: black; background: #90EE90; font-size: 12px');
      this.spinnerEventEmitter.emit(true); // Spinner on
      this._websocketService.sendChat('query', this.sn, reclSaveQry);
    } else {  // No changes to reclaims so next ck reclaimsDetail
      reclSaveQry = this.reclDetailsQry(this.reclaims[this.idxSeld].reclID);
      if (reclSaveQry) {
        console.log('%c' + "CkRegisterComponent.saveReclaims(r) q = " + reclSaveQry, 'color: black; background: #90EE90; font-size: 12px');
        this.spinnerEventEmitter.emit(true); // Spinner on
        this._websocketService.sendChat('query', this.sn, reclSaveQry);
      }
    }
  }

  reclDetailsQry(rID): string {
    let q = '';
    let i = 0;
    this.reclaimsDet.forEach(d => {
      if (d.reclDetReclID === this.reclID) {  // Do only visible reclDet rows
        let reasonVal: string = '';
        let reasonEle: HTMLInputElement = <HTMLInputElement>document.getElementById('linReason' + i);
        if (reasonEle) {  // Detail is visible on screen
          reasonVal = reasonEle.value;

          if (+d.need2Save || +d.reclDetID === 0) {
            q += "EXEC spMB_Sio_InsUpdReclaimDetails "
              + "@reclID = '" + rID
              + "', @reclDetID = '" + (d.reclDetID ? d.reclDetID : '0')
              + "', @reclDetCodeSeled = '" + d.reclDetCodeSeled
              + "', @reclDetICN = '" + this._helpRtns.escApos(d.reclDetICN)
              + "', @reclDetFromDt = '" + d.reclDetFromDt
              + "', @reclDetToDt = '" + d.reclDetToDt
              + "', @reclDetQty = '" + d.reclDetQty
              + "', @reclDetPcode = '" + d.reclDetPcode
              + "', @reclDetMods = '" + d.reclDetMods
              + "', @reclDetUsual = '" + d.reclDetAllowed
              + "', @reclDetXpect = '" + d.reclDetXpected
              + "', @reclDetPvdo = '" + d.reclDetCoins
              + "', @reclDetPay = '" + d.reclDetPayed
              + "', @reclDetReason = '" + this._helpRtns.escApos(reasonVal).substring(0, 100)
              + "', @reclDetXml = '" + this._helpRtns.escApos(d.reclDetXml)
              + "', @reclTyp = '" + this.reclTyp
              + "', @indx = '" + d.indx
              + "';";
          }
        }
        i++;
      }
    });
    return q;
  }

  highlightSelected(r): string {
    if (+this.insIdSeld > -1 && r == this.insIdSeld) {
      return 'rowSelected';
    } else {
      if (r % 2) {
        return 'oddReclSrch';
      } else {
        return 'evenReclSrch';
      }
    }
  }

  deleteReclaim(): void {
    console.log('%c' + "CkRegisterComponent.deleteReclaim q = EXEC spMB_Sio_DeleteReclaim @reclID=" + this.reclID + ';', 'color: black; background: #90EE90; font-size: 12px');
    this.spinnerEventEmitter.emit(true); // Spinner on
    this._websocketService.sendChat('query', this.sn, 'EXEC spMB_Sio_DeleteReclaim @reclID=' + this.reclID + ';');
  }

  useXmlOrDetXml(e: any): string {  // Sets e.reclXml or e.reclDetXml with default reclaim form xml. e is element of reclaims[]
    if (this.reclaimForms.find(itm => itm.typ == this.reclTyp && itm.saveDetXml == '0')) {
      return 'x';
    }
    if (this.reclaimForms.find(itm => itm.typ == this.reclTyp && itm.saveDetXml == '1')) {
      return 'd';
    }
    return undefined;
  }

  getReclaimForms(): void {
    this._ckRegister835DetailService.getReclaimFormsXml()
      .subscribe({
        next: data => {
          this.reclaimForms = data;
          this.getReclaims();
        }
      });
  }

  getReclaims(): void {
    this.idxSeld = -1;  // Hides all insurance reclaim components - non selected
    const reclQry = "EXEC spMB_Sio_GetReclaims "
      + "@sn = '" + this.sn
      + "',@reclCasID = '" + this.casID
      + "', @reclPS = '" + this.ps
      // + "', @reclCkNo = '" + this._helpRtns.escApos(this.ckNo)
      + "';"

    console.log('%c' + "ReclaimComponent.getReclaims() q = " + reclQry, 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, reclQry);
  }

  onChange_selectReclForm(): void {
    const i = this.reclaimForms.findIndex(f => f.typ === this.reclTyp);
    if (i >= 0) {
      this.reclForm = this.reclaimForms[i];
      this.img64 = this.base64prefix + this.reclaimForms[i].img64;
    } else {
      this.reclTyp = undefined;
      this.img64 = undefined;
      this.reclForm = undefined;
    }
  }

  getCarcDescrFromAdj(adjs: string): void { // Get adj CARC data from Sql table [CARC]
    if (!adjs) {
      return;
    }

    let adjsArr = adjs.split(',');
    adjsArr.forEach(adj => {
      let adjArr = adj.split(' ');
      if (adjArr.length >= 2) {
        this._appToastsService.updateDeadCenter(false); // Show toast @ top of viewport
        let grpCodeDesc: string = adjArr[0] ? (adjArr[0] + ' = ' + this._ckRegister835DetailService.carcGroupCodes.find(obj => obj.code == adjArr[0]).descr) : adj;
        let reasonCode: string = adjArr[1] ? adjArr[1] : adj;
        this._ckRegister835DetailService.getCarcDescrFromReasonCode(reasonCode)
          .subscribe({
            next: data => {
              data.forEach(itm => {
                this._appToastsService.updateDeadCenter(false);
                this._appToastsService.show(adjArr[1] + ' = ' + itm.descr, { header: grpCodeDesc, autohide: false, success: true });
              });
            },
            error: (err: any) => {
              this._appToastsService.updateDeadCenter(false);
              this._appToastsService.show(err.displayMsg, { header: 'ERROR', autohide: false, error: true });
              return;
            }
          });
      }
    });
  }

  onChange_showForm(typ: string) {
    // console.log('typ', typ)
    const img = this.reclaimForms.find(f => f.typ === typ).img64;
    if (img) {
      this.img64 = this.base64prefix + img;
    } else {
      this.img64 = undefined;
    }
    // console.log('this.img64', this.img64);
  }
}

