// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.placeholder {
    text-align: center;
}

::-webkit-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
}
:-moz-placeholder {
  font-style: italic; 
  font-weight: normal;
  font-size: 75%; 
}
::-moz-placeholder {
  font-style: italic; 
  font-weight: normal; 
  font-size: 75%;
}
:-ms-input-placeholder {  
  font-style: italic; 
  font-weight: normal;
  font-size: 75%;
}

table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
}
table td {
    border: 1px solid #ccc;
}
table .absorbing-column {
    width: 100%;
}

.black {
  filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
}

.odd {
  background-color:#f5f9f8;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/payment-form.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,WAAW;AACf;;AAEA;EAEE,+DAA+D;AACjE;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["input.placeholder {\r\n    text-align: center;\r\n}\r\n\r\n::-webkit-input-placeholder {\r\n  font-style: italic;\r\n  font-weight: normal;\r\n  font-size: 75%;\r\n}\r\n:-moz-placeholder {\r\n  font-style: italic; \r\n  font-weight: normal;\r\n  font-size: 75%; \r\n}\r\n::-moz-placeholder {\r\n  font-style: italic; \r\n  font-weight: normal; \r\n  font-size: 75%;\r\n}\r\n:-ms-input-placeholder {  \r\n  font-style: italic; \r\n  font-weight: normal;\r\n  font-size: 75%;\r\n}\r\n\r\ntable {\r\n    table-layout: auto;\r\n    border-collapse: collapse;\r\n    width: 100%;\r\n}\r\ntable td {\r\n    border: 1px solid #ccc;\r\n}\r\ntable .absorbing-column {\r\n    width: 100%;\r\n}\r\n\r\n.black {\r\n  -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);\r\n  filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);\r\n}\r\n\r\n.odd {\r\n  background-color:#f5f9f8;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
