import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { AppToastsService } from './app-toasts.service';

@Component({
  selector: 'app-toasts',
  template: `
  <ngb-toast
    *ngFor="let toast of _appToastsService.toasts"
    [header]="toast.header"
    [class]="toast.classname"
    [autohide]="toast.autohide"
    [delay]="toast.delay || 5000"
    (mouseenter)="toast.autohide = false && toast.error"
    (mouseleave)="toast.autohide = true && !toast.error"
    (hidden)="_appToastsService.remove(toast)"
    [class.bg-warning]="toast.warning"
    [class.bg-danger]="toast.error"
    [class.bg-success]="toast.success"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  </ngb-toast>`
  ,
  styles: ['* { background-color: #FFE13C; }'],
  host: {
    //   class: 'toast-container position-absolute top-0 start-50 translate-middle-x m-3 p-3'
    // , style: 'z-index: 1200'
    '[class.toast-container]': 'true', style: 'z-index: 1200;',
    '[class.position-absolute]': 'true',
    '[class.top-0]': '!deadCenter',
    '[class.start-50]': 'true',
    '[class.translate-middle-x]': 'true',
    '[class.m-3]': 'true',
    '[class.p-3]': 'true',
    '[class.top-50]': 'deadCenter',
    '[class.translate-middle]': 'deadCenter'
  }
})
export class AppToastsContainer implements OnInit {

  deadCenter: boolean = false;

  constructor(public _appToastsService: AppToastsService) { }

  ngOnInit(): void {
    this._appToastsService.deadCenter.subscribe(bool => this.deadCenter = bool);   // Subscription to deadCenter from other components
  }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

}
