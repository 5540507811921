
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfirmDialogComponent } from './confirm-dialog.component';
import { InputDialogComponent } from './input-dialog.component';
import { MessageDialogComponent } from './message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalRef: NgbModalRef;

  constructor(private _ngbModal: NgbModal) { }

  confirm(prompt = 'Really?'
    , title = 'Confirm'
    , btn_primary = true
    , btn_default = true
    , ok = 'Ok'
    , cancel = 'Cancel'): Observable<boolean> {

    const modal = this._ngbModal.open(ConfirmDialogComponent, { backdrop: 'static' });

    modal.componentInstance.prompt = prompt;
    modal.componentInstance.title = title;
    modal.componentInstance.btn_primary = btn_primary;
    modal.componentInstance.btn_default = btn_default;
    modal.componentInstance.ok = ok;
    modal.componentInstance.cancel = cancel;

    // modal.result.then((userResponse) => {
    //   console.log(`User's choice: ${userResponse}`)
    // }); 

    return from(modal.result).pipe(
      catchError(error => { // Esc key returns false
        //  console.warn(error);
        return of(undefined);
      })
    );
  }

  input(message: string, initialValue: string, title = 'Input'): Observable<string> {
    const modal = this._ngbModal.open(
      InputDialogComponent, { backdrop: 'static' });

    modal.componentInstance.message = message;
    modal.componentInstance.initialValue = initialValue;
    modal.componentInstance.title = title;

    return from(modal.result).pipe(
      catchError(error => {
        console.warn(error);
        return of(undefined);
      })
    );
  }

  message(msgBoxMessage: string
    , msgBoxTitle: string
    , msgBoxImgSrc: string
    , msgBoxImgAlt: string
    , msgBoxImgWidth?: string
    , msgBoxImgHeight?: string
    , engLang?: boolean
    , codeSearchResults?: any
    , codeType?: string
    , bodyBackgColr?: string
    , imgSrc?: string
    , deleteBtn?: boolean
    , printBtn?: boolean): Observable<boolean> {

    const modal = this._ngbModal.open(MessageDialogComponent, { backdrop: 'static' });

    modal.componentInstance.msgBoxMessage = msgBoxMessage;
    modal.componentInstance.msgBoxTitle = msgBoxTitle;
    modal.componentInstance.msgBoxImgSrc = msgBoxImgSrc;
    modal.componentInstance.msgBoxImgAlt = msgBoxImgAlt;
    modal.componentInstance.msgBoxImgWidth = msgBoxImgWidth;
    modal.componentInstance.msgBoxImgHeight = msgBoxImgHeight;
    modal.componentInstance.engLang = engLang;
    modal.componentInstance.codeSearchResults = codeSearchResults;
    modal.componentInstance.resultsView = codeSearchResults;
    modal.componentInstance.codeType = codeType;
    modal.componentInstance.bodyBackgColr = bodyBackgColr ? bodyBackgColr : '#e6e6e6';  // Supplied or default
    modal.componentInstance.imgSrc = imgSrc;
    modal.componentInstance.deleteBtn = deleteBtn;
    modal.componentInstance.printBtn = printBtn;

    return from(modal.result).pipe(
      catchError(error => { // Esc key returns false
        // console.warn(error);
        return of(undefined);
      })
    );
  }

  open(content, opts): Observable<string> {
    this.modalRef = this._ngbModal.open(content, opts);

    return from(this.modalRef.result).pipe(
      catchError(error => {
        console.warn('error', error);
        return of(undefined);
      })
    );
  }

}
