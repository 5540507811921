import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IReclaims, IReclaimsDet } from '../../interfaces/reclaims';
import { CkRegisterService } from 'src/app/ck-register/ck-register.service';
import { AppToastsService } from '../../app-toasts/app-toasts.service';
import { DataMessageService } from '../../data-message.service';
import { NgxMaskDirective } from 'ngx-mask';
import * as moment from 'moment';
import { Json2Pipe } from '../../json2.pipe';

@Component({
  selector: 'app-recl-xml-mmm-gobierno',
  standalone: true,
  imports: [FormsModule, NgxMaskDirective, Json2Pipe],
  templateUrl: './recl-xml-mmm-gobierno.component.html',
  styleUrls: ['./recl-xml-mmm-gobierno.component.css']
})
export class ReclXmlMmmGobiernoComponent implements OnDestroy {

  @Input() reclLineReasonEntered: any;
  @Input() reclaims: IReclaims[];
  @Input() reclaimsDet: IReclaimsDet[];
  @Input() idxSeld: number;
  @Input() reclFormImg64Seled: string;
  @Input() clrData: boolean = false;  // Set true after deleteing
  @Input() print: boolean = false;

  @Output() need2Save = new EventEmitter<string>();

  sn: string;
  engLang: boolean;
  userLastNm: string;
  userFirstNm: string;
  userID: string;
  casNo: string;
  currIndx: number = -1;
  e: any = {
    optMMM: '',
    optPMC: '',
    plan: '',
    txtMMM_PMC_Gob_ProvNm: '',
    txtMMM_PMC_Gob_NPI: '',

    txt3MPMC_ContrNo1: '',
    txt3MPMC_ContrNo2: '',
    txt3MPMC_ContrNo3: '',
    txt3MPMC_ContrNo4: '',
    txt3MPMC_ContrNo5: '',
    txt3MPMC_ContrNo6: '',
    txt3MPMC_ContrNo7: '',
    txt3MPMC_ContrNo8: '',
    txt3MPMC_ContrNo9: '',
    txt3MPMC_ContrNo10: '',
    txt3MPMC_ContrNo11: '',
    txt3MPMC_ContrNo12: '',
    txt3MPMC_ContrNo13: '',
    txt3MPMC_ContrNo14: '',

    txt3MPMC_ReclamNo1: '',
    txt3MPMC_ReclamNo2: '',
    txt3MPMC_ReclamNo3: '',
    txt3MPMC_ReclamNo4: '',
    txt3MPMC_ReclamNo5: '',
    txt3MPMC_ReclamNo6: '',
    txt3MPMC_ReclamNo7: '',
    txt3MPMC_ReclamNo8: '',
    txt3MPMC_ReclamNo9: '',
    txt3MPMC_ReclamNo10: '',
    txt3MPMC_ReclamNo11: '',
    txt3MPMC_ReclamNo12: '',
    txt3MPMC_ReclamNo13: '',
    txt3MPMC_ReclamNo14: '',

    txt3MPMC_Cpt1: '',
    txt3MPMC_Cpt2: '',
    txt3MPMC_Cpt3: '',
    txt3MPMC_Cpt4: '',
    txt3MPMC_Cpt5: '',
    txt3MPMC_Cpt6: '',
    txt3MPMC_Cpt7: '',
    txt3MPMC_Cpt8: '',
    txt3MPMC_Cpt9: '',
    txt3MPMC_Cpt10: '',
    txt3MPMC_Cpt11: '',
    txt3MPMC_Cpt12: '',
    txt3MPMC_Cpt13: '',
    txt3MPMC_Cpt14: '',

    txt3MPMC_Date1: '',
    txt3MPMC_Date2: '',
    txt3MPMC_Date3: '',
    txt3MPMC_Date4: '',
    txt3MPMC_Date5: '',
    txt3MPMC_Date6: '',
    txt3MPMC_Date7: '',
    txt3MPMC_Date8: '',
    txt3MPMC_Date9: '',
    txt3MPMC_Date10: '',
    txt3MPMC_Date11: '',
    txt3MPMC_Date12: '',
    txt3MPMC_Date13: '',
    txt3MPMC_Date14: '',

    txt3MPMC_Bal1: '',
    txt3MPMC_Bal2: '',
    txt3MPMC_Bal3: '',
    txt3MPMC_Bal4: '',
    txt3MPMC_Bal5: '',
    txt3MPMC_Bal6: '',
    txt3MPMC_Bal7: '',
    txt3MPMC_Bal8: '',
    txt3MPMC_Bal9: '',
    txt3MPMC_Bal10: '',
    txt3MPMC_Bal11: '',
    txt3MPMC_Bal12: '',
    txt3MPMC_Bal13: '',
    txt3MPMC_Bal14: '',

    txt3MPMC_Reason1: '',
    txt3MPMC_Reason2: '',
    txt3MPMC_Reason3: '',
    txt3MPMC_Reason4: '',
    txt3MPMC_Reason5: '',
    txt3MPMC_Reason6: '',
    txt3MPMC_Reason7: '',
    txt3MPMC_Reason8: '',
    txt3MPMC_Reason9: '',
    txt3MPMC_Reason10: '',
    txt3MPMC_Reason11: '',
    txt3MPMC_Reason12: '',
    txt3MPMC_Reason13: '',
    txt3MPMC_Reason14: '',

    txtMMM_PMC_Gob_Date: '',
    txtMMM_PMC_Gob_Tot: '',

    userNm: '',
    sn: '',
    casNo: ''
  }

  constructor(private _ckRegisterService: CkRegisterService,
    private _appToastsService: AppToastsService,
    private _dataMessageService: DataMessageService,
  ) { }

  ngOnInit(): void {
    this._dataMessageService.currentUserLastNmStr.subscribe(userLastNmStr => this.userLastNmChange(userLastNmStr)); // Subscription 
    this._dataMessageService.currentUserFirstNmStr.subscribe(userFirstNmStr => this.userFirstNmChange(userFirstNmStr)); // Subscription 
    this._dataMessageService.currentUserIdStr.subscribe(userIdStr => this.userIdChange(userIdStr)); // Subscription 
    this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang
    this._dataMessageService.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn

    this.decodeXmlRecl();
    this.setReclaimDefaults();  // reclaimsDet may be undefined
    this.setTotal();
    this.currIndx = 0;
    this.e.casNo = this.reclaims[this.idxSeld].reclCasNo;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.idxSeld) {
      this.clearData(); // Clear form data
      this.decodeXmlRecl();
      this.setReclaimDefaults();
      this.setTotal();
      this.currIndx = 0;
    }
    // Check if @Input() reclLineReasonEntered & others have changed
    if (changes.reclLineReasonEntered && this.reclLineReasonEntered) {
      console.log(this.reclLineReasonEntered);

      switch (this.currIndx) {
        case -1:
          this.currIndx = 0;
          break;
        case 0:
          this.e.txt3MPMC_ContrNo1 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo1 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt1 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date1 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal1 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason1 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 1:
          this.e.txt3MPMC_ContrNo2 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo2 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt2 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date2 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal2 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason2 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 2:
          this.e.txt3MPMC_ContrNo3 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo3 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt3 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date3 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal3 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason3 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 3:
          this.e.txt3MPMC_ContrNo4 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo4 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt4 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date4 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal4 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason4 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 4:
          this.e.txt3MPMC_ContrNo5 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo5 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt5 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date5 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal5 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason5 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 5:
          this.e.txt3MPMC_ContrNo6 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo6 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt6 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date6 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal6 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason6 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 6:
          this.e.txt3MPMC_ContrNo7 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo7 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt7 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date7 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal7 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason7 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 7:
          this.e.txt3MPMC_ContrNo8 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo8 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt8 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date8 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal8 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason8 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 8:
          this.e.txt3MPMC_ContrNo9 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo9 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt9 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date9 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal9 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason9 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 9:
          this.e.txt3MPMC_ContrNo10 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo10 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt10 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date10 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal10 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason10 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 10:
          this.e.txt3MPMC_ContrNo11 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo11 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt11 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date11 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal11 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason11 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 11:
          this.e.txt3MPMC_ContrNo12 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo12 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt12 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date12 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal12 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason12 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 12:
          this.e.txt3MPMC_ContrNo13 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo13 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt13 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date13 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal13 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason13 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        case 13:
          this.e.txt3MPMC_ContrNo14 = this.reclLineReasonEntered.contrNo;
          this.e.txt3MPMC_ReclamNo14 = this.reclLineReasonEntered.icn;
          this.e.txt3MPMC_Cpt14 = this.reclLineReasonEntered.cpt;
          this.e.txt3MPMC_Date14 = this.reclLineReasonEntered.from;
          this.e.txt3MPMC_Bal14 = +this.reclLineReasonEntered.bal.toFixed(2);
          this.e.txt3MPMC_Reason14 = this.reclLineReasonEntered.reason.trim();
          this.currIndx++;
          break;
        default:
      }
      this.setTotal();
    }
    if (changes.clrData?.currentValue) {
      this.clearData();
    }
    if (changes.print?.currentValue) {
      this.printReclaim();
    }
    if (changes.reclaimsDet?.currentValue) {
      let d: number = this.reclaimsDet.findIndex(det => det.reclDetICN === this.reclaims[this.idxSeld].reclICN);
      if (d > -1) {
        d = 0;
        this.e.txt3MPMC_ReclamNo1 = this.reclaimsDet[d].reclDetICN ? this.reclaimsDet[d].reclDetICN : this.reclaims[this.idxSeld].reclICN;
      }
      this.e.txt3MPMC_ContrNo1 = +this.reclaims[this.idxSeld].reclPS == 1 ? this.reclaims[0].reclContr1 : this.reclaims[0].reclContr2;
      this.setReclaimDefaults();  // Important
    }
  }

  ngOnDestroy(): void {
    this.currIndx = 0;
    this.e = undefined;
  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
  }

  userLastNmChange(userLastNmStr) {
    this.userLastNm = userLastNmStr;
  }

  userFirstNmChange(userFirstNmStr) {
    this.userFirstNm = userFirstNmStr;
  }

  userIdChange(userIdStr) {
    this.userID = userIdStr;
  }

  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  setReclaimDefaults(): void {
    const r: number = this.idxSeld;
    const d: number = this.reclaimsDet ? this.reclaimsDet.findIndex(det => det.reclDetICN === this.reclaims[r].reclICN) : -1;
    if (d > -1) {
      this.e.txtMMM_PMC_Gob_ProvNm = this.e.txtMMM_PMC_Gob_ProvNm ? this.e.txtMMM_PMC_Gob_ProvNm : this.reclaims[r].reclProv;
      this.e.txtMMM_PMC_Gob_NPI = this.e.txtMMM_PMC_Gob_NPI ? this.e.txtMMM_PMC_Gob_NPI : this.reclaims[r].reclNPI;
    }
    this.e.txtMMM_PMC_Gob_Date = this.e.txtMMM_PMC_Gob_Date ? this.e.txtMMM_PMC_Gob_Date : moment().format('MM/DD/YYYY');
  }

  onChange_need2Save(): void {
    this.setTotal();
    this.need2Save.emit('');
  }

  setTotal() {
    let tot: number = this.nul2num(this.e.txt3MPMC_Bal1) + this.nul2num(this.e.txt3MPMC_Bal2) + this.nul2num(this.e.txt3MPMC_Bal3) + this.nul2num(this.e.txt3MPMC_Bal4)
      + this.nul2num(this.e.txt3MPMC_Bal5) + this.nul2num(this.e.txt3MPMC_Bal6) + this.nul2num(this.e.txt3MPMC_Bal7) + this.nul2num(this.e.txt3MPMC_Bal8)
      + this.nul2num(this.e.txt3MPMC_Bal9) + this.nul2num(this.e.txt3MPMC_Bal10) + this.nul2num(this.e.txt3MPMC_Bal11) + this.nul2num(this.e.txt3MPMC_Bal12)
      + this.nul2num(this.e.txt3MPMC_Bal13) + this.nul2num(this.e.txt3MPMC_Bal14);
    this.e.txtMMM_PMC_Gob_Tot = tot.toFixed(2);
  }

  nul2num(s: string): number {
    if (typeof s === 'number' || +s) {
      return +s;
    } else {
      return 0;
    }
  }

  onClick(event): void {
    if (event && event.target.id) {
      const i = event.target.id.match(/\d$/g);
      if (i) {
        this.currIndx = +i[0] - 1;
      }
    }
  }

  clearData(): void { // Clears all elements with default data.
    Object.keys(this.e).forEach(key => this.e[key] = '');
    this.setReclaimDefaults();
  }

  decodeXmlRecl(): void {
    const r: number = this.idxSeld;

    if (r >= 0 && this.reclaims) {
      let parser = new DOMParser();
      let xmlDoc;
      if (!this.reclaims[r].reclXml) {
        setTimeout(() => {
          this.reclaims[r].reclXml = this.blankXml();
        }, 0);
        return;
      }
      xmlDoc = parser.parseFromString(this.reclaims[r].reclXml, "text/xml");

      for (let i = 0; i < xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes.length; i++) {
        let fullNod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i];
        let nod = xmlDoc.getElementsByTagName("ReclaimFormFields")[0].childNodes[i].nodeName;

        if (nod.match(/txt\w+/g) || nod.match(/chk\w+/g) || nod.match(/opt\w+/g)) {
          let val = fullNod.textContent;

          switch (nod) {
            case 'optMMM':
              if (+val === 1 || val === 'True') {
                this.e.plan = 'mmm';
              }
              break;
            case 'optPMC':
              if (+val === 1 || val === 'True') {
                this.e.plan = 'pmc';
              }
              break;
            default:
              if (nod.match(/txt\w+/g)) {
                this.e[nod] = val.replace(/^%/, '');
              }
          }
        }
      }
    }
  }

  blankXml(): string {
    return '<ReclaimFormFields><optMMM></optMMM><optPMC></optPMC><txtMMM_PMC_Gob_ProvNm>%</txtMMM_PMC_Gob_ProvNm><txtMMM_PMC_Gob_NPI>%</txtMMM_PMC_Gob_NPI><txtMMM_PMC_Gob_Date>%</txtMMM_PMC_Gob_Date><txtMMM_PMC_Gob_Tot>%</txtMMM_PMC_Gob_Tot>'
      + '<txt3MPMC_ContrNo1>%</txt3MPMC_ContrNo1><txt3MPMC_ReclamNo1>%</txt3MPMC_ReclamNo1><txt3MPMC_Cpt1>%</txt3MPMC_Cpt1><txt3MPMC_Date1>%</txt3MPMC_Date1><txt3MPMC_Bal1>%</txt3MPMC_Bal1><txt3MPMC_Reason1>%</txt3MPMC_Reason1>'
      + '<txt3MPMC_ContrNo2>%</txt3MPMC_ContrNo2><txt3MPMC_ReclamNo2>%</txt3MPMC_ReclamNo2><txt3MPMC_Cpt2>%</txt3MPMC_Cpt2><txt3MPMC_Date2>%</txt3MPMC_Date2><txt3MPMC_Bal2>%</txt3MPMC_Bal2><txt3MPMC_Reason2>%</txt3MPMC_Reason2>'
      + '<txt3MPMC_ContrNo3>%</txt3MPMC_ContrNo3><txt3MPMC_ReclamNo3>%</txt3MPMC_ReclamNo3><txt3MPMC_Cpt3>%</txt3MPMC_Cpt3><txt3MPMC_Date3>%</txt3MPMC_Date3><txt3MPMC_Bal3>%</txt3MPMC_Bal3><txt3MPMC_Reason3>%</txt3MPMC_Reason3>'
      + '<txt3MPMC_ContrNo4>%</txt3MPMC_ContrNo4><txt3MPMC_ReclamNo4>%</txt3MPMC_ReclamNo4><txt3MPMC_Cpt4>%</txt3MPMC_Cpt4><txt3MPMC_Date4>%</txt3MPMC_Date4><txt3MPMC_Bal4>%</txt3MPMC_Bal4><txt3MPMC_Reason4>%</txt3MPMC_Reason4>'
      + '<txt3MPMC_ContrNo5>%</txt3MPMC_ContrNo5><txt3MPMC_ReclamNo5>%</txt3MPMC_ReclamNo5><txt3MPMC_Cpt5>%</txt3MPMC_Cpt5><txt3MPMC_Date5>%</txt3MPMC_Date5><txt3MPMC_Bal5>%</txt3MPMC_Bal5><txt3MPMC_Reason5>%</txt3MPMC_Reason5>'
      + '<txt3MPMC_ContrNo6>%</txt3MPMC_ContrNo6><txt3MPMC_ReclamNo6>%</txt3MPMC_ReclamNo6><txt3MPMC_Cpt6>%</txt3MPMC_Cpt6><txt3MPMC_Date6>%</txt3MPMC_Date6><txt3MPMC_Bal6>%</txt3MPMC_Bal6><txt3MPMC_Reason6>%</txt3MPMC_Reason6>'
      + '<txt3MPMC_ContrNo7>%</txt3MPMC_ContrNo7><txt3MPMC_ReclamNo7>%</txt3MPMC_ReclamNo7><txt3MPMC_Cpt7>%</txt3MPMC_Cpt7><txt3MPMC_Date7>%</txt3MPMC_Date7><txt3MPMC_Bal7>%</txt3MPMC_Bal7><txt3MPMC_Reason7>%</txt3MPMC_Reason7>'
      + '<txt3MPMC_ContrNo8>%</txt3MPMC_ContrNo8><txt3MPMC_ReclamNo8>%</txt3MPMC_ReclamNo8><txt3MPMC_Cpt8>%</txt3MPMC_Cpt8><txt3MPMC_Date8>%</txt3MPMC_Date8><txt3MPMC_Bal8>%</txt3MPMC_Bal8><txt3MPMC_Reason8>%</txt3MPMC_Reason8>'
      + '<txt3MPMC_ContrNo9>%</txt3MPMC_ContrNo9><txt3MPMC_ReclamNo9>%</txt3MPMC_ReclamNo9><txt3MPMC_Cpt9>%</txt3MPMC_Cpt9><txt3MPMC_Date9>%</txt3MPMC_Date9><txt3MPMC_Bal9>%</txt3MPMC_Bal9><txt3MPMC_Reason9>%</txt3MPMC_Reason9>'
      + '<txt3MPMC_ContrNo10>%</txt3MPMC_ContrNo10><txt3MPMC_ReclamNo10>%</txt3MPMC_ReclamNo10><txt3MPMC_Cpt10>%</txt3MPMC_Cpt10><txt3MPMC_Date10>%</txt3MPMC_Date10><txt3MPMC_Bal10>%</txt3MPMC_Bal10><txt3MPMC_Reason10>%</txt3MPMC_Reason10>'
      + '<txt3MPMC_ContrNo11>%</txt3MPMC_ContrNo11><txt3MPMC_ReclamNo11>%</txt3MPMC_ReclamNo11><txt3MPMC_Cpt11>%</txt3MPMC_Cpt11><txt3MPMC_Date11>%</txt3MPMC_Date11><txt3MPMC_Bal11>%</txt3MPMC_Bal11><txt3MPMC_Reason11>%</txt3MPMC_Reason11>'
      + '<txt3MPMC_ContrNo12>%</txt3MPMC_ContrNo12><txt3MPMC_ReclamNo12>%</txt3MPMC_ReclamNo12><txt3MPMC_Cpt12>%</txt3MPMC_Cpt12><txt3MPMC_Date12>%</txt3MPMC_Date12><txt3MPMC_Bal12>%</txt3MPMC_Bal12><txt3MPMC_Reason12>%</txt3MPMC_Reason12>'
      + '<txt3MPMC_ContrNo13>%</txt3MPMC_ContrNo13><txt3MPMC_ReclamNo13>%</txt3MPMC_ReclamNo13><txt3MPMC_Cpt13>%</txt3MPMC_Cpt13><txt3MPMC_Date13>%</txt3MPMC_Date13><txt3MPMC_Bal13>%</txt3MPMC_Bal13><txt3MPMC_Reason13>%</txt3MPMC_Reason13>'
      + '<txt3MPMC_ContrNo14>%</txt3MPMC_ContrNo14><txt3MPMC_ReclamNo14>%</txt3MPMC_ReclamNo14><txt3MPMC_Cpt14>%</txt3MPMC_Cpt14><txt3MPMC_Date14>%</txt3MPMC_Date14><txt3MPMC_Bal14>%</txt3MPMC_Bal14><txt3MPMC_Reason14>%</txt3MPMC_Reason14>'
      + '</ReclaimFormFields>';
  }

  printReclaim(): void {
    this.e.plan === 'mmm' ? this.e.optMMM = 'X' : this.e.optMMM = '';
    this.e.plan === 'pmc' ? this.e.optPMC = 'X' : this.e.optPMC = '';
    this.e.userNm = this.userLastNm + ', ' + this.userFirstNm + ' (' + this.userID + ')';
    this.e.sn = this.sn;
    // this.e.casNo = this.casNo;
    this.e.txtMMM_PMC_Gob_Tot = this.setTotal();

    let data: any = {
      "e": this.e,
    };
    let bdy = {
      "template":
      {
        "name": "/reclaimForms/mmm_pmc_gobierno/mmm_pmc_gobierno-html"
      }, "data": data,
      "options": { "reports": { "save": true } }
    };

    this._ckRegisterService.postRenderJsRpt(bdy).subscribe({
      next: blob => { this.backFromPrintRpt(blob) },
      error: (error: any) => { this.backFromServerError(error) }
    });
  }

  backFromPrintRpt(blob: Blob): void {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
      let win = window.open();
      win.document.open();
      win.document.write(iframe);
      win.document.close();
    };
  }

  backFromServerError(error): void {
    this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
    this._appToastsService.show(error.message, { header: 'Error preparing report * Error preparando reporte', autohide: false, error: true });
  }
}
