import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { IAppointments } from "../shared/interfaces/appointments";

@Pipe({
    name: 'agendaPipe',
    pure: false    // true is stateless & faster but will not detect changes; false is dynamic and detects changes in input array
})
export class AgendaPipe implements PipeTransform {
    transform(input: IAppointments[], dayFilter: string, hiddenFilter: boolean, grpFilter: boolean, grpStartTm: string, grpEndTm: string): IAppointments[] {  // cast the output to IAppointments
        if (!input || !dayFilter) {
            return input;
        }
        return input.filter(appt => {
            if (hiddenFilter == undefined) {
                return appt.d == dayFilter;
            } else {
                if (grpFilter) {
                    const startTm = moment(grpStartTm, 'HH:mm');
                    const endTm = moment(grpEndTm, 'HH:mm');
                    const dt = moment.utc(appt.start);  // utc important here or dt will be local time off by -4 hrs if PR
                    const hh: string = ('0' + dt.get('hour').toString()).match(/\d{2}$/g)[0];
                    const mm: string = ('0' + dt.get('minute').toString()).match(/\d{2}$/g)[0];

                    // To ck if hh & mm match hour:minute in appt.start
                    // console.log(hh + ':' + mm);
                    // console.log(appt.start);

                    const ckTm = moment(hh + ':' + mm, 'HH:mm');    // Moment obj ot group time to ck

                    // To ck if it works
                    // console.log(ckTm.isBetween(startTm, endTm));
                    // console.log(appt.patLastNm);
                    // console.log('+')

                    return appt.d == dayFilter && appt.hidden == hiddenFilter
                        && ckTm.isBetween(startTm, endTm);
                } else {
                    return appt.d == dayFilter && appt.hidden == hiddenFilter;
                }
            }
        });
    }
}