// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.placeholder {
    text-align: center;
}

::-webkit-input-placeholder {
  font-style: italic;
  font-weight: normal;
  font-size: 75%;
  text-align: center;
}
:-moz-placeholder {
  font-style: italic; 
  font-weight: normal;
  font-size: 75%; 
  text-align: center;
}
::-moz-placeholder {
  font-style: italic; 
  font-weight: normal; 
  font-size: 75%;
}
:-ms-input-placeholder {  
  font-style: italic; 
  font-weight: normal;
  font-size: 75%;
  text-align: center;
}

.uppercase { 
  text-transform: uppercase; 
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["input.placeholder {\r\n    text-align: center;\r\n}\r\n\r\n::-webkit-input-placeholder {\r\n  font-style: italic;\r\n  font-weight: normal;\r\n  font-size: 75%;\r\n  text-align: center;\r\n}\r\n:-moz-placeholder {\r\n  font-style: italic; \r\n  font-weight: normal;\r\n  font-size: 75%; \r\n  text-align: center;\r\n}\r\n::-moz-placeholder {\r\n  font-style: italic; \r\n  font-weight: normal; \r\n  font-size: 75%;\r\n}\r\n:-ms-input-placeholder {  \r\n  font-style: italic; \r\n  font-weight: normal;\r\n  font-size: 75%;\r\n  text-align: center;\r\n}\r\n\r\n.uppercase { \r\n  text-transform: uppercase; \r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
