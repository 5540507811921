import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebsocketService } from 'src/app/shared/websocket.service';

interface ICkDet {
  CkDetID: string
  , CkDetCkgID: string
  , CkDetPayID: string
  , PatNm: string
  , CasCont1: string
  , CasCont2: string
  , CasNo: string
  , PayPS: string
  , PayAmnt: string
  , PayDt: string
  , PayMode: string
  , PayCasID: string
  , PayID: string
  , FromDt: string
  , DetPcode: string
  , DetMod: string
  , allowDelete: string
  , ProvNPI: string
  , PayerID: string
  , ProvLNm: string
  , InLNm: string
  , sqlProcNm: string
}

interface IPayAdjDet {
  id: string,
  dt: string,
  amnt: string,
  desc: string,
  mode: string,
  ps: string,
}

@Component({
  selector: 'app-ck-register-detail',
  templateUrl: './ck-register-detail.component.html',
  styleUrls: ['./ck-register-detail.component.css']
})
export class CkRegisterDetailComponent implements OnInit {
  @Input() sn: string;
  @Input() engLang: boolean;
  @Input() ckgID: string;
  @Input() ckgNo: string;
  @Input() ckgDt: string;
  @Output() payDeletedEvent = new EventEmitter<any>();
  @Output() showChildPaymentsEvent = new EventEmitter<boolean>();

  @ViewChild('ckRegClaimsPaymentDetailModal', { read: TemplateRef, static: true }) ckRegClaimsPaymentDetailModal: TemplateRef<any>;
  @ViewChild('ckRegClaimsPaymentDeleteModal', { read: TemplateRef, static: true }) ckRegClaimsPaymentDeleteModal: TemplateRef<any>;

  sio: boolean = false;   // true when len(sn) = 4 using socket io in hybrid mode & false when using all web api access.
  ckDetails: ICkDet[];
  payAdjDetails: IPayAdjDet[];
  seledCkDetIndx: number;
  tPayAmnt: number = 0;
  tCasCnt: number = 0;

  sioSubscr: Subscription;
  waiting4Response: boolean = false;  // for visual feedback of ajax calls
  currentPageCksDet: number = 1;
  modalStack: string[] = [];

  constructor(private _websocketService: WebsocketService,
    private _modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.sio = this.sn.length > 4 ? false : true;

    if (this.sio) {
      this.sioSubscr = this._websocketService.getMessages().subscribe((dataSet) => {

        if (dataSet?.length) {
          console.log('%c' + 'dataSet in CkRegisterDetailComponent.ngOnInit()', 'color: green; background: yellow; font-size: 12px');
          console.log('dataSet', dataSet);

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_GetCkRegisterDetail') {
            this.ckDetails = dataSet;
            const idx = this.ckDetails.findIndex((ckDet) => ckDet.CkDetID === '0');
            if (idx >= 0) {
              this.ckDetails.splice(idx, 1);
            }
            this.updateTpayed();

          } else if (dataSet[0]?.sqlProcNm == 'spMB_Sio_GetPayAdjustments') {
            this.payAdjDetails = [];
            if (this.seledCkDetIndx >= 0 && this.ckDetails[this.seledCkDetIndx].PayID) {
              this.payAdjDetails.push({
                id: this.ckDetails[this.seledCkDetIndx].PayID,
                dt: this.ckDetails[this.seledCkDetIndx].PayDt,
                amnt: this.ckDetails[this.seledCkDetIndx].PayAmnt,
                desc: (this.engLang ? 'PAYED' : 'PAGO') + ' Ck#: ' + this.ckgNo,
                mode: this.ckDetails[this.seledCkDetIndx].PayMode,
                ps: this.ckDetails[this.seledCkDetIndx].PayPS
              });
            }

            for (let p = 0; p < dataSet.length; p++) {
              if (dataSet[p].DetID > 0) {
                this.payAdjDetails.push({
                  id: dataSet[p].DetID,
                  dt: dataSet[p].DetFrom,
                  amnt: dataSet[p].DetXpect,
                  desc: dataSet[p].DetDesc,
                  mode: dataSet[p].mode,
                  ps: dataSet[p].DetPS
                });
              }
            }

          } else if (dataSet[0]?.sqlProcNm == 'spMB_Sio_DeletePayAdjs') {
            if (dataSet[0].payAdjsRowsDeleted > 0 && this.seledCkDetIndx >= 0) {
              const i = this.ckDetails.findIndex(adj => { return adj.PayID == this.ckDetails[this.seledCkDetIndx].PayID });
              if (i >= 0) {
                this.payDeletedEvent.emit({ 'pay': this.ckDetails[this.seledCkDetIndx].PayAmnt, 'children': dataSet[0].ckChildren });  // Send back payment deleted to update checks[] table in parent
                this.ckDetails.splice(i, 1); // Deletes the case payment row
              }
              this.updateTpayed();
            }
          }

        } else {
          if (this.seledCkDetIndx) {
            if (this.ckDetails[this.seledCkDetIndx].PayDt && this.ckDetails[this.seledCkDetIndx].PayAmnt) {
              this.payAdjDetails = [];
              if (this.seledCkDetIndx >= 0 && +this.ckDetails[this.seledCkDetIndx].PayID > 0) {
                this.payAdjDetails.push({
                  id: this.ckDetails[this.seledCkDetIndx].PayID,
                  dt: this.ckDetails[this.seledCkDetIndx].PayDt,
                  amnt: this.ckDetails[this.seledCkDetIndx].PayAmnt,
                  desc: (this.engLang ? 'PAYED' : 'PAGO') + ' Ck#: ' + this.ckgNo,
                  mode: this.ckDetails[this.seledCkDetIndx].PayMode,
                  ps: this.ckDetails[this.seledCkDetIndx].PayPS
                });
              }
            }
          }
        }

        this.waiting4Response = false;
      });
    }

    console.log("%c" + "cks-details q = EXEC spMB_Sio_GetCkRegisterDetail "
      + "@sn = '" + this.sn
      + "', @ckgID = '" + this.ckgID + "';", "color: black; background: #90EE90; font-size: 12px");
    this.waiting4Response = true;
    this._websocketService.sendChat("query", this.sn, "EXEC spMB_Sio_GetCkRegisterDetail "
      + "@sn = '" + this.sn
      + "', @ckgID = '" + this.ckgID + "';");
  }

  ngAfterViewInit(): void {
    this.open(this.ckRegClaimsPaymentDetailModal, { backdrop: 'static', size: 'md', scrollable: false }, 'ckRegClaimsPaymentDetailModal');
  }

  ngOnDestroy() {
    this.sioSubscr.unsubscribe();
    console.log('%c' + 'UNSUSCRIBE-CkRegDetailComponent', 'color: white; background: black; font-size: 10px');
  }

  onKeyup_escapeShowDeleteModal(event, btn): void {
    if (event.keyCode == 27) {
      event.stopPropagation();
      document.getElementById(btn).click();  // Toggle off
      let parentModal = <HTMLElement>document.getElementById('ckRegClaimPayDetailModal');
      parentModal.focus();
    }
  }

  onClick_promptDeletePayment(ckdetid: string): void {
    this.seledCkDetIndx = this.ckDetails.findIndex(det => { return det.CkDetID === ckdetid });

    console.log('%c' + 'cks-details-adjs q = EXEC spMB_Sio_GetPayAdjustments '
      + "@sn = '" + this.sn
      + "', @casID = '" + this.ckDetails[this.seledCkDetIndx].PayCasID
      + "', @payPS = '" + this.ckDetails[this.seledCkDetIndx].PayPS.replace(/\D*/g, '')
      + "', @ckNo = '" + this.ckgNo + "';"
      , 'color: black; background: #90EE90; font-size: 12px');

    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, "EXEC spMB_Sio_GetPayAdjustments "
      + "@sn = '" + this.sn
      + "', @casID = '" + this.ckDetails[this.seledCkDetIndx].PayCasID
      + "', @payPS = '" + this.ckDetails[this.seledCkDetIndx].PayPS.replace(/\D*/g, '')
      + "', @ckNo = '" + this.ckgNo + "';");

    this.open(this.ckRegClaimsPaymentDeleteModal, { backdrop: 'static', size: 'md', scrollable: false }, 'ckRegClaimsPaymentDeleteModal');
  }

  onClick_deletePayment(): void {
    console.log('%c' + 'cks-details-deletePayment q = EXEC spMB_Sio_DeletePayAdjs '
      + "@sn = '" + this.sn
      + "', @ckgNo = '" + this.ckgNo
      + "', @casID='" + this.ckDetails[this.seledCkDetIndx].PayCasID
      + "', @payID='" + this.ckDetails[this.seledCkDetIndx].PayID
      + "', @payPS='" + this.ckDetails[this.seledCkDetIndx].PayPS
      + "', @ckDetID='" + this.ckDetails[this.seledCkDetIndx].CkDetID
      + "', @ckgID='" + this.ckDetails[this.seledCkDetIndx].CkDetCkgID + "';"
      , 'color: black; background: #90EE90; font-size: 12px');

    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, "EXEC spMB_Sio_DeletePayAdjs "
      + "@sn = '" + this.sn
      + "', @ckgNo = '" + this.ckgNo
      + "', @casID='" + this.ckDetails[this.seledCkDetIndx].PayCasID
      + "', @payID='" + this.ckDetails[this.seledCkDetIndx].PayID
      + "', @payPS='" + this.ckDetails[this.seledCkDetIndx].PayPS
      + "', @ckDetID='" + this.ckDetails[this.seledCkDetIndx].CkDetID
      + "', @ckgID='" + this.ckDetails[this.seledCkDetIndx].CkDetCkgID + "';");
  }

  open(content, opts, modalNm) {
    this.modalStack.push(modalNm);
    this._modalService.open(content, opts).result.then((result) => {
      console.log(this.modalStack);
      console.log('%c' + "modal closed result=" + result, 'color: black; background: yellow; font-size: 12px');

      if (result === 'oK_closePayDetailModal') {
        this.showChildPaymentsEvent.emit(false);
        this.modalStack.pop();
        return;
      }
    }, (reason) => {
      console.log(this.modalStack);
      console.log('%c' + "modal dismissed reason=" + reason, 'color: black; background: yellow; font-size: 12px');

      switch (modalNm) {
        case 'ckRegClaimsPaymentDetailModal':
          this.showChildPaymentsEvent.emit(false);
          break;
        default:
      }

      this.modalStack.pop();
    });
  }

  updateTpayed() {
    let clmArr: string[] = [];
    this.tPayAmnt = 0;
    this.tCasCnt = 0;
    this.ckDetails.forEach(pay => {
      if (!clmArr.includes(pay.CasNo)) {
        clmArr.push(pay.CasNo);
      }
      this.tPayAmnt += +pay['PayAmnt'].replace(',', '')
    });
    this.tCasCnt = clmArr.length;
    this.currentPageCksDet = 1;
  }

}
