import { Injectable } from '@angular/core';
import { EMPTY, Observable, interval, of, timer } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  sn: string;

  checkForRemotelyCapturedSignatureSio(sqlQry: string): Observable<any> {
    return timer(0, 10000).pipe(  // Emit value immediately and then every 30 seconds
      switchMap(() => this.checkSignatureSaved(sqlQry))
    );
  }

  private checkSignatureSaved(sqlQry: string): Observable<any> {
    this._websocketService.sendChat('query', this.sn, sqlQry);
    // Assuming that sendChat is an asynchronous operation,
    // we can return an observable that completes after sending the message.
    return EMPTY; // EMPTY is an observable that immediately completes.
  }

  constructor(private _websocketService: WebsocketService) { }
}

