/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 * It handles & manipulates the INPUT.
 */

import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {  // Called when using the keyboard
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: (+date[2] > 99 ? +date[2].substr(2, 4) : parseInt(date[2], 10)),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {  // Date displayed in datepicker as MM/DD/yy
    return date ? date.month.toString().padStart(2, '0')
      + this.DELIMITER + date.day.toString().padStart(2, '0')
      + this.DELIMITER + (date.year > 99 ? date.year.toString().substring(2, 4).padStart(2, '0') : date.year.toString().padStart(2, '0'))
      : null;
  }

}
