import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'selectedServiceItemPipe',
  pure: false    // true is stateless & faster but will not detect changes; false is dynamic and detects changes in input array
})
export class SelectedServiceItemPipe implements PipeTransform {
    transform(input: any[]): any[] {  // cast the input to IAppointments
        if (!input) {
            return [];
        }        
        return input.filter(item => {
            return item.selected;
        });
    }
}