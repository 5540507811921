import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DataMessageService } from '../data-message.service';
import { ISite } from '../interfaces/site';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { WebsocketService } from '../websocket.service';

@Component({
  selector: 'app-q837',
  templateUrl: './q837.component.html',
  styleUrls: ['./q837.component.css']
})
export class Q837Component {
  @Output() setS837errCnt: EventEmitter<number> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;

  sn: string;
  engLang: boolean;
  sioSubscrpt: Subscription;
  spinner: boolean = false; // Turns on spinner while io is active
  s837errCnt: number = 0;
  s837ErrArr: string[];
  delm: string = '>~>';

  site: ISite = {
    pk: undefined,
    nm: undefined,
    ad1: undefined,
    ad2: undefined,
    ct: undefined,
    st: undefined,
    zp: undefined,
    tl1: undefined,
    xt1: undefined,
    tl2: undefined,
    xt2: undefined,
    em1: undefined,
    em2: undefined
  }

  clms: [{
    'patLst': string,
    'patFst': string,
    'casDt': string,
    'casContr': string,
    'casNo': string,
    'usual': string,
    'xpect': string,
    'prov': string,
    'plan': string,
    'ps': string,
    'stat': string,
    'expandCol': string
  }];
  dataSource: MatTableDataSource<any>;
  displydCols: string[] = [
    'patLst',
    'patFst',
    'casDt',
    'casContr',
    'casNo',
    'usual',
    'xpect',
    'prov',
    'plan',
    'ps',
    'stat'
  ];
  tot = {
    'patLst': '',
    'patFst': '',
    'casDt': '',
    'casContr': '',
    'casNo': '',
    'usual': '0',
    'xpect': '0',
    'prov': '',
    'plan': '',
    'ps': '',
    'stat': ''
  }

  constructor(
    private _dmsg: DataMessageService,
    private _ws: WebsocketService
  ) { }

  ngOnInit(): void {
    this._dmsg.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
    this._dmsg.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang
    this._dmsg.currentSiteStr.subscribe(siteStr => this.siteChange(siteStr)); // Subscription looking for changes in siteName
    this._dmsg.currentSiteAd1Str.subscribe(siteAd1Str => this.siteAd1Change(siteAd1Str)); // Subscription looking for changes in siteAd1
    this._dmsg.currentSiteAd2Str.subscribe(siteAd2Str => this.siteAd2Change(siteAd2Str)); // Subscription looking for changes in siteAd2
    this._dmsg.currentSiteCtStr.subscribe(siteCtStr => this.siteCtChange(siteCtStr)); // Subscription looking for changes in siteCt
    this._dmsg.currentSiteStStr.subscribe(siteStStr => this.siteStChange(siteStStr)); // Subscription looking for changes in siteSt
    this._dmsg.currentSiteZpStr.subscribe(siteZpStr => this.siteZpChange(siteZpStr)); // Subscription looking for changes in siteZp
    this._dmsg.currentSiteTl1Str.subscribe(siteTl1Str => this.siteTl1Change(siteTl1Str)); // Subscription looking for changes in siteTl1
    this._dmsg.currentSiteXt1Str.subscribe(siteXt1Str => this.siteXt1Change(siteXt1Str)); // Subscription looking for changes in siteXt1
    this._dmsg.currentSiteTl2Str.subscribe(siteTl2Str => this.siteTl2Change(siteTl2Str)); // Subscription looking for changes in siteTl2
    this._dmsg.currentSiteXt2Str.subscribe(siteXt2Str => this.siteXt2Change(siteXt2Str)); // Subscription looking for changes in siteXt2

    this.sioSubscrpt = this._ws.getMessages().subscribe((dataSet) => { // Sets listenning events

      console.log('%c' + 'dataSet Q837Component', 'color: green; background: yellow; font-size: 14px');
      console.log(dataSet[0]?.sqlProcNm, dataSet);

      if (dataSet?.length > 0) {

        if (dataSet[0]?.sqlProcNm == 'spMB_Sio_GetQ837') {
          this.clms = dataSet;
          let arr: any[] = this.clms.slice();
          let regex = new RegExp(this.delm + '$', "g");
          arr.forEach(e => e['errArr'] = e.sErrs.replace(regex, '').split(this.delm));  // Prepare errors for display
          this.setS837errCnt.emit(arr.filter(c => { return c.sErrs }).length);
          this.dataSource = new MatTableDataSource(arr);
          this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow synced



          this.spinner = false;

        }
      }

    });

    console.log('%c' + 'query @ ngOnInit:' + "Exec spMB_Sio_GetQ837 @sn = '" + this.sn + "';", 'color: black; background: yellow; font-size: 18px');
    this._ws.sendChat('query', this.sn, "Exec spMB_Sio_GetQ837 @sn = '" + this.sn + "';");
    this.spinner = true;

  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
  }

  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  siteChange(siteStr: string) { // for when site changes
    this.site.nm = siteStr;
  }

  siteAd1Change(siteAd1Str: string) { // for when site changes
    this.site.ad1 = siteAd1Str;
  }

  siteAd2Change(siteAd2Str: string) { // for when site changes
    this.site.ad2 = siteAd2Str;
  }

  siteCtChange(siteCtStr: string) { // for when site changes
    this.site.ct = siteCtStr;
  }

  siteStChange(siteStStr: string) { // for when site changes
    this.site.st = siteStStr;
  }

  siteZpChange(siteZpStr: string) { // for when site changes
    this.site.zp = siteZpStr;
  }

  siteTl1Change(siteTl1Str: string) { // for when site changes
    this.site.tl1 = siteTl1Str;
  }

  siteXt1Change(siteXt1Str: string) { // for when site changes
    this.site.xt1 = siteXt1Str;
  }

  siteTl2Change(siteTl2Str: string) { // for when site changes
    this.site.tl2 = siteTl2Str;
  }

  siteXt2Change(siteXt2Str: string) { // for when site changes
    this.site.xt2 = siteXt2Str;
  }

  siteEm1Change(siteEm1Str: string) { // for when site changes
    this.site.em1 = siteEm1Str;
  }

  sortea(sort) {

  }

  isExpanded(row) {
    return row.expandCol;
  }

  onClick_expandErrs(clm) {
    if (clm.sErrs) {
      if (!clm.expandCol) {
        clm.expandCol = 'true';
      } else {
        clm.expandCol = '';
      }
    }
  }

}
