import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'casSeqNoPipe'
})
export class CasSeqNoPipe implements PipeTransform {

  transform(input: any[], casSeqNo: string, svcSeqNo: string): any[] {
    if (!input) {
      return input;
    }
    return input.filter(obj => {
      if (obj.hasOwnProperty('svcCasSeqNo') && obj.svcCasSeqNo == casSeqNo) {
        return input; // From <Svc></Svc> segment in [PayFileXml] col [PayFiles] table
      }
      if (obj.hasOwnProperty('adjSvcSeqNo') && obj.adjCasAdjSvcSeqNo == casSeqNo && obj.adjSvcSeqNo == svcSeqNo) {
        return input; // Adjustments from <Svc></Svc> segment in [PayFileXml] col [PayFiles] table
      }
      if (obj.hasOwnProperty('adjSvcSeqNo') && obj.adjSvcSeqNo == svcSeqNo && obj.adjCasAdjSvcSeqNo == svcSeqNo) {
        return input; // Adjustments from <CasAdj></CasAdj> segment in [PayFileXml] col [PayFiles] table
      }
      return null;
    });
  }

}
