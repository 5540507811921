// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rtalign {
    text-align: right;
}

.ltalign {
    text-align: left;
}

.font10 {
    font-size: 10px;
}

.font11 {
    font-size: 11px;
}

.clmRow {
    height: 25px;
}

.clmRow:hover {
    background-color: #e6e6e6 !important;
    cursor: pointer;
}

.expandedRw {
    font-style: italic;
    background-color: rgb(241, 202, 162);
}

.collapsedRow {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/q837/q837.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,oCAAoC;AACxC;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".rtalign {\r\n    text-align: right;\r\n}\r\n\r\n.ltalign {\r\n    text-align: left;\r\n}\r\n\r\n.font10 {\r\n    font-size: 10px;\r\n}\r\n\r\n.font11 {\r\n    font-size: 11px;\r\n}\r\n\r\n.clmRow {\r\n    height: 25px;\r\n}\r\n\r\n.clmRow:hover {\r\n    background-color: #e6e6e6 !important;\r\n    cursor: pointer;\r\n}\r\n\r\n.expandedRw {\r\n    font-style: italic;\r\n    background-color: rgb(241, 202, 162);\r\n}\r\n\r\n.collapsedRow {\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
