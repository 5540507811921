// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dlg-close {
    display: flex;
    /* Allow horizontal positioning */
    justify-content: flex-end;
    /* Align to the right */
    position: absolute;
    /* Make it absolute for bottom positioning */
    bottom: 0;
    /* Position at the bottom */
    width: 100%;
    /* Span the full width (optional) */
}`, "",{"version":3,"sources":["webpack://./src/app/shared/mat-msg-dlg/mat-msg-dlg.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iCAAiC;IACjC,yBAAyB;IACzB,uBAAuB;IACvB,kBAAkB;IAClB,4CAA4C;IAC5C,SAAS;IACT,2BAA2B;IAC3B,WAAW;IACX,mCAAmC;AACvC","sourcesContent":[".mat-dlg-close {\r\n    display: flex;\r\n    /* Allow horizontal positioning */\r\n    justify-content: flex-end;\r\n    /* Align to the right */\r\n    position: absolute;\r\n    /* Make it absolute for bottom positioning */\r\n    bottom: 0;\r\n    /* Position at the bottom */\r\n    width: 100%;\r\n    /* Span the full width (optional) */\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
